import { Box, Button, Divider, Grid, Theme, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import BreadCrumb_Icon from '../../../assets/breadcrumb-icon.svg';
import VerifiedIcon from '../../../assets/verified-cart-icon.png';
import useUserPermission from '../../../hooks/useUserPermission';
import { Investorcard } from '../../auth/login/styles/styledComponents';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import { navigateBasedonUserPermission } from '../../common/constants';
import { ConfirmationProfile } from '../../common/InvestorProfile';
import { startLoading, stopLoading } from '../../common/Loader/loaderSlice';
import PanDetailsBox from '../../common/PanDetails';
import { useRootContext } from '../../data/root.context';
import { StepperNextButton } from '../../onboarding/styles/styledComponents';
import AdditionalPurchaseConfirmation from '../../transactions/additionalPurchase/Confirmation';
import { CheckoutPaymentFormValues } from '../../transactions/additionalPurchase/types';
import RedeemConfirmation from '../../transactions/redeem/Confirmation';
import {
  useAddItemInSwpMutation,
  useAddItemToSwitchMutation,
  useMakeDirectAdditionalPurchaseMutation,
  useMakeSIPAdditionalPurchaseMutation,
  useMakeSipAndLumpsumAdditionalPurchaseMutation,
  useModifyItemInCartMutation,
  useRedeemptionMutation,
  useStpTransactionMutation,
} from '../../transactions/slice';
import StpConfirmation from '../../transactions/stp/Confirmation';
import SwitchConfirmation, { FormData } from '../../transactions/switch/Confirmation';
import SwpConfirmation from '../../transactions/swp/Confirmation';
import { TRANSACTION_TYPE_TO_TITLE } from '../../transactions/types';
import { useOrderReinitiateMutation } from '../slice';
import NewPurchaseConfirmation from './NewPurchaseConfirmation';

interface ResponseObject {
  [key: string]: any;
}

interface OrderResponse {
  response?: ResponseObject;
}

interface ConfirmationRendererProps {
  transactionType: string;
  responseData: any;
  formikRef?: any;
}

const ConfirmationRenderer: React.FC<ConfirmationRendererProps> = ({
  transactionType,
  responseData,
  formikRef = null,
}) => {
  const newPurchaseSipType = responseData?.newPurchaseSipType;
  const dispatch = useDispatch();
  useEffect(() => {
    if (!responseData) {
      dispatch(startLoading());
    } else {
      dispatch(stopLoading());
    }
  }, [responseData, dispatch]);

  switch (transactionType) {
    case 'STP':
      return <StpConfirmation data={responseData} />;
    case 'SWITCH':
      return <SwitchConfirmation data={responseData} />;
    case 'RED':
      return <RedeemConfirmation data={responseData} />;
    case 'SWP':
      return <SwpConfirmation data={responseData} />;
    case 'ISIP':
      return (
        <AdditionalPurchaseConfirmation
          data={responseData}
          formikRef={formikRef}
        />
      );
    case 'APL':
      return (
        <AdditionalPurchaseConfirmation
          data={responseData}
          formikRef={formikRef}
        />
      );
    case 'NPL':
      return <NewPurchaseConfirmation data={responseData} />;
    case 'SINI':
      return <NewPurchaseConfirmation data={responseData} />;
    case 'SIN':
      if (newPurchaseSipType) return <NewPurchaseConfirmation data={responseData} />;
      else
        return (
          <AdditionalPurchaseConfirmation
            data={{ ...responseData, transactionType: 'SIN' }}
            formikRef={formikRef}
          />
        );

    default:
      return null;
  }
};

function Reinitiate() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { item } = location.state;
  const { showToast } = useRootContext();
  const [orderResponse, setOrderResponse] = useState<OrderResponse>({});
  const [requestId, setRequestId] = useState('');
  const [openDialogBox, setOpenDialogBox] = useState(false);

  const [orderReinitiate] = useOrderReinitiateMutation();
  const [reedemScheme] = useRedeemptionMutation();
  const [modifyItemInCart] = useModifyItemInCartMutation();
  const [makeSwpTransaction] = useAddItemInSwpMutation();
  const [addItemToSwitch] = useAddItemToSwitchMutation();
  // All AlP APIs.
  const [makeDirectAdditionalPurchase] = useMakeDirectAdditionalPurchaseMutation();
  const [makeSIPAdditionalPurchase] = useMakeSIPAdditionalPurchaseMutation();
  const [makeSipAndLumpsumAdditionalPurchase] =
    useMakeSipAndLumpsumAdditionalPurchaseMutation();
  const [stpPost] = useStpTransactionMutation();
  const formikRef = useRef<FormikProps<CheckoutPaymentFormValues>>(null);
  const { showDashboard } = useUserPermission();

  const keysToRemove = [
    'user_agent',
    'userId',
    'dcr',
    'arnCode',
    'distributorID',
    'userAgent',
    'arn',
    'subArn',
    'euin',
    'euinDeclaration',
    'parentIhno',
  ];

  const filterKeys = (obj: ResponseObject) =>
    Object.fromEntries(
      Object.entries(obj).filter(([key]) => !keysToRemove.includes(key)),
    );
  const { arn, name, subArn } = useSelector((state: { login: any }) => state.login);

  const trtType =
    orderResponse?.response?.transactionType === 'ISIP'
      ? orderResponse?.response?.installmentAmount && orderResponse?.response?.amount
        ? 'SIN'
        : 'ISIP'
      : orderResponse?.response?.transactionType;
  const confirmButtonHandler = async () => {
    try {
      dispatch(startLoading());
      let response;
      const filteredResponse = filterKeys(orderResponse.response || {});
      if (formikRef?.current) {
        const errors: any = await formikRef.current.validateForm();
        if (Object.keys(errors).length > 0) {
          formikRef.current.setTouched(errors);
          return;
        }
      }
      const { selectedMandateObj, paymentMode }: CheckoutPaymentFormValues =
        formikRef?.current?.values || {};
      const lumpsumPaymentObj = {
        paymentMode,
        ...(paymentMode?.includes('OTM') && {
          selectedMandates: selectedMandateObj,
        }),
        createMandate: selectedMandateObj?.value === 'createMandate',
        umrn: formikRef?.current?.values.paymentMode?.includes('OTM')
          ? selectedMandateObj?.value
          : '',
      };

      const sipPaymentObj = {
        umrn:
          selectedMandateObj?.value !== 'createMandate' &&
          formikRef?.current?.values?.modeOfRegistration?.includes('OTM')
            ? selectedMandateObj?.value
            : '',
        ...(selectedMandateObj?.value !== 'createMandate' &&
          formikRef?.current?.values?.modeOfRegistration?.includes('OTM') && {
            selectedMandates: selectedMandateObj,
          }),
        createMandate: selectedMandateObj?.value === 'createMandate',
        modeOfRegistration: formikRef?.current?.values?.modeOfRegistration,
      };
      const sipWithPayment = {
        paymentMode,
        createMandate: selectedMandateObj?.value === 'createMandate',
        modeOfRegistration: formikRef?.current?.values?.modeOfRegistration,
        umrn:
          selectedMandateObj?.value !== 'createMandate' &&
          formikRef?.current?.values?.modeOfRegistration?.includes('OTM')
            ? selectedMandateObj?.value
            : '',
        ...(selectedMandateObj?.value !== 'createMandate' &&
          formikRef?.current?.values?.modeOfRegistration?.includes('OTM') && {
            selectedMandates: selectedMandateObj,
          }),
      };
      switch (trtType) {
        case 'RED':
          response = await reedemScheme(filteredResponse).unwrap();
          break;
        case 'STP':
          response = await stpPost(filteredResponse).unwrap();
          break;
        case 'SWP':
          response = await makeSwpTransaction(filteredResponse).unwrap();
          break;
        case 'SWITCH':
          response = await addItemToSwitch(filteredResponse).unwrap();
          break;
        case 'NPL':
          navigate('/transaction-dashboard/new-purchase/investor-details', {
            state: {
              reinitiate: true,
              response: orderResponse?.response,
            },
          });
          break;
        case 'SINI':
          if (
            orderResponse?.response?.installmentAmount !== 0 &&
            orderResponse?.response?.amount === 0
          ) {
            navigate('/transaction-dashboard/new-purchase/investor-details', {
              state: {
                reinitiate: true,
                response: orderResponse?.response,
              },
            });
          }
          break;
        case 'ISIP':
          response = await makeSIPAdditionalPurchase({
            ...orderResponse?.response,
            ...sipPaymentObj,
          }).unwrap();
          break;
        case 'SIN':
          if (
            orderResponse?.response?.newPurchaseSipType !== '' &&
            orderResponse?.response?.newPurchaseSipType === 'SIPN' &&
            orderResponse?.response?.installmentAmount !== 0 &&
            orderResponse?.response?.amount !== 0
          ) {
            navigate('/transaction-dashboard/new-purchase/investor-details', {
              state: {
                reinitiate: true,
                response: orderResponse?.response,
              },
            });
          } else {
            response = await makeSipAndLumpsumAdditionalPurchase({
              ...orderResponse?.response,
              transacationType: 'SIN',
              ...sipWithPayment,
            }).unwrap();
          }
          break;
        case 'APL':
          // no need to remove keys.
          response = await makeDirectAdditionalPurchase({
            ...orderResponse?.response,
            ...lumpsumPaymentObj,
          }).unwrap();
          break;
        default:
          // Handle other transaction type
          break;
      }

      if (response) {
        setRequestId(response?.ihno);
      }
      setOpenDialogBox(true);
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

  useEffect(() => {
    const getOrders = async () => {
      try {
        const payload = {
          ihno: item?.orders[0]?.dco_ihno,
        };
        const result = await orderReinitiate(payload).unwrap();
        setOrderResponse(result);
      } catch (error: any) {
        showToast(
          (error as { message: string })?.message || error?.data?.message,
          'error',
        );
      }
    };
    getOrders();
  }, []);
  const isNewPurchaseSipType = orderResponse?.response?.newPurchaseSipType;
  const trTypeToTitle = isNewPurchaseSipType
    ? Number(orderResponse?.response?.amount) !== 0 &&
      Number(orderResponse?.response?.installmentAmount) !== 0
      ? 'New Purchase SIP Lumpsum'
      : 'New Purchase SIP'
    : orderResponse?.response?.transactionType === 'ISIP'
    ? orderResponse?.response?.installmentAmount && orderResponse?.response?.amount
      ? TRANSACTION_TYPE_TO_TITLE['SIN' as keyof typeof TRANSACTION_TYPE_TO_TITLE]
      : TRANSACTION_TYPE_TO_TITLE['ISIP' as keyof typeof TRANSACTION_TYPE_TO_TITLE]
    : TRANSACTION_TYPE_TO_TITLE[
        orderResponse?.response?.transactionType as keyof typeof TRANSACTION_TYPE_TO_TITLE
      ];

  return (
    <>
      <PanDetailsBox
        name={orderResponse?.response?.investorName || 'NA'}
        value={orderResponse?.response?.pan || 'NA'}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, mt: { xs: 4, md: 0 } }}>
        <Button
          sx={{
            color: 'text.labelColor',
            p: 0,
            justifyContent: { xs: 'flex-start', sm: 'center' },
          }}
          disableFocusRipple={true}
          onClick={() => navigate(-1)}
        >
          Home
        </Button>
        <img
          src={BreadCrumb_Icon}
          alt='breadcrumb-icon'
        />
        <Button>
          <Typography sx={{ color: 'primary.main', fontWeight: 500 }}>
            {trTypeToTitle || 'NA'}
          </Typography>
        </Button>
      </Box>
      {/* Mobile UI starts*/}
      <Box
        sx={{
          border: '1px solid',
          borderColor: 'text.borderColorLight',
          borderRadius: '5px',
          padding: '10px',
          mb: 2,
          display: { xs: 'block', md: 'none' },
        }}
      >
        <Typography
          sx={{
            color: 'primary.main',
            fontWeight: 500,
            fontSize: { xs: '16px', xl: '18px' },
            mb: 2,
          }}
        >
          Distributor Profile
        </Typography>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={6}
          >
            <FormData
              label='ARN Name'
              value={name || 'NA'}
            />
          </Grid>
          <Grid
            item
            xs={6}
          >
            <FormData
              label='ARN Code'
              value={arn || 'NA'}
            />
          </Grid>
          <Grid
            item
            xs={6}
          >
            <FormData
              label='Sub ARN Code'
              value={subArn || 'NA'}
            />
          </Grid>
        </Grid>
      </Box>
      {/* Mobile UI Ends*/}
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          md={8}
        >
          <Investorcard
            sx={(theme: Theme) => ({
              padding: '30px 30px',
              [theme.breakpoints.down('sm')]: {
                padding: '0px',
                boxShadow: 'unset',
                border: 'unset',
              },
            })}
          >
            <Typography
              sx={{
                fontSize: { xs: '14px', sm: '16px', xl: '18px' },
                fontWeight: 500,
                color: 'primary.main',
                mb: 2,
              }}
            >{`Confirm re-initiation of ${trTypeToTitle} transaction`}</Typography>

            <ConfirmationRenderer
              transactionType={trtType}
              responseData={orderResponse?.response}
              formikRef={formikRef}
            />
            <Divider
              orientation='horizontal'
              variant='fullWidth'
              flexItem
              sx={{ border: '1px dashed', borderColor: 'text.borderColorDark', my: 3 }}
            />
            <Box>
              <StepperNextButton
                sx={{ minWidth: { xs: '100%', md: '220px' }, padding: '8px 20px' }}
                onClick={confirmButtonHandler}
              >
                Confirm
              </StepperNextButton>
            </Box>
          </Investorcard>
        </Grid>
        <Grid
          item
          md={4}
        >
          <ConfirmationProfile data={orderResponse?.response} />
        </Grid>
      </Grid>
      <CustomDialog
        isVisible={openDialogBox}
        secondButtonHandler={() => {
          setOpenDialogBox(false);
          navigateBasedonUserPermission(showDashboard, navigate);
        }}
        secondButtonTitle='Ok'
        firstButtonTitle=''
        showFirstButton={false}
        showSecondButton
        handleClose={() => {}}
        firstButtonHandler={() => {}}
      >
        <Box
          sx={{
            'textAlign': 'center',
            'pt': 2,
            '& .MuiTypography-root': {
              'opacity': '0.85',
              'my': 2,
              '& >*': {
                fontWeight: '600',
              },
            },
          }}
        >
          <img
            src={VerifiedIcon}
            alt='verified-icon'
          />

          <Typography sx={{ color: 'text.darkGreen', fontSize: '16px', fontWeight: 500 }}>
            Successfully Re-Initiated Transaction
          </Typography>
          <Typography variant='body1'>
            {`Transaction for ${trTypeToTitle} has been initiated Successfully`}
          </Typography>
          <Typography variant='body1'>{`Your investor will receive the details via an mail and sms with reference number ${requestId}`}</Typography>
        </Box>
      </CustomDialog>
    </>
  );
}

export default Reinitiate;
