export const getIncomeValue: { [key: string]: string } = {
  '31': 'Below 1 Lac',
  '32': '1-5 Lacs',
  '33': '5-10 Lacs',
  '34': '10-25 Lacs',
  '35': '25 Lakhs-1 Crore',
  '36': 'Above 1 Crore',
};
export const getPepValue: { [key: string]: string } = {
  Y: 'Yes',
  N: 'No',
};
export const getOccupation: { [key: string]: string } = {
  '1': 'Business',
  '2': 'Service',
  '3': 'Professional',
  '4': 'Agriculturist',
  '5': 'Retired',
  '6': 'Housewife',
  '7': 'Student',
  '8': 'Others',
  '41': 'Private Sector Service',
  '42': 'Public Sector/ Government Service',
  '43': 'Forex Dealer',
  '44': 'Government Service',
};

export const getAddressType: { [key: string]: string } = {
  RB: 'Residential or Business',
  R: 'Residential',
  B: 'Business',
  RO: 'Registered Office',
};

export const getSourceOfWealth: { [key: string]: string } = {
  '1': 'Salary',
  '2': 'Business Income',
  '3': 'Gift',
  '4': 'Ancestral Property',
  '5': 'Rental Income',
  '6': 'Prize Money',
  '7': 'Royalty',
  '8': 'Others',
};

export type kycValuesType = {
  occupation: string;
  pepFlag: string;
  annualIncomeSlab: string;
  fatherName: string;
  spouseName: string;
  netWorth: string;
  sourceOfWealth: string;
  placeOfBirth: string;
  addressType: string;
  netWorthDate: string | Date;
};
