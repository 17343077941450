import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';

import { PopUpDialog } from '../../common/AllDialogs/popup-dialog';
import FormCheckbox from '.';

export default function FormAgreeCheckbox({
  name = 'agree',
  termsText = 'KFintech carries on the business as Registrar and Transfer Agents and is registered with the Securities and Exchange Board of India (‘ SEBI’) under the Securities and Exchange Board of India (Registrars to an issue and Share Transfer Agents) Regulations, 1993 engaged in providing various ‘Registrar & Transfer Agent ( RTA) related Services’ with respect to, (i) collection of applications forms, keeping of records, and assisting in allotment of securities, in terms of ‘Registrar to an Issue’ (ii) in terms of ‘Transfer Agent’ (Shares), maintenance of the records of the holders of securities and dealing with all matters connected with transactions which includes transfer, transmission and issuance of dividends.’ and (iii) in terms of ‘ Transfer Agent’ (Mutual Funds), dealing with Mutual Fund transactions on behalf of Asset Management Companies.',
  termsTitle = 'TERMS & CONDITIONS',
}: {
  name?: string;
  termsText?: string;
  termsTitle?: string;
}) {
  const [openDialog, setOpenDialog] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleTermsConditions = () => {
    setOpenDialog(true);
  };

  const messageText = termsText;

  return (
    <>
      <FormCheckbox
        label={
          <Box>
            <Typography
              sx={{
                color: 'text.inputLabelText',
                fontSize: '14px',
                fontWeight: 400,
              }}
            >
              I agree to
              <Button
                onClick={handleTermsConditions}
                sx={{
                  p: 0,
                  fontSize: '14px',
                  fontWeight: 400,
                  color: 'primary.main',
                  textDecoration: 'underline',
                  ml: 1,
                }}
              >
                Terms and Conditions
              </Button>
            </Typography>
          </Box>
        }
        name={name}
      />

      {openDialog && (
        <PopUpDialog
          open={openDialog}
          message_text={messageText}
          message_title={termsTitle}
          onClose={handleClose}
        />
      )}
    </>
  );
}
