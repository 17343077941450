import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import VerifiedIcon from '../../../../assets/verified-cart-icon.png';
import CustomDialog from '../../../common/AllDialogs/CustomDialog';
import { CheckBoxHtmlTooltip } from '../../../common/checkbox';
import { startLoading, stopLoading } from '../../../common/Loader/loaderSlice';
import { useRootContext } from '../../../data/root.context';
import { TransactionResponse } from '../../../features/transactions/transactionSlice';
import { FieldSubHeader3, MainHeader } from '../../../onboarding/styles/styledComponents';
import Layout from '../../Layout';
import { useCreateUpdateIfscRequestMutation } from '../../slice';
import BankForm from '../common/BankForm';
import DetailsDataTable from '../common/DetailsDataTable';
import { BankFormValues, DetailType } from '../common/types';

const UpdateBankIFSC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showToast } = useRootContext();
  const [requestId, setRequestId] = useState('');
  const [createUpdateIfscRequest] = useCreateUpdateIfscRequestMutation();
  const [selectedFolios, setSelectedFolios] = useState<any>();

  const { pan, mobile, email } = useSelector(
    (state: { transactions: TransactionResponse }) => state.transactions,
  );

  const [formData, setFormData] = useState<BankFormValues>({
    accountNo: '',
    ifsc: '',
    name: '',
    accountType: '',
    branch: '',
    city: '',
    pincode: '',
  });

  async function handleTableSubmit(data: any) {
    const bankData = data[0]?.bank;
    setSelectedFolios(data);
    setFormData({
      accountNo: bankData.account_no || bankData.accountNo,
      ifsc: bankData?.ifsc,
      name: bankData.name,
      accountType:
        bankData.BankAcType?.toLowerCase() === 'savings' ||
        bankData?.accountType?.toLowerCase() === 'saving'
          ? 'SAVING'
          : bankData.BankAcType, //TODO: optimise this line
      branch: bankData.branch,
      city: bankData.city,
      pincode: bankData.BankPin,
    });
  }

  async function handleFormSubmit(data: BankFormValues) {
    try {
      dispatch(startLoading());
      const payload = {
        requestData: {
          pan: pan,
          pekrn: '',
          mobile: mobile,
          email: email,
          data: selectedFolios.map((folio: any) => {
            const bankDetails = folio.bank;
            return {
              folio: `${folio.folio}`,
              amc: folio.amcId,
              bankDetails: {
                accountNo: bankDetails.account_no,
                accountType: bankDetails.account_type,
                name: bankDetails.name,
                newIfsc: data.ifsc,
                branch: String(data.branch) || bankDetails.branch,
                city: bankDetails.city,
                pincode: String(data.pincode) || String(bankDetails.pincode),
              },
            };
          }),
        },
      };
      const response: any = await createUpdateIfscRequest(payload).unwrap();
      setRequestId(response.req_id);
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  }

  function isRowDisabled(currentRow: any, selected?: any): boolean {
    if (!selected?.length || currentRow.folio === selected[0].folio) return false;
    const selectedBank = selected[0]?.banks?.filter(
      (bank: any) => bank?.default === 'Y',
    )[0];
    const currentBank = currentRow?.banks?.filter((bank: any) => bank.default === 'Y')[0];
    if (!selectedBank || !currentBank) return true;
    if (
      selectedBank.accountNo === currentBank.accountNo &&
      selectedBank.name === currentBank.name
    )
      return false;
    return true;
  }

  function getTooltip(isSelected: boolean, isDisabled: boolean) {
    if (isSelected || !isDisabled) return undefined;
    return ({ children }: { children: JSX.Element }) => {
      return (
        <CheckBoxHtmlTooltip
          title={
            <>
              <Typography color='inherit'>
                Account Number and Bank Name should be same
              </Typography>
            </>
          }
        >
          {children}
        </CheckBoxHtmlTooltip>
      );
    };
  }

  return (
    <Layout
      title={'Update Bank IFSC'}
      isNctTransaction
    >
      {!selectedFolios?.length ? (
        <DetailsDataTable
          columns={[
            'Folio Number',
            'Fund House',
            'Branch',
            'Bank Name',
            'IFSC',
            'Account No.',
          ]}
          onSubmit={handleTableSubmit}
          detailType={DetailType.InvestorBank}
          showEmailPhoneCombination
          initialFilter={{ ifsc: true }}
          isRowDisabled={isRowDisabled}
          disableSelectAll
          checkboxTooltip={getTooltip}
          paginationActive
        />
      ) : (
        <BankForm
          initialValues={formData}
          onSubmit={handleFormSubmit}
          header='Add the IFSC of the same bank registered under the folio.'
          subHeader='Please Note: We will credit your bank account with a small token amount in
          order to verify your bank account, kindly enter correct bank details
          carefully.'
          isUpdateIfsc
          folios={selectedFolios}
          setFolios={setSelectedFolios}
        />
      )}
      {!!requestId && (
        <CustomDialog
          isVisible={true}
          showFirstButton={false}
          firstButtonTitle=''
          firstButtonHandler={() => {}}
          secondButtonHandler={() => navigate('../')}
          secondButtonTitle='OK'
          handleClose={() => {}}
          showSecondButton
        >
          <Box sx={{ textAlign: 'center', pt: 2 }}>
            <img
              src={VerifiedIcon}
              alt='verified-icon'
            />
            <MainHeader sx={{ fontWeight: 500, mb: 2 }}>
              Your request for Update Bank IFSC is created successfully with request id{' '}
              {requestId}
            </MainHeader>
            <FieldSubHeader3 sx={{ fontSize: '18px' }}>
              A Link has been sent to the Investor Email ID. After investor authorises the
              transaction, details will be updated after T+1 day.
            </FieldSubHeader3>
          </Box>
        </CustomDialog>
      )}
    </Layout>
  );
};

export default UpdateBankIFSC;
