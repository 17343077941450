import { FormikErrors } from 'formik';

import { ErrorText } from '../onboarding/styles/styledComponents';

interface ErrorProps {
  error: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
}
export const ErrorComponent: React.FC<ErrorProps> = ({ error }) => {
  if (!error) {
    return null;
  }

  let errorMessage: any;

  if (Array.isArray(error)) {
    errorMessage = error[0];
  } else {
    errorMessage = String(error);
  }

  return <ErrorText>{errorMessage}</ErrorText>;
};
