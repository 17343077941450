import {
  Box,
  CardMedia,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { ReactComponent as Bank_Icon } from '../../../assets/bank-account-icon.svg';
import { ReactComponent as CloseIcon } from '../../../assets/close-icon.svg';
import { ReactComponent as InfoIcon } from '../../../assets/info-filled-icon.svg';
import VerifiedIcon from '../../../assets/verified-cart-icon.png';
import { Investorcard } from '../../auth/login/styles/styledComponents';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import ButtonScroller from '../../common/ButtonScroller';
import { startLoading, stopLoading } from '../../common/Loader/loaderSlice';
import { useRootContext } from '../../data/root.context';
import { TransactionResponse } from '../../features/transactions/transactionSlice';
import FormCheckbox from '../../forms/FormCheckbox';
import FormDateInput from '../../forms/FormDateInput';
import SubmitButton from '../../forms/FormSubmitButton';
import FormTextInput from '../../forms/FormTextInput';
import Layout from '../Layout';
import {
  useCreateEnachMandateMutation,
  useGetInvestorDetailsMutation,
  useLazyEnachEnabledAmcListQuery,
} from '../slice';
import { EnachValues } from '../types';
import { maskAccountNumber } from '../utils';

interface InvestorDetail {
  name: string;
  pan: string;
  mobile: string;
  email: string;
  npsEmpanelmentStatus: boolean;
  pran: string | null;
  AckNumber: string | null;
  UniqueAgentIdentifier: string | null;
  AccountType: string | null;
}

const ONE_CRORE = 10000000;

const Enach = () => {
  const { name, pan, email, mobile } = useSelector(
    (state: { transactions: TransactionResponse }) => state.transactions,
  );
  const [loading, setLoading] = React.useState<boolean>(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [requestId, setRequestId] = useState('');
  const { showToast } = useRootContext();
  const [bankData, setBankData] = useState<any>(null);
  const [getInvestorDetails] = useGetInvestorDetailsMutation();
  const [createEnachMandate] = useCreateEnachMandateMutation();
  const [amcEnabledAmc, setAmcEnabledAmc] = useState<any[]>([]);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [tempAccNo, settempAccNo] = useState('');
  const [investorDetails, setInvestorDetails] = useState<InvestorDetail>();

  const [getEnachEnabledScheme, enachEnabledAmcResponse] =
    useLazyEnachEnabledAmcListQuery();
  const formikRef = useRef<FormikProps<EnachValues>>(null);

  useEffect(() => {
    async function getDetails() {
      try {
        setLoading(true);
        const response: any = await getInvestorDetails({
          invPan: pan,
        }).unwrap();

        setInvestorDetails(response?.investor);
        setBankData(response?.bankData);
        settempAccNo(response?.bankData?.[0].BankAcno);
      } catch (error: any) {
        const message =
          (error as any).data?.message || (error as any).message || 'Unknown error';
        showToast(message, 'error');
      } finally {
        setLoading(false);
      }
    }
    getDetails();
  }, []);

  useEffect(() => {
    if (
      enachEnabledAmcResponse &&
      enachEnabledAmcResponse.isSuccess &&
      !enachEnabledAmcResponse.isFetching
    ) {
      const getAllSchemes = async () => {
        setAmcEnabledAmc(enachEnabledAmcResponse.data);
      };
      getAllSchemes();
    }
  }, [enachEnabledAmcResponse]);

  useEffect(() => {
    try {
      getEnachEnabledScheme('');
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    }
  }, []);

  async function handleSubmit(values: EnachValues) {
    try {
      dispatch(startLoading());
      const {
        amount,
        fromDT,
        toDT,
        investorName,
        investorMobile,
        investorEmail,
        investorPan,
      } = values;
      const response = await createEnachMandate({
        bankName: values.newBank ? '' : values.bankName,
        ifsc: values.newBank ? '' : values.ifsc,
        accountNo: values.newBank ? '' : tempAccNo,
        accountType: values.newBank ? '' : values.accountType,
        amount,
        fromDT,
        toDT,
        investorName,
        investorMobile,
        investorEmail,
        investorPan,
      }).unwrap();
      setRequestId(response.ihno);
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  }

  function handleChangeBank(index: number) {
    formikRef.current?.setValues({
      ...formikRef.current.values,
      bankName: bankData?.[index].BankName || '',
      ifsc: bankData?.[index].ifsccode?.toUpperCase() || '',
      accountNo: bankData?.[index].BankAcno || '',
      accountType: bankData?.[index].bankAcType || '',
    });
    settempAccNo(bankData?.[index].BankAcno);
  }

  const validationSchema = () => {
    return Yup.object().shape({
      amount: Yup.number()
        .required('Amount is required')
        .min(500, 'Minimium Amount Required is 500 Rs.')
        .max(ONE_CRORE, "Maximum Amount can't exceed 10000000 Rs."),
      fromDT: Yup.date()
        .typeError('Invalid Date')
        .required('Time Period from is required'),
      toDT: Yup.date().typeError('Invalid Date').required('Time Period to is required'),
      investorMobile: Yup.string()
        .required('Mobile Number is required')
        .matches(
          /^(?!0000000000)([1-9][0-9]{9}|[6-9][0-9]{9})$/,
          'Invalid Mobile Number',
        ),
    });
  };
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  const getToDate = (date: string | Date | undefined): Date => {
    let current: Date;

    if (date === undefined || date === '') {
      current = new Date(); // Use current date if input is invalid
    } else {
      current = new Date(date);
    }

    // Check if the date is valid
    if (isNaN(current.getTime())) {
      showToast('Invalid date', 'error');
      return current;
    }

    current.setMonth(current.getMonth() + 12);
    return current;
  };

  const maxEnachDate = (date: string | Date | undefined): Date => {
    let current: Date;

    if (date === undefined || date === '') {
      current = new Date(); // Use current date if input is invalid
    } else {
      current = new Date(date);
    }

    // Check if the date is valid
    if (isNaN(current.getTime())) {
      showToast('Invalid date', 'error');
      return current;
    }

    current.setFullYear(current.getFullYear() + 40);
    return current;
  };

  const handleValidation = async (values: any) => {
    const errors: Record<string, any> = {};
    if (
      values.amount &&
      (isNaN(parseFloat(values.amount)) ||
        Number(values.amount) <= 0 ||
        /^0\d+/.test(values.amount))
    ) {
      errors.amount = `Enter valid amount`;
    }
    return errors;
  };

  return (
    <Layout
      title='eNach'
      subtitle='Please enter the investment details'
    >
      <Formik
        innerRef={formikRef}
        initialValues={{
          bankName: bankData?.[0].BankName,
          ifsc: bankData?.[0].ifsccode?.toUpperCase(),
          accountNo: bankData?.[0].BankAcno,
          accountType: bankData?.[0].bankAcType,
          amount: '',
          fromDT: '',
          toDT: '',
          investorName: name,
          investorMobile: investorDetails?.mobile || '',
          investorEmail: investorDetails?.email || '',
          investorPan: pan,
          newBank: false,
        }}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        validate={async values => handleValidation(values)}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Investorcard
              sx={theme => ({
                padding: { xs: '0px', sm: '15px 30px' },
                [theme.breakpoints.down('md')]: {
                  border: 'unset',
                  boxShadow: 'unset',
                },
              })}
            >
              <Typography
                variant='subtitle1'
                sx={{ color: { xs: 'text.valueColor', md: 'primary.main' } }}
              >
                Personal Details
              </Typography>
              <Grid
                container
                sx={{ my: 1 }}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormTextInput
                    name='investorPan'
                    label='PAN'
                    required={false}
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormTextInput
                    name='investorName'
                    label='Investor Name'
                    required={false}
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormTextInput
                    name='investorEmail'
                    label='Email ID'
                    required={false}
                    // disabled
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Stack flexDirection='row'>
                    <Box
                      sx={{
                        'width': { xs: '25%', lg: '25%' },
                        '& .MuiInputBase-input ': {
                          borderRadius: '7px 0px 0px 7px',
                          borderRight: '0',
                        },
                      }}
                    >
                      <FormTextInput
                        label='Country code'
                        name='countryCode'
                        value={'+91'}
                        disabled
                        required={false}
                      />
                    </Box>
                    <Box
                      width='75%'
                      sx={{
                        '& .MuiInputBase-input ': {
                          borderRadius: '0px 7px 7px 0px',
                        },
                      }}
                    >
                      <FormTextInput
                        name='investorMobile'
                        label='Mobile number'
                      />
                    </Box>
                  </Stack>
                </Grid>
              </Grid>
              <Divider
                sx={{ border: '1px dashed', borderColor: 'text.borderColorDark', my: 3 }}
              />
              <Typography
                variant='subtitle1'
                sx={{ color: 'primary.main', my: 3 }}
              >
                Bank Details
              </Typography>

              <ButtonScroller
                loading={loading}
                setLoading={setLoading}
                data={
                  bankData?.map((bank: any) => ({
                    header: bank.BankName,
                    subHeader: bank.BankAcno,
                    icon: <Bank_Icon />,
                  })) || []
                }
                onClick={handleChangeBank}
                defaultClicked={values.newBank ? -1 : 0}
                disabled={values.newBank}
              />
              <Grid
                container
                sx={{ justifyContent: { xs: 'flex-start', sm: 'flex-end' } }}
              >
                <FormCheckbox
                  name='newBank'
                  label={'Adding New Bank'}
                />
              </Grid>

              {bankData && !values.newBank && (
                <Grid
                  container
                  sx={{ my: 1 }}
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormTextInput
                      name='bankName'
                      label='Bank Name'
                      required={false}
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormTextInput
                      name='ifsc'
                      label='IFSC Code'
                      required={false}
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormTextInput
                      name='accountNo'
                      label='Account Number'
                      required={false}
                      disabled
                      value={maskAccountNumber(values.accountNo)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormTextInput
                      name='accountHolderName'
                      label='Account Holder Name'
                      required={false}
                      value={name}
                      disabled
                    />
                  </Grid>
                </Grid>
              )}
              <Divider
                sx={{ border: '1px dashed', borderColor: 'text.borderColorDark', my: 3 }}
              />
              <Grid
                container
                sx={{ my: 1 }}
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormTextInput
                    name='amount'
                    label='Amount'
                    required={false}
                    typeOfInput='number'
                    onInput={(e: any) => {
                      if (e.target.value.length > 8) {
                        e.target.value = e.target.value.slice(0, 8);
                      }
                      setFieldValue('value', e.target.value);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormDateInput
                    name='fromDT'
                    label='Time Period from'
                    maxDate={values.toDT ? new Date(values.toDT) : undefined}
                    minDate={tomorrow}
                    required={false}
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormDateInput
                    name='toDT'
                    label='Time Period to'
                    // value={`${`0${getToDate(values.fromDT).getDate()}`.slice(-2)}/${`0${getToDate(values.fromDT).getMonth()+1}`.slice(-2)}/${getToDate(values.fromDT).getFullYear()}`}
                    minDate={getToDate(values.fromDT)}
                    maxDate={maxEnachDate(values.fromDT)}
                    required={false}
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '12.5px 20px',
                      alignItems: 'center',
                      bgcolor: 'text.boxColorLight',
                      borderRadius: '5px',
                    }}
                    onClick={() => setOpenDialogBox(true)}
                  >
                    <Typography sx={{ color: 'text.navyBlue', fontWeight: 500, mr: 2 }}>
                      eMandate participating AMCs
                    </Typography>
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
              <SubmitButton label='Submit' />
            </Investorcard>
            {!!requestId && (
              <CustomDialog
                isVisible={true}
                firstButtonHandler={() => {}}
                firstButtonTitle=''
                showFirstButton={false}
                showSecondButton
                secondButtonTitle='OK'
                secondButtonHandler={() => navigate('../')}
                handleClose={() => {}}
              >
                <Box sx={{ textAlign: 'center', pt: 2 }}>
                  <CardMedia
                    component={'img'}
                    src={VerifiedIcon}
                    sx={{ maxWidth: { xs: '50px', md: '90px', margin: '10px auto' } }}
                  />

                  <Typography variant='subtitle1'>
                    Your have initiated the eMandate successfully with Reference Number:{' '}
                    {requestId}
                  </Typography>
                </Box>
              </CustomDialog>
            )}
            <CustomDialog
              isVisible={openDialogBox}
              firstButtonHandler={() => {}}
              secondButtonHandler={() => {}}
              firstButtonTitle=''
              secondButtonTitle=''
              handleClose={() => {}}
              showSecondButton={false}
              showFirstButton={false}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    color: 'primary.main',
                    fontSize: '18px',
                    fontWeight: 500,
                  }}
                >
                  eNACH Participating AMCs
                </Typography>

                <IconButton
                  onClick={() => setOpenDialogBox(false)}
                  sx={{
                    '& svg': {
                      width: '30px',
                      height: '30px',
                    },
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box>
                {amcEnabledAmc.map(item => (
                  <List
                    key={item}
                    sx={{
                      'p': 0,
                      'borderBottom': '1px solid',
                      'borderColor': 'text.borderColorLight',
                      '& .MuiListItem-root': {
                        p: 0,
                      },
                    }}
                  >
                    <ListItem>
                      <ListItemText>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            color: 'text.inputLabelText',
                          }}
                        >
                          {item}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </List>
                ))}
              </Box>
            </CustomDialog>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

export default Enach;
