import { Box, Grid, Theme, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { omit } from 'lodash';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import VerifiedIcon from '../../../assets/verified-cart-icon.png';
import {
  Investorcard,
  StepperNextButton,
} from '../../auth/login/styles/styledComponents';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import { PageNavigator } from '../../common/BackToHomePage';
import { ConfirmationProfile } from '../../common/InvestorProfile';
import { startLoading, stopLoading } from '../../common/Loader/loaderSlice';
import PanDetailsBox from '../../common/PanDetails';
import { useRootContext } from '../../data/root.context';
import { LoginResponse } from '../../features/login/loginSlice';
import { CheckoutPaymentFormValues } from '../additionalPurchase/types';
import TransactionFactory from '../common/TransactionsFactory';
import {
  useAddItemInSwpMutation,
  useAddItemToSwitchMutation,
  useMakeDirectAdditionalPurchaseMutation,
  useMakeSIPAdditionalPurchaseMutation,
  useMakeSipAndLumpsumAdditionalPurchaseMutation,
  useModifyItemInCartMutation,
  useRedeemptionMutation,
  useStpTransactionMutation,
} from '../slice';
import { FormData } from '../switch/Confirmation';
import { ModeTypeToTrtType, TransactionComponentsType, TransactionType } from '../types';

export default function Confirmation() {
  const location = useLocation();
  const [requestId, setRequestId] = useState('');
  const state: any = location.state;
  const { showToast } = useRootContext();

  const { name, arn, subArn, euin } = useSelector(
    (state: { login: LoginResponse }) => state.login,
  );

  const transactionType = state.payload.transactionType;
  const [reedemScheme] = useRedeemptionMutation();
  const [modifyItemInCart] = useModifyItemInCartMutation();
  const [makeSwpTransaction] = useAddItemInSwpMutation();
  const [addItemToSwitch] = useAddItemToSwitchMutation();
  const [stpPost] = useStpTransactionMutation();
  // All AlP APIs.
  const [makeDirectAdditionalPurchase] = useMakeDirectAdditionalPurchaseMutation();
  const [makeSIPAdditionalPurchase] = useMakeSIPAdditionalPurchaseMutation();
  const [makeSipAndLumpsumAdditionalPurchase] =
    useMakeSipAndLumpsumAdditionalPurchaseMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // For Payment methods.
  const formikRef = useRef<FormikProps<CheckoutPaymentFormValues>>(null);
  const confirmButtonHandler = async () => {
    if (formikRef?.current) {
      const errors: any = await formikRef.current.validateForm();
      if (Object.keys(errors).length > 0) {
        formikRef.current.setTouched(errors);
        return;
      }
    }

    try {
      dispatch(startLoading());
      let response;
      if (state.isEditMode) {
        let modifyItemPayload = state.payload;
        await modifyItemInCart(modifyItemPayload).unwrap();
        // Getting details object no success response being received
        response = 'Details updated successfully!';
      } else {
        if (transactionType === 'APL') {
          // Call the API for APL
          const { modeType } = state.payload;
          const commonData = {
            ...omit(state.payload, ['modeType', 'isPanBasedAotm', 'isNriInvestor']),
            createMandate:
              formikRef?.current?.values?.selectedMandateObj?.value === 'createMandate',
          };
          // for lumpsum
          if (ModeTypeToTrtType[modeType as keyof typeof ModeTypeToTrtType] === 'APL') {
            response = await makeDirectAdditionalPurchase({
              ...commonData,
              pioTransaction: false,
              utrno: '',
              paymentMode: formikRef?.current?.values.paymentMode,
              transactionType:
                modeType && modeType in ModeTypeToTrtType
                  ? ModeTypeToTrtType[modeType as keyof typeof ModeTypeToTrtType]
                  : '',
              ...(formikRef?.current?.values.paymentMode?.includes('OTM') && {
                selectedMandates: formikRef?.current?.values.selectedMandateObj,
              }),
              umrn: formikRef?.current?.values.paymentMode?.includes('OTM')
                ? formikRef?.current?.values?.selectedMandateObj?.value
                : '',
            }).unwrap();
          } else if (
            ModeTypeToTrtType[modeType as keyof typeof ModeTypeToTrtType] === 'ISIP'
          ) {
            // for sip
            response = await makeSIPAdditionalPurchase({
              ...commonData,
              modeOfRegistration: formikRef?.current?.values.modeOfRegistration,
              transactionType:
                modeType && modeType in ModeTypeToTrtType
                  ? ModeTypeToTrtType[modeType as keyof typeof ModeTypeToTrtType]
                  : '',
              ...(formikRef?.current?.values?.selectedMandateObj?.value !==
                'createMandate' &&
                formikRef?.current?.values?.modeOfRegistration?.includes('OTM') && {
                  selectedMandates: formikRef?.current?.values?.selectedMandateObj,
                }),
              umrn:
                formikRef?.current?.values?.selectedMandateObj?.value !==
                  'createMandate' &&
                formikRef?.current?.values?.modeOfRegistration?.includes('OTM')
                  ? formikRef?.current?.values?.selectedMandateObj?.value
                  : '',
            }).unwrap();
          } else {
            response = await makeSipAndLumpsumAdditionalPurchase({
              ...commonData,
              modeOfRegistration: formikRef?.current?.values.modeOfRegistration,
              paymentMode: formikRef?.current?.values.paymentMode,
              transactionType:
                modeType && modeType in ModeTypeToTrtType
                  ? ModeTypeToTrtType[modeType as keyof typeof ModeTypeToTrtType]
                  : '',
              ...(formikRef?.current?.values?.selectedMandateObj?.value !==
                'createMandate' &&
                formikRef?.current?.values?.modeOfRegistration?.includes('OTM') && {
                  selectedMandates: formikRef?.current?.values?.selectedMandateObj,
                }),
              umrn:
                formikRef?.current?.values?.selectedMandateObj?.value !==
                  'createMandate' &&
                formikRef?.current?.values?.modeOfRegistration?.includes('OTM')
                  ? formikRef?.current?.values?.selectedMandateObj?.value
                  : '',
            }).unwrap();
          }
        } else if (transactionType === 'RED') {
          // Call the API for RED
          response = await reedemScheme(state.payload).unwrap();
        } else if (transactionType === 'SWP') {
          // Call the API for SWP
          response = await makeSwpTransaction(state.payload).unwrap();
        } else if (transactionType === 'STP') {
          // Call the API for STP
          response = await stpPost(state.payload).unwrap();
        } else {
          // Call the API for other transaction types
          response = await addItemToSwitch(state.payload).unwrap();
        }
        response = response.ihno || response.batchID;
      }

      if (response) {
        setRequestId(response);
      }
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };
  return (
    <>
      <PanDetailsBox
        name={state.payload?.investorName}
        value={state.payload?.pan}
      />
      <PageNavigator
        title='Confirmation'
        subtitle='Please check the following details'
        backHandler={() => navigate(-1)}
      />
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          md={8}
        >
          <Investorcard
            sx={(theme: Theme) => ({
              [theme.breakpoints.down('md')]: {
                border: 'unset',
                boxShadow: 'unset',
              },
            })}
          >
            {/* Distributor Details for  mobile UI*/}
            <Box
              sx={{
                border: '1px solid',
                borderColor: 'text.borderColorLight',
                borderRadius: '5px',
                padding: '10px',
                mb: 2,
                display: { xs: 'block', md: 'none' },
              }}
            >
              <Typography
                sx={{
                  color: 'primary.main',
                  fontWeight: 500,
                  fontSize: { xs: '16px', xl: '18px' },
                  mb: 2,
                }}
              >
                Distributor Details
              </Typography>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={6}
                >
                  <FormData
                    label='ARN Name'
                    value={name || 'NA'}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <FormData
                    label='ARN Code'
                    value={arn || 'NA'}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <FormData
                    label='Sub ARN Code'
                    value={subArn || 'NA'}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                >
                  <FormData
                    label='EUIN'
                    value={euin || 'NA'}
                  />
                </Grid>
              </Grid>
            </Box>
            {/* Distributor Details for  mobile UI*/}
            <Box
              sx={(theme: Theme) => ({
                borderTop: {
                  xs: `0.5px dashed ${theme.palette.text.borderColorLight}`,
                  md: 'unset',
                },
                borderBottom: {
                  xs: 'unset',
                  md: `0.5px dashed ${theme.palette.text.borderColorLight}`,
                },
                padding: { xs: '10px 0px 0px 0px', md: '30px 30px 0px 30px' },
              })}
            >
              <Typography
                sx={{
                  color: 'primary.main',
                  mb: 2,
                  fontSize: { xs: '16px', xl: '18px', fontWeight: 500 },
                }}
              >
                Scheme Details
              </Typography>
              <TransactionFactory
                type={TransactionType[transactionType as keyof typeof TransactionType]}
                component={TransactionComponentsType.Confirmation}
                data={state.payload}
                formikRef={formikRef}
              />
            </Box>
            <Box sx={{ padding: { xs: '0px', sm: '30px' } }}>
              <StepperNextButton
                sx={{ minWidth: { xs: '100%', md: '200px' } }}
                onClick={confirmButtonHandler}
              >
                Confirm
              </StepperNextButton>
            </Box>
          </Investorcard>
        </Grid>
        <Grid
          item
          md={4}
        >
          <ConfirmationProfile data={state.payload} />
        </Grid>
      </Grid>
      {!!requestId && (
        <CustomDialog
          isVisible={true}
          firstButtonHandler={() => {}}
          firstButtonTitle=''
          secondButtonHandler={() => navigate('../')}
          secondButtonTitle='OK'
          showFirstButton={false}
          showSecondButton
          handleClose={() => {}}
        >
          <Box
            sx={{
              'textAlign': 'center',
              'pt': 2,
              '& .MuiTypography-root': {
                'fontSize': '16px',
                'color': 'text.grayishBlue',
                'my': 2,
                '& >*': {
                  fontWeight: '600',
                },
              },
            }}
          >
            <img
              src={VerifiedIcon}
              alt='verified-icon'
            />

            <Typography
              sx={{
                fontWeight: 600,
              }}
            >
              Transaction Submitted Successfully with batch number {requestId}
            </Typography>
          </Box>
        </CustomDialog>
      )}
    </>
  );
}
