import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React from 'react';

import { ReactComponent as BackIcon_Mobile } from '../../../assets/back-icon.svg';
import { ReactComponent as Close_Icon } from '../../../assets/close-icon.svg';
import { BulkUpload } from '../BulkUploadUsers';
import FilterTabs from '../FilterTabs';
import { AddTabValuesStates } from '../types';
import AddUsersForm from './AddUsersForm';
interface AddUsersFormContentProps {
  tabValues: AddTabValuesStates;
  setTabValues: React.Dispatch<React.SetStateAction<AddTabValuesStates>>;
  setDialogVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  isBigScreen: boolean;
  onCloseOrCancelHandler: (isUserAdded?: boolean) => void;
}

const AddUsersFormContent = ({
  tabValues,
  setTabValues,
  setDialogVisibility,
  isBigScreen,
  onCloseOrCancelHandler,
}: AddUsersFormContentProps) => {
  return (
    <>
      <Box
        sx={{
          borderBottom: !isBigScreen ? 'unset ' : '1px solid',
          borderColor: 'text.borderColorLight',
          display: 'flex',
          alignItems: 'center',
          justifyContent: !isBigScreen ? 'flex-start' : 'space-between',
          p: isBigScreen ? '0px 10px 0px 24px  ' : '0px',
          mx: isBigScreen ? '-24px' : '0px',
        }}
      >
        {!isBigScreen && (
          <IconButton onClick={() => onCloseOrCancelHandler()}>
            <BackIcon_Mobile />
          </IconButton>
        )}

        <Typography
          sx={{ fontSize: { xs: '16px', xl: '18px', fontWeight: 500 } }}
          color='common.black'
        >
          Add Users
        </Typography>
        <IconButton onClick={() => onCloseOrCancelHandler()}>
          {!isBigScreen ? '' : <Close_Icon />}
        </IconButton>
      </Box>
      <FilterTabs
        tabValue={tabValues.tabValuesUsers}
        isAddUsersForm
        onChange={newValue => {
          setTabValues(prevTabValues => ({
            ...prevTabValues,
            tabValuesUsers: newValue,
          }));
        }}
        tabsArr={[
          { label: 'Add single user', value: '1' },
          { label: 'Add Multiple User', value: '2' },
        ]}
        tabContent={[
          <AddUsersForm
            isBigScreen={isBigScreen}
            key='1'
            showCancel
            buttonTitle={'Cancel'}
            cancelButtonHandler={(isUserAdded: boolean = false) => {
              onCloseOrCancelHandler(isUserAdded);
            }}
            submitButtonLabel={'Add'}
          />,
          <BulkUpload
            isBigScreen={isBigScreen}
            key='2'
            onCloseOrCancelHandler={onCloseOrCancelHandler}
          />,
        ]}
      />
    </>
  );
};

export default AddUsersFormContent;
