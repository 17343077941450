import {
  Box,
  CardMedia,
  Grid,
  // IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect } from 'react';
import {
  ShimmerButton,
  ShimmerCircularImage,
  ShimmerTable,
  ShimmerText,
} from 'react-shimmer-effects';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';

import { ReactComponent as NoDataIcon } from '../../assets/no-data-table.svg';
import AUM from '../../assets/OnboardingDashboard/aum.svg';
// import { ReactComponent as DirectionIcon } from '../../assets/OnboardingDashboard/direction-icon.svg';
// import { ReactComponent as ExpiredFilled } from '../../assets/OnboardingDashboard/expired-filled.svg';
import PFM from '../../assets/OnboardingDashboard/pfm.svg';
// import { ReactComponent as ProcessingFilled } from '../../assets/OnboardingDashboard/processing-filled.svg';
import Investors from '../../assets/OnboardingDashboard/total-investors.svg';
// import { ReactComponent as WhatsappFilled } from '../../assets/OnboardingDashboard/whatsapp-filled.svg';
import { Investorcard } from '../auth/login/styles/styledComponents';
import { DASHBOARD_COLORS } from '../common/constants';
import { useNpsDashboardQuery } from './slice';

// Define the interfaces for the data structure
interface Detail {
  PRAN: number;
  PFM: string;
  Scheme: string;
  AccountType: number; // 1 for Tier I, 2 for Tier II
  Percentage: number;
  AUM: number;
}

interface ApiResponse {
  statusCode: string;
  message: string;
  data: {
    data: any;
    message: string;
    response: {
      TotalInvestors: string;
      Details: Detail[];
    };
  };
}

export const NPS = () => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const { data: apiData } = useNpsDashboardQuery<ApiResponse>('');

  useEffect(() => {
    if (apiData?.data.response.Details) setLoading(false);
  }, [apiData?.data.response.Details]);

  const details: Detail[] = apiData?.data.response.Details || [];

  // Calculate total AUM and round to 2 decimal places as a string
  const totalAUM: string = details
    .reduce((sum: number, item: Detail) => sum + item.AUM, 0)
    .toFixed(2);

  // Convert totalAUM back to a number for percentage calculations
  const totalAUMNumber: number = parseFloat(totalAUM);

  // Calculate Tier I and Tier II AUM sums
  const tier1AUM: number = details
    .filter(item => item.AccountType === 1)
    .reduce((sum: number, item: Detail) => sum + item.AUM, 0);

  const tier2AUM: number = details
    .filter(item => item.AccountType === 2)
    .reduce((sum: number, item: Detail) => sum + item.AUM, 0);

  // Calculate percentages
  const tier1Percentage: number =
    totalAUMNumber > 0 ? (tier1AUM / totalAUMNumber) * 100 : 0;
  const tier2Percentage: number =
    totalAUMNumber > 0 ? (tier2AUM / totalAUMNumber) * 100 : 0;

  const piechartData = [
    {
      name: 'Tier1',
      value: tier1Percentage,
    },
    {
      name: 'Tier2',
      value: tier2Percentage,
    },
  ];

  // Calculate total unique PFMs based on AccountType and PRAN
  const uniquePFMsSet = new Set<string>();

  details.forEach(item => {
    const key = `${item.AccountType}-${item.PRAN}`; // Create a unique key
    uniquePFMsSet.add(key);
  });

  const totalPFMs: number = uniquePFMsSet.size; // Count of unique PFMs

  // Prepare final data object
  const resultData = {
    TotalInvestors: apiData?.data.response.TotalInvestors,
    Details: details,
    TotalAUM: totalAUM, // Total AUM as a string with 2 decimal places
    Tier1Percentage: tier1Percentage,
    Tier2Percentage: tier2Percentage,
    TotalPFMs: totalPFMs,
  };

  const items = [
    { name: 'AUM(INR)', rupees: Number(totalAUM)?.toLocaleString('en-IN'), icon: AUM },
    { name: "Total PFM's", rupees: resultData?.TotalPFMs, icon: PFM },
    { name: 'Total Investors', rupees: resultData?.TotalInvestors, icon: Investors },
  ];

  const AMCData = details.map(item => ({
    mf: item.PFM,
    percentage: `${item.Percentage}%`,
    inr: `₹${item.AUM.toLocaleString('en-IN')}`, // Format AUM to Indian currency format
  }));

  const pfmMap: Record<
    string,
    { pfm: string; percentage: number; inr: number; count: number }
  > = {};

  details.forEach(item => {
    if (!pfmMap[item.AccountType + '-' + item.PRAN]) {
      pfmMap[item.AccountType + '-' + item.PRAN] = {
        pfm: item.Scheme,
        percentage: item.Percentage,
        inr: item.AUM,
        count: 1,
      };
    } else {
      const existing = pfmMap[item.AccountType + '-' + item.PRAN];
      existing.count += 1;
      existing.percentage = (existing.percentage + item.Percentage) / existing.count;
      existing.inr += item.AUM;
    }
  });

  const formattedPfmMap = Object.values(pfmMap).map(entry => ({
    pfm: entry.pfm,
    percentage: `${entry.percentage.toFixed(2)}%`,
    inr: `₹${entry.inr.toLocaleString('en-IN')}`,
  }));

  const pfmData = Object.values(formattedPfmMap) || (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '400px',
        margin: '5% auto',
      }}
    >
      <NoDataIcon />
      <Typography
        sx={{
          fontSize: { xs: '14px', sm: '16px', xl: '18px' },
          fontWeight: 500,
          color: 'text.labelColor',
          my: 4,
        }}
      >
        You currently have no Investments for this transaction
      </Typography>
    </Box>
  );

  return (
    <>
      <Grid
        container
        my={'20px'}
        spacing={2}
      >
        {items.map((item, index) => (
          <Grid
            item
            key={index}
            xs={12}
            sm={6}
            md={4}
          >
            <Box
              sx={(theme: Theme) => ({
                'position': 'relative',
                'borderRadius': '5px 10px 10px 5px',
                'border': '0.1px solid',
                'borderColor': 'text.borderColorLight',
                'bgcolor': 'common.white',
                'boxShadow': '0px 4px 30px 0px rgba(0, 0, 0, 0.06)',
                'padding': '25px',
                'display': 'flex',
                'alignItems': 'center',
                '&:before': {
                  content: `' '`,
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: '5px',
                  height: '100%',
                  background: theme.palette.background.primaryLinearGradient2,
                  borderRadius: '15px 0px 0px 15px',
                },
              })}
            >
              <CardMedia
                component='img'
                image={item.icon}
                alt='image'
                sx={{ width: '70px', height: '70px', mr: 4 }}
              />
              <Stack>
                <Typography sx={{ color: 'text.labelColor', mb: 1 }}>
                  {item.name}
                </Typography>
                {loading ? (
                  <ShimmerButton size='sm' />
                ) : (
                  <Typography
                    sx={{
                      color: 'text.valueColor',
                      fontWeight: 700,
                      fontSize: { xs: '14px', lg: '18px', xl: '22px' },
                    }}
                  >
                    {item.rupees}
                  </Typography>
                )}
              </Stack>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Typography
        variant='subtitle2'
        sx={{ my: 2, color: 'text.labelColor' }}
      >
        As on: {format(new Date(), 'd MMMM, yyyy')}
      </Typography>
      <Grid
        container
        spacing={3}
        sx={{ mt: '10px', mb: 5 }}
      >
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Investorcard sx={{ padding: { xs: '16px', md: '30px' } }}>
            <Typography
              sx={{
                fontSize: { xs: '14px', sm: '16px', xl: '18px' },
                color: 'primary.main',
                fontWeight: 500,
                mb: 2,
              }}
            >
              Tier-Wise Investment Breakup
            </Typography>

            <Typography
              variant='subtitle2'
              sx={{ color: 'text.labelColor' }}
            >
              As on: {format(new Date(), 'd MMMM, yyyy')}
            </Typography>
            {loading ? (
              <>
                <Box
                  sx={{ width: '200px', height: '200px', margin: '0  auto 30px auto' }}
                >
                  <ShimmerCircularImage size={200} />
                </Box>

                <ShimmerText
                  line={5}
                  gap={10}
                />
              </>
            ) : (
              <>
                <Box>
                  <PieChart
                    style={{ width: '200px', height: '200px', margin: '0 auto' }}
                    width={200}
                    height={200}
                  >
                    <Pie
                      dataKey='value'
                      data={piechartData}
                      cx='50%'
                      cy='50%'
                      startAngle={90}
                      endAngle={-270}
                      innerRadius={60} // Adjust inner radius to create space in the middle
                      outerRadius={80}
                      fill='#8884d8'
                    >
                      {!!piechartData &&
                        piechartData?.length > 0 &&
                        piechartData?.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={DASHBOARD_COLORS[index % DASHBOARD_COLORS.length]}
                          />
                        ))}
                    </Pie>
                    <Tooltip
                      formatter={(value, name) => [
                        `${name} - ${Number(value)?.toFixed(2)} %`,
                        '',
                      ]}
                    />
                  </PieChart>
                  <Box>
                    {!!piechartData &&
                      piechartData?.map((entry, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            columnGap: '10px',
                            my: 2,
                            justifyContent: 'center',
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: DASHBOARD_COLORS[index as number] || 'red',
                              height: '10px',
                              width: '10px',
                              borderRadius: '50%',
                            }}
                          />
                          <Typography component={'span'}>{entry?.name}</Typography>
                        </Box>
                      ))}
                  </Box>
                </Box>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow
                        sx={{
                          '& .MuiTableCell-root ': {
                            padding: { xs: '16px 8px', sm: '16px' },
                          },
                        }}
                      >
                        <TableCell colSpan={1} />
                        <TableCell>
                          <Typography
                            variant='subtitle2'
                            sx={{
                              color: 'text.valueColor',
                              fontWeight: 600,
                            }}
                          >
                            Percentage(%)
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            variant='subtitle2'
                            sx={{ color: 'text.valueColor', fontWeight: 600 }}
                          >
                            INR
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        '& .MuiTableRow-root:nth-of-type(odd)': {
                          bgcolor: 'common.white',
                        },
                        '& .MuiTableCell-root ': {
                          padding: { xs: '16px 8px', sm: '16px' },
                        },
                      }}
                    >
                      <TableRow>
                        <TableCell>
                          <Stack
                            direction={'row'}
                            alignItems={'center'}
                            columnGap={'20px'}
                          >
                            <Box
                              sx={{
                                bgcolor: 'text.paleAqua',
                                width: '11px',
                                height: '11px',
                                borderRadius: '50px',
                              }}
                            />
                            <Typography sx={{ color: 'text.valueColor' }}>
                              Tier-I
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ color: 'text.valueColor' }}>
                            {tier1Percentage?.toFixed(2)}%
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ color: 'text.valueColor' }}>
                            ₹{tier1AUM?.toLocaleString('en-IN')}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Stack
                            direction={'row'}
                            alignItems={'center'}
                            columnGap={'20px'}
                          >
                            <Box
                              sx={{
                                bgcolor: 'text.skyBlue',
                                width: '11px',
                                height: '11px',
                                borderRadius: '50px',
                              }}
                            />
                            <Typography sx={{ color: 'text.valueColor' }}>
                              Tier-II
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ color: 'text.valueColor' }}>
                            {' '}
                            {tier2Percentage?.toFixed(2)}%%
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ color: 'text.valueColor' }}>
                            {' '}
                            ₹{tier2AUM?.toLocaleString('en-IN')}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Investorcard>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Investorcard sx={{ padding: { xs: '20px', sm: 'unset' } }}>
            <Typography
              sx={{
                fontSize: { xs: '14px', sm: '16px', xl: '18px' },
                color: 'primary.main',
                fontWeight: 500,
                margin: { sm: '10px 10px', md: '30px 25px 16px' },
              }}
            >
              PFM Wise Breakup
            </Typography>
            {loading ? (
              <ShimmerTable
                row={4}
                col={3}
              />
            ) : (
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <Grid
                  container
                  sx={{
                    bgcolor: 'text.tableBgColor',
                    position: 'sticky',
                    padding: { xs: '8px 20px', md: '16px 25px' },
                    justifyContent: 'space-between',
                  }}
                >
                  <Grid
                    item
                    xs={4}
                    sm={6}
                  >
                    <Typography
                      sx={{
                        fontSize: { sm: '14px', xl: '15px' },
                        fontWeight: 500,
                      }}
                    >
                      PFM
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    md={3.5}
                  >
                    <Typography
                      sx={{
                        fontSize: { sm: '14px', xl: '15px' },
                        fontWeight: 500,
                      }}
                    >
                      Percentage(%)
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    md={2.5}
                  >
                    <Typography
                      sx={{
                        fontSize: { sm: '14px', xl: '15px' },
                        fontWeight: 500,
                      }}
                    >
                      INR
                    </Typography>
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    'maxHeight': '350px',
                    'overflow': 'hidden auto',
                    '&::-webkit-scrollbar': {
                      width: '8px',
                      height: '214px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      height: '6px',
                      border: '2px solid rgba(0, 0, 0, 0)',
                      backgroundClip: 'padding-box',
                      backgroundColor: 'text.stepperLine',
                      borderRadius: '33px',
                      boxShadow:
                        'inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05)',
                    },
                    '&::-webkit-scrollbar-button': {
                      display: 'none',
                      width: 0,
                      height: 0,
                    },
                    '&::-webkit-scrollbar-corner': {
                      backgroundColor: 'transparent',
                    },
                    '&::-webkit-scrollbar-track': {
                      borderRadius: '33px',
                      background: 'rgba(255, 255, 255, 0.8)',
                    },
                  }}
                >
                  {pfmData?.map((data, index) => (
                    <Grid
                      container
                      sx={{
                        'padding': { xs: '8px 20px', md: '30px 25px' },
                        '&:not(:last-child)': {
                          borderBottom: '1px solid',
                          borderColor: 'text.borderColorDark',
                        },
                        '& .MuiTypography-root': {
                          padding: '0px 5px',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        },
                      }}
                      key={index}
                    >
                      <Grid
                        item
                        xs={4}
                        sm={6}
                      >
                        <Typography sx={{ fontSize: { sm: '14px', xl: '16px' } }}>
                          {data.pfm}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={3.5}
                      >
                        <Typography sx={{ fontSize: { sm: '14px', xl: '16px' } }}>
                          {data.percentage}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={2.5}
                      >
                        <Typography sx={{ fontSize: { sm: '14px', xl: '16px' } }}>
                          {data.inr}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              </Box>
            )}
            {/*Mobile UI*/}
            <Box
              sx={{
                display: { xs: 'block', sm: 'none' },
                mt: 2,
              }}
            >
              {AMCData.map((amc, index) => (
                <Box
                  key={index}
                  sx={{
                    borderBottom: '1px dashed',
                    borderColor: 'text.borderColorLight',
                    pb: 2.5,
                    mb: 2.5,
                  }}
                >
                  <Typography sx={{ fontWeight: 500, mb: 2 }}>{amc.mf}</Typography>
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Stack>
                      <Typography
                        sx={{ color: 'text.labelColor', fontSize: '12px', mb: 1 }}
                      >
                        Percent(%)
                      </Typography>
                      <Typography
                        sx={{
                          color: 'text.valueColor',
                          fontSize: '14px',
                          fontWeight: 500,
                        }}
                      >
                        {amc?.percentage}
                      </Typography>
                    </Stack>
                    <Stack sx={{ textAlign: 'end' }}>
                      <Typography
                        sx={{ color: 'text.labelColor', fontSize: '12px', mb: 1 }}
                      >
                        INR
                      </Typography>
                      <Typography
                        sx={{
                          color: 'text.valueColor',
                          fontSize: '14px',
                          fontWeight: 500,
                        }}
                      >
                        {amc.inr}
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
              ))}
            </Box>
            {/*Mobile UI*/}
          </Investorcard>
        </Grid>
      </Grid>
      {/* <Investorcard sx={{ padding: { xs: '20px 20px', sm: '30px 30px' } }}>
        <Typography
          sx={{
            fontSize: { xs: '14px', sm: '16px', xl: '18px' },
            color: 'primary.main',
            fontWeight: 500,
            mb: 3,
          }}
        >
          Transaction Snapshots
        </Typography>

        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <Box
              sx={(theme: Theme) => ({
                'bgcolor': 'text.lightShadeGreen',
                'padding': '10px 15px 15px 35px',
                'borderRadius': '10px',
                'display': 'flex',
                'justifyContent': 'space-between',
                'cursor': 'pointer',
                'alignItems': 'center',
                '&:hover': {
                  '& .MuiBox-root': {
                    '&:before': {
                      left: 0,
                      bottom: 0,
                      top: 0,
                    },
                    '& svg path': {
                      fill: theme.palette.text.greenShade, // Change fill color of paths within SVG on hoverZ
                      transition: 'fill 0.5s',
                    },
                  },
                },
              })}
            >
              <Stack>
                <Typography
                  sx={{
                    color: 'text.greenShade',
                    fontSize: { xs: '26px', sm: '28px', xl: '32px' },
                    fontWeight: 900,
                  }}
                >
                  512
                </Typography>
                <Typography
                  sx={{
                    color: 'text.labelColor',
                    fontSize: { xs: '12px', sm: '16px', xl: '18px' },
                    fontWeight: 500,
                  }}
                >
                  Approved
                </Typography>
              </Stack>
              <Stack
                alignItems={'end'}
                rowGap={'25px'}
              >
                <IconButton>
                  <DirectionIcon />
                </IconButton>

                <Box
                  sx={(theme: Theme) => ({
                    'borderRadius': '50% 50% 20% 50%',
                    'border': 0, // Remove default border
                    'boxShadow': '0px 4px 30px 0px rgba(0, 0, 0, 0.06)',
                    // 'padding': '25px',
                    'width': { xs: '35px', sm: '50px' },
                    'height': { xs: '35px', sm: '50px' },
                    'bgcolor': 'text.greenShade',
                    'position': 'relative',
                    'overflow': 'hidden',
                    'display': 'flex',
                    'justifyContent': 'center',
                    'alignItems': 'center',
                    'zIndex': 1,

                    'transition': 'background-color 0.5s', // Add transition for smooth effect

                    '& svg': {
                      zIndex: '2',
                      width: { xs: '18px', sm: 'unset' },
                      height: { xs: '18px', sm: 'unset' },
                    },

                    '&:before': {
                      content: `' '`,
                      position: 'absolute',
                      top: '-50px',
                      left: '100%',
                      bottom: '100%',
                      width: '100%',
                      height: '100%',
                      bgcolor: 'text.primary',
                      transition: '.6s',
                      zIndex: '2',
                      borderRadius: '50% 50% 20% 50%',
                    },
                  })}
                >
                  <WhatsappFilled />
                </Box>
              </Stack>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <Box
              sx={(theme: Theme) => ({
                'bgcolor': 'text.lightShadeAmber',
                'padding': '10px 15px 15px 35px',
                'borderRadius': '10px',
                'display': 'flex',
                'justifyContent': 'space-between',
                'cursor': 'pointer',
                'alignItems': 'center',
                '&:hover': {
                  '& .MuiBox-root': {
                    '&:before': {
                      left: 0,
                      bottom: 0,
                      top: 0,
                    },
                    '& svg path': {
                      fill: theme.palette.text.amber, // Change fill color of paths within SVG on hoverZ
                      transition: 'fill 0.5s',
                    },
                  },
                },
              })}
            >
              <Stack>
                <Typography
                  sx={{
                    fontSize: { xs: '26px', sm: '28px', xl: '32px' },
                    color: 'text.amber',
                    fontWeight: 900,
                  }}
                >
                  160
                </Typography>
                <Typography
                  sx={{
                    color: 'text.labelColor',
                    fontSize: { xs: '12px', sm: '16px', xl: '18px' },
                    fontWeight: 500,
                  }}
                >
                  Pending
                </Typography>
              </Stack>
              <Stack
                alignItems={'end'}
                rowGap={'25px'}
              >
                <IconButton>
                  <DirectionIcon />
                </IconButton>
                <Box
                  sx={(theme: Theme) => ({
                    'borderRadius': '50% 50% 20% 50%',
                    'border': 0, // Remove default border
                    'boxShadow': '0px 4px 30px 0px rgba(0, 0, 0, 0.06)',
                    // 'padding': '25px',
                    'width': { xs: '35px', sm: '50px' },
                    'height': { xs: '35px', sm: '50px' },
                    'bgcolor': 'text.amber',
                    'position': 'relative',
                    'overflow': 'hidden',
                    'display': 'flex',
                    'justifyContent': 'center',
                    'alignItems': 'center',
                    'zIndex': 1,

                    'transition': 'background-color 0.5s', // Add transition for smooth effect

                    '& svg': {
                      zIndex: '2',
                    },

                    '&:before': {
                      content: `' '`,
                      position: 'absolute',
                      top: '-50px',
                      left: '100%',
                      bottom: '100%',
                      width: '100%',
                      height: '100%',
                      bgcolor: 'text.primary',
                      transition: '.6s',
                      zIndex: '2',
                      borderRadius: '50% 50% 20% 50%',
                    },
                  })}
                >
                  <ProcessingFilled />
                </Box>
              </Stack>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
          >
            <Box
              sx={(theme: Theme) => ({
                'bgcolor': 'text.coral',
                'padding': '10px 15px 15px 35px',
                'borderRadius': '10px',
                'display': 'flex',
                'justifyContent': 'space-between',
                'cursor': 'pointer',
                'alignItems': 'center',
                '&:hover': {
                  '& .MuiBox-root': {
                    '&:before': {
                      left: 0,
                      bottom: 0,
                      top: 0,
                    },
                    '& svg path': {
                      fill: theme.palette.text.clearText, // Change fill color of paths within SVG on hoverZ
                      transition: 'fill 0.5s',
                    },
                  },
                },
              })}
            >
              <Stack>
                <Typography
                  sx={{
                    fontSize: { xs: '26px', sm: '28px', xl: '32px' },
                    color: 'text.clearText',
                    fontWeight: 900,
                  }}
                >
                  53
                </Typography>
                <Typography
                  sx={{
                    color: 'text.labelColor',
                    fontSize: { xs: '12px', sm: '16px', xl: '18px' },
                    fontWeight: 500,
                  }}
                >
                  Expired
                </Typography>
              </Stack>
              <Stack
                alignItems={'end'}
                rowGap={'25px'}
              >
                <IconButton>
                  <DirectionIcon />
                </IconButton>
                <Box
                  sx={(theme: Theme) => ({
                    'borderRadius': '50% 50% 20% 50%',
                    'border': 0, // Remove default border
                    'boxShadow': '0px 4px 30px 0px rgba(0, 0, 0, 0.06)',
                    // 'padding': '25px',
                    'width': { xs: '35px', sm: '50px' },
                    'height': { xs: '35px', sm: '50px' },
                    'bgcolor': 'text.clearText',
                    'position': 'relative',
                    'overflow': 'hidden',
                    'display': 'flex',
                    'justifyContent': 'center',
                    'alignItems': 'center',
                    'zIndex': 1,
                    'transition': 'background-color 0.5s', // Add transition for smooth effect
                    '& svg': {
                      zIndex: '2',
                      width: { xs: '18px', sm: 'unset' },
                      height: { xs: '18px', sm: 'unset' },
                    },

                    '&:before': {
                      content: `' '`,
                      position: 'absolute',
                      top: '-50px',
                      left: '100%',
                      bottom: '100%',
                      width: '100%',
                      height: '100%',
                      bgcolor: 'text.primary',
                      transition: '.6s',
                      zIndex: '2',
                      borderRadius: '50% 50% 20% 50%',
                    },
                  })}
                >
                  <ExpiredFilled />
                </Box>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Investorcard> */}
    </>
  );
};
