import {
  Avatar,
  Box,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';
import Female from '../../assets/female.svg';
import Male from '../../assets/male.svg';
import Others from '../../assets/others.svg';
import { ErrorText, FieldSubHeader3 } from '../auth/login/styles/styledComponents';
import { useLazyGetCountriesListQuery } from '../auth/signup/slices';
import { statesList } from '../common/constants';
import { startLoading, stopLoading } from '../common/Loader/loaderSlice';
import { useRootContext } from '../data/root.context';
import { Country, setCountries } from '../features/utils/countriesAndStatesSlice';
import FormDateInput from '../forms/FormDateInput';
import { FormSelectInput } from '../forms/FormSelect';
import FormTextInput from '../forms/FormTextInput';
import { emailRegex } from '../transactions/regex';
import { calculateAge } from './common';
import { NOMINEE_NUMBERS } from './NomineeDetails';
import { NomineeList } from './types';

const gendersList = {
  Male: Male,
  Female: Female,
  Others: Others,
};
export const nomineeValidationSchema = Yup.object().shape({
  nominees: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string()
          .required('Full Name is required')
          .matches(
            /^[A-Za-z\s]+$/,
            'Full Name should not contain numbers or special characters',
          )
          .min(3, 'Full Name must be at least 3 characters'),
        relation: Yup.string().required('Relation is required'),
        gender: Yup.string().required('Gender is required'),
        pan: Yup.string().matches(
          /^[A-Z]{3}[P][A-Z]{1}[0-9]{4}[A-Z]{1}$/,
          'Please enter a Valid PAN',
        ),
        email: Yup.string().matches(emailRegex, 'Invalid email address'),
        dateOfBirth: Yup.date()
          .typeError('Invalid Date')
          .nullable()
          .required('Date of Birth is required'),
        percent: Yup.number()
          .required('Nominee % is required')
          .positive('Nominee % greater than 0')
          .max(100, 'Nominee % cannot exceed 100'),
        guardianName: Yup.string().when('dateOfBirth', {
          is: (val: string) => {
            return val && calculateAge(val) < 18;
          },
          then: Yup.string().required('Guardian Name is required'),
        }),
        guardianPan: Yup.string().when('dateOfBirth', {
          is: (val: string) => {
            return val && calculateAge(val) < 18;
          },
          then: Yup.string()
            .required('Guardian PAN is required')
            .matches(/^[A-Z]{3}[P][A-Z]{1}[0-9]{4}[A-Z]{1}$/, 'Please enter a Valid PAN'),
        }),
        guardianGender: Yup.string().when('dateOfBirth', {
          is: (val: string) => {
            return val && calculateAge(val) < 18;
          },
          then: Yup.string().required('Guardian Age is required'),
        }),
        mobile: Yup.string()
          .required('Mobile is required')
          .matches(/^[0-9]\d{9}$/, {
            message: 'Please enter a valid mobile number',
            excludeEmptyString: true,
          }),
        city: Yup.string().matches(/^[a-zA-Z\s]*$/, 'City should contain alphabets only'),
        state: Yup.string().matches(
          /^[a-zA-Z\s]*$/,
          'State should contain alphabets only',
        ),
        pincode: Yup.string()
          .matches(/^[0-9]+$/, `Please enter valid Pincode`)
          .min(6)
          .max(6),
      }),
    )
    .test(
      'unique-names-pans-and-guardians-cross-nominee',
      'Nominee names and PANs must be unique and different from all other nominee and guardian names and PANs',
      function (nominees) {
        const errors: any[] = [];
        const allNames = new Set();
        const allPans = new Set();
        nominees?.forEach((nominee, index) => {
          const name = nominee.name?.toLowerCase().trim();
          const guardianName = nominee.guardianName?.toLowerCase().trim();
          const pan = nominee.pan?.toLowerCase().trim();
          const guardianPan = nominee.guardianPan?.toLowerCase().trim();
          if (name) {
            if (allNames.has(name)) {
              errors.push(
                this.createError({
                  path: `nominees.${index}.name`,
                  message:
                    'Nominee name must be unique across all nominees and guardians',
                }),
              );
            } else {
              allNames.add(name);
            }
          }
          if (guardianName) {
            if (allNames.has(guardianName)) {
              errors.push(
                this.createError({
                  path: `nominees.${index}.guardianName`,
                  message:
                    'Guardian name must be unique across all nominees and guardians',
                }),
              );
            } else {
              allNames.add(guardianName);
            }
          }
          if (pan) {
            if (allPans.has(pan)) {
              errors.push(
                this.createError({
                  path: `nominees.${index}.pan`,
                  message: 'Nominee PAN must be unique across all nominees and guardians',
                }),
              );
            } else {
              allPans.add(pan);
            }
          }
          if (guardianPan) {
            if (allPans.has(guardianPan)) {
              errors.push(
                this.createError({
                  path: `nominees.${index}.guardianPan`,
                  message:
                    'Guardian PAN must be unique across all nominees and guardians',
                }),
              );
            } else {
              allPans.add(guardianPan);
            }
          }
          if (name && guardianName && name === guardianName) {
            errors.push(
              this.createError({
                path: `nominees.${index}.guardianName`,
                message: 'Guardian name cannot be the same as its nominee name',
              }),
            );
          }
          if (pan && guardianPan && pan === guardianPan) {
            errors.push(
              this.createError({
                path: `nominees.${index}.guardianPan`,
                message: 'Guardian PAN cannot be the same as its nominee PAN',
              }),
            );
          }
        });
        if (errors.length > 0) {
          return new Yup.ValidationError(errors);
        }
        return true;
      },
    )
    .test('totalPercentage', 'Total percentage cannot exceed 100%', function (nominees) {
      const totalPercentage =
        nominees &&
        nominees.reduce((acc, nominee) => {
          return acc + Number(nominee.percent || 0);
        }, 0);
      const last = nominees!.length - 1;
      if (totalPercentage && totalPercentage > 100) {
        const errors = nominees.map((_, index) =>
          this.createError({
            path: `nominees.${index}.percent`,
            message: 'Total percentage cannot exceed 100%',
          }),
        );
        return new Yup.ValidationError(errors);
      }
      return true;
    })
    .nullable()
    .min(1, 'At least one nominee is required')
    .max(5, 'Maximum of 5 nominees allowed'),
});
export const extendedNomineeValidationSchema = nomineeValidationSchema.concat(
  Yup.object().shape({
    address: Yup.string().matches(
      /^[a-zA-Z0-9\s]*$/,
      'Address should not contain special characters',
    ),
    addressTwo: Yup.string().matches(
      /^[a-zA-Z0-9\s]*$/,
      'Address should not contain special characters',
    ),
  }),
);

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();
const currentDate = new Date().getDate();
const minYear = currentYear - 100;
const minDate = new Date(minYear, currentMonth, currentDate);

export const NomineeFormSection = ({
  values,
  editMode,
  index,
  header,
  onDelete,
  age,
  setFormCounter,
  formCounter,
  name,
  errors,
  userAddresses,
  touched,
}: {
  values: NomineeList;
  editMode: boolean;
  index: number;
  header?: string;
  onDelete?: () => void;
  age: number;
  setFormCounter?: React.Dispatch<React.SetStateAction<number>>;
  formCounter?: number;
  name?: string;
  errors?: any;
  touched?: any;
  userAddresses: any;
}) => {
  const showGuardianFields = age < 18;
  const { setFieldValue } = useFormikContext();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [getCountriesList] = useLazyGetCountriesListQuery();
  const allCountriesData = useSelector((state: any) => state.countriesAndStates);
  const dispatch = useDispatch();
  const { showToast } = useRootContext();

  const handleFieldChange = (
    fieldName: string,
    value: string | boolean | number | Date,
  ) => {
    setFieldValue(`nominees.${index}.${fieldName}`, value);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const target = event.target as HTMLInputElement;
    const checked = target.checked;
    if (checked) {
      const { address1, address2, city, pincode, state, country } = userAddresses;
      setFieldValue(`nominees.${index}.address`, address1 || '');
      setFieldValue(`nominees.${index}.addressTwo`, address2 || '');
      setFieldValue(`nominees.${index}.city`, city || '');
      setFieldValue(`nominees.${index}.pincode`, pincode || '');
      setFieldValue(`nominees.${index}.state`, state || '');
      setFieldValue(`nominees.${index}.country`, country || '');
    } else {
      // Clear the fields if the checkbox is unchecked
      setFieldValue(`nominees.${index}.address`, '');
      setFieldValue(`nominees.${index}.addressTwo`, '');
      setFieldValue(`nominees.${index}.city`, '');
      setFieldValue(`nominees.${index}.pincode`, '');
      setFieldValue(`nominees.${index}.state`, '');
      setFieldValue(`nominees.${index}.country`, '');
    }
    setIsChecked(checked);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(startLoading());
        if (!allCountriesData?.countries || allCountriesData?.countries.length === 0) {
          const data = await getCountriesList({ all: true }).unwrap();
          dispatch(setCountries(data));
        }
      } catch (error: any) {
        showToast((error.data as { message: string }).message, 'error');
      } finally {
        dispatch(stopLoading());
      }
    };
    fetchData();
  }, []);

  const genderError = errors?.nominees && errors?.nominees?.[index];
  const isTouched = touched?.nominees && touched?.nominees?.[index];

  return (
    <>
      <Grid
        container
        spacing={2}
        mb='10px'
        key={index}
      >
        <Grid
          item
          xs={12}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderRadius: '5px',
              bgcolor: 'text.boxColor',
              padding: '10px 20px',
              height: '52px',
            }}
          >
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: { xs: '14px', xl: '16px' },
                fontWeight: 500,
              }}
            >
              {NOMINEE_NUMBERS[index]} Nominee Details
            </Typography>
            {((index > 0 && !editMode) || onDelete) && (
              <IconButton
                sx={{ float: 'right' }}
                onClick={() => {
                  if (onDelete) onDelete();
                  else {
                    values.nominees.splice(index, 1);
                    setFormCounter?.(formCounter! - 1);
                  }
                }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <FormTextInput
            name={`nominees.${index}.name`}
            label='Nominee Name'
            typeOfInput='singleSpace'
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <FormSelectInput
            name={`nominees.${index}.relation`}
            label='Relationship'
            required
            options={[
              {
                label: 'Father',
                value: 'Father',
              },
              {
                label: 'Son',
                value: 'Son',
              },
              {
                label: 'Spouse',
                value: 'Spouse',
              },
              {
                label: 'Daughter',
                value: 'Daughter',
              },
              {
                label: 'Mother',
                value: 'Mother',
              },
              {
                label: 'Brother',
                value: 'Brother',
              },
              {
                label: 'Others',
                value: 'Others',
              },
            ]}
          />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
        >
          <Typography
            sx={{ fontSize: '16px', fontWeight: 600, color: 'text.valueColor', mb: 0.5 }}
          >
            Gender
          </Typography>
          <Stack
            sx={{
              'flexDirection': 'row',
              'flexWrap': 'wrap',
              'gap': '10px',
              '& .MuiButtonBase-root.MuiChip-root': {
                'padding': '10px 5px',
                'borderRadius': '44px',
                '& .MuiChip-label': {
                  fontSize: '14px',
                  fontWeight: 500,
                },
              },
            }}
          >
            {Object.keys(gendersList).map(gender => (
              <Chip
                key={gender}
                avatar={
                  <Avatar
                    alt={gender.toLowerCase()}
                    src={(gendersList as Record<string, string>)[gender]}
                  />
                }
                label={gender}
                onClick={() => {
                  handleFieldChange('gender', gender);
                }}
                variant='outlined'
                sx={{
                  'border': '1px solid ',
                  'borderColor':
                    values.nominees[index].gender === gender
                      ? 'text.navyBlue'
                      : 'text.borderColorLight',
                  'backgroundColor':
                    values.nominees[index].gender === gender
                      ? 'text.boxColor'
                      : 'common.white',
                  '& .MuiChip-label': {
                    color:
                      values.nominees[index].gender === gender
                        ? 'primary.main'
                        : 'text.primary',
                  },
                }}
              />
            ))}
          </Stack>
          <ErrorText>
            {isTouched &&
              isTouched['gender' as keyof typeof isTouched] &&
              genderError &&
              genderError['gender' as keyof typeof genderError]}
          </ErrorText>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <FormDateInput
            name={`nominees.${index}.dateOfBirth`}
            label='Date of Birth'
            disableFuture
            minDate={minDate}
            disabled
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <FormTextInput
            name={`nominees.${index}.percent`}
            label='Nominee %'
            typeOfInput='number'
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <FormTextInput
            required={false}
            name={`nominees.${index}.pan`}
            label='Nominee PAN (optional)'
            typeOfInput='alphanumeric'
            onInput={(e: any) => {
              if (e.target.value.length > 10) {
                e.target.value = e.target.value.slice(0, 10);
              }
            }}
            onChange={async (e: any) =>
              setFieldValue(`nominees.${index}.pan`, e.target.value?.toUpperCase())
            }
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <FormTextInput
            required={false}
            name={`nominees.${index}.email`}
            label='Email (optional)'
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <FormTextInput
            name={`nominees.${index}.mobile`}
            label='Mobile Number'
            type='number'
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Typography
            sx={{ fontSize: '16px', fontWeight: 600, color: 'text.valueColor' }}
          >
            Address
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Box>
            <FormControlLabel
              sx={{
                '& .MuiTypography-root ': {
                  color: 'text.primary',
                  fontWeight: '500',
                  fontSize: '14px',
                },
              }}
              control={
                <Checkbox
                  sx={{
                    'cursor': 'pointer',
                    '&.Mui-checked': {
                      color: 'primary.main',
                    },
                  }}
                  disableRipple
                  checked={isChecked}
                  onClick={handleClick}
                />
              }
              label='Same as Applicant'
            />
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <FormTextInput
            required={false}
            name={`nominees.${index}.address`}
            label='Address 1 (optional)'
            typeOfInput='singleSpace'
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <FormTextInput
            required={false}
            name={`nominees.${index}.addressTwo`}
            label='Address 2 (optional)'
            typeOfInput='singleSpace'
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <FormTextInput
            required={false}
            name={`nominees.${index}.city`}
            label='City (optional)'
            typeOfInput='singleSpace'
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <FormTextInput
            required={false}
            name={`nominees.${index}.pincode`}
            label='PIN Code (optional)'
            type='number'
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <FormSelectInput
            name={`nominees.${index}.state`}
            label='State (optional)'
            options={statesList.map(item => ({
              label: item.Name.trim(),
              value: item.Name.trim(),
            }))}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        >
          <FormSelectInput
            name={`nomineeDetails.${index}.country`}
            label='Country (optional)'
            options={
              Boolean(allCountriesData?.countries) &&
              allCountriesData?.countries?.length > 0
                ? allCountriesData?.countries.map((country: Country) => ({
                    label: country.name.trim(),
                    value: country.countryValue.trim(),
                  }))
                : []
            }
          />
        </Grid>
        {showGuardianFields && (
          <>
            <Grid
              item
              xs={12}
            >
              <Box
                sx={{
                  borderRadius: '5px',
                  padding: '16px 20px',
                  mt: 2,
                  background:
                    'linear-gradient(90deg, #EEE 0%, rgba(255, 255, 255, 0.00) 100%)',
                }}
              >
                <FieldSubHeader3>Guardian Details</FieldSubHeader3>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
            >
              <FormTextInput
                name={`nominees.${index}.guardianName`}
                label='Guardian Name'
                typeOfInput='singleSpace'
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
            >
              <FormTextInput
                name={`nominees.${index}.guardianPan`}
                label='Guardian PAN'
                typeOfInput='alphanumeric'
                onInput={(e: any) => {
                  if (e.target.value.length > 10) {
                    e.target.value = e.target.value.slice(0, 10);
                  }
                }}
                onChange={async (e: any) =>
                  setFieldValue(
                    `nominees.${index}.guardianPan`,
                    e.target.value?.toUpperCase(),
                  )
                }
              />
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Stack
                direction='row'
                spacing={1}
                sx={{
                  '& .MuiButtonBase-root.MuiChip-root': {
                    'padding': '10px 5px',
                    '& .MuiChip-label': {
                      fontSize: '14px',
                      fontWeight: 500,
                      color: 'text.primary',
                    },
                  },
                }}
              >
                {Object.keys(gendersList).map(gender => (
                  <Chip
                    key={gender}
                    avatar={
                      <Avatar
                        alt={gender.toLowerCase()}
                        src={(gendersList as Record<string, string>)[gender]}
                      />
                    }
                    label={gender}
                    onClick={() => {
                      handleFieldChange('guardianGender', gender);
                    }}
                    variant='outlined'
                    sx={{
                      'border': '1px solid ',
                      'borderColor':
                        values.nominees[index].guardianGender === gender
                          ? 'text.navyBlue'
                          : 'text.borderColorLight',
                      'backgroundColor':
                        values.nominees[index].guardianGender === gender
                          ? 'text.boxColor'
                          : 'common.white',
                      '& .MuiChip-label': {
                        color:
                          values.nominees[index].guardianGender === gender
                            ? 'primary.main'
                            : 'text.primary',
                      },
                    }}
                  />
                ))}
              </Stack>
              <ErrorText>{errors?.nominees?.[index]?.guardianGender}</ErrorText>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <FormSelectInput
                name={`nominees.${index}.guardianRelation`}
                label='Guardian Relation'
                options={[
                  {
                    label: 'Father',
                    value: 'Father',
                  },
                  {
                    label: 'Mother',
                    value: 'Mother',
                  },
                  {
                    label: 'Brother',
                    value: 'Brother',
                  },
                  {
                    label: 'Sister',
                    value: 'Sister',
                  },
                ]}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
