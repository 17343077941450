import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Country {
  name: string;
  countryValue: string;
}
export interface State {
  name: string;
  stateValue: string;
}
export interface CountriesState {
  countries: Country[];
  states: State[];
}
const initialState: CountriesState = {
  countries: [],
  states: [],
};
const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    setCountries(state, action: PayloadAction<Country[]>) {
      state.countries = action.payload;
    },
    setStates(state, action: PayloadAction<State[]>) {
      state.states = action.payload;
    },
  },
});

export const { setCountries, setStates } = countriesSlice.actions;
export default countriesSlice.reducer;
