import {
  BaseQueryApi,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

import { decryptData, encryptData } from '../utils/crypto';

// Define a service using a base URL and expected endpoints

const BASE_URL = `${import.meta.env.VITE_API_BASE_URL || ''}`;
const VITE_IS_ENCRYPT_ENABLED = JSON.parse(
  import.meta.env.VITE_IS_ENCRYPT_ENABLED || 'false',
);

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as any).auth.token;
    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    } else {
      const loginToken = (getState() as any).login.token;
      headers.set('Authorization', `Bearer ${loginToken}`);
    }
    return headers;
  },
});

const noEncryptionUrls = [
  '/get-admin-template',
  '/user/bank-details/cheque-upload',
  'user/document/upload',
  '/admin/bulkUpload-distributors',
];

// Custom base query function
const CustomBaseQuery = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {},
) => {
  if (
    typeof args === 'object' &&
    !noEncryptionUrls.includes(args.url) &&
    args.body &&
    VITE_IS_ENCRYPT_ENABLED
  ) {
    args.body = { encryptedBody: encryptData(JSON.stringify(args.body)) }; // Encrypt the payload
  }
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    localStorage.clear();
    window.location.href = '/auth/login';
  }
  if (
    result.data &&
    typeof result.data === 'object' &&
    'encryptedResponse' in (result.data as any)
  ) {
    result.data = decryptData((result.data as any).encryptedResponse); // Decrypt the response data
  }
  return result;
};

// Define a service using a base URL and expected endpoints
const api = createApi({
  baseQuery: CustomBaseQuery,
  endpoints: () => ({}),
  // endpoints: builder => ({
  //   getAllReports: builder.mutation({
  //     query: payload => ({
  //       url: `/nps`,
  //       method: 'POST',
  //       body: payload,
  //       headers: {
  //         'Content-Type': 'application/json', // Set Content-Type header
  //       },
  //     }),
  //   }),
  // }),
});

export default api;
