import { Box, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { startLoading, stopLoading } from '../../../../common/Loader/loaderSlice';
import { useRootContext } from '../../../../data/root.context';
import FormCheckbox from '../../../../forms/FormCheckbox';
import { FormStyledRadioButton } from '../../../../forms/FormStyledRadioButton';
import SubmitButton from '../../../../forms/FormSubmitButton';
import FormTextInput from '../../../../forms/FormTextInput';
import { useGetIfscDetailsMutation } from '../../../slice';
import { SUCCESS_RESPONSE_CODE } from '..';
import {
  setBankDetails as setBankDetailsContext,
  setBasicDetails,
  setPennyDropTypes,
  setStatusBackToSummary,
  setStepNumber,
} from '../context/NewSubscriberAction';
import { useNewSubscriberContext } from '../context/NewSubscriberContext';
import {
  useGetBankDetailsMutation,
  useSaveBankDetailsMutation,
  useSavePennyDetailsMutation,
} from '../slice';

const BankDetails: React.FC = () => {
  const [getIfscDetails] = useGetIfscDetailsMutation();
  const [savePennyDetails] = useSavePennyDetailsMutation();
  const [saveBankDetails] = useSaveBankDetailsMutation();
  const [getBankDetails] = useGetBankDetailsMutation();
  const { showToast } = useRootContext();
  const {
    state: { ackNumber: AckNumber, accountType: AT, bankDetails: BD, isBackToSummary },
    dispatch: contextDispatch,
  } = useNewSubscriberContext();

  const reduxDispatch = useDispatch();

  const hasTierTwo = AT?.data?.AccountType === '2';
  const T2BankSameAsT1Bank = BD?.data?.T2BankSameAsT1Bank;
  const initialValues = {
    tier1: {
      BankIFSC: BD?.data?.BankIFCS || '',
      BankName: BD?.data?.BankName || '',
      AccountType: BD?.data?.BankAccountTypeDes || 'Savings A/C',
      BankAccountNumber: BD?.data?.BankAccountNumber || '',
      ConfirmBankAccountNumber: BD?.data?.ConfirmBankAccountNumber || '',
      Declaration: false,
    },
    ...(hasTierTwo
      ? {
          T2BankSameAsT1Bank: T2BankSameAsT1Bank ? T2BankSameAsT1Bank === 'Y' : true,
          tier2: {
            BankIFSC: BD?.data?.T2BankIFSC || '',
            BankName: BD?.data?.T2BankName || '',
            AccountType:
              BD?.data?.T2SelectedAccountType === 54 ? 'Current A/C' : 'Savings A/C',
            BankAccountNumber: BD?.data?.T2BankAccountNumber || '',
            ConfirmBankAccountNumber: BD?.data?.T2BankAccountNumber || '',
            Declaration: false,
          },
        }
      : {}),
  };

  const handleIfscChanged = async (
    tier: number,
    ifsc: string,
    setFieldError: (field: string, message: string | undefined) => void,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
  ) => {
    setFieldValue(`tier${tier}.BankIFSC`, ifsc?.toUpperCase());
    if (ifsc.length === 11) {
      try {
        const ifscDetailsResponse: any = await getIfscDetails({
          requestData: {
            ifscCode: ifsc,
          },
        }).unwrap();
        if (ifscDetailsResponse?.data?.[0]) {
          setFieldValue(`tier${tier}.BankName`, ifscDetailsResponse?.data[0].BankName);
        } else {
          setFieldError(`tier${tier}.BankIFSC`, 'Incorrect IFSC entered.');
        }
      } catch (e) {
        setFieldError(`tier${tier}.BankIFSC`, 'Could not validate IFSC code.');
      }
    }
  };

  const _handleSubmit = async (values: any) => {
    try {
      reduxDispatch(startLoading());
      const params = {
        AckNumber,
        AccountType: hasTierTwo ? '2' : '1',
        BankAccountTypeDes: values.tier1.AccountType,
        BankAccountType: values.tier1.AccountType === 'Savings A/C' ? 53 : 54,
        BankAccountNumber: values.tier1.BankAccountNumber,
        ConfirmBankAccountNumber: values.tier1.ConfirmBankAccountNumber,
        BankIFCS: values.tier1.BankIFSC,
        BankName: values.tier1.BankName,
        ...(hasTierTwo
          ? {
              T2BankSameAsT1Bank: values.T2BankSameAsT1Bank ? 'Y' : 'N',
              T2BankAccountType: values.T2BankSameAsT1Bank
                ? values.tier1.AccountType === 'Savings A/C'
                  ? 53
                  : 54
                : values.tier2!.AccountType === 'Savings A/C'
                ? 53
                : 54,
              T2BankAccountNumber: values.T2BankSameAsT1Bank
                ? values.tier1.BankAccountNumber
                : values.tier2!.BankAccountNumber,
              T2ConfirmBankAccountNumber: values.T2BankSameAsT1Bank
                ? values.tier1.ConfirmBankAccountNumber
                : values.tier2!.ConfirmBankAccountNumber,
              T2BankIFSC: values.T2BankSameAsT1Bank
                ? values.tier1.BankIFSC
                : values.tier2!.BankIFSC,
              T2BankName: values.T2BankSameAsT1Bank
                ? values.tier1.BankName
                : values.tier2!.BankName,
            }
          : {}),
      };

      const paramsPenny = {
        beneficiaryIFSC: values.tier1.BankIFSC,
        beneficiaryAccount: values.tier1.BankAccountNumber,
        beneficiaryName: values.tier1.BankName,
        AckNumber,
        tier: 1,
      };
      const responsePenny = await savePennyDetails(paramsPenny).unwrap();

      if (hasTierTwo) {
        const paramsPenny = {
          beneficiaryIFSC: values.T2BankSameAsT1Bank
            ? values.tier1.BankIFSC
            : values.tier2!.BankIFSC,
          beneficiaryAccount: values.T2BankSameAsT1Bank
            ? values.tier1.BankAccountNumber
            : values.tier2!.BankAccountNumber,
          beneficiaryName: values.T2BankSameAsT1Bank
            ? values.tier1.BankName
            : values.tier2!.BankName,
          tier: 2,
          AckNumber,
        };
        const responsePenny = await savePennyDetails(paramsPenny).unwrap();
        contextDispatch(
          setBasicDetails({
            tier2PennyDrop: responsePenny?.data?.data
              ? responsePenny.data
              : responsePenny,
          }),
        );
      }
      const response = await saveBankDetails(params).unwrap();

      if (Number(response.statusCode) !== SUCCESS_RESPONSE_CODE) {
        throw new Error(response?.message || 'Error in saving Bank details');
      }

      const responseBank = await getBankDetails({ AckNumber }).unwrap();

      contextDispatch(
        setPennyDropTypes(responsePenny?.data?.data ? responsePenny.data : responsePenny),
      );

      contextDispatch(setBankDetailsContext(responseBank));

      if (isBackToSummary) {
        contextDispatch(setStatusBackToSummary(false));
      }

      contextDispatch(setStepNumber(7));
    } catch (error) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    } finally {
      reduxDispatch(stopLoading());
    }
  };

  const commonValidationSchema = Yup.object().shape({
    AccountType: Yup.string().required('Account Type is required'),
    BankIFSC: Yup.string()
      .required('IFSC code is required')
      .length(11, 'IFSC code must be 11 characters'),
    BankName: Yup.string().required('Bank Name is required'),
    BankAccountNumber: Yup.string()
      .required('Account number is required')
      .matches(/^[0-9]{9,18}$/, 'Enter a valid Account Number'),
    ConfirmBankAccountNumber: Yup.string()
      .required('Confirm account number is required')
      .oneOf(
        [Yup.ref('BankAccountNumber')],
        'Confirm account number should match account number',
      ),
    Declaration: Yup.boolean().oneOf([true], 'Declaration is required'),
  });

  const validationSchema = Yup.object().shape({
    tier1: commonValidationSchema,
    ...(hasTierTwo
      ? {
          T2BankSameAsT1Bank: Yup.boolean(),
          tier2: commonValidationSchema.when('T2BankSameAsT1Bank', {
            is: true,
            then: schema => Yup.mixed().notRequired(),
            otherwise: schema => schema,
          }),
        }
      : {}),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={_handleSubmit}
    >
      {({ values, setFieldError, setFieldValue }) => {
        return (
          <Form>
            <Box sx={{ padding: { xs: '0px 24px', md: '0px 0px 0px 24px' } }}>
              {[1, ...(hasTierTwo ? [2] : [])].map(tier => (
                <Fragment key={tier}>
                  <Box
                    sx={{
                      backgroundColor: 'text.boxColor',
                      borderRadius: '5px',
                      mb: 2.5,
                      p: 1.5,
                    }}
                  >
                    <Typography
                      sx={{ fontSize: '16px', fontWeight: 700, color: 'text.primary' }}
                    >
                      Tier {tier}
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      fontSize: { xs: '14px', sm: '16px' },
                      fontWeight: 500,
                      color: 'text.primary',
                      mb: 2,
                    }}
                  >
                    Account Type*
                  </Typography>

                  {tier === 2 && (
                    <Box sx={{ mb: 1 }}>
                      <FormCheckbox
                        name={`T2BankSameAsT1Bank`}
                        label='Bank Details same as Tier 1'
                        margin='dense'
                      />
                    </Box>
                  )}
                  {(tier === 1 || !values.T2BankSameAsT1Bank) && (
                    <>
                      <FormStyledRadioButton
                        options={[
                          { label: 'Saving', value: 'Savings A/C' },
                          { label: 'Current', value: 'Current A/C' },
                        ]}
                        name={`tier${tier}.AccountType`}
                      />

                      <Grid
                        container
                        spacing={2}
                        sx={{ mt: 1 }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                        >
                          <FormTextInput
                            onChange={async e => {
                              handleIfscChanged(
                                tier,
                                e.target.value,
                                setFieldError,
                                setFieldValue,
                              );
                            }}
                            name={`tier${tier}.BankIFSC`}
                            label='IFSC Code'
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                        >
                          <FormTextInput
                            name={`tier${tier}.BankName`}
                            label='Bank Name'
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                        >
                          <FormTextInput
                            type='password'
                            name={`tier${tier}.BankAccountNumber`}
                            label='Account number (Masked)'
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                        >
                          <FormTextInput
                            name={`tier${tier}.ConfirmBankAccountNumber`}
                            label='Confirm Account number'
                          />
                        </Grid>
                        <Grid
                          item
                          sm={6}
                        >
                          <FormCheckbox
                            name={`tier${tier}.Declaration`}
                            label='I accept the Terms & Conditions'
                            margin='dense'
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Fragment>
              ))}

              <SubmitButton
                sx={{
                  '&.MuiButtonBase-root': {
                    borderRadius: '5px',
                    minWidth: { xs: '100%', sm: '200px' },
                  },
                }}
                label='Save & Proceed'
              />
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BankDetails;
