import { Box, Grid, Theme, Typography } from '@mui/material';
import { format, isBefore, isValid } from 'date-fns';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import VerifiedIcon from '../../../../assets/verified-cart-icon.png';
import { Investorcard } from '../../../auth/login/styles/styledComponents';
import CustomDialog from '../../../common/AllDialogs/CustomDialog';
import { startLoading, stopLoading } from '../../../common/Loader/loaderSlice';
import { useRootContext } from '../../../data/root.context';
import FormDateInput from '../../../forms/FormDateInput';
import { FormSelectInput } from '../../../forms/FormSelect';
import SubmitButton from '../../../forms/FormSubmitButton';
import { FormPasswordInput } from '../../../forms/FormTextInput';
import { reportTypesOptions } from '../constant';
import {
  useDistributorMailBackListMutation,
  useDistributorReportsMutation,
} from '../slice';
import { Option } from './soa';

export interface ReportForProps {
  amcOptions: Option[];
}

interface ReportsOptions {
  KDM_Repcode: string;
  KDM_Repdesc: string;
}

interface DistributorReport {
  amc: string;
  selectReports: string;
  reportType: string;
  passwordToOpen: string;
  startDate: Date | null;
  endDate: Date | null;
}

function ReportFor({ amcOptions }: ReportForProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { showToast } = useRootContext();
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [reportsOptions, setReportsOptions] = useState<any[]>([]);
  const [resMsg, setResMsg] = useState('');
  const [getDistributorMailbackList] = useDistributorMailBackListMutation();
  const [getDistributorReports] = useDistributorReportsMutation();

  const isFromDashboard = location.state?.isFromDashboard;
  const report = location.state?.report;

  useEffect(() => {
    const fetchMailBackList = async () => {
      try {
        dispatch(startLoading());
        const res: any = await getDistributorMailbackList({
          customfund: '',
        });
        const reportsData = res?.data?.map((type: ReportsOptions) => ({
          label: type.KDM_Repdesc.split(' - ')[1]?.trim(),
          value: type.KDM_Repcode,
        }));
        setReportsOptions(reportsData);
      } catch (error: any) {
        showToast(error?.data?.message, 'error');
      } finally {
        dispatch(stopLoading());
      }
    };
    fetchMailBackList();
  }, []);

  const initialReportValue =
    (reportsOptions?.length > 0 &&
      reportsOptions?.find(item => item?.label === report)?.value) ||
    '';

  const validationSchema = Yup.object().shape({
    amc: Yup.string().required('Fund is required'),
    selectReports: Yup.string().required('Report is required'),
    reportType: Yup.string().required('ReportType is required'),
    passwordToOpen: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[A-Za-z0-9!@#$%^&*()_+]+$/,
        'Password should contain at least 1 Upper Case alphabet(A-Z).1 Special Character and 1 Numeric Value (0-9)',
      ),
    startDate: Yup.date()
      .nullable()
      .typeError('Invalid Date')
      .max(new Date(), 'Start date cannot be in the future')
      .required('Start date is required'),
    endDate: Yup.date()
      .nullable()
      .typeError('Invalid Date')
      .required('End date is required')
      .test('is-greater', 'Start Date should be earlier than End Date', function (value) {
        const { startDate } = this.parent;
        return startDate && value ? isBefore(new Date(startDate), new Date(value)) : true;
      }),
  });

  const handleSubmit = async (values: DistributorReport) => {
    const startDate = values.startDate ? new Date(values.startDate) : null;
    const endDate = values.endDate ? new Date(values.endDate) : null;
    if (!startDate || !isValid(startDate)) {
      showToast('Invalid start date format', 'error');
      return;
    }
    if (!endDate || !isValid(endDate)) {
      showToast('Invalid end date format', 'error');
      return;
    }
    try {
      dispatch(startLoading());
      const payload = {
        fDate: format(startDate, 'dd/MM/yyyy'),
        tDate: format(endDate, 'dd/MM/yyyy'),
        fund: values.amc,
        reportNo: values.selectReports,
        zipPwd: values.passwordToOpen,
        reptype: values.reportType,
      };
      const res = await getDistributorReports(payload).unwrap();
      setResMsg(res?.message);
      setOpenDialogBox(true);
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  return (
    <>
      <Formik
        initialValues={{
          amc: '',
          selectReports: initialReportValue,
          reportType: '',
          passwordToOpen: '',
          startDate: null,
          endDate: null,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, resetForm }) => {
          return (
            <>
              <Form>
                <Typography
                  variant='subtitle1'
                  sx={{ color: 'primary.main', my: 2 }}
                >
                  Mailback Self Reports
                </Typography>
                <Investorcard
                  sx={(theme: Theme) => ({
                    padding: '35px 35px',
                    position: 'relative',
                    [theme.breakpoints.down('sm')]: {
                      boxShadow: 'unset',
                      border: 'unset',
                      padding: '0px',
                    },
                  })}
                >
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormSelectInput
                        name='amc'
                        label='AMC'
                        defaultValue={''}
                        options={amcOptions}
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormSelectInput
                        name='selectReports'
                        label='Select Reports'
                        defaultValue={initialReportValue}
                        disabled={isFromDashboard && initialReportValue !== ''}
                        options={reportsOptions}
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormSelectInput
                        name='reportType'
                        label='Report Type'
                        defaultValue={''}
                        options={reportTypesOptions}
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormPasswordInput
                        name='passwordToOpen'
                        label='Password to Open'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormDateInput
                        name='startDate'
                        label='Start Date'
                        required={true}
                        disableFuture
                        maxDate={yesterday}
                        disabled
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormDateInput
                        name='endDate'
                        label='End Date'
                        required={true}
                        disableFuture
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Box sx={{ display: 'flex', mt: 5 }}>
                    <SubmitButton
                      sx={(theme: Theme) => ({
                        '&.MuiButtonBase-root': {
                          borderRadius: '5px',
                          minWidth: '200px',

                          [theme.breakpoints.down('sm')]: {
                            minWidth: '100%',
                          },
                        },
                      })}
                      label='Submit'
                    />
                  </Box>
                </Investorcard>
              </Form>
              <CustomDialog
                isVisible={openDialogBox}
                showFirstButton={false}
                firstButtonHandler={() => {}}
                firstButtonTitle=''
                showSecondButton
                secondButtonTitle='Continue'
                secondButtonHandler={() => {
                  setOpenDialogBox(false);
                  resetForm();
                }}
                handleClose={() => {}}
              >
                <Box
                  sx={{
                    'textAlign': 'center',
                    'pt': 2,
                    '& img': {
                      maxWidth: { xs: '17%', md: '70%' },
                    },
                  }}
                >
                  <img
                    src={VerifiedIcon}
                    alt='verified-icon'
                  />
                  <Typography
                    variant='subtitle1'
                    mt={2}
                  >
                    {resMsg}
                  </Typography>
                </Box>
              </CustomDialog>
            </>
          );
        }}
      </Formik>
    </>
  );
}

export default ReportFor;
