import { Box, Button, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useField } from 'formik';
import React from 'react';

import { ReactComponent as DeleteIcon } from '../../../assets/delete-icon.svg';
import { useRootContext } from '../../data/root.context';
import { ErrorText } from '../../onboarding/styles/styledComponents';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface FormFileUploadProps {
  name: string;
}

const MIN_FILE_SIZE = 2 * 1024;
const MAX_FILE_SIZE = 2 * 1024 * 1024;
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];

export const FormFileUpload: React.FC<FormFileUploadProps> = ({ name }) => {
  const [{ value }, meta, { setValue }] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : '';
  const { showToast } = useRootContext();

  const validateFileSize = (file: File): boolean => {
    const fileSize = file.size;

    if (fileSize < MIN_FILE_SIZE) {
      showToast(
        `File size must be at least 2KB. Current size: ${(fileSize / 1024).toFixed(2)}KB`,
        'error',
      );
      return false;
    }

    if (fileSize > MAX_FILE_SIZE) {
      showToast(
        `File size must not exceed 2MB. Current size: ${(
          fileSize /
          (1024 * 1024)
        ).toFixed(2)}MB`,
        'error',
      );
      return false;
    }

    return true;
  };

  const validateFileType = (file: File): boolean => {
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      showToast('Only JPG, JPEG, and PNG files are allowed.', 'error');
      return false;
    }
    return true;
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];
    if (!file) return;

    if (validateFileSize(file)) {
      setValue(file);
    } else {
      setValue(null);
      // Reset the input value to allow re-uploading the same file
      event.target.value = '';
    }
  };

  return (
    <Box
      sx={{
        borderRadius: '7px',
        border: '1px solid',
        borderColor: 'text.mainLight',
        padding: '10px',
        minHeight: '250px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
      }}
    >
      <Box
        sx={{
          borderRadius: '7px',
          border: '0.6px dashed',
          borderColor: value ? 'primary.main' : 'text.shadyGray',
          background: value ? 'rgba(32, 87, 166, 0.12)' : 'rgba(200, 200, 200, 0.12)',
          height: '160px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '40px',
          textAlign: 'center',
          position: 'relative',
        }}
      >
        <>
          <Typography
            sx={{
              color: '#6C737F',
              fontSize: '14px',
              fontWeight: '500',
              wordBreak: 'break-word',
            }}
          >
            {value ? value.name : 'No file choosen'}
          </Typography>
          {value && (
            <IconButton
              onClick={async () => setValue(null)}
              sx={{ position: 'absolute', top: '5px', right: '5px' }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </>
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <Button
          variant='contained'
          component='label'
          sx={{
            borderRadius: '7px',
            bgcolor: 'primary.main',
            minWidth: { xs: '100%', xl: '200px' },
            color: 'common.white',
            fontSize: '14px',
            fontWeight: '500',
          }}
        >
          Browse file
          <VisuallyHiddenInput
            type='file'
            id={name}
            accept='image/jpeg, image/jpg, image/png'
            onChange={handleFileChange}
          />
        </Button>
      </Box>
      <ErrorText sx={{ textAlign: 'center' }}>{errorText}</ErrorText>
    </Box>
  );
};
