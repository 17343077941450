import { Box, Grid, IconButton, Stack, Theme, Typography } from '@mui/material';
import { FieldArray, Form, Formik, FormikProps } from 'formik';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as AlertIcon } from '../../../../assets/alert-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/delete-icon.svg';
import { ReactComponent as EditIcon } from '../../../../assets/edit-icon.svg';
import NomineeDetails from '../../../../assets/nominee-details-icon.svg';
import { ReactComponent as CircleAddIcon } from '../../../../assets/rounded-add-icon.svg';
import VerifiedIcon from '../../../../assets/verified-cart-icon.png';
import {
  StepperAddButton,
  StyledSubmitButton,
} from '../../../auth/login/styles/styledComponents';
import CustomDialog from '../../../common/AllDialogs/CustomDialog';
import { NUMBERS_ARRAY } from '../../../common/constants';
import { useRootContext } from '../../../data/root.context';
import { TransactionResponse } from '../../../features/transactions/transactionSlice';
import { calculateAge } from '../../../onboarding/common';
import {
  extendedNomineeValidationSchema,
  NomineeFormSection,
} from '../../../onboarding/NomineeFormContent';
// import { ErrorText } from '../../../onboarding/styles/styledComponents';
import { NomineeDetailsPayload, NomineeList } from '../../../onboarding/types';
import { useSaveNomineesMutation } from '../../slice';

const NOMINEE_NUMBERS = NUMBERS_ARRAY.slice(0, 5);
export const InvestorNominee = ({
  data,
  noNominee,
}: {
  data: any;
  noNominee: boolean;
}) => {
  const { showToast } = useRootContext();
  const navigate = useNavigate();
  const [isNomineesSaved, setIsNomineesSaved] = useState(true);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [saveNominees] = useSaveNomineesMutation();
  const [nomineeEditing, setNomineeEditing] = useState<boolean[]>(
    Array(data.nominees?.length || 0).fill(false),
  );
  const { name, pan, mobile, email } = useSelector(
    (state: { transactions: TransactionResponse }) => state.transactions,
  );
  const { arn } = useSelector((state: { login: any }) => state.login);
  const [requestId, setRequestId] = useState('');

  const formikRef = useRef<FormikProps<NomineeList>>(null);
  const NOMINEE_DETAILS = {
    name: '',
    relation: '',
    gender: '',
    dateOfBirth: '',
    percent: '100',
    pan: '',
    email: '',
    mobile: '',
    address: '',
    addressTwo: '',
    city: '',
    pincode: '',
    state: '',
    country: '',
    guardianName: '',
    guardianPan: '',
    guardianRelation: '',
    guardianGender: '',
  };

  const handleSubmit = async (
    nominees: NomineeDetailsPayload[],
    setSubmitting: (val: boolean) => void,
  ) => {
    try {
      const sumOfPercent = nominees?.reduce(
        (acc, nominee) => acc + Number(nominee.percent),
        0,
      );
      if (!noNominee && sumOfPercent !== 100) {
        showToast(
          'Nominee Share Percentage should not be greater or less than 100',
          'error',
        );
        setSubmitting(false);
        return;
      }
      const payload = {
        arn: arn,
        user: 'alice',
        requestData: {
          email: email,
          mobile: mobile,
          pan: pan,
          distributor_id: arn,
          data: [
            {
              folio: data.folio,
              amc: data.amcId,
              amc_name: data.amcName,
              noNominee: `${noNominee}`,
              nominees:
                !noNominee && nominees.length
                  ? nominees.map((nominee, index) => ({
                      nominee: index + 1,
                      name: nominee.name,
                      percent: `${nominee.percent}`,
                      gender: nominee.gender,
                      dob: nominee.dateOfBirth,
                      pan: nominee.pan,
                      email: nominee.email,
                      address1: nominee.address,
                      address2: nominee.addressTwo,
                      address3: '',
                      city: nominee.city,
                      state: nominee.state,
                      pincode: nominee.pincode,
                      country: nominee.country,
                      relation: nominee.relation,
                      guardian: {
                        name: nominee.guardianName || '',
                        pan: nominee.guardianPan || '',
                        pekrn: nominee.guardianRelation || '',
                        dob: '',
                      },
                    }))
                  : [],
              eNomType: 'F',
              eSignFlag: '',
              eNomDocId: '',
            },
          ],
        },
      };
      const response = await saveNominees(payload).unwrap();
      setRequestId(response?.req_id);
    } catch (error: any) {
      showToast((error?.data as { message: string })?.message, 'error');
      setSubmitting(false);
    }
  };

  const isEditingTrueForAnyForm = () => {
    return nomineeEditing.some((item: any) => item === true);
  };
  return (
    <Box sx={{ marginBottom: '50px' }}>
      <Formik
        initialValues={
          {
            nominees:
              data.nominees?.map((nominee: any) => ({
                name: nominee.name ?? '',
                relation: nominee.relation ?? '',
                gender: nominee.gender ?? '',
                dateOfBirth: nominee.dob ?? '',
                percent: nominee.percent ?? '',
                pan: nominee.pan ?? '',
                mobile: nominee.mobile ?? '',
                address: nominee.address.line1 ?? '',
                addressTwo: nominee.address.line2 ?? '',
                city: nominee.address.city ?? '',
                pincode: nominee.address.pincode ?? '',
                state: nominee.address.state ?? '',
                country: nominee.address.country ?? '',
                guardianName: nominee.guardian.name ?? '',
                guardianPan: nominee.guardian.pan ?? '',
                guardianRelation: nominee.guardian.relation ?? '',
              })) || [],
          } as { nominees: NomineeDetailsPayload[] }
        }
        validationSchema={
          !isEditingTrueForAnyForm() ? null : extendedNomineeValidationSchema
        }
        onSubmit={(values, { setSubmitting }) => {
          if (!isNomineesSaved) {
            setNomineeEditing(prev => prev.map(_ => false));
            setIsNomineesSaved(true);
            setSubmitting(false);
          } else handleSubmit(values.nominees, setSubmitting);
        }}
        innerRef={formikRef}
        // validateOnBlur={false} //Comment to handle error onChange
        // validateOnChange={false}
      >
        {({ values, errors, touched }) => {
          return (
            <Form>
              <FieldArray name='nominees'>
                {({ push, remove }) => (
                  <>
                    {!values.nominees.length ? (
                      <Box
                        sx={{
                          borderRadius: '7px',
                          border: { xs: 'unset', sm: '1px solid' },
                          borderColor: { sm: 'text.borderColorDark' },
                          bgcolor: 'common.white',
                          minHeight: { xs: 'auto', sm: '440px' },
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          py: { xs: 5, sm: 0 },
                        }}
                      >
                        <Stack
                          direction='column'
                          justifyContent='space-between'
                          alignItems='center'
                          gap='20px'
                        >
                          <img
                            src={NomineeDetails}
                            alt='nominee-details-icons'
                            width='150px'
                            height='150px'
                          />
                          <Typography
                            sx={{
                              color: 'text.labelColor',
                              fontSize: { xs: '16px', sm: '18px' },
                              fontWeight: 500,
                              textAlign: 'center',
                              maxWidth: { xs: '275px', sm: '315px' },
                            }}
                          >
                            You do not have Nominee added to this this Folio
                          </Typography>
                          <StepperAddButton
                            sx={{ minWidth: '180px', padding: { xs: '6px 20px' } }}
                            onClick={() => {
                              push(NOMINEE_DETAILS);
                              setNomineeEditing([true]);
                              setIsNomineesSaved(false);
                            }}
                            startIcon={<CircleAddIcon />}
                          >
                            Add Nominee
                          </StepperAddButton>
                        </Stack>
                      </Box>
                    ) : (
                      <>
                        {values.nominees.map((nominee, index) => {
                          if (!nomineeEditing[index]) {
                            return (
                              <Box
                                sx={(theme: Theme) => ({
                                  boxShadow: {
                                    xs: 'unset',
                                    sm: '0px 0px 5px 0px rgba(0, 0, 0, 0.2)',
                                  },
                                  borderRadius: '15px',
                                  marginBottom: { xs: 0, sm: '35px' },
                                  border: {
                                    xs: 'unset',
                                    sm: `0.3px solid ${theme.palette.text.borderColorDark}`,
                                  },
                                })}
                                key={index}
                              >
                                <Box
                                  sx={{
                                    borderRadius: {
                                      xs: '5px',
                                      sm: '15px 15px 0px 0px',
                                    },
                                    bgcolor: 'text.boxColor',
                                    padding: '5px 20px',
                                  }}
                                >
                                  <Stack
                                    direction='row'
                                    justifyContent='space-between'
                                    alignItems='center'
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      sx={{ color: 'primary.main' }}
                                    >
                                      {NOMINEE_NUMBERS[index]} Nominee Details
                                    </Typography>

                                    <Box>
                                      <IconButton
                                        onClick={() => {
                                          setIsNomineesSaved(false);
                                          setNomineeEditing(prev =>
                                            prev.map((nomineeEditing, i) => {
                                              if (i === index) {
                                                return true;
                                              }
                                              return nomineeEditing;
                                            }),
                                          );
                                        }}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                      <IconButton
                                        onClick={() => {
                                          setOpenDialogBox(true);
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Box>
                                  </Stack>
                                </Box>

                                <Box sx={{ padding: { xs: '0px', sm: '5px 20px 20px' } }}>
                                  <Grid
                                    container
                                    my='5px'
                                    spacing={2}
                                    sx={{
                                      '& .MuiTypography-root': {
                                        fontSize: '14px',
                                        color: 'text.darkGray2',
                                      },
                                      '& .MuiTypography-h5 ': {
                                        fontWeight: '400',
                                      },
                                      '& .MuiTypography-body1 ': {
                                        fontWeight: '600',
                                      },
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={12}
                                      sm={4}
                                    >
                                      <Stack gap='10px'>
                                        <Typography variant='h5'>
                                          Nomineee Name{' '}
                                        </Typography>

                                        <Typography component='span'>
                                          {nominee.name}
                                        </Typography>
                                      </Stack>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      sm={4}
                                    >
                                      <Stack gap='10px'>
                                        <Typography variant='h5'>Relation :</Typography>

                                        <Typography component='span'>
                                          {nominee.relation}
                                        </Typography>
                                      </Stack>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      sm={4}
                                    >
                                      <Stack gap='10px'>
                                        <Typography variant='h5'>Mobile No. :</Typography>

                                        <Typography component='span'>
                                          {nominee.mobile ?? 'N/A'}
                                        </Typography>
                                      </Stack>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      sm={4}
                                    >
                                      <Stack gap='10px'>
                                        <Typography variant='h5'>Gender : </Typography>

                                        <Typography component='span'>
                                          {nominee.gender ?? 'N/A'}
                                        </Typography>
                                      </Stack>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      sm={4}
                                    >
                                      <Stack gap='10px'>
                                        <Typography variant='h5'>Share :</Typography>

                                        <Typography component='span'>
                                          {nominee.percent}%
                                        </Typography>
                                      </Stack>
                                      {/* <ErrorText>Exceeding 100%</ErrorText> */}
                                    </Grid>

                                    <Grid
                                      item
                                      xs={12}
                                      sm={4}
                                    >
                                      <Stack gap='10px'>
                                        <Typography variant='h5'>Address :</Typography>

                                        <Typography component='span'>
                                          {nominee.address ?? 'N/A'}
                                        </Typography>
                                      </Stack>
                                    </Grid>
                                  </Grid>
                                </Box>
                                <CustomDialog
                                  isVisible={openDialogBox}
                                  firstButtonHandler={() => setOpenDialogBox(false)}
                                  secondButtonHandler={() => {
                                    remove(index);
                                    setNomineeEditing(prev =>
                                      prev.filter((_, i) => i !== index),
                                    );
                                    setOpenDialogBox(false);
                                  }}
                                  firstButtonTitle='Cancel'
                                  secondButtonTitle='Ok'
                                  handleClose={() => {}}
                                  showFirstButton={true}
                                  showSecondButton={true}
                                >
                                  <Stack
                                    display='flex'
                                    direction='row'
                                    alignItems='center'
                                    gap='10px'
                                  >
                                    <AlertIcon />
                                    <Typography variant='h5'>Deleting Nominee</Typography>
                                  </Stack>
                                  <Typography
                                    variant='subtitle1'
                                    sx={{
                                      fontWeight: 400,
                                      color: 'text.labelColor',
                                      my: 3,
                                    }}
                                  >
                                    Are you want to remove the Nominee for your mutual
                                    fund investment?
                                  </Typography>
                                </CustomDialog>
                              </Box>
                            );
                          }
                          return (
                            <NomineeFormSection
                              key={index}
                              values={values}
                              touched={touched}
                              editMode={index > 0}
                              age={calculateAge(nominee.dateOfBirth)}
                              header={`${NOMINEE_NUMBERS[index]} Nominee Details`}
                              index={index}
                              onDelete={
                                index > 0
                                  ? () => {
                                      remove(index);
                                      setNomineeEditing(prev =>
                                        prev.filter((_, i) => i !== index),
                                      );
                                    }
                                  : undefined
                              }
                              name='Nominee'
                              errors={errors}
                              userAddresses={{
                                address1: data.address.line1 ?? '',
                                address2: data.address.line2 ?? '',
                                city: data.address.city ?? '',
                                pincode: data.address.pincode ?? '',
                                state: data.address.state ?? '',
                                country: data.address.country ?? '',
                              }}
                            />
                          );
                        })}
                        <Stack
                          direction='row'
                          gap='20px'
                          sx={{ flexDirection: { md: 'row' } }}
                        >
                          {values.nominees.length < 3 && !noNominee && (
                            <StepperAddButton
                              sx={{
                                minWidth: { xs: 'unset', sm: '260px' },
                                my: 3,
                                flex: { xs: 1, sm: 'unset' },
                              }}
                              startIcon={<CircleAddIcon />}
                              onClick={() => {
                                const percentage =
                                  100 -
                                  values.nominees.reduce(
                                    (acc: number, nominee: any) =>
                                      acc + Number(nominee.percent),
                                    0,
                                  );
                                push({
                                  ...NOMINEE_DETAILS,
                                  percent: percentage > 0 ? `${percentage}` : 0,
                                });
                                setNomineeEditing(prev => [...prev, true]);
                                setIsNomineesSaved(false);
                              }}
                            >
                              Add Nominee
                            </StepperAddButton>
                          )}
                          <StyledSubmitButton
                            label={noNominee || isNomineesSaved ? 'Submit' : 'Save'}
                          />
                        </Stack>
                      </>
                    )}
                  </>
                )}
              </FieldArray>
            </Form>
          );
        }}
      </Formik>
      {!!requestId && (
        <CustomDialog
          isVisible={true}
          showFirstButton={false}
          firstButtonTitle=''
          firstButtonHandler={() => {}}
          secondButtonHandler={() => navigate('../')}
          secondButtonTitle='OK'
          handleClose={() => {}}
          showSecondButton
        >
          <Box
            sx={{
              'textAlign': 'center',
              'pt': 2,
              '& .MuiTypography-root': {
                'my': 2,
                '& >*': {
                  fontWeight: '600',
                },
              },
              '& img': {
                width: { xs: '58px', sm: 'unset' },
              },
            }}
          >
            <img
              src={VerifiedIcon}
              alt='verified-icon'
            />
            <Typography>
              Your request for{' '}
              <Typography component={'span'}>Updating the Nominee details</Typography> is
              created successfully with request id{' '}
              <Typography component={'span'}>{requestId}</Typography>
            </Typography>
            <Typography>
              A Link has been sent to the Investor Email ID. After investor authorises the
              transaction, details will be updated after T+1 day.
            </Typography>
          </Box>
        </CustomDialog>
      )}
    </Box>
  );
};
