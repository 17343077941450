import { Box, Divider, Grid, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { Form, Formik, FormikProps } from 'formik';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import VerifiedIcon from '../../../assets/verified-cart-icon.png';
import {
  calculateDifference,
  calculateNextInstallmentDate,
  calculateTatDate,
  disableDatesOnStartDate,
  formatToMMDDYYYYByUTC,
  Frequency,
  getDayFromDate,
  toUTCDate,
} from '../../../utils';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import { toFixedToNumber } from '../../common/constants';
import { startLoading, stopLoading } from '../../common/Loader/loaderSlice';
import { useRootContext } from '../../data/root.context';
import { LoginResponse } from '../../features/login/loginSlice';
import { TransactionResponse } from '../../features/transactions/transactionSlice';
import FormDateInput from '../../forms/FormDateInput';
import { FormSelectInput } from '../../forms/FormSelect';
import { FormStyledRadioButton } from '../../forms/FormStyledRadioButton';
import FormTextInput from '../../forms/FormTextInput';
import { FieldSubHeader3, MainHeader } from '../../onboarding/styles/styledComponents';
import {
  findMinInstallmentAllowed,
  processFrequencyData,
} from '../additionalPurchase/common';
import { DropDownData, MinInstallmentsAllowed } from '../additionalPurchase/types';
import ClearUnitsBox from '../common/ClearUnitsBox';
// import ClearUnitsBox from '../common/ClearUnitsBox';
import SubmitCartButtons from '../common/SubmitCartButtons';
import EuinsFields from '../euinsComponent';
import { useSchemeByfundMutation } from '../mfs/newInvestorOnboarding/slice';
import { mapFrequency, mapFrequencyToLetter } from '../mfs/newInvestorOnboarding/utils';
import {
  useAddItemToCartMutation,
  // useGetEUINSQuery,
  useGetIndividualSchemeDetailsMutation,
  useGetSchemaDetailsMutation,
  useListOfSchemesMutation,
  useModifyItemInCartMutation,
  useValidateDatesMutation,
} from '../slice';
import { InvestorSchemeDetails } from '../switch/types';
import { capitalizeFirstLetter, capitalizeWords, removeSpaces } from '../swp/common';
import { useGetSWPwithdrawalTypeMutation } from '../swp/slice';
import { FIELD_NAMES, InitialValues, InvestmentDetailsFormProps } from './utils';

const InvestmentDetailsForm: React.FC<InvestmentDetailsFormProps> = ({
  data,
  isEditMode,
  handleCancelModifyingCart,
}) => {
  const [stpOptions, setStpOptions] = useState<string[]>([
    'Fixed',
    'Capital Appreciation',
  ]);
  const [addStpItemToCart] = useAddItemToCartMutation();
  const [getSchemaDetails] = useGetSchemaDetailsMutation();
  const { showToast } = useRootContext();

  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [schemeDetails, setSchemeDetails] = useState<any>({});
  const [schemeList, setSchemeList] = useState<any>([]);
  const [investorSchemeData, setInvestorSchemeData] =
    useState<InvestorSchemeDetails | null>();

  const [frequencyType, setFrequencyType] = useState(
    data?.frequency ? mapFrequency(data?.frequency?.toUpperCase()) : '',
  );
  const firstButtonTitle = !isEditMode ? 'Add to Cart' : 'Cancel';
  const secondButtonTitle = !isEditMode ? 'Save & Proceed' : 'Save';
  const [calculatedInstallments, setCalculatedInstallments] = useState<number>(
    data?.noOfTransfers || 0,
  );
  const navigate = useNavigate();
  const formikRef = useRef<FormikProps<InitialValues>>(null);
  const { arn, subArn, subbrokerCode, euin } = useSelector(
    (state: { login: LoginResponse }) => state.login,
  );
  const [calendarOpeningMonths, setCalendarOpeningMonths] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [modifyItemInCart] = useModifyItemInCartMutation();

  const [getWithdrawalType] = useGetSWPwithdrawalTypeMutation();
  const [individualSchemeDetails] = useGetIndividualSchemeDetailsMutation();
  const [validateDates] = useValidateDatesMutation();

  const { name: investorName, pan } = useSelector(
    (state: { transactions: TransactionResponse }) => state.transactions,
  );

  const [listOfSchemes] = useListOfSchemesMutation();
  const [selectedCategory, setSelectedCategory] = useState(
    data.selectedCategory || data.category,
  );
  const [schemesByFund, setSchemesByFund] = useState<any>([]);
  const [getSchemesByFund] = useSchemeByfundMutation();
  const [switchoutScheme, SelectedSwitchoutscheme] = useState<any>(data);
  const [switchInScheme, SelectedSwitchInScheme] = useState<any>(data);
  const firstChipCall = useRef<boolean>(false);
  const [categoriesData, setCategories] = useState(data.categories);
  const dispatch = useDispatch();
  const [allDropDownData, setAllDropDownData] = useState<DropDownData>({});
  const [minDetailsObj, setMinDetailsObj] = useState<MinInstallmentsAllowed>(
    {} as MinInstallmentsAllowed,
  );
  const [hasAnyErrorOccured, setHasAnyErrorOccured] = useState<boolean>(false);
  const totalAvailableAmount = (
    Number(investorSchemeData?.units) * Number(investorSchemeData?.nav)
  )?.toFixed(2);

  const getWithdrawalTypes = useCallback(async (fund: string) => {
    const res = await getWithdrawalType({ fund }).unwrap();
    if (res.error) {
      throw new Error(res.error.data.message);
    }
    const descriptions = res.map((item: { description: string }) =>
      capitalizeWords(item.description || ''),
    );
    const sortedDescriptions = descriptions.sort((a: string, b: string) =>
      a.toLowerCase() === 'fixed' ? -1 : b.toLowerCase() === 'fixed' ? 1 : 0,
    );
    setStpOptions(sortedDescriptions);
  }, []);

  const fetchSchemaDetails = useCallback(async () => {
    const result = await getSchemaDetails({
      fund: data.fund,
      scheme: data.scheme,
      plan: data.plan,
      option: data.option,
      trType: 'STP',
      toScheme: data.scheme,
    }).unwrap();
    if (result.error) {
      throw new Error(result.error.data.message);
    }
    setSchemeDetails(result);
  }, [data.fund, data.scheme, data.plan, data.option]);

  const fetchInvestorSchemeDetails = useCallback(async () => {
    const result = await individualSchemeDetails({
      pan: pan,
      folio: switchoutScheme?.folio,
      fund: switchoutScheme?.fund,
      trtype: 'STP',
      scheme: switchoutScheme?.scheme,
      option: switchoutScheme?.option,
      plan: switchoutScheme?.plan,
    }).unwrap();
    if (result.error) {
      throw new Error(result.error.data.message);
    }
    setInvestorSchemeData(result);
  }, [pan, switchoutScheme]);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        dispatch(startLoading());
        const promises = [];
        if (data?.fund) {
          promises.push(getWithdrawalTypes(data.fund));
        }
        if (data?.scheme) {
          promises.push(fetchSchemaDetails());
        }
        if (data?.scheme) {
          promises.push(fetchInvestorSchemeDetails());
        }
        await Promise.all(promises);
      } catch (error: any) {
        const message = error?.data?.message || error.message || 'Unknown error';
        showToast(message, 'error');
        setHasAnyErrorOccured(true);
      } finally {
        dispatch(stopLoading());
      }
    };

    if (data?.fund) {
      fetchDetails();
    }
  }, [data, getWithdrawalTypes, fetchSchemaDetails, fetchInvestorSchemeDetails]);

  useEffect(() => {
    async function fetchData() {
      if (!pan) {
        return;
      }
      try {
        dispatch(startLoading());
        const response = await listOfSchemes({ pan: pan.toUpperCase() }).unwrap();
        const filteredData = response
          ?.map((item: any) => ({
            ...item,
            key: nanoid(),
          }))
          ?.filter((scheme: any) => scheme.stpAllow && scheme.fund === data.fund);
        setSchemeList(filteredData);
      } catch (error: any) {
        const message =
          (error as any).data?.message || (error as any).message || 'Unknown error';
        showToast(message, 'error');
      } finally {
        dispatch(stopLoading());
      }
    }
    if (isEditMode) fetchData();
  }, []);

  useEffect(() => {
    async function fetchSchemesByFund() {
      try {
        dispatch(startLoading());
        const schemeByFundBody = {
          funds: [data.fund],
          investorPan: '',
          start: 0,
          end: 20000,
        };
        const resFund = await getSchemesByFund(schemeByFundBody).unwrap();
        // Initializing it with the first category.
        if (!firstChipCall.current) {
          const arrangedObj: { [key: string]: any[] } = {};
          for (const item of resFund) {
            const { fund } = item;
            if (!arrangedObj[fund]) {
              arrangedObj[fund] = [item];
            } else {
              arrangedObj[fund].push(item);
            }
          }
          const uniqueCategories: string[] = [
            ...new Set(arrangedObj[data.fund]?.map(item => item.category) || []),
          ];
          setCategories(uniqueCategories);
          firstChipCall.current = true;
        }
        setSchemesByFund(resFund);
      } catch (error: any) {
        const message =
          (error as any).data?.message || (error as any).message || 'Unknown error';
        showToast(message, 'error');
      } finally {
        dispatch(stopLoading());
      }
    }
    if (isEditMode) fetchSchemesByFund();
  }, []);

  const getSwitchInSchemes = (isExistingScheme: 'E' | 'N', folioNumber: number) => {
    if (isExistingScheme === 'E') {
      return schemeList.filter(
        (scheme: any) =>
          `${scheme.scheme}_${scheme.plan}_${scheme.option}` !==
          `${formikRef.current?.values.switchOutScheme}`,
      );
    } else {
      return schemesByFund.filter(
        (scheme: { category: string }) => scheme.category === selectedCategory,
      );
    }
  };

  const minTransactionAmount =
    minDetailsObj?.minTransactionAmount || data?.minTransactionAmount || 0;
  const minInstallments = minDetailsObj?.minInstallments || data?.minInstallments || 0;
  const [, coolingPeriod]: [string[], number] = useMemo(() => {
    const frequency = schemeDetails?.frequency?.find(
      (f: any) => removeSpaces(mapFrequency(f.mode?.toUpperCase())) === frequencyType,
    );
    const cycle = frequency?.cycle;
    const dates =
      !cycle || !/\d/.test(cycle)
        ? Array.from({ length: 28 }, (_, index) => `${index + 1}`)
        : cycle.split(',');

    return [
      dates,
      // frequency?.minInstallments,
      // frequency?.minTransactionAmount,
      frequency?.coolingPeriod,
    ];
  }, [schemeDetails?.frequency, frequencyType]);
  const stpModes: string[] = useMemo(() => {
    if (schemeDetails?.frequency) {
      const frequencyDataObject = processFrequencyData(schemeDetails?.frequency);
      setAllDropDownData(frequencyDataObject);
    }
    const modes = schemeDetails?.frequency?.map((f: any) =>
      removeSpaces(capitalizeFirstLetter(mapFrequency(f.mode?.toUpperCase()))),
    );
    return Array.from(new Set(modes));
  }, [schemeDetails?.frequency]);

  const handleAddToCartClick = async () => {
    const errors: any = await formikRef.current?.validateForm();
    if (Object.keys(errors).length > 0) {
      formikRef.current?.setTouched(errors);
      return;
    }
    try {
      dispatch(startLoading());
      const payload = {
        ...(data.extraData ? data.extraData : {}),
        pan,
        mobile: data?.stpOutData?.mobile,
        email: data?.stpOutData?.email,
        folio: data.folio,
        fund: data.fund,
        fundName: data.fundName,
        scheme: data.scheme,
        plan: data.plan,
        option: data.option,
        toScheme: data.toScheme,
        toPlan: data.toPlan,
        toOption: data.toOption,
        amount:
          formikRef.current?.values.stpOption?.toLowerCase() === 'fixed'
            ? String(formikRef.current?.values.amount)
            : '0',
        subBroker: formikRef.current?.values.subBroker || '',
        subArn: formikRef.current?.values.subArn || '',
        euin: formikRef.current?.values.euin || '',
        transactionType: 'STP',
        category: data.category,
        stpOption: formikRef.current?.values.stpOption,
        frequency: formikRef.current?.values.frequency[0],
        stpDay: getDayFromDate(formikRef.current?.values.stpStartDate as Date),
        stpStartDate: formatToMMDDYYYYByUTC(
          formikRef.current?.values.stpStartDate as Date,
        ),
        stpEndDate: formatToMMDDYYYYByUTC(formikRef.current?.values.stpEndDate as Date),
        noOfTransfers: calculatedInstallments || 0,
        investorName,
        switchInSchemeName: data.switchInSchemeName,
        switchOutSchemeName: data.switchOutSchemeName,
        taxSaverFlag: data.taxSaverFlag,
        unitsValue: data.stpOutData?.units,
        navValue: data.stpOutData?.nav,
        minTransactionAmount: minTransactionAmount,
        minInstallments: minInstallments,
      };

      await addStpItemToCart(payload).unwrap();
      setOpenDialogBox(true);
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

  async function handleSubmit(values: InitialValues) {
    try {
      const payload = isEditMode
        ? {
            pan,
            mobile: switchoutScheme ? switchoutScheme?.mobile : data?.stpOutData?.mobile,
            email: switchoutScheme ? switchoutScheme?.email : data?.stpOutData?.email,
            folio: switchoutScheme ? switchoutScheme?.folio : data.folio,
            fund: switchoutScheme ? switchoutScheme?.fund : data.fund,
            fundName: data.fundName,

            scheme: switchoutScheme ? switchoutScheme?.scheme : data.scheme,
            plan: switchoutScheme ? switchoutScheme?.plan : data.plan,
            option: switchoutScheme ? switchoutScheme?.option : data.option,

            toScheme: !switchInScheme?.arnCode ? switchInScheme?.scheme : data.toScheme,
            toPlan: !switchInScheme?.arnCode ? switchInScheme?.plan : data.toPlan,
            toOption: !switchInScheme?.arnCode ? switchInScheme?.option : data.toOption,

            amount:
              values.stpOption?.toLowerCase() === 'fixed' ? String(values.amount) : '0',
            subBroker: values.subBroker || '',
            euin: values.euin || '',
            subArn: values.subArn || '',
            transactionType: 'STP',
            category: selectedCategory,
            stpOption: values.stpOption,
            frequency: values.frequency[0],
            stpDay: String(getDayFromDate(values.stpStartDate as Date)),
            stpStartDate: formatToMMDDYYYYByUTC(values.stpStartDate as Date),
            stpEndDate: formatToMMDDYYYYByUTC(values.stpEndDate as Date),
            noOfTransfers: calculatedInstallments || 0,
            investorName,
            switchInSchemeName: !switchInScheme?.arnCode
              ? `${switchInScheme?.schemeDesc || switchInScheme?.schemeName} - ${
                  switchInScheme?.planMode
                } - ${switchInScheme?.optionDesc}`
              : data.switchInSchemeName,
            switchOutSchemeName: !switchoutScheme?.arnCode
              ? `${switchoutScheme?.schemeDesc || switchoutScheme?.schemeName} - ${
                  switchoutScheme?.planMode
                } - ${switchoutScheme?.optionDesc}`
              : data.switchOutSchemeName,
            taxSaverFlag: `${data.taxSaverFlag}`,
            isExistingScheme: values.isExistingScheme,
            cartId: data?.cartId || '',
            cartItemId: data?.cartItemId || '',
            withdrawalType: data?.stpOption || '',
            unitsValue: switchoutScheme && (switchoutScheme?.units || data?.unitsValue),
            navValue: switchoutScheme && (switchoutScheme?.nav || data?.navValue),
            minInstallments: minInstallments,
            minTransactionAmount: minTransactionAmount,
          }
        : {
            pan,
            mobile: data?.stpOutData?.mobile,
            email: data?.stpOutData?.email,
            folio: data.folio,
            fund: data.fund,
            fundName: data.fundName,
            scheme: data.scheme,
            plan: data.plan,
            option: data.option,
            toScheme: data.toScheme,
            toPlan: data.toPlan,
            toOption: data.toOption,
            amount:
              values.stpOption?.toLowerCase() === 'fixed' ? String(values.amount) : '0',
            subBroker: values.subBroker || '',
            euin: values.euin || '',
            subArn: values.subArn || '',
            transactionType: 'STP',
            category: data.category,
            stpOption: values.stpOption,
            frequency: values.frequency[0],
            stpDay: String(getDayFromDate(values.stpStartDate as Date)),
            stpStartDate: formatToMMDDYYYYByUTC(values.stpStartDate as Date),
            stpEndDate: formatToMMDDYYYYByUTC(values.stpEndDate as Date),
            noOfTransfers: calculatedInstallments || 0,
            investorName,
            switchInSchemeName: data.switchInSchemeName,
            switchOutSchemeName: data.switchOutSchemeName,
            taxSaverFlag: `${data.taxSaverFlag}`,
          };

      if (isEditMode) {
        try {
          dispatch(startLoading());
          await modifyItemInCart(payload).unwrap();
          showToast(`CartItem updated successfully`, 'success');
          if (handleCancelModifyingCart) handleCancelModifyingCart();
        } catch (error: any) {
          const message =
            (error as any).data?.message || (error as any).message || 'Unknown error';
          showToast(message, 'error');
        } finally {
          dispatch(stopLoading());
        }
      } else {
        navigate('/transaction-dashboard/confirmation', {
          state: { payload: payload, isEditMode: isEditMode },
        });
      }
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  }

  const handleGoToCart = () => {
    navigate('../../cart');
  };

  const handleAddAnotherTransaction = () => {
    navigate('../');
  };
  const resetLaterFields = (
    values: InitialValues,
    touched: Record<string, boolean>, // New parameter for touched fields
    key: any,
    fieldValues: Partial<InitialValues>,
  ): { updatedValues: InitialValues; updatedTouched: Record<string, boolean> } => {
    const index = FIELD_NAMES.findIndex(fieldName => fieldName === key);

    // Reset form values based on FIELD_NAMES
    const updatedValues = FIELD_NAMES.slice(index).reduce<Partial<InitialValues>>(
      (acc, curr) => {
        return {
          ...acc,
          [curr]: curr === 'stpStartDate' || curr === 'stpEndDate' ? null : '',
        };
      },
      {},
    );

    // Reset touched fields similarly
    const updatedTouched = FIELD_NAMES.slice(index).reduce<Record<string, boolean>>(
      (acc, curr) => {
        acc[curr] = false; // Set touched to false
        return acc;
      },
      {},
    );

    return {
      updatedValues: { ...values, ...updatedValues, ...fieldValues },
      updatedTouched,
    };
  };

  const validationSchema = Yup.object().shape({
    stpOption: Yup.string()
      .oneOf(['Fixed', 'Capital Appreciation'], 'Select one option')
      .required('STP Option is required'),

    frequency: Yup.string()
      .oneOf(stpModes ?? [], 'Select one option')
      .required('STP Frequency is required'),

    amount: Yup.string().when('stpOption', {
      is: 'Fixed',
      then: Yup.string().required('Amount is required'),
      otherwise: Yup.string().nullable(), // Allow amount to be nullable when not required
    }),
    stpStartDate: Yup.string()
      .nullable() // Mark stpStartDate as nullable
      .required('STP start date is required'),

    stpEndDate: Yup.string()
      .nullable() // Mark stpEndDate as nullable
      .required('STP end date is required'),
  });

  const initialValues: InitialValues = {
    stpOption: isEditMode ? data?.stpOption : 'Fixed',
    frequency: isEditMode ? mapFrequency(data?.frequency) : frequencyType || stpModes[0],
    amount: isEditMode ? data?.amount : '',
    // stpDay: isEditMode ? data?.stpDay : stpDates[0],
    stpStartDate: isEditMode ? data?.stpStartDate : null,
    stpEndDate: isEditMode ? data?.stpEndDate : null,
    euin: euin,
    // euinValue: euin,
    subArn: subArn,
    subBroker: subbrokerCode,
    isExistingScheme: data.isExistingScheme,
    folioNumber: data.folio,
    switchOutScheme: `${data.scheme}_${data.plan}_${data.option}`,
    switchInScheme: `${data.toScheme}_${data.toPlan}_${data.toOption}`,
    category: data.selectedCategory || data.category,
    amc: data.fundName,
  };

  const handleValidation = async (values: any) => {
    const errors: Record<string, any> = {};

    if (values.stpOption?.toLowerCase() !== 'fixed') {
      return errors;
    }

    if (Number(values.amount) < Number(minTransactionAmount)) {
      errors.amount = `Investment amount should not greater than ${toFixedToNumber(
        minTransactionAmount,
        2,
      )}`;
    }

    if (Number(values.amount) > Number(totalAvailableAmount)) {
      errors.amount = `Investment amount should not exceed ${toFixedToNumber(
        totalAvailableAmount,
        2,
      )}`;
    }
    return errors;
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      innerRef={formikRef}
      validate={async values => handleValidation(values)}
    >
      {({ values, setFieldValue, errors, setValues, touched, setTouched }) => (
        <Form>
          <Grid
            container
            spacing={2}
            mb={'24px'}
          >
            {isEditMode && (
              <>
                <Grid
                  item
                  sm={6}
                >
                  <FormTextInput
                    name='amc'
                    label='AMC'
                    disabled
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                >
                  <FormSelectInput
                    name='folioNumber'
                    label='folioNumber'
                    options={schemeList.map((scheme: any) => ({
                      label: scheme.folio,
                      value: scheme.folio,
                    }))}
                    onChange={(e: any) => {
                      setCalculatedInstallments(0);
                      const { updatedValues, updatedTouched } = resetLaterFields(
                        values,
                        touched,
                        'folioNumber',
                        {
                          folioNumber: e.target.value,
                        },
                      );
                      setValues(updatedValues);
                      setTouched(updatedTouched);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                >
                  <FormSelectInput
                    name='switchOutScheme'
                    label='Switch Out Scheme'
                    options={schemeList
                      .filter((scheme: any) => scheme.folio === values.folioNumber)
                      .map((scheme: any) => ({
                        label: `${scheme.schemeName} - ${scheme.planMode} - ${scheme.optionDesc}`,
                        value: `${scheme.scheme}_${scheme.plan}_${scheme.option}`,
                      }))}
                    onChange={(e: any) => {
                      const [scheme, plan, option] = e.target.value.split('_');
                      const { updatedValues, updatedTouched } = resetLaterFields(
                        values,
                        touched,
                        'switchOutScheme',
                        {
                          switchOutScheme: e.target.value,
                        },
                      );
                      setValues(updatedValues);
                      setTouched(updatedTouched);

                      const filteredResult = schemeList.filter(
                        (schemes: any) =>
                          schemes.scheme === scheme &&
                          schemes.plan === plan &&
                          schemes.option === option,
                      );
                      SelectedSwitchoutscheme(filteredResult?.[0]);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                  mb={2}
                >
                  <Box sx={{ bgcolor: 'text.boxColor', padding: '20px 15px' }}>
                    <Grid
                      container
                      spacing={2}
                      alignItems={'center'}
                    >
                      <Grid
                        item
                        sm={3}
                      >
                        <Stack sx={{ flexDirection: 'row', columnGap: '20px' }}>
                          <Typography sx={{ color: 'text.labelColor' }}>
                            Balance Units:
                          </Typography>
                          <Typography sx={{ color: 'text.valueColor', fontWeight: 500 }}>
                            {values.switchOutScheme
                              ? switchoutScheme?.arnCode
                                ? data?.unitsValue
                                : switchoutScheme?.units
                              : 0}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        sm={3}
                      >
                        <Stack sx={{ flexDirection: 'row', columnGap: '20px' }}>
                          <Typography sx={{ color: 'text.labelColor' }}>
                            Current Value:
                          </Typography>
                          <Typography sx={{ color: 'text.valueColor', fontWeight: 500 }}>
                            {values.switchOutScheme
                              ? (
                                  (switchoutScheme?.arnCode
                                    ? data?.unitsValue ?? 0
                                    : switchoutScheme?.units ?? 0) *
                                  (switchoutScheme?.arnCode
                                    ? data?.navValue ?? 0
                                    : switchoutScheme?.nav ?? 0)
                                ).toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        sm={3}
                      >
                        <Stack sx={{ flexDirection: 'row', columnGap: '20px' }}>
                          <Typography sx={{ color: 'text.labelColor' }}>
                            NAV as on Date:
                          </Typography>
                          <Typography sx={{ color: 'text.valueColor', fontWeight: 500 }}>
                            {formatToMMDDYYYYByUTC()}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid
                  item
                  sm={12}
                >
                  <FormStyledRadioButton
                    options={[
                      { label: 'Existing Scheme & Plan', value: 'E' },
                      { label: 'New Scheme & Plan', value: 'N' },
                    ]}
                    name='isExistingScheme'
                  />
                </Grid>
                {values.isExistingScheme === 'N' && (
                  <Grid
                    item
                    sm={6}
                  >
                    <FormSelectInput
                      name='category'
                      label='Category'
                      options={categoriesData?.map((category: string) => ({
                        label: category,
                        value: category,
                      }))}
                      onChange={(e: SelectChangeEvent<any>) => {
                        const { updatedValues, updatedTouched } = resetLaterFields(
                          values,
                          touched,
                          'category',
                          {},
                        );
                        setValues(updatedValues);
                        setTouched(updatedTouched);
                        setValues(values => ({
                          ...values,
                          category: e.target.value,
                          switchInScheme: '',
                        })),
                          setSelectedCategory(e.target.value);
                      }}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  sm={6}
                >
                  <FormSelectInput
                    name='switchInScheme'
                    label='Switch In Scheme'
                    options={getSwitchInSchemes(
                      values.isExistingScheme,
                      values.folioNumber,
                    ).map((scheme: any) => ({
                      label: `${scheme.schemeName || scheme.schemeDesc} - ${
                        scheme.planMode
                      } - ${scheme.optionDesc}`,
                      value: `${scheme.scheme}_${scheme.plan}_${scheme.option}`,
                    }))}
                    onChange={(e: any) => {
                      const [scheme, plan, option] = e.target.value.split('_');
                      const { updatedValues, updatedTouched } = resetLaterFields(
                        values,
                        touched,
                        'switchInScheme',
                        {
                          switchInScheme: e.target.value,
                        },
                      );
                      setValues(updatedValues);
                      setTouched(updatedTouched);
                      setCalculatedInstallments(0);
                      const filteredResult = getSwitchInSchemes(
                        values.isExistingScheme,
                        values.folioNumber,
                      ).filter(
                        (schemes: any) =>
                          schemes.scheme === scheme &&
                          schemes.plan === plan &&
                          schemes.option === option,
                      );

                      SelectedSwitchInScheme(filteredResult?.[0]);
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid
            container
            spacing={2}
            mb={'24px'}
          >
            <Grid
              item
              sm={6}
            >
              <Typography
                sx={{
                  color: 'text.valueColor',
                  mb: 1.5,
                  fontWeight: 500,
                  fontSize: { xs: '14px', xl: '16px' },
                }}
              >
                STP Type
              </Typography>
              <FormStyledRadioButton
                options={stpOptions.map(item => ({
                  label: item,
                  value: item,
                }))}
                name='stpOption'
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Typography
                sx={{
                  color: 'text.valueColor',
                  mb: 1,
                  fontWeight: 500,
                  fontSize: { xs: '14px', xl: '16px' },
                }}
              >
                STP Mode
              </Typography>
              <FormSelectInput
                name='frequency'
                label='STP Mode'
                onChange={async (e: SelectChangeEvent<unknown>) => {
                  setFrequencyType(e.target.value as string);
                  const sortedAllData = [
                    ...allDropDownData[mapFrequencyToLetter(e.target.value as string)],
                  ].sort((a, b) => a.minInstallments - b.minInstallments);
                  setMinDetailsObj(sortedAllData[0]);
                  setCalculatedInstallments(0);
                  const { updatedValues, updatedTouched } = resetLaterFields(
                    values,
                    touched,
                    'frequency',
                    {
                      frequency: e.target.value as string,
                    },
                  );
                  setValues(updatedValues);
                  setTouched(updatedTouched);
                }}
                options={
                  stpModes?.map((mode: string) => ({
                    label: mode,
                    value: mode,
                  })) || []
                }
                // disabled={isLoading}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              sm={6}
              xs={12}
            >
              <FormDateInput
                name='stpStartDate'
                label='Time Period from'
                placeholder='Time Period from'
                shouldDisableDate={(date: Date) => {
                  const calculatedTatDate = calculateTatDate(coolingPeriod || 0);
                  return disableDatesOnStartDate(date, calculatedTatDate);
                }}
                defaultCalendarMonth={calendarOpeningMonths.startDate}
                minDate={calculateTatDate(coolingPeriod || 0)}
                onChange={(date: any) => {
                  const newDate = calculateNextInstallmentDate({
                    startDate: date,
                    frequency: frequencyType?.toUpperCase() as Frequency,
                    minInstallments,
                  });
                  setCalendarOpeningMonths({
                    ...calendarOpeningMonths,
                    endDate: newDate,
                  });
                  // setFirstEnabledDate(newDate);
                  // Setting the date to null whenever date is chosen.
                  setFieldValue('stpEndDate', null);
                }}
                viewOnly={!values.frequency}
              />
              {minInstallments !== undefined && (
                <Typography>
                  Minimum No. of Installments: {minInstallments || 0}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
            >
              <FormDateInput
                name='stpEndDate'
                placeholder='Time Period to'
                label='Time Period to'
                minDate={calendarOpeningMonths.endDate}
                // minDate={calculateTatDate(coolingPeriod || 0)}
                viewOnly={!values.frequency}
                onChange={async (date: any) => {
                  const endDate = toUTCDate(new Date(date));
                  const startDate = toUTCDate(new Date(values?.stpStartDate as Date));

                  const selectedInstallments = calculateDifference(
                    new Date(values?.stpStartDate as Date),
                    new Date(date),
                    values?.frequency,
                  );
                  setCalculatedInstallments(selectedInstallments as number);
                  const result = findMinInstallmentAllowed(
                    allDropDownData[
                      mapFrequencyToLetter(values?.frequency)?.toUpperCase()
                    ],
                    mapFrequencyToLetter(values?.frequency),
                    Number(selectedInstallments),
                  );
                  if (result) {
                    setMinDetailsObj({
                      ...minDetailsObj,
                      minTransactionAmount: result?.minTransactionAmount,
                    });
                  }
                  try {
                    dispatch(startLoading());
                    // TODO: Check for validateDates for half-yearly.
                    const res = await validateDates({
                      fund: data?.fund,
                      scheme: data?.scheme,
                      trType: 'STP',
                      endDate: formatToMMDDYYYYByUTC(new Date(endDate)),
                      frequency: values?.frequency,
                      startDate: formatToMMDDYYYYByUTC(new Date(startDate)),
                    }).unwrap();
                    if (res?.error === true) {
                      showToast(res?.message || 'Unknown Error', 'error');
                    } else {
                      showToast(res?.message || 'Valid Dates chosen.', 'success');
                    }
                  } catch (error: any) {
                    setCalculatedInstallments(0);
                    const { updatedValues, updatedTouched } = resetLaterFields(
                      values,
                      touched,
                      'stpStartDate',
                      {},
                    );
                    setValues(updatedValues);
                    setTouched(updatedTouched);
                    const message =
                      (error as any).data?.message ||
                      (error as any).message ||
                      'Unknown error';
                    showToast(message, 'error');
                  } finally {
                    dispatch(stopLoading());
                  }
                }}
                defaultCalendarMonth={calendarOpeningMonths.endDate}
              />
              {calculatedInstallments > 0 && (
                <Typography sx={{ mb: 2, my: { xs: 2, md: 0 } }}>
                  Selected No. of Installments: {calculatedInstallments}
                </Typography>
              )}
            </Grid>
          </Grid>
          {values.stpOption === 'Fixed' && (
            <Grid
              container
              spacing={2}
              sx={{ mb: { sm: 3 } }}
            >
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Typography
                  sx={{
                    color: 'text.valueColor',
                    mb: 1,
                    fontSize: { xs: '14px', xl: '16px' },
                    fontWeight: 500,
                  }}
                >
                  Investment Amount :
                </Typography>

                <FormTextInput
                  name='amount'
                  label='Investment Amount'
                  placeholder='Enter the Investment Amount'
                  typeOfInput='number'
                  onInput={(e: any) => {
                    if (e.target.value.length > 10) {
                      e.target.value = e.target.value.slice(0, 10);
                    }
                  }}
                />
                <ClearUnitsBox
                  modeType={'Amount'}
                  availableAmount={minTransactionAmount || 0}
                  trtType={'STP'}
                />
              </Grid>
            </Grid>
          )}
          <Divider
            orientation='horizontal'
            variant='fullWidth'
            flexItem
            sx={{
              border: '1px dashed',
              borderColor: 'text.borderColorDark',
              my: 3,
            }}
          />
          <Typography
            sx={{
              fontSize: {
                xs: '16px',
                xl: '18px',
              },
              fontWeight: 500,
              color: { xs: 'text.valueColor', sm: 'primary.main' },
              mb: 2,
            }}
          >
            Distributor Details
          </Typography>
          <Grid
            container
            spacing={2}
            mb={'24px'}
          >
            <Grid
              item
              sm={6}
              xs={12}
            >
              <FormTextInput
                required={false}
                name='arn'
                label={'ARN Code'}
                defaultValue={arn}
                disabled
              />
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
            >
              <FormTextInput
                required={false}
                name='subArn'
                label={'Sub ARN Code'}
                defaultValue={subArn}
                typeOfInput='alphanumeric'
              />
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
            >
              <FormTextInput
                required={false}
                name='subBroker'
                label={'Sub Broker Code'}
                defaultValue={subbrokerCode}
                typeOfInput='alphanumeric'
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              sm={6}
              xs={12}
            >
              <EuinsFields
                name='euin'
                // allEUINs={allEUINs}
                initialValue={isEditMode ? (data?.euin ? 'yes' : 'no') : 'yes'}
                defaultValue={data?.euin || euin || ''}
                disabled={isEditMode}
              />
            </Grid>
          </Grid>
          <Divider
            orientation='horizontal'
            variant='fullWidth'
            flexItem
            sx={{
              border: '1px dashed',
              borderColor: 'text.borderColorDark',
              mt: 3,
            }}
          />
          <SubmitCartButtons
            onAddToCartClick={
              !isEditMode ? handleAddToCartClick : handleCancelModifyingCart
            }
            firstButtonTitle={firstButtonTitle}
            secondButtonTitle={secondButtonTitle}
            disableCondition={hasAnyErrorOccured}
          />

          {openDialogBox && (
            <CustomDialog
              isVisible={true}
              firstButtonHandler={handleGoToCart}
              secondButtonHandler={handleAddAnotherTransaction}
              firstButtonTitle='Go to cart'
              secondButtonTitle='Add another Transaction'
              handleClose={() => {}}
              showSecondButton={true}
            >
              <Box sx={{ textAlign: 'center', pt: 2 }}>
                <img
                  src={VerifiedIcon}
                  alt='verified-icon'
                />
                <MainHeader sx={{ fontWeight: 500, mb: 2 }}>Added To Cart</MainHeader>
                <FieldSubHeader3 sx={{ fontSize: '16px' }}>
                  Scheme is added to investor cart
                </FieldSubHeader3>
              </Box>
            </CustomDialog>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default InvestmentDetailsForm;
