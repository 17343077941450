import './main.css';

import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import CommonLoader from './components/common/Loader/CommonLoader';
import { persistor, store } from './store';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <CommonLoader />
    <BrowserRouter basename={import.meta.env.BASE_URL}>
      <PersistGate
        loading={null}
        persistor={persistor}
      >
        <App />
      </PersistGate>
    </BrowserRouter>
  </Provider>,
);
