import { Box, Grid, Theme, Typography } from '@mui/material';
import { format, isValid } from 'date-fns';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import VerifiedIcon from '../../../../../assets/verified-cart-icon.png';
import useUserPermission from '../../../../../hooks/useUserPermission';
import { Investorcard } from '../../../../auth/login/styles/styledComponents';
import CustomDialog from '../../../../common/AllDialogs/CustomDialog';
import { useRootContext } from '../../../../data/root.context';
import FormDateInput from '../../../../forms/FormDateInput';
import { FormStyledRadioButton } from '../../../../forms/FormStyledRadioButton';
import SubmitButton from '../../../../forms/FormSubmitButton';
import FormTextInput, { FormPasswordInput } from '../../../../forms/FormTextInput';
import Layout from '../../../Layout';
import { emailRegex } from '../../../regex';
import { useCcgsReportMutation } from '../../slice';
import {
  getDatesForCurrentFinancialYr,
  handleDetailsOptionChange,
  handleEndDateChange,
  validateDates,
  YYMMDDtoMMDDYYYY,
} from '../../utils';
import { AccountStatementType } from '../consolidateAccountStatement';

export default function CapitalGainsAccountStatement() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { showToast } = useRootContext();
  const pan: string = useSelector((state: any) => state.transactions.pan);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [responseMsg, setResponseMsg] = useState<string>('');
  const { showDashboard } = useUserPermission();

  const [getCaptialGainStatement] = useCcgsReportMutation();

  const initialValues: AccountStatementType = {
    statementType: 'Summary',
    asOnDate: new Date().toISOString().split('T')[0] || null,
    detailedOption: 'Current Financial Year',
    startDate: getDatesForCurrentFinancialYr().startDate || null,
    endDate: getDatesForCurrentFinancialYr().endDate || null,
    emailId: '',
    password: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    statementType: Yup.string().required('Statement type is required'),
    asOnDate: Yup.date()
      .typeError('Invalid Date')
      .when('statementType', {
        is: 'Summary',
        then: Yup.date().typeError('Invalid Date').required('As on date is required'),
      }),
    detailedOption: Yup.string().when('statementType', {
      is: 'Detailed',
      then: Yup.string().required('Detailed option is required'),
    }),
    startDate: Yup.date()
      .typeError('Invalid Date')
      .when('statementType', {
        is: 'Detailed',
        then: Yup.date().typeError('Invalid Date').required('Start date is required'),
      }),
    endDate: Yup.date()
      .typeError('Invalid Date')
      .when('statementType', {
        is: 'Detailed',
        then: Yup.date().typeError('Invalid Date').required('End date is required'),
      }),
    emailId: Yup.string()
      .required('Email ID is required')
      .matches(emailRegex, 'email must be a valid email'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[A-Za-z0-9!@#$%^&*()_+]+$/,
        'Password should contain at least 1 Upper Case alphabet(A-Z).1 Special Character and 1 Numeric Value (0-9)',
      ),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .test(
        'passwordMatch',
        'The Password and confirm Password do not match',
        function (value) {
          return value === this.parent.password;
        },
      ),
  });

  const handleSubmit = async (values: AccountStatementType) => {
    const startDate = values.startDate ? new Date(values.startDate) : null;
    const endDate = values.endDate ? new Date(values.endDate) : null;
    if (!startDate || !isValid(startDate)) {
      showToast('Invalid start date format', 'error');
      return;
    }
    if (!endDate || !isValid(endDate)) {
      showToast('Invalid end date format', 'error');
      return;
    }
    const params = {
      trType: 'WBCAP',
      fund: '0',
      stDate:
        values.statementType === 'Summary'
          ? '01/01/1970'
          : format(startDate, 'dd/MM/yyyy'),
      endDate:
        values.statementType === 'Summary'
          ? YYMMDDtoMMDDYYYY(values?.asOnDate)
          : format(endDate, 'dd/MM/yyyy'),
      emailId: values.emailId,
      zipPwd: values.password,
      pan: pan || '',
      detailed: '',
      canNo: '',
      reportFlag: values.statementType === 'Summary' ? 'T' : 'F',
    };
    try {
      const response: any = await getCaptialGainStatement(params).unwrap();
      if (response?.message) {
        setResponseMsg(response?.message);
        setOpenDialogBox(true);
      }
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    }
  };

  return (
    <>
      <Layout
        title='Capital Gains Account Statement'
        isNctTransaction
      >
        <Investorcard
          sx={(theme: Theme) => ({
            padding: '30px 30px',
            [theme.breakpoints.down('sm')]: {
              boxShadow: 'unset',
              border: 'unset',
              padding: '0px',
            },
          })}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validate={values => validateDates(values)}
          >
            {({ values, setFieldValue, setFieldError, resetForm }) => {
              return (
                <Form>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography
                        sx={{
                          mb: 2,
                          fontSize: { xs: '14px', xl: '16px' },
                          fontWeight: 500,
                          color: 'text.valueColor',
                        }}
                      >
                        Statement Type
                      </Typography>
                      <FormStyledRadioButton
                        options={[
                          { label: 'Summary', value: 'Summary' },
                          { label: 'Detailed', value: 'Detailed' },
                        ]}
                        name='statementType'
                        handleChange={e => {
                          resetForm();
                          setFieldValue('statementType', e.target.value);
                        }}
                      />
                    </Grid>
                    {values.statementType === 'Detailed' ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          mt={2}
                        >
                          <Typography
                            sx={{
                              mb: 2,
                              fontSize: { xs: '14px', xl: '16px' },
                              fontWeight: 500,
                              color: 'text.valueColor',
                            }}
                          >
                            Detailed Option
                          </Typography>
                          <FormStyledRadioButton
                            options={[
                              {
                                label: 'Current Financial Year',
                                value: 'Current Financial Year',
                              },
                              {
                                label: 'Previous Financial Year',
                                value: 'Previous Financial Year',
                              },
                              {
                                label: 'Specified Period',
                                value: 'Specified Period',
                              },
                            ]}
                            name='detailedOption'
                            handleChange={e =>
                              handleDetailsOptionChange(e, setFieldValue)
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          xs={12}
                        >
                          <FormDateInput
                            name='startDate'
                            label='Start Date'
                            required={true}
                            viewOnly={values.detailedOption !== 'Specified Period'}
                            disableFuture
                            disabled
                          />
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          xs={12}
                        >
                          <FormDateInput
                            name='endDate'
                            label='End Date'
                            required={true}
                            viewOnly={
                              values.detailedOption !== 'Specified Period' ||
                              values.startDate === null
                            }
                            disableFuture
                            disabled
                            onChange={e =>
                              handleEndDateChange(
                                e,
                                values,
                                setFieldError,
                                setFieldValue,
                                showToast,
                              )
                            }
                          />
                        </Grid>
                      </>
                    ) : (
                      <Grid
                        item
                        sm={6}
                        xs={12}
                      >
                        <FormDateInput
                          name='asOnDate'
                          label='As Date On'
                          required={true}
                          viewOnly
                        />
                      </Grid>
                    )}
                    <Grid
                      item
                      sm={6}
                      xs={12}
                    >
                      <FormTextInput
                        name='emailId'
                        label='Email ID'
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    mt={1}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormPasswordInput
                        name='password'
                        label='Self Extraction Password'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormPasswordInput
                        name='confirmPassword'
                        label='Confirm Self Extraction Password'
                      />
                    </Grid>
                  </Grid>
                  <Box sx={{ display: 'flex', mt: 5 }}>
                    <SubmitButton
                      sx={(theme: Theme) => ({
                        '&.MuiButtonBase-root': {
                          borderRadius: '5px',
                          minWidth: '200px',

                          [theme.breakpoints.down('sm')]: {
                            minWidth: '100%',
                          },
                        },
                      })}
                      label='Submit'
                    />
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Investorcard>

        <CustomDialog
          isVisible={openDialogBox}
          showFirstButton={false}
          firstButtonHandler={() => {}}
          firstButtonTitle=''
          showSecondButton
          secondButtonTitle='Continue'
          secondButtonHandler={() => navigate('/transaction-dashboard/mis/mfs-reports')}
          handleClose={() => {}}
        >
          <Box
            sx={{
              'textAlign': 'center',
              'pt': 2,
              '& img': {
                maxWidth: { xs: '17%', md: '70%' },
              },
            }}
          >
            <img
              src={VerifiedIcon}
              alt='verified-icon'
            />
            <Typography
              variant='h3'
              sx={{ color: 'primary.main', my: 2 }}
            >
              Successfully submitted your request
            </Typography>
            <Typography variant='subtitle1'>{responseMsg}</Typography>
          </Box>
        </CustomDialog>
      </Layout>
    </>
  );
}
