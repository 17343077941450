import { Avatar, Box, Chip, Grid, IconButton, Stack, Typography } from '@mui/material';
import { FieldArray, Form, Formik, FormikProps } from 'formik';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { ReactComponent as DeleteIcon } from '../../../../../assets/delete-icon.svg';
import Female from '../../../../../assets/female.svg';
import Male from '../../../../../assets/male.svg';
import Others from '../../../../../assets/others.svg';
import { ReactComponent as CircleAddIcon } from '../../../../../assets/rounded-add-icon.svg';
import { StyledSubmitButton } from '../../../../auth/login/styles/styledComponents';
import {
  FieldSubHeader3,
  StepperAddButton,
} from '../../../../auth/login/styles/styledComponents';
import { getDateFromString } from '../../../../common/custom-functions';
import { startLoading, stopLoading } from '../../../../common/Loader/loaderSlice';
import { useRootContext } from '../../../../data/root.context';
import FormCheckbox from '../../../../forms/FormCheckbox';
import FormDateInput from '../../../../forms/FormDateInput';
import { FormSelectInput } from '../../../../forms/FormSelect';
import { FormStyledRadioButton } from '../../../../forms/FormStyledRadioButton';
import FormTextInput from '../../../../forms/FormTextInput';
import { calculateAge } from '../../../../onboarding/common';
import { NomineeDetailsFormValues } from '../../../types';
import { setNomineeDetails, setStepNumber } from '../context/NewSubscriberAction';
import { useNewSubscriberContext } from '../context/NewSubscriberContext';
import { useGetAllNomineeDetailsMutation, useSaveNomineeDetailsMutation } from '../slice';

type NominationPayload = {
  AccountType: '1';
  FirstName: string;
  RelationType: string;
  Age: number;
  Nompercentage: string;
  DateOfBirth: string;
  Slno: number;
  IsMajor: 0 | 1;
  GuardianFirstName?: string; // Optional if IsMajor is 0
};

type Payload = {
  AckNumber: string;
  chkNomineeSameasTire1: string;
  NominationList: string;
  NominationsList2: string;
  AccountType: null | string; // TODO: Update the type based on the actual type
};

type RelationshipOption = {
  Id: number;
  Name: string;
  IsSelected: boolean;
};

const gendersList = {
  Male: Male,
  Female: Female,
  Others: Others,
};

const NomineeDetails: React.FC = () => {
  const { dispatch: contextDispatch } = useNewSubscriberContext();
  const reduxDispatch = useDispatch();
  const [saveAllNomineeDetails] = useSaveNomineeDetailsMutation();
  const [getAllSavedNomineeDetails] = useGetAllNomineeDetailsMutation();

  const formikRef = useRef<FormikProps<any>>(null);
  const { showToast } = useRootContext();
  const {
    state: { ackNumber: AckNumber, accountType: AT, nomineeDetails: ND },
  } = useNewSubscriberContext();
  const relationshipOptions = (ND?.data?.lstRelationships || []).filter(
    (r: any) => r.Name !== 'Self',
  );
  const NominationList2 = ND?.data?.NominationsList2 || [];
  const NominationList = ND?.data?.NominationList || [];
  const hasTierTwo = AT?.data?.AccountType === '2';
  const T2NomineeSameAsT1Nominee = ND?.data?.chkNomineeSameasTire1;
  const commonValidationSchema = Yup.array().of(
    Yup.object().shape({
      IsMajor: Yup.number().oneOf([0, 1]).required('Select Yes/No'),
      FirstName: Yup.string().required('Nominee Name is required'),
      NomRelationship: Yup.string().required('Nominee relation is required'),
      DateOfBirth: Yup.date()
        .typeError('Enter valid date')
        .required('Date of Birth is required'),
      Nompercentage: Yup.number()
        .required('Nominee percentage is required')
        .positive('Nominee percentage greater than 0')
        .max(100, 'Nominee percentage cannot exceed 100'),
      NomPan: Yup.string()
        // .required('Nominee pan is required')
        .matches(/^[A-Z]{3}[P][A-Z]{1}[0-9]{4}[A-Z]{1}$/, 'Invalid PAN Number')
        .max(10, 'PAN Number should be exactly 10 characters'),

      GuardianFirstName: Yup.string().when('IsMajor', {
        is: 0,
        then: Yup.string().required('Guardian Name is required'),
      }),
      Gender: Yup.string()
        .required('Gender is required')
        .min(1, 'Gender cannot be empty'),
    }),
  );
  const validationSchema = Yup.object()
    .shape({
      tier1: commonValidationSchema,
      ...(hasTierTwo
        ? {
            T2NomineeSameAsT1Nominee: Yup.boolean(),
            tier2: commonValidationSchema.when('T2NomineeSameAsT1Nominee', {
              is: true,
              then: () => Yup.mixed().notRequired(),
              otherwise: schema => schema,
            }),
          }
        : {}),
    })
    .test(
      'total-nominee-percentage',
      'Total percentage should be equal to 100',
      function (value) {
        let totalPercentage = 0;
        if (value.tier1) {
          totalPercentage += value.tier1?.reduce((sum: number, nominee: any) => {
            return sum + (parseFloat(nominee.Nompercentage) || 0);
          }, 0);
        }
        if (value.tier2 && !value.T2NomineeSameAsT1Nominee && value.tier2) {
          totalPercentage += (value.tier2 as any)?.reduce((sum: number, nominee: any) => {
            return sum + (parseFloat(nominee.Nompercentage) || 0);
          }, 0);
        }
        if (totalPercentage !== 100) {
          let errors: any[] = [];
          value.tier1?.forEach((t1, i) => {
            errors.push(
              this.createError({
                path: `tier1[${i}].Nompercentage`,
                message: 'Total percentage should be equal to 100',
              }),
            );
          });
          (value.tier2 as unknown as any[])?.forEach((t2, i) => {
            errors.push(
              this.createError({
                path: `tier2[${i}].Nompercentage`,
                message: 'Total percentage should be equal to 100',
              }),
            );
          });
          return new Yup.ValidationError(errors);
        }
        return true;
      },
    );

  const initialValues: Partial<NomineeDetailsFormValues> = {
    tier1:
      NominationList?.length === 0
        ? [
            {
              IsMajor: '1',
              FirstName: '',
              NomRelationship: '',
              DateOfBirth: null,
              Nompercentage: '100',
              NomPan: '',
              GuardianFirstName: '',
              Gender: '',
            },
          ]
        : NominationList?.map(
            ({
              FirstName,
              RelationType,
              DateOfBirth,
              Nompercentage,
              GuardianFirstName,
            }: any) => ({
              IsMajor: '1',
              FirstName: FirstName,
              NomRelationship: RelationType,
              DateOfBirth: getDateFromString(DateOfBirth),
              Nompercentage: Nompercentage,
              NomPan: '',
              GuardianFirstName: GuardianFirstName,
              Gender: '',
            }),
          ),

    ...(hasTierTwo
      ? {
          T2NomineeSameAsT1Nominee: T2NomineeSameAsT1Nominee === 'N' ? false : true,

          tier2:
            NominationList2?.length === 0
              ? [
                  {
                    IsMajor: '1',
                    FirstName: '',
                    NomRelationship: '',
                    DateOfBirth: null,
                    Nompercentage: '100',
                    NomPan: '',
                    GuardianFirstName: '',
                    Gender: '',
                  },
                ]
              : NominationList2?.map(
                  ({
                    FirstName,
                    RelationType,
                    DateOfBirth,
                    Nompercentage,
                    GuardianFirstName,
                    RelationTypeName,
                  }: any) => ({
                    IsMajor: '1',
                    FirstName: FirstName,
                    NomRelationship: RelationType,
                    DateOfBirth: getDateFromString(DateOfBirth),
                    Nompercentage: Nompercentage,
                    NomPan: '',
                    GuardianFirstName: GuardianFirstName,
                    Gender: '',
                  }),
                ),
        }
      : {}),
  };

  const handleSubmitHandler = async (values: any) => {
    try {
      reduxDispatch(startLoading());
      let allNominations: NominationPayload[][] = [];
      ['tier1', ...(hasTierTwo ? ['tier2'] : [])].forEach((tierKey: any) => {
        let tierNominations: NominationPayload[] = [];
        const nominees = values[tierKey];
        nominees?.forEach((nominee: any, index: number) => {
          const age = calculateAge(nominee.DateOfBirth);
          const formattedDate = `${new Date(nominee.DateOfBirth)
            .getDate()
            .toString()
            .padStart(2, '0')}/${(new Date(nominee.DateOfBirth).getMonth() + 1)
            .toString()
            .padStart(2, '0')}/${new Date(nominee.DateOfBirth).getFullYear()}`;

          const nominationPayload: NominationPayload = {
            AccountType: '1',
            FirstName: nominee.FirstName,
            RelationType: nominee.NomRelationship,
            Age: age,
            Nompercentage: nominee.Nompercentage,
            DateOfBirth: formattedDate,
            Slno: +index + 1,
            IsMajor: age >= 18 ? 1 : 0,
            ...(age < 18
              ? {
                  GuardianFirstName: nominee.GuardianFirstName,
                }
              : {}),
          };
          tierNominations.push(nominationPayload);
        });
        allNominations.push(tierNominations);
      });
      const payload: Payload = {
        AckNumber,
        chkNomineeSameasTire1: !values.T2NomineeSameAsT1Nominee ? 'N' : 'Y',
        NominationList: JSON.stringify(allNominations[0]),
        NominationsList2: !values.T2NomineeSameAsT1Nominee
          ? JSON.stringify(allNominations[1])
          : JSON.stringify(allNominations[0]),
        AccountType: AT?.data?.AccountType || '1',
      };
      await saveAllNomineeDetails(payload).unwrap();
      const getNomineeResponse = await getAllSavedNomineeDetails({ AckNumber }).unwrap();
      contextDispatch(setNomineeDetails(getNomineeResponse));
      contextDispatch(setStepNumber(6));
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    } finally {
      reduxDispatch(stopLoading());
    }
  };
  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmitHandler}
        innerRef={formikRef}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <Box sx={{ padding: { xs: '0px 24px', md: '0px 0px 0px 24px' } }}>
              {[1, ...(hasTierTwo ? [2] : [])].map(tier => (
                <FieldArray
                  name={`tier${tier}`}
                  key={tier}
                >
                  {({ remove, push }) => (
                    <>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: 700,
                          color: 'primary.main',
                          mb: 1,
                        }}
                      >
                        Tier {tier}
                      </Typography>
                      {hasTierTwo && tier === 2 && (
                        <Box>
                          <FormCheckbox
                            name={`T2NomineeSameAsT1Nominee`}
                            label='Nominee Details same as Tier 1'
                            margin='dense'
                          />
                        </Box>
                      )}

                      {tier === 1 || (tier === 2 && !values.T2NomineeSameAsT1Nominee)
                        ? values[`tier${tier}`]?.map((nominee: any, index: number) => {
                            return (
                              <>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '10px 20px',
                                    height: '52px',
                                    backgroundColor: 'text.boxColor',
                                    borderRadius: '5px',
                                    mb: 2.5,
                                    p: 1.5,
                                  }}
                                >
                                  <FieldSubHeader3>Nominee {index + 1}</FieldSubHeader3>
                                  {index > 0 && (
                                    <IconButton
                                      sx={{ float: 'right' }}
                                      onClick={() => remove(index)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  )}
                                </Box>

                                <Typography
                                  sx={{
                                    color: '#212B36',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    mb: 2,
                                  }}
                                >
                                  Is Nominee a minor?
                                </Typography>
                                <FormStyledRadioButton
                                  options={[
                                    { label: 'Yes', value: '0' },
                                    { label: 'No', value: '1' },
                                  ]}
                                  name={`tier${tier}.${index}.IsMajor`}
                                />

                                <Grid
                                  key={index}
                                  container
                                  spacing={2}
                                  my={'20px'}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <FormTextInput
                                      name={`tier${tier}.${index}.FirstName`}
                                      label='Nominee Name'
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <FormSelectInput
                                      name={`tier${tier}.${index}.NomRelationship`}
                                      label='Nominee Relationship'
                                      defaultValue=''
                                      options={relationshipOptions.map(
                                        (relationship: RelationshipOption) => ({
                                          label: relationship.Name,
                                          value: relationship.Id,
                                        }),
                                      )}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <FormDateInput
                                      name={`tier${tier}.${index}.DateOfBirth`}
                                      label='Date of Birth'
                                      disableFuture
                                      maxDate={
                                        values[`tier${tier}`][index].IsMajor === '1'
                                          ? new Date(
                                              new Date().setFullYear(
                                                new Date().getFullYear() - 18,
                                              ),
                                            )
                                          : undefined
                                      }
                                      minDate={
                                        values[`tier${tier}`][index].IsMajor !== '1'
                                          ? new Date(
                                              new Date().setFullYear(
                                                new Date().getFullYear() - 18,
                                              ),
                                            )
                                          : undefined
                                      }
                                      disabled
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <FormTextInput
                                      name={`tier${tier}.${index}.Nompercentage`}
                                      label='Nominee %'
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={6}
                                  >
                                    <FormTextInput
                                      name={`tier${tier}.${index}.NomPan`}
                                      label='Nominee PAN (optional)'
                                      required={false}
                                      onInput={(e: any) => {
                                        if (e.target.value.length > 10) {
                                          e.target.value = e.target.value.slice(0, 10);
                                        }
                                      }}
                                    />
                                  </Grid>
                                  {nominee.IsMajor === '0' && (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      md={6}
                                    >
                                      <FormTextInput
                                        name={`tier${tier}.${index}.GuardianFirstName`}
                                        label='Guardian Name'
                                      />
                                    </Grid>
                                  )}
                                  <Grid
                                    item
                                    xs={12}
                                  >
                                    <Typography
                                      sx={{
                                        color: '#212B36',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        mb: 2,
                                      }}
                                    >
                                      Gender
                                    </Typography>
                                    <Stack
                                      direction='row'
                                      spacing={1}
                                      sx={{
                                        'justifyContent': {
                                          xs: 'space-between',
                                          sm: 'flex-start',
                                        },
                                        '& .MuiButtonBase-root.MuiChip-root': {
                                          'padding': '10px 5px',
                                          'borderRadius': '44px',
                                          '& .MuiChip-label': {
                                            fontSize: '14px',
                                            fontWeight: 500,
                                          },
                                        },
                                      }}
                                    >
                                      {Object.keys(gendersList).map(gender => (
                                        <Chip
                                          key={gender}
                                          avatar={
                                            <Avatar
                                              alt={gender.toLowerCase()}
                                              src={
                                                (gendersList as Record<string, string>)[
                                                  gender
                                                ]
                                              }
                                            />
                                          }
                                          label={gender}
                                          onClick={async () =>
                                            setFieldValue(
                                              `tier${tier}.${index}.Gender`,
                                              gender,
                                            )
                                          }
                                          variant='outlined'
                                          sx={{
                                            'border': '1px solid',
                                            'borderColor':
                                              values[`tier${tier}`][index].Gender ===
                                              gender
                                                ? 'text.navyBlue'
                                                : 'text.borderColorLight',
                                            'backgroundColor':
                                              values[`tier${tier}`][index].Gender ===
                                              gender
                                                ? 'text.boxColorLight'
                                                : 'common.white',
                                            '& .MuiChip-label': {
                                              color:
                                                values[`tier${tier}`][index].Gender ===
                                                gender
                                                  ? 'primary.main'
                                                  : 'text.primary',
                                            },
                                          }}
                                        />
                                      ))}
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </>
                            );
                          })
                        : null}

                      {(tier === 1 || (tier === 2 && !values.T2NomineeSameAsT1Nominee)) &&
                        values[`tier${tier}`].length < 3 && (
                          <Box
                            display={'grid'}
                            sx={{ placeItems: 'center' }}
                          >
                            <StepperAddButton
                              sx={{
                                minWidth: '220px',
                              }}
                              startIcon={<CircleAddIcon />}
                              onClick={() => {
                                push(initialValues);
                              }}
                            >
                              Add Another Nominee
                            </StepperAddButton>
                          </Box>
                        )}
                    </>
                  )}
                </FieldArray>
              ))}
              <StyledSubmitButton
                sx={{ minWidth: { xs: '100%', sm: '200px' } }}
                label='Save and Proceed'
              />
            </Box>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default NomineeDetails;
