import { Theme } from '@mui/material';
import { Stack } from '@mui/system';
import React from 'react';

import { ReactComponent as CartIcon } from '../../../assets/add-cart.svg';
import { StepperAddButton } from '../../auth/login/styles/styledComponents';
import SubmitButton from '../../forms/FormSubmitButton';

type SubmitCartButtonsProps = {
  onAddToCartClick: (() => void) | undefined;
  firstButtonTitle: string;
  secondButtonTitle: string;
  disableCondition?: boolean;
};

const SubmitCartButtons: React.FC<SubmitCartButtonsProps> = ({
  onAddToCartClick,
  firstButtonTitle,
  secondButtonTitle,
  disableCondition,
}) => {
  const isDisabled = disableCondition === true;
  return (
    <Stack
      sx={{
        flexDirection: 'row',
        gap: '30px',
        justifyContent: { sm: 'center', lg: 'unset' },
      }}
    >
      <StepperAddButton
        onClick={async () => {
          if (onAddToCartClick) {
            onAddToCartClick();
          }
        }}
        disabled={isDisabled}
        sx={{
          minWidth: { xs: 'unset', sm: '260px' },
          color: 'text.navyBlue',
          background: 'unset',
          WebkitTextFillColor: 'unset',
          my: 3,
          flex: { xs: 1, sm: 'unset' },
        }}
        startIcon={<CartIcon />}
      >
        {firstButtonTitle}
      </StepperAddButton>
      <SubmitButton
        sx={(theme: Theme) => ({
          m: 0,
          minWidth: '260px',
          [theme.breakpoints.down('sm')]: {
            flex: 1,
          },
        })}
        disabled={isDisabled}
        label={secondButtonTitle}
      />
    </Stack>
  );
};

export default SubmitCartButtons;
