import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FormHelperText, InputLabel, Stack, TextField } from '@mui/material';
// import { useTheme } from '@mui/material/styles';
import { useField } from 'formik';
import { KeyboardEvent, useEffect, useRef } from 'react';

interface FormOTPInputProps {
  name?: string;
  label?: string;
  otpLength?: number;
  // eslint-disable-next-line no-unused-vars
  onChange?: (otp: string) => void;
  disabled?: boolean;
  required?: boolean;
}

export default function FormOTPInput({
  name = 'otp',
  label = '',
  otpLength = 6,
  onChange,
  required = true,
  disabled,
}: FormOTPInputProps) {
  const [field, meta, { setValue }] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : '';

  const { value: _value } = field;

  const value = Array.isArray(_value) ? _value : Array(otpLength).fill('');

  // const theme = useTheme();
  const ref = useRef<(HTMLInputElement | null)[]>([]);

  const onOtpChange = (text: string, index: number): void => {
    const singleChar = text.slice(0, 1); // Taking only the first character

    if (index < otpLength && ref.current[index + 1]) {
      ref.current[index + 1]?.focus();
    }

    setValue(value.map((ele, i) => (i === index ? singleChar : ele)));
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>, index: number) => {
    if (e.keyCode === 8) {
      if (ref.current[index]?.value === '' && index) {
        setValue(value.map((ele, i) => (i === index - 1 ? '' : ele)));
        ref.current[index - 1]?.focus();
      }
    }
  };

  useEffect(() => {
    onChange && onChange(value.join(''));
  }, [value]);

  return (
    <>
      {label && (
        <InputLabel
          required={required}
          sx={{
            'color': 'text.labelColor',
            'fontSize': '14px',
            'fontWeight': '500',
            'top': '2px',
            'left': '6px',
            '&.Mui-focused': {
              color: 'inherit',
              fontSize: '12px',
              fontWeight: '500',
            },
          }}
        >
          {label}
        </InputLabel>
      )}
      <Stack
        direction='row'
        sx={{
          mb: 2.5,
          justifyContent: 'space-between',
          gap: { xs: 1.25 },
        }}
      >
        {[...Array(otpLength)].map((_, index) => (
          <TextField
            key={index}
            variant='outlined'
            margin='dense'
            inputProps={{
              maxLength: 1,
              autoComplete: 'off',
            }}
            type='number'
            disabled={disabled}
            value={value[index]}
            onInput={(e: any) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, '');
            }}
            onChange={e => onOtpChange(e.target.value, index)}
            onKeyDown={e => handleKeyDown(e, index)}
            inputMode='numeric'
            inputRef={input => (ref.current[index] = input)}
            sx={{
              'border': 'none',
              'height': '58px',
              '& .MuiInputBase-root.MuiOutlinedInput-root': {
                'border': 'none',
                'width': 'unset',
                'height': '58px',

                '&.Mui-focused': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: '2px solid #337fc9',
                  },
                },
                '&.Mui-disabled': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: value[index] ? '2px solid rgba(51, 127, 201, 0.50)' : 'none',
                  },
                  '& .MuiInputBase-input.MuiOutlinedInput-input': {
                    backgroundColor: 'rgba(173, 204, 233, 0.35)',
                    color: 'rgba(51, 127, 201, 0.50)',
                    WebkitTextFillColor: 'rgba(51, 127, 201, 0.50)',
                  },
                },
                '& .MuiInputBase-input.MuiOutlinedInput-input': {
                  backgroundColor: 'rgba(173, 204, 233, 0.35)',
                  border: '0px',
                  fontSize: '24px',
                  fontWeight: '700',
                  color: '#337fc9',
                  textAlign: 'center',
                  height: '25px',
                  borderRadius: '5px',
                },
                '& .MuiOutlinedInput-notchedOutline ': {
                  border: 'none',
                },
              },
            }}
          />
        ))}
      </Stack>
      <FormHelperText
        style={{ color: 'error.main', fontSize: '11px', fontWeight: '400' }}
      >
        {errorText && (
          <InfoOutlinedIcon
            sx={{ fontSize: '16px', verticalAlign: 'middle', mr: 0.5 }}
            color='error'
          />
        )}
        {errorText}
      </FormHelperText>
    </>
  );
}
