import { Box, Button, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as Prev_Button } from '../../assets/back-icon-large.svg';
import { ReactComponent as Checked_Icon } from '../../assets/button-checked.svg';
import { ReactComponent as Next_Button } from '../../assets/next-button.svg';
import { maskAccountNumber } from '../transactions/utils';

interface ButtonScrollerProps {
  data: { header: string; subHeader: string; icon: JSX.Element }[];
  onClick?: (index: number) => void;
  defaultClicked?: number;
  disabled?: boolean;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ButtonScroller = ({
  data,
  onClick,
  defaultClicked,
  disabled,
  loading,
  setLoading,
}: ButtonScrollerProps) => {
  const containerRef = useRef<any>(null);
  const [showLeftScrollButton, setShowLeftScrollButton] = useState(true);
  const [showRightScrollButton, setShowRightScrollButton] = useState(true);
  const [clicked, setClicked] = useState<number>(defaultClicked || 0);
  const handleClick = (index: number) => {
    setClicked(index);
    onClick?.(index);
  };
  useEffect(() => {
    if (defaultClicked !== undefined) setClicked(defaultClicked);
  }, [defaultClicked]);

  const scrollToRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += 100; // Adjust the scroll amount as needed
    }
  };

  const scrollToLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= 100; // Adjust the scroll amount as needed
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const container = e.target as HTMLDivElement;
    const isScrollable = container.scrollWidth > container.clientWidth;
    const scrolledToEnd =
      container.scrollLeft >= container.scrollWidth - container.clientWidth;
    const scrolledToStart = container.scrollLeft === 0;

    if (!isScrollable) {
      setShowLeftScrollButton(false);
      setShowRightScrollButton(false);
    } else if (scrolledToEnd) {
      setShowRightScrollButton(false);
    } else if (scrolledToStart) {
      setShowLeftScrollButton(false);
    } else {
      setShowLeftScrollButton(true);
      setShowRightScrollButton(true);
    }
  };
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 3 }}>
      <IconButton
        onClick={scrollToLeft}
        sx={{
          width: '44px',
          height: '44px',
          bgcolor: 'common.white',
          boxShadow: '0px 4px 15px 0px #00000026',
          justifyContent: 'center',
          alignItems: 'center',
          display:
            !showLeftScrollButton ||
            (containerRef.current && containerRef.current.scrollLeft === 0)
              ? 'none'
              : 'flex',
        }}
      >
        <Prev_Button />
      </IconButton>
      <Box
        sx={{
          'width': '100%',
          'overflowX': 'auto',
          'whiteSpace': 'nowrap',
          'scrollbarWidth': 'none',
          'msOverflowStyle': 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        ref={containerRef}
        onScroll={handleScroll}
      >
        {loading
          ? [1, 2, 3, 4].map(item => (
              <Skeleton
                key={item}
                variant='rectangular'
                width={240}
                height={80}
                sx={{
                  display: 'inline-block',
                  width: '240px',

                  mx: '10px',
                  borderRadius: '10px',
                  bgcolor: 'text.lightGray',
                }}
              />
            ))
          : data.map((item, index) => (
              <Button
                key={index}
                onClick={() => handleClick(index)}
                disabled={Boolean(disabled)}
                sx={{
                  'display': 'inline-block',
                  'width': '240px',
                  'border': '2px solid',
                  'borderColor':
                    index === clicked ? 'primary.main' : 'text.borderColorLight',
                  'padding': '10px',
                  'mx': '10px',
                  'borderRadius': '10px',
                  'bgcolor': index === clicked ? 'text.boxColor' : 'common.white',
                  'position': 'relative',
                  '&:hover': {
                    bgcolor: 'text.boxColor',
                    borderColor: 'primary.main',
                  },
                }}
              >
                <Stack
                  direction={'row'}
                  columnGap={'10px'}
                  alignItems={'flex-start'}
                >
                  {item.icon}
                  <Box sx={{ textAlign: 'left' }}>
                    <Typography
                      variant='subtitle2'
                      sx={{
                        fontSize: { xs: '14px', xl: '16px' },
                        fontWeight: 500,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        maxWidth: '140px',
                        mb: 0.25,
                        color: index === clicked ? 'primary.main' : 'text.primary',
                      }}
                    >
                      {item.header}
                    </Typography>
                    <Typography
                      variant='subtitle2'
                      sx={{ color: 'text.labelColor' }}
                    >
                      {maskAccountNumber(item.subHeader)}
                    </Typography>
                  </Box>
                </Stack>
                {index === clicked ? (
                  <Box sx={{ position: 'absolute', right: '10px', bottom: '1px' }}>
                    <Checked_Icon />
                  </Box>
                ) : (
                  ''
                )}
              </Button>
            ))}
      </Box>
      <IconButton
        onClick={scrollToRight}
        sx={{
          width: '44px',
          height: '44px',
          bgcolor: 'common.white',
          boxShadow: '0px 4px 15px 0px #00000026',
          justifyContent: 'center',
          alignItems: 'center',
          display:
            !showRightScrollButton ||
            (containerRef.current &&
              containerRef.current.scrollLeft ===
                containerRef.current.scrollWidth - containerRef.current.clientWidth)
              ? 'none'
              : 'flex',
        }}
      >
        <Next_Button />
      </IconButton>
    </Box>
  );
};

export default ButtonScroller;
