import { Grid, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { forwardRef, useEffect } from 'react';

import { FileUpload } from '../../common/FileUpload';
import { useRootContext } from '../../data/root.context';
interface UploadChequeCopyProps {
  files: (File | null)[];
  setFiles: React.Dispatch<React.SetStateAction<(File | null)[]>>;
  hasAccountValidationFailed: boolean;
  index: number;
}
const UploadChequeCopy: React.ForwardRefExoticComponent<
  UploadChequeCopyProps & React.RefAttributes<any>
> = forwardRef(({ files, setFiles, hasAccountValidationFailed, index }, ref) => {
  const { setFieldError, errors, setErrors } = useFormikContext();
  const { showToast } = useRootContext();
  useEffect(() => {
    // Clear validation errors when files are selected
    if (hasAccountValidationFailed && files.length > 0) {
      setFieldError(`bankList.${index}.accountNumber`, ''); // Replace 'accountNumber' with the actual field names
      setFieldError(`bankList.${index}.verifiedAccountNumber`, ''); // Replace 'verifiedAccountNumber' with the actual field names
      // setFieldError('ifsc', ''); // Replace 'ifsc' with the actual field names
    }
  }, [files]);
  const handleFileUpload = () => {
    showToast('File Uploaded Successfully', 'success');
  };
  return (
    <>
      <Typography sx={{ color: '#212b36', fontSize: '16px', fontWeight: 500, mb: 2 }}>
        Please Provide the cancelled cheque copy. (Only JPG, JPEG, PNG and PDF files are
        accepted)
      </Typography>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={4}
        >
          <FileUpload
            ref={ref}
            key={0}
            index={index}
            files={files}
            setFiles={setFiles}
            fileName={''}
            accept={'.pdf,.png,.jpg,.jpeg'}
            onUpload={handleFileUpload}
          />
        </Grid>
      </Grid>
    </>
  );
});

export default UploadChequeCopy;
