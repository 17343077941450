import React, { Fragment } from 'react';

interface ClearUnitsBoxProps {
  availableAmount: number | string;
  trtType: string;
  modeType: string;
}

const ClearUnitsBox: React.FC<ClearUnitsBoxProps> = ({
  availableAmount,
  trtType,
  modeType,
}) => {
  return (
    <Fragment>
      {modeType} allowed for {trtType}: {availableAmount || 0}
    </Fragment>
  );
};

export default ClearUnitsBox;
