import {
  Box,
  Collapse,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TablePagination,
  Theme,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { ShimmerTable, ShimmerText } from 'react-shimmer-effects';

import { ReactComponent as SelectSchemesMobile } from '../../assets/scheme-table-mobile.svg';
import { ReactComponent as SelectSchemes } from '../../assets/schemeTable.svg';
import { StepperNextButton } from '../onboarding/styles/styledComponents';
import { findLogo } from '../transactions/utils';
interface RowProps {
  [key: string]: any;
}

interface ExpandableRadioTableProps {
  rows: RowProps[];
  keysToShow: string[];
  colorMappings?: { [key: string]: string };
  rowHeader?: string;
  onContinue: (selectedRow: RowProps) => void;
  filter?: React.ReactNode;
  showIcon?: boolean;
  showSelectAmcImage?: boolean;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
const ExpandableRadioTable: React.FC<ExpandableRadioTableProps> = ({
  rows,
  keysToShow,
  colorMappings,
  rowHeader,
  onContinue,
  filter,
  showIcon,
  loading,
  setLoading,
  showSelectAmcImage = false,
}) => {
  const start = 0;
  const limit = 10;
  const [selectedScheme, setSelectedScheme] = useState<any>();
  const [page, setPage] = useState(start);
  const [rowsPerPage, setRowsPerPage] = useState(limit);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedScheme(value === selectedScheme ? null : value);
  };

  const handleContinue = () => {
    if (selectedScheme !== null) {
      const selectedRow = rows.find(row => {
        const rowScheme = `${row.scheme}_${row.plan}_${row.option}`;
        return rowScheme === selectedScheme;
      });

      if (selectedRow) {
        onContinue(selectedRow);
      }
    }
  };
  // Handlers to change the states
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Box>
        {/* <CustomSearch
              placeholder='Search'
              value={query}
              onChange={handleSearchTextChanged}
            /> */}
        {filter && <Box>{filter}</Box>}{' '}
      </Box>
      {loading && rows.length === 0 ? (
        <>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <ShimmerTable
              row={4}
              col={3}
            />
          </Box>
          <Box sx={{ display: { md: 'none' } }}>
            {Array.from({ length: 5 }, (_, i) => i + 1).map(index => (
              <ShimmerText
                key={index}
                line={5}
                gap={10}
              />
            ))}
          </Box>
        </>
      ) : rows.length !== 0 ? (
        <Box sx={{ mx: { xs: '-20px', sm: '0px' } }}>
          <RadioGroup
            value={selectedScheme}
            onChange={handleChange}
          >
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item: RowProps, index: number) => {
                const amcLogo = findLogo(item.fund);
                const uniqueKey = `${item.scheme}_${item.plan}_${item.option}`;
                return (
                  <Box
                    key={uniqueKey}
                    sx={(theme: Theme) => ({
                      'position': 'relative',
                      'p': { xs: '15px 15px', sm: '25px 30px' },
                      'paddingLeft': selectedScheme === uniqueKey ? '0px' : '5px',
                      'borderLeft':
                        selectedScheme === uniqueKey
                          ? `5px solid ${theme.palette.primary.main}`
                          : 'unset',
                      'backgroundColor':
                        selectedScheme === uniqueKey
                          ? `${theme.palette.text.boxColor}`
                          : 'common.white',
                      '&:not(:last-child)': {
                        borderBottom: `0.5px solid ${theme.palette.text.borderColorLight}`,
                      },
                    })}
                  >
                    <Box
                      sx={{
                        display: { xs: 'none', sm: 'flex' },
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Stack
                        sx={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          columnGap: '20px',
                        }}
                      >
                        <Radio
                          sx={{
                            'position': 'absolute',
                            'width': { sm: '60%', md: '70%', lg: '75%', xl: '80%' },
                            'justifyContent': 'flex-start',
                            'left': '20px',
                            'top': '42px',
                            'right': '0px',
                            'bottom': '0px',
                            'alignItems': 'baseline',
                            'padding': '0px',
                            'display': 'block',
                            '& .MuiSvgIcon-root': {
                              width: '16px',
                              height: '16px',
                            },
                          }}
                          value={uniqueKey}
                          name='radio-buttons'
                          inputProps={{ 'aria-label': 'A' }}
                          disableFocusRipple
                          disableRipple
                        />
                        <Box
                          sx={{
                            'borderRadius': '10px',
                            'border': '0.5px solid',
                            'borderColor': 'text.borderColorLight',
                            'boxShadow': '0px 4px 15px 0px rgba(0, 0, 0, 0.05)',
                            'ml': 4,
                            'p': '6px 6px',
                            'display': 'flex',
                            'justifyContent': 'center',
                            'alignItems': 'center',
                            '& svg': {
                              width: '30px',
                              height: '30px',
                            },
                          }}
                        >
                          {amcLogo?.icon && <amcLogo.icon />}
                        </Box>
                        <Typography
                          variant='subtitle2'
                          sx={(theme: Theme) => ({
                            fontSize: { sm: '14px', xl: '16px' },
                            fontWeight: selectedScheme === uniqueKey ? 500 : 400,
                            color: 'text.valuColor',
                            ...(selectedScheme === uniqueKey
                              ? {
                                  backgroundClip: 'text',
                                  webkitBackgroundClip: 'text',
                                  WebkitTextFillColor: 'transparent',
                                  backgroundImage: `linear-gradient(180deg,  ${theme.palette.text.darkBlue} 0%,  ${theme.palette.text.gradientSkyBlue} 100%)`,
                                }
                              : { color: 'text.valueColor' }),
                            ml: showIcon ? 0 : 7,
                          })}
                        >
                          {rowHeader
                            ? `${item[rowHeader]} - ${item.planMode} - ${item.optionDesc}`
                            : 'Row Header'}
                        </Typography>
                      </Stack>
                      <Stack
                        direction={'row'}
                        columnGap={'20px'}
                      >
                        {keysToShow.map(key => {
                          if (key !== rowHeader) {
                            return (
                              <Typography
                                key={key}
                                sx={{ color: colorMappings?.[key] }}
                                component={'span'}
                              >
                                {item[key]}
                              </Typography>
                            );
                          }
                        })}
                      </Stack>
                    </Box>
                    <Grid
                      container
                      alignItems={'center'}
                      sx={{ display: { sm: 'none' } }}
                    >
                      <Grid item>
                        <Radio
                          sx={{
                            'position': 'absolute',
                            'width': '100%',
                            'justifyContent': 'flex-start',
                            'left': '10px',
                            'top': showIcon ? '20px' : '18px',
                            'right': '0px',
                            'bottom': '0px',
                            'alignItems': 'baseline',
                            'padding': '0px',
                            'display': 'block',
                            'height': '40%',
                            '& .MuiSvgIcon-root': {
                              width: '16px',
                              height: '16px',
                            },
                          }}
                          value={uniqueKey}
                          name='radio-buttons'
                          inputProps={{ 'aria-label': 'A' }}
                          disableFocusRipple
                          disableRipple
                        />
                      </Grid>
                      <Grid
                        item
                        xs={11}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                          <Box
                            sx={{
                              'borderRadius': '5px',
                              'border': '0.5px solid',
                              'borderColor': 'text.borderColorLight',
                              'boxShadow': '0px 4px 15px 0px rgba(0, 0, 0, 0.05)',
                              'ml': 3,
                              'p': '0px 6px',
                              'display': 'flex',
                              'justifyContent': 'center',
                              'alignItems': 'center',
                              '& svg': {
                                width: '30px',
                                height: '30px',
                              },
                            }}
                          >
                            {amcLogo?.icon && <amcLogo.icon />}
                          </Box>
                          <Typography
                            variant='subtitle1'
                            sx={{
                              fontWeight: { xs: 500, sm: 400 },
                              fontSize: { xs: '14px', xl: '16px' },
                              color: 'text.valueColor',
                              ml: showIcon ? 2 : 7,
                            }}
                          >
                            {rowHeader ? item[rowHeader] : 'Row Header'}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                          {keysToShow.map(key => {
                            if (key !== rowHeader) {
                              return (
                                <Typography
                                  variant='subtitle2'
                                  key={key}
                                  sx={{
                                    color: colorMappings?.[key],
                                    ml: 3,
                                    mt: 1,
                                    // color: 'text.labelColor3',
                                    fontWeight: 400,
                                  }}
                                  component={'span'}
                                >
                                  {item[key]}
                                </Typography>
                              );
                            }
                          })}
                        </Box>
                      </Grid>
                    </Grid>

                    <Collapse
                      in={selectedScheme === uniqueKey}
                      timeout='auto'
                      unmountOnExit
                    >
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <StepperNextButton
                          sx={{
                            minWidth: { xs: '125px', sm: '160px', lg: '200px' },
                            flex: { xs: 'unset' },
                            mt: 1,
                            padding: { xs: '6px 20px', sm: '10px 20px' },
                          }}
                          onClick={handleContinue}
                        >
                          Continue
                        </StepperNextButton>
                      </Box>
                    </Collapse>
                  </Box>
                );
              })}
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component='div'
              count={rows?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </RadioGroup>
        </Box>
      ) : showSelectAmcImage ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: '50px 0px 60px 0px',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <SelectSchemes />
          </Box>
          <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            <SelectSchemesMobile />
          </Box>

          <Box sx={{ my: 3 }}>
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: { xs: '16px', sm: '26px' },
                fontWeight: 500,
                mb: 2,
              }}
            >
              Scheme Viewing :{' '}
              <Typography
                component={'span'}
                sx={{
                  fontSize: { xs: '16px', sm: '26px' },
                  color: 'primary.main',
                  fontWeight: 700,
                }}
              >
                AMC
              </Typography>{' '}
              &{' '}
              <Typography
                sx={{
                  fontSize: { xs: '16px', sm: '26px' },
                  color: 'primary.main',
                  fontWeight: 700,
                }}
                component={'span'}
              >
                Folio{' '}
              </Typography>
              Selection
            </Typography>
            <Typography
              variant='subtitle2'
              sx={{ textAlign: 'center', color: 'text.labelColor' }}
            >
              Please select the AMC and folio to access the schemes!
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          my={4}
        >
          <Typography sx={{ fontSize: { xs: '14px', sm: '16px', xl: '18px' } }}>
            You currently have no Investments for this transaction
          </Typography>
        </Box>
      )}
    </>
  );
};

export default ExpandableRadioTable;
