export interface Scheme {
  amcName: string;
  folio: string;
  schemeName: string;
  fund: string;
  nav: number;
  units: number;
  additionalPurAllow: boolean;
  plan?: string;
  option?: string;
}
export interface RowData {
  fundName: string;
  schemeDesc: string;
  schemeName?: any;
  folioNo: string;
  availableAmount: number;
  fund: string;
  [key: string]: string | number;
}
export const investorSchemeDetailsColumns = {
  radio: '',
  fundName: 'AMC',
  schemeDesc: 'Scheme Name',
  folioNo: 'Folio Number',
  availableAmount: 'Available Amount',
};
export const pauseCancelColumns = {
  radio: '',
  fundName: 'AMC',
  schemeName: 'Scheme Name',
  folioNo: 'Folio Number',
  mode: 'Mode',
};
export interface AdditionalPurchaseFormValues {
  accountType: string;
  fundManager: string;
  lumpsumAmount: string;
}
export interface AgentDetails {
  TPI_UserID: number;
  TPI_Branchcode: number;
  tpi_pfmFundsOpted: string;
  TPI_ClientName: string;
  TPI_ContributionURL: string;
  POP_SPCode: string;
  POPCode: string;
}

export interface EnachValues {
  bankName: string;
  ifsc: string;
  accountNo: string;
  accountType: string;
  amount: string;
  fromDT: string;
  toDT: string;
  investorName: string;
  investorMobile: string;
  investorEmail: string;
  investorPan: string;
  newBank: boolean;
}

export interface ServiceCharges {
  Totalcontributionamount: number;
  Servicecharge: number;
  ServiceTax: number;
  TotalAmountPayble: number;
  CGSTAmount: number;
  SGSTAmount: number;
  IGSTAmount: number;
  POPCharges: number;
}
export interface NomineeDetailsFormValues {
  tier1: NomineeDetails[];
  tier2: NomineeDetails[];
}

export interface NomineeDetails {
  IsMajor: '0' | '1';
  FirstName: string;
  NomRelationship: string;
  DateOfBirth: string;
  Nompercentage: string;
  NomPan: string;
  GuardianFirstName?: string;
  Gender: string;
}

export interface Bank {
  bankName: string;
  bankAdd1: string;
  bankAdd2: string;
  bankAdd3: string;
  bankCity: string;
  bankState: string;
  ifscCode: string;
  bankAccNo: string;
  bankAcType: string;
  defaultBank: string | null;
}
export interface DefaultBank {
  bankAcType: string;
  bankAccNo: string;
  bankAdd1: string;
  bankAdd2: string;
  bankAdd3: string;
  bankCity: string;
  bankName: string;
  bankPin: string;
  bankState: string;
  ifscCode: string;
}

export interface SchemeDetails {
  cartAmount: number;
  cartUnits: number;
  folioNo: number;
  fundName: string;
  scheme: string;
  schemeDesc: string;
  plan: string;
  planDesc: string;
  option: string;
  pan: string;
  name: string;
  mobile: string;
  email: string;
  moh: string;
  mohDesc: string | null;
  nav: number;
  taxSaver: string;
  fund: string;
  banks: Bank[];
  defaultBank: DefaultBank;
  units: number;
  clearedUnits: number;
  minTranAmount: number;
  cartId?: string;
  cartItemId?: string;
  schemeName?: string;
  folio?: number;
  sipAmount?: any;
  transactionType?: string;
  investorName?: string;
  taxSaverFlag?: string;
  withdrawalType?: string;
  swpFrequency?: string;
  swpType?: string;
  investmentAmount?: any;
  bankName?: string;
  noOfTransfers?: string;
  swpStartDate?: string;
  swpEndDate?: string;
  amount?: string;
  redeemType?: string;
  reedemMode?: string;
  reedemptionValue?: string;
  noOfWithdrawals?: string;
  payload?: any;
  modeOfRegistration?: string;
}

export interface SWP {
  swpType: string;
  swpDate: string;
  timePeriodFrom: Date | null | string;
  investmentAmount: string;
  swpMode: string;
  noOfTransfers: string;
  timePeriodTo: Date | null | string;
  minTransferAllowed: number;
}
export interface Reedem {
  redeemType: string;
  redeemMode: string;
  reedemptionValue: string;
  bankName: string;
  selectedAmc: string;
  schemeName: string;
}
export interface Investment {
  fund: string;
  amcName: string;
  folio: number;
  dob: string | null;
  investorName: string;
  mobile: string;
  email: string;
  schemeName: string;
  scheme: string;
  schemePlan: string;
  schemeOption: string;
  planDesc: string;
  transactionSource: string;
  isDemat: boolean;
  taxsaverFlag: boolean;
  mcrid: boolean;
  category: string;
  modeOfHolding: string;
  mohDesc: string;
  units: number;
  nav: number;
  redemptionAllow: boolean;
  swdAllow: boolean;
  switchAllow: boolean;
  stpAllow: boolean;
  additionalPurAllow: boolean;
  newPurAllow: boolean;
}
export enum TransactionType {
  SWITCH = 'SWITCH',
  SWP = 'SWP',
  RED = 'RED',
  STP = 'STP',
  NPL = 'NPL',
  SINI = 'SINI',
  APL = 'APL',
  // SIP and SIP + Lumpsum is implemented in APL.
  SIN = 'APL',
  ISIP = 'APL',
  // Pause and cancels.
  SIPC = 'SIPC',
  STPC = 'STPC',
  SWPC = 'SWPC',
  PAUS = 'PAUS', // SIP's Pause.
}

export const nonCommercialTransactionTypes = ['SWITCH', 'SWP', 'RED', 'STP'];

export enum TRANSACTION_TYPE_TO_TITLE {
  SWITCH = 'SWITCH',
  SWP = 'Systematic Withdrawal Plan',
  RED = 'Reedemption',
  STP = 'Systematic Transfer Plan',
  NPL = 'New Purchase Lumpsum',
  SINI = 'New Purchase SIP',
  APL = 'Additional Purchase Lumpsum',
  // SIP and SIP + Lumpsum is implemented in APL.
  SIN = 'Additional Purchase SIP Lumpsum',
  ISIP = 'Additional Purchase SIP',
  // Pause and cancels.
  SIPC = 'SIP Cancel',
  STPC = 'STP Cancel',
  SWPC = 'SWP Cancel',
  PAUS = 'SIP Pause', // SIP's Pause.
  // New types
  NEW = 'New Purchase Lumpsum',
  ADD = 'Additional Purchase Lumpsum',
}
export enum TransactionAllow {
  RED = 'redemptionAllow',
  SWP = 'swdAllow',
  SWITCH = 'switchAllow',
  STP = 'stpAllow',
  APL = 'additionalPurAllow',
  NPL = 'newPurAllow',
}
export enum AplTrtType {
  SIN = 'Lumpsum And SIP',
  ISIP = 'SIP',
  APL = 'Lumpsum',
}
export enum AlpModeType {
  SIN = 'LumpsumAndSIP',
  ISIP = 'SIP',
  APL = 'Lumpsum',
  NEW = 'Lumpsum',
}
export enum ModeTypeToTrtType {
  LumpsumAndSIP = 'SIN',
  SIP = 'ISIP',
  Lumpsum = 'APL',
}
export enum TransactionComponentsType {
  Cart = 'Cart',
  Confirmation = 'Confirmation',
  InvestmentDetailsForm = 'InvestmentDetailsForm',
}

export enum TransactionTypeRoute {
  SWITCH = 'switch',
  SWP = 'swp',
  RED = 'redemption',
  STP = 'stp',
  NPL = 'new-purchase',
  SINI = 'new-purchase',
  APL = 'additional-purchase',
  SIN = 'additional-purchase',
  ISIP = 'additional-purchase',
}
