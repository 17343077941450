import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';
import {
  JSXElementConstructor,
  Key,
  ReactElement,
  ReactFragment,
  ReactPortal,
  useEffect,
  useState,
} from 'react';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';

const COLORS = ['#AAC5F8', '#C5FCE2', '#FFE089'];

const OPTIONS: Record<string, string> = {
  '2': 'Moderate (LC-50)',
  '3': 'Conservative (LC-25)',
  '4': 'Aggressive (LC-75)',
};
const LABELS: Record<string, string> = {
  E: 'Label for Equity',
  C: 'Label for Corp Bonds',
  G: 'Label for Govt Sec',
};
interface Props {
  index: number;
  entry: any;
}
export const PieChartRadioGroup = ({
  lifecycleOptions = {},
  name,
  label,
  fullWidth,
  commonProps = {},
  ...rest
}: {
  lifecycleOptions?: any;
  name: string;
  label?: string;
  fullWidth?: boolean;
  commonProps?: any;
}) => {
  const [lifeCyclePercentages, setLifeCyclePercentages] = useState({});

  useEffect(() => {
    setLifeCyclePercentages(lifecycleOptions);
  }, [lifecycleOptions]);

  const [field, meta] = useField(name);
  const { value, ...other } = field;
  const formikContext = useFormikContext();
  const errorMessage = meta.error && meta.touched ? meta.error : '';

  const keys = Object.keys(lifeCyclePercentages);

  const CustomTooltip: React.FC<Props> = ({ index, entry }) => {
    return (
      <Box key={index}>
        <Box
          sx={{
            backgroundColor: COLORS[index] || 'red',
            height: '10px',
            marginTop: 4,
            width: '10px',
          }}
        />
        <Typography component='span'>
          {LABELS[entry?.AssetCategory]} - {entry?.MaxValue}%
        </Typography>
      </Box>
    );
  };

  return (
    <Box>
      <Box>{label}</Box>
      <FormControl component='fieldset'>
        <RadioGroup
          value={value}
          style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          {...other}
          {...rest}
        >
          {keys.map(_lifeCycleOption => {
            const percentageAllocations = (lifeCyclePercentages as Record<string, any>)[
              _lifeCycleOption
            ];

            return (
              <>
                <Box
                  key={_lifeCycleOption}
                  sx={{ my: 2, width: { xs: '100%', sm: 'unset' } }}
                >
                  <Box sx={{ textAlign: 'center' }}>
                    <FormControlLabel
                      sx={{
                        '& .MuiTypography-root': {
                          fontSize: '14px',
                          color: '#111927',
                          fontWeight: 700,
                        },
                      }}
                      label={OPTIONS[_lifeCycleOption]}
                      value={_lifeCycleOption}
                      control={
                        <Radio
                          sx={{
                            '&.Mui-checked': {
                              color: 'primary.main',
                            },
                          }}
                        />
                      }
                    />
                  </Box>
                  <Box
                    sx={{
                      '& .recharts-wrapper': {
                        width: { xs: '100% !important', sm: '300px !important' },
                      },
                    }}
                  >
                    <PieChart
                      width={300}
                      height={200}
                    >
                      <Pie
                        fill='#8884d8'
                        data={percentageAllocations}
                        dataKey='MaxValue'
                        nameKey='AssetCategory'
                        innerRadius={60}
                        outerRadius={80}
                      >
                        {percentageAllocations.map(
                          (
                            entry: { AssetCategory: string | undefined },
                            index: string | number,
                          ) => (
                            <>
                              <Cell
                                name={entry.AssetCategory}
                                key={`cell-${index}`}
                                fill={COLORS[index as number]}
                              />
                            </>
                          ),
                        )}
                      </Pie>
                      {/* <Tooltip content={<CustomTooltip index={index} entry={entry} />} /> */}
                      <Tooltip />
                    </PieChart>
                    <>
                      {percentageAllocations.map(
                        (
                          entry: {
                            AssetCategory: string | number;
                            MaxValue:
                              | string
                              | number
                              | boolean
                              | ReactElement<any, string | JSXElementConstructor<any>>
                              | ReactFragment
                              | ReactPortal
                              | null
                              | undefined;
                          },
                          index: Key | null | undefined,
                        ) => (
                          <Box
                            key={index}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              columnGap: '10px',
                              my: 2,
                              justifyContent: 'center',
                            }}
                          >
                            <Box
                              sx={{
                                backgroundColor: COLORS[index as number] || 'red',
                                height: '10px',
                                width: '10px',
                              }}
                            />
                            <Typography component={'span'}>
                              {LABELS[entry.AssetCategory]} - {entry.MaxValue}%
                            </Typography>
                          </Box>
                        ),
                      )}
                    </>
                  </Box>
                </Box>
              </>
            );
          })}
        </RadioGroup>
      </FormControl>
      <Box sx={{ color: 'text.errorText' }}>{errorMessage}</Box>
    </Box>
  );
};
