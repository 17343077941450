export const schemeFilter = {
  ONLY_LUMPSUM: 'onlyLumpSum',
  ONLY_SIP: 'onlySip',
  SIP_LUMPSUM: 'sipLumpSum',
  SWITCH: 'switch',
  REDEMPTION: 'redemption',
};

export const planModes = {
  DIRECT: 'Direct',
  REGULAR: 'Regular',
};
