/* eslint-disable no-unused-vars */
// import styled from '@emotion/styled';
import {
  Box,
  Button,
  InputBase,
  Switch,
  SwitchProps,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import SubmitButton from '../../../forms/FormSubmitButton';

export const StyledTabs = styled(Tabs)(({ theme }) => {
  return {
    '&.MuiTabs-root': {
      '& .MuiTabs-scroller': {
        '& .MuiTabs-flexContainer': {
          padding: '4px',
        },
      },
      '& .MuiTabs-indicator': {
        backgroundColor: 'unset',
        justifyContent: 'space-evenly',
      },
    },
    'width': '350px',
    // width: '100%',
    'backgroundColor': theme.palette.common.white,
    'borderRadius': '47px',
    'border': `1px solid ${theme.palette.text.borderColorLight}`,
    'height': '46px',
  };
});

export const StyledTab = styled(Tab<typeof Link>)(({ theme }) => ({
  'width': '50%',
  'height': '38px',
  'color': theme.palette.text.labelColor,
  'fontSize': '14px',
  'fontWeight': 500,
  'minHeight': '38px',

  '&.Mui-selected': {
    color: theme.palette.common.white,
    background: theme.palette.background.primaryLinearGradient2,
    borderRadius: '47px',
    width: '50%',
    height: '38px',
    minHeight: '38px',
  },
}));

export const StyleTab = styled(Tab)(({ theme }) => ({
  'width': '50%',
  'height': '38px',
  'color': theme.palette.text.labelColor,
  'fontSize': '14px',
  'fontWeight': 500,
  'minHeight': '38px',

  '&.Mui-selected': {
    color: theme.palette.common.white,
    background: theme.palette.background.primaryLinearGradient2,
    borderRadius: '47px',
    width: '50%',
    height: '38px',
    minHeight: '38px',
  },
}));
export const OtpField = styled(InputBase)(({ theme }) => ({
  'width': '60px',
  'height': '58px',
  '&.Mui-focused': {
    '&.MuiInputBase-root': {
      '& .MuiInputBase-input': {
        borderColor: theme.palette.text.dodgerBlue,
        border: '2px solid',
        background: theme.palette.background.shadeBlue,
      },
    },
  },
  '&.Mui-disabled': {
    '&.MuiInputBase-root': {
      '& .MuiInputBase-input': {
        borderColor: theme.palette.text.dodgerBlue,
        border: '2px solid',
      },
    },
  },
  '&.MuiInputBase-root': {
    '& .MuiInputBase-input': {
      'textAlign': 'center',
      'padding': '10px 16px',
      'color': theme.palette.text.dodgerBlue,
      'fontSize': '24px',
      'fontWeight': '700',
      'borderRadius': '5px',
      'border': '0',
      'background': 'rgba(173, 204, 233, 0.35)',
      '&.Mui-disabled': {
        WebkitTextFillColor: 'rgba(51, 127, 201, 0.50)',
        borderColor: theme.palette.text.transparencyBlue,
        border: '2px solid',
      },
    },
  },
}));
export const StepperNextButton = styled(Button)(({ theme }) => ({
  'color': theme.palette.common.white,
  'backgroundImage': theme.palette.background.primaryLinearGradient2,
  'fontSize': '14px',
  'fontWeight': '600',
  'minWidth': '160px',
  'padding': '10px 20px',
  'borderRadius': '5px',
  '&.Mui-disabled': {
    opacity: '0.3',
    color: theme.palette.common.white,
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '16px',
  },
  [theme.breakpoints.between(768, 1200)]: {
    fontSize: '13px',
  },
  [theme.breakpoints.down('md')]: {
    minWidth: 'unset',
    flex: 1,
  },
}));
export const StepperAddButton = styled(Button)(({ theme }) => ({
  'color': theme.palette.primary.main,
  'borderRadius': '5px',
  'backgroundColor': theme.palette.common.white,
  'fontSize': '14px',
  'fontWeight': '600',
  // minWidth: '160px',
  'padding': '10px 20px',
  'border': '1.5px solid',
  'borderColor': theme.palette.primary.main,
  '&.Mui-disabled': {
    opacity: '0.3',
    color: theme.palette.primary.main,
  },
  [theme.breakpoints.between(768, 1200)]: {
    fontSize: '13px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '11px 20px',
    fontSize: '14px',
  },
}));
export const StepperCancelButton = styled(Button)(({ theme }) => ({
  'backgroundColor': theme.palette.common.white,
  'fontSize': '14px',
  'fontWeight': '600',
  'color': theme.palette.text.primary,
  'padding': '10px 20px',
  'border': `1.5px solid ${theme.palette.text.borderColorLight}`,
  '&.Mui-disabled': {
    opacity: '0.3',
    color: theme.palette.common.white,
  },
  [theme.breakpoints.between(768, 1200)]: {
    fontSize: '13px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '11px 20px',
    fontSize: '14px',
    minWidth: 'unset',
    flex: 1,
  },
}));
export const MainHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '20px',
  fontWeight: '700',
  [theme.breakpoints.between(768, 1200)]: {
    fontSize: '16px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
  },
}));
export const SubHeader = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: '600',
  [theme.breakpoints.between(768, 1200)]: {
    fontSize: '16px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
  },
}));
export const SubTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.labelColor,
  fontWeight: 400,
  fontSize: '16px',
  [theme.breakpoints.up(1700)]: {
    fontSize: '18px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
}));
export const LinkText = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: '500',
  textDecoration: 'underline',
  textDecorationColor: theme.palette.text.dodgerBlue,
  [theme.breakpoints.between(768, 1200)]: {
    fontSize: '13px',
  },
}));
export const FieldSubHeader1 = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.inputLabelText,
  fontWeight: '600',
  [theme.breakpoints.between(768, 1200)]: {
    fontSize: '13px',
  },
}));
export const FieldSubHeader2 = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '16px',
  fontWeight: '500',
  [theme.breakpoints.between(768, 1200)]: {
    fontSize: '13px',
  },
}));
export const DetailsHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '14px',
  fontWeight: '500',
  [theme.breakpoints.between(768, 1200)]: {
    fontSize: '13px',
  },
}));
export const Detailslabel = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  color: theme.palette.text.labelColor,
  fontWeight: 400,
}));
export const DetailsValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.valueColor,
  fontSize: '14px',
  fontWeight: '500',
}));
export const TransactionHeader = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  fontWeight: '500',
  marginBottom: '16px',
  [theme.breakpoints.up('xl')]: {
    fontSize: '20px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
  },
}));

export const FieldSubHeader3 = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '14px',
  fontWeight: '500',
}));
export const CartHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '20px',
  fontWeight: '500',
  [theme.breakpoints.between(768, 1200)]: {
    fontSize: '18px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
  },
}));
export const LinkButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '14px',
  fontWeight: '500',
  textDecoration: 'underline',
  textDecorationColor: theme.palette.text.dodgerBlue,
  [theme.breakpoints.between(768, 1200)]: {
    fontSize: '13px',
  },
}));
export const LeftContentText = styled(Typography)(({ theme }) => {
  return {
    color: theme.palette.common.white,
    fontSize: '65px',
    fontWeight: '700',
    lineHeight: '120px',
    [theme.breakpoints.up(1600)]: {
      fontSize: '100px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '45px',
      lineHeight: '72px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '45px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      lineHeight: 'normal',
    },
  };
});
export const LeftContentTitle = styled(Typography)(({ theme }) => {
  return {
    color: theme.palette.common.white,
    fontSize: '20px',
    fontWeight: '500',
    [theme.breakpoints.up(1600)]: {
      fontSize: '22px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  };
});

export const StyledSubmitButton = styled(SubmitButton)(({ theme }) => ({
  'color': theme.palette.common.white,
  'backgroundImage': theme.palette.background.primaryLinearGradient2,
  'fontSize': '16px',
  'fontWeight': '500',
  'padding': '10px 20px',
  '&.Mui-disabled': {
    opacity: '0.3',
    color: theme.palette.common.white,
  },
  [theme.breakpoints.between(768, 1200)]: {
    fontSize: '13px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
  },
}));
export const Investorcard = styled(Box)(({ theme }) => ({
  borderRadius: '15px',
  border: '0.3px solid',
  borderColor: theme.palette.text.borderColorDark,
  background: theme.palette.common.white,
  boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.05)',
}));
export const ErrorText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '0.75rem',
  lineHeight: 1.66,
  textAlign: 'left',
  margin: '3px 14px 0px 14px',
  color: theme.palette.text.crimsonShade,
}));
export const ToggleSwitch = styled((props: SwitchProps) => (
  <Switch
    focusVisibleClassName='.Mui-focusVisible'
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  'width': 42,
  'height': 24,
  [theme.breakpoints.down('md')]: {
    width: 42,
    height: 21,
  },
  'padding': 0,
  '& .MuiSwitch-switchBase': {
    'padding': 0,
    'margin': 2,
    'transitionDuration': '300ms',
    'top': '3px',
    'left': '3px',
    [theme.breakpoints.down('md')]: {
      top: '1.3px',
      left: '5px',
    },

    '&.Mui-checked': {
      'transform': 'translateX(16px)',
      'color': theme.palette.common.white,
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid',
      borderColor: 'common.white',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 14,
    height: 14,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor:
      theme.palette.mode === 'light'
        ? theme.palette.text.crimson
        : theme.palette.text.emerald,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
export const AutocompleteField = styled(TextField)(({ theme }) => ({
  'backgroundColor': theme.palette.common.white,
  '& .MuiFormLabel-root': {
    'fontWeight': 500,
    'fontSize': '14px',
    'top': '2px',
    'left': '6px',
    '&.Mui-disabled': {
      color: theme.palette.text.labelColor,
    },
    '&.Mui-focused': {
      color: theme.palette.text.labelColor,
    },
  },
  '& .MuiInputBase-root': {
    'border': '1px solid',
    'borderColor': theme.palette.text.borderColorLight,
    'backgroundColor': theme.palette.common.white,
    'borderRadius': '7px',
    'paddingLeft': '16px',
    '& .MuiInputBase-input': {
      height: '30px',
      padding: 0,
      fontSize: 14,
      fontWeight: '500',
      color: theme.palette.text.primary,
      border: 'unset',
    },

    '&.Mui-disabled': {
      '&, &:hover': {
        backgroundColor: theme.palette.text.borderColorDark,
      },
      '& .MuiInputBase-input': {
        'backgroundColor': theme.palette.text.borderColorDark,
        'color': theme.palette.text.primary,
        '-webkitTextFillColor': 'unset',
      },
      '&:before': {
        borderBottom: 'unset',
      },
    },
    '&:after': {
      borderBottom: 'unset',
    },
    ' &:hover': {
      backgroundColor: theme.palette.common.white,
    },
    '&,&:hover': {
      '&:before': {
        borderBottom: 'unset',
      },
      '&:not(.Mui-disabled, .Mui-error)(:before)': {
        borderBottom: 'unset',
      },
    },
    '&:hover': {
      '&:not(.Mui-disabled, .Mui-error):before': {
        borderBottom: 'unset',
      },
    },
  },
}));
export const CartLabel = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
  fontWeight: 500,
  color: theme.palette.text.labelColor,
  [theme.breakpoints.up('xl')]: {
    fontSize: '14px',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '12px',
  },
}));
export const CartValue = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 500,
  color: theme.palette.text.valueColor,
  [theme.breakpoints.up('xl')]: {
    fontSize: '16px',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '12px',
  },
}));
