import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  styled,
  Theme,
  Typography,
} from '@mui/material';
import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { ReactComponent as AlertIcon } from '../../../../assets/alert-icon.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/delete-icon.svg';
import {
  DetailsHeader,
  Investorcard,
  TransactionHeader,
} from '../../../auth/login/styles/styledComponents';
import CustomDialog from '../../../common/AllDialogs/CustomDialog';
import { CustomizedCheckbox } from '../../../common/checkbox';
import { startLoading, stopLoading } from '../../../common/Loader/loaderSlice';
import { useRootContext } from '../../../data/root.context';
import FormSelect from '../../../forms/FormSelect';
import SubmitButton from '../../../forms/FormSubmitButton';
import FormTextInput from '../../../forms/FormTextInput';
import { useGetIfscDetailsMutation } from '../../slice';
import { maskAccountNumber } from '../../utils';
import { BankFormValues } from './types';

const StyledGrid = styled(Grid)(({ theme }: { theme: Theme }) => ({
  '& .MuiGrid-item': {
    '&:last-child': {
      '& .MuiFormControl-root': {
        borderBottom: 'unset',
      },
    },
    '& .MuiTypography-root': {
      fontWeight: '500',
      color: '#23263B',
      marginBottom: '16px',
      [theme.breakpoints.up('xl')]: {
        fontSize: '16px',
      },
    },
    '& .MuiFormControl-root': {
      'paddingBottom': '16px',
      'borderBottom': '1px solid',
      'borderColor': theme.palette.text.borderColorDark,
      '& .MuiInputBase-root': {
        '& .MuiInputBase-input': {
          'padding': '8px 16px',
          '&.Mui-disabled': {
            'backgroundColor': theme.palette.text.borderColorDark,
            'border': 'unset',
            'color': theme.palette.text.primary,
            '-webkitTextFillColor': 'unset',
          },
        },
        '& .MuiSelect-select': {
          padding: '12px 16px',
        },
      },
    },
    '& .MuiFormControlLabel-root': {
      '& .MuiFormControlLabel-label': {
        marginBottom: 0,
      },
    },
  },
}));

export default function BankForm({
  initialValues,
  onSubmit,
  header,
  subHeader,
  isUpdateIfsc,
  folios,
  setFolios,
  bankFormUseFor,
}: {
  initialValues: BankFormValues;
  onSubmit: (data: BankFormValues) => void;
  header: string;
  subHeader: string;
  isUpdateIfsc?: boolean;
  folios: any;
  setFolios: React.Dispatch<any>;
  bankFormUseFor?: string;
}) {
  const dispatch = useDispatch();
  const { showToast } = useRootContext();
  const [getIfscDetails] = useGetIfscDetailsMutation();
  const [ifscDetailsPopup, setIfscDetailsPopup] = React.useState<any>(undefined);
  const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
  const [openDialogBox, setOpenDialogBox] = React.useState(false);
  const formikRef = React.useRef<FormikProps<BankFormValues>>(null);

  useEffect(() => {
    if (initialValues.ifsc) {
      const fetchIfscData = async () => {
        try {
          dispatch(startLoading());
          const ifscDetailsResponse: any = await getIfscDetails({
            requestData: {
              ifscCode: initialValues.ifsc,
            },
          }).unwrap();
          if (ifscDetailsResponse?.data?.[0]) {
            const ifscData = ifscDetailsResponse.data[0];
            formikRef?.current?.setFieldValue(
              'branch',
              ifscData.BankAdd1 || ifscData.BankAdd2 || ifscData.BankAdd3,
            );
            formikRef?.current?.setFieldValue('city', ifscData.BankCity);
            formikRef?.current?.setFieldValue('name', ifscData.BankName);
            formikRef?.current?.setFieldValue('branch', ifscData.BankCity);
            formikRef?.current?.setFieldValue('pincode', ifscData.BankPin);
          } else {
            showToast(ifscDetailsResponse?.errors[0]?.message, 'error');
          }
        } catch (e) {
          showToast('Error fetching initial bank details', 'error');
        } finally {
          dispatch(stopLoading());
        }
      };
      fetchIfscData();
    }
  }, []);

  const handleIfscChanged = async (
    ifsc: string,
    setFieldError: (field: string, message: string | undefined) => void,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => Promise<void | FormikErrors<BankFormValues>>,
  ) => {
    if (ifsc.length === 11) {
      try {
        dispatch(startLoading());
        const ifscDetailsResponse: any = await getIfscDetails({
          requestData: {
            ifscCode: ifsc,
          },
        }).unwrap();
        if (ifscDetailsResponse?.data?.[0]) {
          const ifscData = ifscDetailsResponse.data[0];
          setIfscDetailsPopup(ifscData);
          setFieldValue(
            'branch',
            ifscData.BankAdd1 || ifscData.BankAdd2 || ifscData.BankAdd3,
          );
          setFieldValue('city', ifscData.BankCity);
          setFieldValue('name', ifscData.BankName);
          setFieldValue('pincode', ifscData.BankPin);
        } else {
          showToast(ifscDetailsResponse?.errors[0]?.message, 'error');
          setFieldError('ifsc', ifscDetailsResponse?.errors[0]?.message);
          setFieldValue('branch', '');
          setFieldValue('city', '');
          setFieldValue('pincode', '');
        }
      } catch (e) {
        setFieldError('ifsc', 'Could not validate IFSC code.');
        setFieldValue('branch', '');
        setFieldValue('city', '');
      } finally {
        dispatch(stopLoading());
      }
    }
  };

  const validationSchema = Yup.object().shape({
    accountNo: Yup.string()
      .required('Account Number is required')
      .matches(/^[0-9]+$/, 'Account number must contain only numbers')
      .min(9, 'Account number should be at least 9 digits')
      .max(18, 'Account number should not be greater than 18 digits'),
    ifsc: Yup.string()
      .required('IFSC code is required')
      .matches(/^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/, 'Invalid ifsc code')
      .length(11),
    name: Yup.string().required('Account name is required'),
    accountType: Yup.string().required('Account type is required'),
    branch: Yup.string().required('Branch name is required'),
    city: Yup.string().required('City name is required'),
    ...(!isUpdateIfsc
      ? {
          confirmAccountNo: Yup.string()
            .required('Account Number is required')
            .oneOf(
              [Yup.ref('accountNo')],
              'Confirm account number should match account number',
            ),
        }
      : {}),
  });

  const handleCheckboxChange = (e: any, setFieldValue: any) => {
    setFieldValue('defaultBankAccount', e.target.checked);
  };

  function getDefaultBankData(folio: any) {
    const banks = Array.isArray(folio?.banks)
      ? folio.banks
      : [folio?.bank].filter(Boolean);
    return isUpdateIfsc
      ? banks[0]
      : banks.find(
          (bank: any) =>
            bank.default === 'Y' || bank.default_bank === 'Y' || bank.DefaultBank === 'Y',
        );
  }

  return (
    <Investorcard
      sx={(theme: Theme) => ({
        padding: { xs: '0px', sm: '30px 30px' },
        [theme.breakpoints.down('sm')]: {
          boxShadow: 'unset',
          border: 0,
        },
      })}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          sm={5}
          md={4}
        >
          <Typography
            sx={{
              mb: 2,
              color: 'primary.main',
              fontWeight: 500,
              fontSize: { xs: '14px', sm: '16px', xl: '16px' },
            }}
          >
            Selected Folio(s)
          </Typography>
          {folios.map((folio: any) => {
            const defaultBank = getDefaultBankData(folio);
            return (
              <Box
                key={`${folio.folio}_${folio.amcId}`}
                sx={{
                  padding: { xs: '10px 0px', md: '20px 0px' },
                  borderTop: '0.3px solid',
                  borderBottom: '0.3px solid',
                  borderColor: 'text.borderColorDark',
                }}
              >
                <Stack
                  sx={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      color: 'primary.main',
                      fontSize: { xs: '14px', sm: '16px' },
                      opacity: '0.85',
                      fontWeight: 500,
                    }}
                  >
                    {folio.amcName}
                  </Typography>
                  <IconButton onClick={() => setOpenDeletePopup(true)}>
                    <DeleteIcon />
                  </IconButton>
                </Stack>
                <Grid container>
                  <Grid
                    item
                    xs={6}
                  >
                    <Stack
                      rowGap='10px'
                      my='10px'
                    >
                      <Typography sx={{ color: 'text.labelColor' }}>
                        Primary Holder:
                      </Typography>
                      <Typography
                        sx={{
                          color: 'text.valueColor',
                          fontWeight: 500,
                          fontSize: { xs: '14px', xl: '16px' },
                        }}
                      >
                        {folio.investorName || 'N/A'}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                  >
                    <Stack
                      rowGap='10px'
                      my='10px'
                    >
                      <Typography sx={{ color: 'text.labelColor' }}>
                        IFSC Code:
                      </Typography>
                      <Typography
                        sx={{
                          color: 'text.valueColor',
                          fontWeight: 500,
                          fontSize: { xs: '14px', xl: '16px' },
                        }}
                      >
                        {defaultBank?.ifsccode?.toUpperCase() ||
                          defaultBank?.ifsc?.toUpperCase() ||
                          'N/A'}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                  >
                    <Stack
                      rowGap='10px'
                      my='10px'
                    >
                      <Typography sx={{ color: 'text.labelColor' }}>
                        Account Number:
                      </Typography>
                      <Typography
                        sx={{
                          color: 'text.valueColor',
                          fontWeight: 500,
                          fontSize: { xs: '14px', xl: '16px' },
                        }}
                      >
                        {maskAccountNumber(
                          defaultBank?.BankAccNo || defaultBank?.accountNo,
                        ) || 'N/A'}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                  >
                    <Stack
                      rowGap='10px'
                      my='10px'
                    >
                      <Typography sx={{ color: 'text.labelColor' }}>
                        Folio number:
                      </Typography>
                      <Typography
                        sx={{
                          color: 'text.valueColor',
                          fontWeight: 500,
                          fontSize: { xs: '14px', xl: '16px' },
                        }}
                      >
                        {folio.folio || 'N/A'}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <CustomDialog
                  isVisible={openDeletePopup}
                  firstButtonHandler={() => setOpenDeletePopup(false)}
                  secondButtonHandler={() => {
                    if (folios.length === 1) {
                      setOpenDialogBox(true);
                      setOpenDeletePopup(false);
                    } else {
                      setOpenDeletePopup(false);
                      setFolios((prev: any) =>
                        prev.filter((prevFolio: any) => prevFolio.folio !== folio.folio),
                      );
                    }
                  }}
                  firstButtonTitle='Cancel'
                  secondButtonTitle='OK'
                  handleClose={() => {}}
                  showSecondButton={true}
                >
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '10px',
                      mb: 2,
                    }}
                  >
                    <AlertIcon />
                    <Typography
                      sx={{ fontSize: { xs: '14px', xl: '16px' }, fontWeight: 500 }}
                    >
                      Deleting Folio
                    </Typography>
                  </Stack>
                  <Typography
                    variant='subtitle1'
                    sx={{ fontWeight: 400, color: 'text.labelColor' }}
                  >
                    Are you sure you want to delete the folio.
                  </Typography>
                </CustomDialog>
                <CustomDialog
                  isVisible={openDialogBox}
                  firstButtonHandler={() => {}}
                  secondButtonHandler={() =>
                    setFolios((prev: any) =>
                      prev.filter((prevFolio: any) => prevFolio.folio !== folio.folio),
                    )
                  }
                  firstButtonTitle=''
                  secondButtonTitle='OK'
                  handleClose={() => {}}
                  showSecondButton
                  showFirstButton={false}
                >
                  <Stack
                    display='flex'
                    direction='row'
                    alignItems='center'
                    gap='10px'
                  >
                    <Typography variant='h5'>Folio Selection</Typography>
                  </Stack>
                  <Typography
                    variant='subtitle1'
                    sx={{ fontWeight: 400, color: 'text.labelColor', my: 3 }}
                  >
                    {bankFormUseFor === 'Add'
                      ? 'You must have nominee for your investments. if you have only one nominee you only edit it.'
                      : 'You must have a folio to be selected to Change Bank Account Details.'}
                  </Typography>
                </CustomDialog>
              </Box>
            );
          })}
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          md={8}
        >
          <Box
            sx={(theme: Theme) => ({
              borderLeft: {
                xs: 'unset',
                sm: `1px solid ${theme.palette.text.borderColorDark}`,
              },
              py: { xs: 2.5, sm: 'unset' },
              paddingLeft: { xs: '0px', sm: '26px' },
              marginLeft: { xs: '0px', sm: '26px' },
            })}
          >
            <DetailsHeader
              sx={{
                '&.MuiTypography-root': {
                  fontSize: { xs: '16px', xl: '18px' },
                  opacity: '0.85',
                  mb: 1,
                },
              }}
            >
              {header}
            </DetailsHeader>
            <Typography sx={{ color: 'text.labelColor', mb: 2 }}>{subHeader}</Typography>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              innerRef={formikRef}
            >
              {({ setFieldValue, setFieldError, values }) => (
                <Form>
                  <StyledGrid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      lg={11}
                    >
                      <Typography>IFSC Code</Typography>
                      <FormTextInput
                        onChange={async e => {
                          setFieldValue('ifsc', e.target.value.toUpperCase());
                          handleIfscChanged(e.target.value, setFieldError, setFieldValue);
                        }}
                        required={false}
                        name='ifsc'
                        value={values?.ifsc?.toUpperCase()}
                        placeholder='IFSC Code'
                        typeOfInput='alphanumeric'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={11}
                    >
                      <Typography>Account Number</Typography>
                      <FormTextInput
                        disabled={isUpdateIfsc}
                        required={false}
                        name='accountNo'
                        placeholder='Account Number'
                        onInput={(e: any) => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, '');
                          if (e.target.value.length > 18) {
                            e.target.value = e.target.value.slice(0, 18);
                          }
                          setFieldValue('accountNo', e.target.value);
                        }}
                        type='password'
                      />
                    </Grid>
                    {!isUpdateIfsc && (
                      <Grid
                        item
                        xs={12}
                        lg={11}
                      >
                        <Typography>Confirm Account Number</Typography>
                        <FormTextInput
                          required={false}
                          name='confirmAccountNo'
                          placeholder='Confirm Account Number'
                          onInput={(e: any) => {
                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            if (e.target.value.length > 18) {
                              e.target.value = e.target.value.slice(0, 18);
                            }
                            setFieldValue('confirmAccountNo', e.target.value);
                          }}
                          // type='password'
                        />
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={12}
                      lg={11}
                    >
                      <Typography>Bank Name</Typography>
                      <FormTextInput
                        disabled
                        required={false}
                        name='name'
                        placeholder='Bank Name'
                        typeOfInput='singleSpace'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={11}
                    >
                      <Typography>Account Type</Typography>
                      <FormSelect
                        options={[
                          { label: 'NRE', value: 'NRE' },
                          { label: 'NRO', value: 'NRO' },
                          { label: 'Savings', value: 'SAVING' },
                        ]}
                        disabled={isUpdateIfsc}
                        required={false}
                        name='accountType'
                      />
                    </Grid>
                    <Grid
                      item
                      lg={11}
                      xs={12}
                    >
                      <Typography>Branch</Typography>
                      <FormTextInput
                        disabled
                        required={false}
                        name='branch'
                        placeholder='Branch'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={11}
                    >
                      <Typography>City</Typography>
                      <FormTextInput
                        disabled
                        required={false}
                        name='city'
                        placeholder='City'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={11}
                    >
                      <FormControlLabel
                        control={
                          <CustomizedCheckbox
                            checked={values.defaultBankAccount}
                            onChange={e => handleCheckboxChange(e, setFieldValue)}
                          />
                        }
                        label='Set as default bank Account'
                      />
                    </Grid>
                  </StyledGrid>
                  {!!ifscDetailsPopup && (
                    <CustomDialog
                      isVisible={true}
                      handleClose={() => {}}
                      secondButtonTitle='Ok'
                      secondButtonHandler={() => setIfscDetailsPopup(undefined)}
                      firstButtonTitle='Cancel'
                      firstButtonHandler={() => (
                        setFieldValue('ifsc', ''),
                        setFieldValue('branch', ''),
                        setFieldValue('city', ''),
                        setIfscDetailsPopup(undefined)
                      )}
                      showSecondButton={true}
                    >
                      <Box>
                        <TransactionHeader>
                          Please confirm Bank Details{' '}
                        </TransactionHeader>
                        <Grid
                          container
                          spacing={2}
                        >
                          <Grid
                            item
                            xs={6}
                            sm={4}
                          >
                            <Stack rowGap={'8px'}>
                              <Typography sx={{ color: 'text.labelColor' }}>
                                Bank Name:
                              </Typography>
                              <Typography
                                variant='subtitle2'
                                sx={{ color: 'text.valueColor' }}
                              >
                                {ifscDetailsPopup.BankName}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={4}
                          >
                            <Stack rowGap={'8px'}>
                              <Typography sx={{ color: 'text.labelColor' }}>
                                IFSC Code:
                              </Typography>
                              <Typography
                                variant='subtitle2'
                                sx={{ color: 'text.valueColor' }}
                              >
                                {ifscDetailsPopup.IFSCCode}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={4}
                          >
                            <Stack rowGap={'8px'}>
                              <Typography sx={{ color: 'text.labelColor' }}>
                                City:
                              </Typography>
                              <Typography
                                variant='subtitle2'
                                sx={{ color: 'text.valueColor' }}
                              >
                                {ifscDetailsPopup.BankCity}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                          >
                            <Stack rowGap={'8px'}>
                              <Typography sx={{ color: 'text.labelColor' }}>
                                Branch:
                              </Typography>
                              <Typography
                                variant='subtitle2'
                                sx={{ color: 'text.valueColor' }}
                              >
                                {ifscDetailsPopup.BankAdd1 ||
                                  ifscDetailsPopup.BankAdd2 ||
                                  ifscDetailsPopup.BankAdd3}
                              </Typography>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>
                    </CustomDialog>
                  )}
                  <SubmitButton
                    sx={{ minWidth: '200px', padding: '8px 12px' }}
                    label='Submit'
                  />
                </Form>
              )}
            </Formik>
          </Box>
        </Grid>
      </Grid>
    </Investorcard>
  );
}
