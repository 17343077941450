export interface InitialValues {
  stpOption: 'Fixed' | 'Capital Appreciation';
  frequency: string;
  amount: string;
  // noOfTransfers: string;
  stpStartDate: Date | null;
  stpEndDate: Date | null;
  euin: string;
  subArn: string;
  subBroker: string;
  folioNumber: number;
  switchOutScheme: string;
  isExistingScheme: 'E' | 'N';
  switchInScheme: string;
  category: string;
  amc: string;
}
export const FIELD_NAMES = [
  'folioNumber',
  'switchOutScheme',
  'switchInScheme',
  'frequency',
  'amount',
  'stpStartDate',
  'stpEndDate',
  // 'perpetual',
];
export interface InvestmentDetailsFormProps {
  data: any;
  isEditMode: boolean;
  handleCancelModifyingCart?: () => void;
}

export enum InstallmentCycleFrequency {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Halfyearly = 'Half Yearly',
  Annually = 'Annually',
  Fortnightly = 'Fortnightly',
}
