import { Dispatch } from 'react';

interface Action {
  type: string;
  payload: any;
}

export const setBasicDetails = (body: any): Action => ({
  type: 'SET_BASIC',
  payload: body,
});

export const setACKNumber = (body: any): Action => ({
  type: 'SET_ACK_NUMBER',
  payload: body,
});

export const setPersonalDetails = (body: any): Action => ({
  type: 'SET_PERSONAL_DETAILS',
  payload: body,
});

export const setKycDetails = (body: any): Action => ({
  type: 'SET_KYC_DATA',
  payload: body,
});

export const setKycTrackDetails = (body: any): Action => ({
  type: 'SET_KYC_TRACK_DETAILS',
  payload: body,
});

export const setSchemeDetails = (body: any): Action => ({
  type: 'SET_SCHEME_DETAILS',
  payload: body,
});

export const setFundDetails = (body: any): Action => ({
  type: 'SET_FUND_DETAILS',
  payload: body,
});

export const setBankDetails = (body: any): Action => ({
  type: 'SET_BANK_DETAILS',
  payload: body,
});

export const setNomineeDetails = (body: any): Action => ({
  type: 'SET_NOMINEE_DETAILS',
  payload: body,
});

export const setAccountTypes = (body: any): Action => ({
  type: 'SET_ACCOUNT_TYPE',
  payload: body,
});

export const setPennyDropTypes = (body: any): Action => ({
  type: 'SET_PENNY_DROP_RESPONSE',
  payload: body,
});

export const setSummaryDetails = (body: any): Action => ({
  type: 'SET_SUMMARY_DETAILS',
  payload: body,
});

export const setEKYCDetails = (body: any): Action => ({
  type: 'SET_EKYC_DETAILS',
  payload: body,
});

export const setPOPbankDetails = (body: any): Action => ({
  type: 'SET_POP_BANK_DETAILS',
  payload: body,
});

export const setCountriesListStateListDetails = (body: any): Action => ({
  type: 'SET_COUNTRIES_DETAILS',
  payload: body,
});

export const setKYCBankDetails = (body: any): Action => ({
  type: 'SET_KYC_BANK_DETAILS',
  payload: body,
});

export const setStatusBackToSummary = (body: any): Action => ({
  type: 'SET_STATUS_BACK_TO_SUMMARY',
  payload: body,
});

export const setAadhaarDetails = (body: any): Action => ({
  type: 'SET_AADHAAR_DETAILS',
  payload: body,
});

export const setAAADetails = (body: any): Action => ({
  type: 'SET_AAA_DETAILS',
  payload: body,
});

export const setFatcaDetails = (body: any): Action => ({
  type: 'SET_FATCA_DETAILS',
  payload: body,
});

export const setDistrict = (body: any): Action => ({
  type: 'SET_DISTRICT',
  payload: body,
});

export const setSkipKyc = (body: any): Action => ({
  type: 'SET_SKIP_KYC',
  payload: body,
});

export const setStepNumber = (body: any): Action => ({
  type: 'SET_STEP',
  payload: body,
});

export const resetState = (): Action => ({
  type: 'RESET',
  payload: undefined,
});

export const setStatesAndCountries =
  ({ statesList, countriesList }: { statesList: any[]; countriesList: any[] }) =>
  (dispatch: Dispatch<Action>): void => {
    dispatch({
      type: 'SET_BASIC',
      payload: {
        statesList,
        countriesList,
      },
    });
  };

export const setOldAccountType =
  (accountType: any) =>
  (dispatch: Dispatch<Action>): void => {
    dispatch({
      type: 'SET_BASIC',
      payload: {
        oldAccountType: accountType,
        isBackToSummary: true,
      },
    });
  };

export const resetOldAccountType = (dispatch: Dispatch<Action>): void => {
  dispatch({
    type: 'SET_BASIC',
    payload: {
      oldAccountType: '',
      isBackToSummary: false,
    },
  });
};

export const setDocumentDetails =
  (payload: any) =>
  (dispatch: Dispatch<Action>): void => {
    dispatch({
      type: 'SET_DOCUMENT_DETAILS',
      payload: {
        isPersisted: true,
        documents: payload,
      },
    });
  };
