export function capitalizeFirstLetter(string: string) {
  if (!string) return string; // Return the original string if it's falsy (null or undefined)
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function removeSpaces(str: string) {
  return str.replace(/\s+/g, '');
}

export const capitalizeWords = (input: any): string => {
  // Check for non-string input or empty/falsy input
  if (typeof input !== 'string' || input.trim().length === 0) {
    return '';
  }

  // Trim leading/trailing spaces, capitalize the first letter of each word
  return input
    .trim()
    .toLowerCase() // Converts the entire string to lowercase first
    .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize the first letter of each word
};
