import apiSlice from '../../../../service/api';

const npsSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getMasterData: builder.query({
      query: () => ({
        url: 'user/nps/onBoarding/subscriberJourney/GetMasterData',
        method: 'GET',
      }),
    }),
    saveBasicDetails: builder.mutation({
      query: payload => ({
        url: 'user/nps/onBoarding/subscriberJourney/SaveBasicDetails',
        method: 'POST',
        body: payload,
      }),
    }),
    savePersonalDetails: builder.mutation({
      query: payload => ({
        url: 'user/nps/onBoarding/subscriberJourney/SavePersonalDetails',
        method: 'POST',
        body: payload,
      }),
    }),
    getDetailsFromPincode: builder.mutation({
      query: payload => ({
        url: 'user/nps/onBoarding/subscriberJourney/GetStateDistrictDetailsfromPincode',
        method: 'POST',
        body: payload,
      }),
    }),
    saveFatcaDetails: builder.mutation({
      query: payload => ({
        url: 'user/nps/onBoarding/subscriberJourney/SaveFatcaDetails',
        method: 'POST',
        body: payload,
      }),
    }),
    getCountriesAndState: builder.query({
      query: () => ({
        url: 'user/nps/onBoarding/subscriberJourney/CountriesAndStates',
        method: 'GET',
      }),
    }),
    getEkycDetails: builder.mutation({
      query: payload => ({
        url: 'user/nps/onBoarding/subscriberJourney/GetEKYCDetails',
        method: 'POST',
        body: payload,
      }),
    }),
    validatePan: builder.mutation({
      query: payload => ({
        url: 'user/nps/onBoarding/subscriberJourney/validatedPan',
        method: 'POST',
        body: payload,
      }),
    }),
    getPersonalDetails: builder.mutation({
      query: payload => ({
        url: 'user/nps/onBoarding/subscriberJourney/GetPersonalDetails',
        method: 'POST',
        body: payload,
      }),
    }),

    getNpsNomineeDetails: builder.mutation({
      query: payload => ({
        url: 'user/nps/onBoarding/subscriberJourney/GetNomineeDetails',
        method: 'POST',
        body: payload,
      }),
    }),
    getNpsSchemeDetails: builder.mutation({
      query: payload => ({
        url: 'user/nps/onBoarding/subscriberJourney/GetSchemeDetails',
        method: 'POST',
        body: payload,
      }),
    }),
    getKycDetails: builder.mutation({
      query: payload => ({
        url: 'user/nps/onBoarding/subscriberJourney/GetKycDetails',
        method: 'POST',
        body: payload,
      }),
    }),
    getAccountType: builder.mutation({
      query: payload => ({
        url: 'user/nps/onBoarding/subscriberJourney/GetAccountType',
        method: 'POST',
        body: payload,
      }),
    }),
    getFatcaDetails: builder.mutation({
      query: payload => ({
        url: 'user/nps/onBoarding/subscriberJourney/GetFatcaDetails',
        method: 'POST',
        body: payload,
      }),
    }),
    getBankDetails: builder.mutation({
      query: payload => ({
        url: 'user/nps/onBoarding/subscriberJourney/GetBankDetails',
        method: 'POST',
        body: payload,
      }),
    }),
    saveAccountType: builder.mutation({
      query: payload => ({
        url: 'user/nps/onBoarding/subscriberJourney/SaveAccountType',
        method: 'POST',
        body: payload,
      }),
    }),
    redirectToDigilocker: builder.query({
      query: () => ({
        url: 'user/nps/onBoarding/subscriberJourney/distributor/redirectToDigilocker',
        method: 'GET',
      }),
    }),
    getEaadhaar: builder.mutation({
      query: payload => ({
        url: 'user/nps/onBoarding/subscriberJourney/distributor/getAadhaarDetails',
        method: 'POST',
        body: payload,
      }),
    }),
    getFatca: builder.mutation({
      query: payload => ({
        url: 'user/nps/onBoarding/subscriberJourney/GetFatcaDetails',
        method: 'POST',
        body: payload,
      }),
    }),
    getRegdSchemes: builder.mutation({
      query: payload => ({
        url: 'user/nps/onBoarding/subscriberJourney/GetRegSchemeNames',
        method: 'POST',
        body: payload,
      }),
    }),
    getPOPbankNames: builder.query({
      query: () => ({
        url: 'user/nps/onBoarding/subscriberJourney/GetPOPBankNames',
        method: 'GET',
      }),
    }),
    sendRegistrationOtp: builder.mutation({
      query: payload => ({
        url: '/user/nps/onBoarding/subscriberJourney/sendRegistrationOtp',
        method: 'POST',
        body: payload,
      }),
    }),
    validateRegistrationOtp: builder.mutation({
      query: payload => ({
        url: '/user/nps/onBoarding/subscriberJourney/validateRegistrationOtp',
        method: 'POST',
        body: payload,
      }),
    }),
    savePennyDetails: builder.mutation({
      query: payload => ({
        url: '/user/nps/onBoarding/pennyDrop',
        method: 'POST',
        body: payload,
      }),
    }),
    saveBankDetails: builder.mutation({
      query: payload => ({
        url: '/user/nps/onBoarding/subscriberJourney/SaveBankDetails',
        method: 'POST',
        body: payload,
      }),
    }),
    saveKycDetails: builder.mutation({
      query: payload => ({
        url: '/user/nps/onBoarding/subscriberJourney/SaveKYCDetails',
        method: 'POST',
        body: payload,
      }),
    }),
    saveSchemeDetails: builder.mutation({
      query: payload => ({
        url: '/user/nps/onBoarding/subscriberJourney/SaveSchemeDetails',
        method: 'POST',
        body: payload,
      }),
    }),
    savePhotoAndSignatureDetails: builder.mutation({
      query: payload => ({
        url: '/user/nps/onBoarding/subscriberJourney/SavePhotoAndSignatureDetails',
        method: 'POST',
        body: payload,
      }),
    }),
    getnpsSchemeDetails: builder.mutation({
      query: payload => ({
        url: '/user/nps/onBoarding/subscriberJourney/GetSchemeDetails',
        method: 'POST',
        body: payload,
      }),
    }),
    saveEpranKit: builder.mutation({
      query: payload => ({
        url: '/user/nps/onBoarding/subscriberJourney/SaveEpranKit',
        method: 'POST',
        body: payload,
      }),
    }),
    getPfmNames: builder.query({
      query: () => ({
        url: 'user/nps/onBoarding/subscriberJourney/GetPfmNames',
        method: 'GET',
      }),
    }),
    getEpranKit: builder.mutation({
      query: payload => ({
        url: '/user/nps/onBoarding/subscriberJourney/GetEpranKit',
        method: 'POST',
        body: payload,
      }),
    }),
    saveNomineeDetails: builder.mutation({
      query: payload => ({
        url: '/user/nps/onBoarding/subscriberJourney/SaveNominationDetails',
        method: 'POST',
        body: payload,
      }),
    }),
    getAllNomineeDetails: builder.mutation({
      query: payload => ({
        url: '/user/nps/onBoarding/subscriberJourney/GetNomineeDetails',
        method: 'POST',
        body: payload,
      }),
    }),
    // Get Portfolio Details by Pran.
    getPortFolioDetailsByPran: builder.mutation({
      query: payload => ({
        url: '/user/nps/investor/subscriberJourney/portfolio',
        method: 'POST',
        body: payload,
      }),
    }),
    // Get Agent Details by Agent Ref no.
    getAgentDetails: builder.mutation({
      query: payload => ({
        url: '/user/nps/contribution/subsequentContribution/agentDetails',
        method: 'POST',
        body: payload,
      }),
    }),
    // Get Service Details
    getServiceDetails: builder.mutation({
      query: payload => ({
        url: '/user/nps/contribution/subsequentContribution/serviceCharges',
        method: 'POST',
        body: payload,
      }),
    }),
    sendSubContributionEmail: builder.mutation({
      query: payload => ({
        url: '/user/nps/contribution/subsequentContribution/sendEmail',
        method: 'POST',
        body: payload,
      }),
    }),
    sendEmail: builder.mutation({
      query: payload => ({
        url: '/user/nps/onBoarding/subscriberJourney/sendEmail',
        method: 'POST',
        body: payload,
      }),
    }),
    generateTinyUrl: builder.mutation({
      query: payload => ({
        url: '/user/nps/confirmation//subscriberJourney/GenerateShortUrl',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useLazyGetMasterDataQuery,
  useSaveBasicDetailsMutation,
  useSavePersonalDetailsMutation,
  useSaveFatcaDetailsMutation,
  useGetDetailsFromPincodeMutation,
  useLazyGetCountriesAndStateQuery,
  useGetEkycDetailsMutation,
  useValidatePanMutation,
  useGetPersonalDetailsMutation,
  useGetNpsNomineeDetailsMutation,
  useGetNpsSchemeDetailsMutation,
  useGetKycDetailsMutation,
  useGetAccountTypeMutation,
  useGetFatcaDetailsMutation,
  useGetBankDetailsMutation,
  useSaveAccountTypeMutation,
  useRedirectToDigilockerQuery,
  useGetEaadhaarMutation,
  useGetFatcaMutation,
  useGetPOPbankNamesQuery,
  useSendRegistrationOtpMutation,
  useValidateRegistrationOtpMutation,
  useSavePennyDetailsMutation,
  useSaveBankDetailsMutation,
  useSaveKycDetailsMutation,
  useGetPfmNamesQuery,
  useGetRegdSchemesMutation,
  useSaveSchemeDetailsMutation,
  useGetnpsSchemeDetailsMutation,
  useSavePhotoAndSignatureDetailsMutation,
  useSaveEpranKitMutation,
  useGetEpranKitMutation,
  useSaveNomineeDetailsMutation,
  useGetAllNomineeDetailsMutation,
  useGetPortFolioDetailsByPranMutation,
  useGetAgentDetailsMutation,
  useGetServiceDetailsMutation,
  useSendSubContributionEmailMutation,
  useSendEmailMutation,
  useGenerateTinyUrlMutation,
} = npsSlice;
