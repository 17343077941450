import { useSelector } from 'react-redux';

import { AuthResponse } from '../components/features/auth/authSlice';
import { LoginResponse } from '../components/features/login/loginSlice';

const useUserPermission = () => {
  const { subArn, euin, subbrokerCode, admin } = useSelector(
    (state: { login: LoginResponse }) => state.login,
  );
  const { role } = useSelector((state: { auth: AuthResponse }) => state.auth);

  const isIndividualDistributor = !role;
  const isCustomerSupport = !subArn && !euin && !subbrokerCode;

  return {
    showDashboard: isIndividualDistributor || admin || !isCustomerSupport,
  };
};

export default useUserPermission;
