import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Form, Formik, FormikProps } from 'formik';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { startLoading, stopLoading } from '../../../common/Loader/loaderSlice';
import { useRootContext } from '../../../data/root.context';
import { FormStyledRadioButton } from '../../../forms/FormStyledRadioButton';
import SubmitButton from '../../../forms/FormSubmitButton';
import FormTextInput from '../../../forms/FormTextInput';
import { NreActypeOption, ResidentIndiaAccTypeOption } from '../../mfs/constants';
import { useGetIfscDetailsMutation } from '../../slice';
import {
  setInvestorBankDetails,
  setPaymentConfirmation,
  setStepNumber,
} from '../context/MfsNewPurchaseAction';
import { useMfsNewPurchaseContext } from '../context/MfsNewPurchaseContext';

interface InitialBankDetails {
  accountType: string;
  ifscCode: string;
  accountNumber: string;
  confirmAccountNumber: string;
  bankName: string;
  bankBranch: string;
  branchAddress: string;
  branchPincode: string;
}

const BankDetails: React.FC = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const [getIfscDetails] = useGetIfscDetailsMutation();
  const { showToast } = useRootContext();
  const formikRef = React.useRef<FormikProps<InitialBankDetails>>(null);

  const {
    dispatch: contextDispatch,
    state: { investorBankDetails, investorDetailsFormResponse, investorDetails },
  } = useMfsNewPurchaseContext();

  const { bankDetails } = investorDetailsFormResponse || {};

  const initialValues: InitialBankDetails = {
    accountType: state?.reinitiate
      ? state?.response?.bankDetails?.accType
      : investorDetails?.category
      ? investorDetails?.category === '11'
        ? 'Saving'
        : 'nre'
      : '',
    ifscCode: state?.reinitiate
      ? state?.response?.bankDetails?.ifsc
      : investorBankDetails?.ifscCode || bankDetails?.ifsc || '',
    accountNumber: state?.reinitiate
      ? state?.response?.bankDetails?.accNo
      : investorBankDetails?.accountNumber || bankDetails?.accNo || '',
    confirmAccountNumber: state?.reinitiate
      ? state?.response?.bankDetails?.accNo
      : investorBankDetails?.confirmAccountNumber || '',
    bankName: state?.reinitiate
      ? state?.response?.bankDetails?.bank
      : investorBankDetails?.bankName || bankDetails?.bank || '',
    bankBranch: state?.reinitiate
      ? state?.response?.bankDetails?.city
      : investorBankDetails?.bankBranch || bankDetails?.city || '',
    branchAddress: state?.reinitiate
      ? `${state?.response?.bankDetails?.address1}, ${state?.response?.bankDetails?.address2}, ${state?.response?.bankDetails?.address3}`
      : investorBankDetails?.branchAddress || bankDetails?.address1 || '',
    branchPincode: state?.reinitiate
      ? state?.response?.bankDetails?.pinCode
      : investorBankDetails?.branchPincode || bankDetails?.pinCode || '',
  };

  const validationSchema = Yup.object().shape({
    accountType: Yup.string().required('Account Type is required'),
    ifscCode: Yup.string()
      .required('IFSC code is required')
      .length(11, 'IFSC code must be 11 characters'),
    branchPincode: Yup.string().matches(
      /^[0-9]{6}$/,
      'Branch pincode must contain exactly 6 numbers',
    ),
    accountNumber: Yup.string()
      .required('Account number is required')
      .matches(/^[0-9]+$/, 'Account number must contain only numbers')
      .min(9, 'Account number should be at least 9 digits')
      .max(18, 'Account number should not be greater than 18 digits'),
    confirmAccountNumber: Yup.string()
      .required('Please verify Account number')
      .matches(/^[0-9]+$/, 'Account number must contain only numbers')
      .test('accountNumberMatch', 'Account numbers do not match', function (value) {
        return value === this.parent.accountNumber;
      }),
  });

  const handleSubmit = (values: InitialBankDetails) => {
    contextDispatch(setInvestorBankDetails(values));
    contextDispatch(setStepNumber(4));
  };

  const handleIfscChanged = async (
    ifsc: string,
    setFieldError: (field: string, message: string | undefined) => void,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
  ) => {
    if (ifsc.length === 11) {
      try {
        dispatch(startLoading());
        const ifscDetailsResponse: any = await getIfscDetails({
          requestData: {
            ifscCode: ifsc,
          },
        }).unwrap();
        if (ifscDetailsResponse?.data?.[0]) {
          setFieldValue(`bankName`, ifscDetailsResponse?.data[0].BankName);
          setFieldValue(`branchPincode`, ifscDetailsResponse?.data[0].BankPin);
          setFieldValue(`bankBranch`, ifscDetailsResponse?.data[0].BankCity);
          const branchAddress = [
            ifscDetailsResponse?.data[0]?.BankAdd1,
            ifscDetailsResponse?.data[0]?.BankAdd2,
            ifscDetailsResponse?.data[0]?.BankAdd3,
          ]
            .filter(Boolean)
            .join(', ');
          setFieldValue(`branchAddress`, branchAddress);
        } else {
          showToast(ifscDetailsResponse?.errors[0]?.message, 'error');
        }
      } catch (error: any) {
        setFieldError(`ifscCode`, 'Could not validate IFSC code.');
      } finally {
        dispatch(stopLoading());
      }
    } else {
      setFieldValue(`bankName`, '');
      setFieldValue(`branchPincode`, '');
      setFieldValue(`bankBranch`, '');
      setFieldValue(`branchAddress`, '');
    }
  };

  React.useEffect(() => {
    contextDispatch(setPaymentConfirmation(false));
    if (!investorBankDetails && bankDetails?.ifsc) {
      const fetchInitialData = async () => {
        try {
          dispatch(startLoading());
          const response: any = await getIfscDetails({
            requestData: {
              ifscCode: bankDetails?.ifsc,
            },
          }).unwrap();

          if (formikRef.current && response?.data?.[0]) {
            formikRef.current.setFieldValue('bankName', response?.data?.[0].BankName);
            formikRef.current.setFieldValue('branchPincode', response?.data?.[0].BankPin);
            formikRef.current.setFieldValue('bankBranch', response?.data?.[0].BankCity);
            const branchAddress = [
              response?.data[0]?.BankAdd1,
              response?.data[0]?.BankAdd2,
              response?.data[0]?.BankAdd3,
            ]
              .filter(Boolean)
              .join(', ');
            formikRef.current.setFieldValue('branchAddress', branchAddress);
          } else {
            showToast(response?.errors?.[0]?.message, 'error');
          }
        } catch (error) {
          showToast('Error fetching initial bank details', 'error');
        } finally {
          dispatch(stopLoading());
        }
      };
      fetchInitialData();
    }
  }, []);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        innerRef={formikRef}
      >
        {({ values, setFieldValue, setFieldError, setFieldTouched, setValues }) => {
          return (
            <Form>
              <Box sx={{ p: { xs: '0px 24px', md: '0px 0px 0px 24px' } }}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    sx={{
                      fontSize: { xs: '14px', xl: '16px' },
                      mb: 2,
                      color: 'text.valueColor',
                      fontWeight: 500,
                    }}
                  >
                    Account Type
                  </Typography>
                  <FormStyledRadioButton
                    options={
                      investorDetails?.category === '11'
                        ? ResidentIndiaAccTypeOption
                        : NreActypeOption
                    }
                    name='accountType'
                  />
                </Box>
                <Grid
                  container
                  spacing={2}
                  mb={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormTextInput
                      name='ifscCode'
                      label='IFSC Code'
                      onChange={e => {
                        setFieldValue('ifscCode', e.target.value.toUpperCase());
                        handleIfscChanged(e.target.value, setFieldError, setFieldValue);
                      }}
                      typeOfInput='alphanumeric'
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormTextInput
                      name='accountNumber'
                      label='Account Number'
                      onChange={() => {
                        setFieldValue(`confirmAccountNumber`, '');
                      }}
                      type='password'
                      typeOfInput='number'
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormTextInput
                      name='confirmAccountNumber'
                      label='Confirm Account Number'
                      type='password'
                      typeOfInput='number'
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormTextInput
                      name='bankName'
                      label='Bank Name'
                      typeOfInput='singleSpace'
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormTextInput
                      name='bankBranch'
                      label='Bank Branch'
                      required={false}
                      typeOfInput='alphanumeric'
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormTextInput
                      name='branchAddress'
                      label='Branch Address'
                      required={false}
                      typeOfInput='address'
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormTextInput
                      name='branchPincode'
                      label='Branch Pincode'
                      typeOfInput='number'
                    />
                  </Grid>
                </Grid>
                <SubmitButton
                  sx={{
                    '&.MuiButtonBase-root': {
                      borderRadius: '5px',
                      minWidth: { xs: '100%', sm: '200px' },
                      mt: 5,
                    },
                  }}
                  label='Proceed'
                />
              </Box>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default BankDetails;
