import { Box, Grid } from '@mui/material';
import React from 'react';

import { CustomSearch } from '../../common/search';
import FilterTabs from '../../teamManagement/FilterTabs';

interface TeamTableFilterProps {
  tabValue: string;
  tabValueHandler: (newValue: string) => void;
  tabsArr: { label: string; value: string; totalCount?: number | string }[];
  tabContent: React.ReactNode[];
  investorName: string;
  investorSearchHandler: (keyword: string) => void;
}

export function Filters({
  tabValue,
  tabValueHandler,
  investorName,
  investorSearchHandler,
  tabsArr,
  tabContent,
}: TeamTableFilterProps) {
  return (
    <Box sx={{ p: { sm: 2 }, mb: { xs: 2.5, sm: 0 } }}>
      <FilterTabs
        tabValue={tabValue}
        onChange={newValue => tabValueHandler(newValue)}
        tabsArr={tabsArr}
        tabContent={tabContent}
      />
      <Box sx={{ padding: '20px 20px 10px 0px', display: { xs: 'none', md: 'block' } }}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={8}
          >
            <CustomSearch
              value={investorName}
              placeholder='Search by Investor'
              onChange={investorSearchHandler}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
