import { Box, Grid, Stack, Typography } from '@mui/material';

import { CartLabel, CartValue } from '../../auth/login/styles/styledComponents';
import { FromAndToHeader } from '../common/helper';
import { TransactionType } from '../types';
import { inrCurrencyFormatter } from '../utils';

const Cart = ({
  data,
  children,
  cartItemslength,
}: {
  data?: any;
  children?: React.ReactNode;
  cartItemslength?: boolean;
}) => {
  return (
    <Box
      sx={{
        'pb': 2,
        'mb': 3,
        '&:nth-of-type(n+2)': {
          borderTop: cartItemslength ? '1px solid ' : 'unset',
          borderColor: cartItemslength ? 'text.borderColorLight' : 'unset',
          pt: cartItemslength ? 3 : 'unset',
        },
      }}
    >
      {children}
      <Typography sx={{ color: 'text.labelColor', mb: 2, fontWeight: 500 }}>
        {data?.fundName}
      </Typography>
      <FromAndToHeader
        data={data}
        type={TransactionType.SWITCH}
      />
      <Grid
        container
        spacing={2}
        sx={{
          justifyContent: 'space-between',
          my: 1,
        }}
      >
        <Grid
          item
          xs={6}
          sm={4}
        >
          <Stack gap='10px'>
            <CartLabel>Folio</CartLabel>
            <CartValue>{data.folio}</CartValue>
          </Stack>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
        >
          <Stack gap='10px'>
            {Number(data.cartUnits) ? (
              <>
                <CartLabel>Units</CartLabel>
                <CartValue>{data.cartUnits}</CartValue>
              </>
            ) : (
              <>
                <CartLabel>Amount</CartLabel>
                <CartValue>₹ {inrCurrencyFormatter(data.amount)}</CartValue>
              </>
            )}
          </Stack>
        </Grid>
        {/* <Grid
          item
          xs={6}
          sm={4}
        >
          <Stack gap='10px'>
            <>
              <CartLabel>Exit Load</CartLabel>
              <CartValue>{data.exitLoad || 'NA'}</CartValue>
            </>
          </Stack>
        </Grid> */}
        {/* TODO: Add Exit Load */}
      </Grid>
    </Box>
  );
};

export default Cart;
