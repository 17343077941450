import { Box, CardMedia, Grid, Stack, Theme, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import InvestorProfileIcon from '../../../../../assets/investor-profile.png';
import { Investorcard } from '../../../../auth/login/styles/styledComponents';
import { LoginResponse } from '../../../../features/login/loginSlice';

export function ConfirmationProfile({ investorDetails }: any) {
  const {
    name: arnName,
    arn,
    subArn,
  } = useSelector((state: { login: LoginResponse }) => state.login);

  const { name, pan, mobile, email } = investorDetails || {};

  return (
    <Investorcard
      sx={(theme: Theme) => ({
        bgcolor: 'text.rightProfileCard',
        [theme.breakpoints.down('sm')]: {
          boxShadow: 'unset',
          border: '1px solid',
          borderColor: 'text.borderColorLight',
          padding: '0px',
          borderRadius: '5px',
        },
      })}
    >
      <Box
        sx={{
          padding: { xs: '10px  16px', sm: '30px 20px' },
          borderBottom: '0.5px solid',
          borderColor: 'text.borderColorLight',
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography
            sx={{
              fontSize: { xs: '14px', sm: '16px', xl: '18px' },
              fontWeight: 500,
              color: 'primary.main',
            }}
          >
            Investor Profile
          </Typography>

          <CardMedia
            component='img'
            image={InvestorProfileIcon}
            alt='Mutual Fund Pooled Investment'
            sx={{ width: '44px', height: '40px', display: { xs: 'none', sm: 'block' } }}
          />
        </Stack>
        <Grid
          container
          spacing={2}
          sx={{
            'my': 0.25,
            '& .MuiTypography-root': {
              wordBreak: 'break-word',
            },
          }}
        >
          <Grid
            item
            xs={6}
          >
            <Stack gap='5px'>
              <Typography
                sx={{ color: 'text.labelColor', fontSize: { xs: '12px', sm: '14px' } }}
              >
                Name
              </Typography>
              <Typography
                sx={{
                  color: 'text.valueColor',
                  fontSize: { xs: '14px', xl: '16px' },
                  fontWeight: 500,
                }}
              >
                {name || 'NA'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Stack gap='5px'>
              <Typography
                sx={{ color: 'text.labelColor', fontSize: { xs: '12px', sm: '14px' } }}
              >
                PAN
              </Typography>
              <Typography
                sx={{
                  color: 'text.valueColor',
                  fontSize: { xs: '14px', xl: '16px' },
                  fontWeight: 500,
                }}
              >
                {pan || 'NA'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Stack gap='5px'>
              <Typography
                sx={{ color: 'text.labelColor', fontSize: { xs: '12px', sm: '14px' } }}
              >
                Mobile Number
              </Typography>
              <Typography
                sx={{
                  color: 'text.valueColor',
                  fontSize: { xs: '14px', xl: '16px' },
                  fontWeight: 500,
                }}
              >
                {mobile || 'NA'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>Email</Typography>
              <Typography
                sx={{
                  color: 'text.valueColor',
                  fontSize: { xs: '14px', xl: '16px' },
                  fontWeight: 500,
                }}
              >
                {email || 'NA'}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ padding: { xs: '10px  16px', sm: '30px 20px' } }}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography
            sx={{
              fontSize: { xs: '14px', sm: '16px', xl: '18px' },
              fontWeight: 500,
              color: 'primary.main',
            }}
          >
            Distributor Profile
          </Typography>
        </Stack>
        <Grid
          container
          spacing={2}
          sx={{
            'my': 0.25,
            '& .MuiTypography-root': {
              wordBreak: 'break-word',
            },
          }}
        >
          <Grid
            item
            xs={6}
          >
            <Stack gap='5px'>
              <Typography
                sx={{ color: 'text.labelColor', fontSize: { xs: '12px', sm: '14px' } }}
              >
                ARN Code
              </Typography>
              <Typography
                sx={{
                  color: 'text.valueColor',
                  fontSize: { xs: '14px', xl: '16px' },
                  fontWeight: 500,
                }}
              >
                {arn || 'NA'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Stack gap='5px'>
              {/* {TODO: dont have the fiels for ARN Name} */}
              <Typography
                sx={{ color: 'text.labelColor', fontSize: { xs: '12px', sm: '14px' } }}
              >
                ARN Name
              </Typography>
              <Typography
                sx={{
                  color: 'text.valueColor',
                  fontSize: { xs: '14px', xl: '16px' },
                  fontWeight: 500,
                }}
              >
                {arnName || 'NA'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Stack gap='5px'>
              <Typography
                sx={{ color: 'text.labelColor', fontSize: { xs: '12px', sm: '14px' } }}
              >
                Sub ARN Name
              </Typography>
              <Typography
                sx={{
                  color: 'text.valueColor',
                  fontSize: { xs: '14px', xl: '16px' },
                  fontWeight: 500,
                }}
              >
                {subArn || 'NA'}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Investorcard>
  );
}
