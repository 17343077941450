import { Box, Divider, Grid, Stack, Theme, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import VerifiedIcon from '../../../../../assets/verified-cart-icon.png';
import {
  Investorcard,
  StepperNextButton,
} from '../../../../auth/login/styles/styledComponents';
import CustomDialog from '../../../../common/AllDialogs/CustomDialog';
import { useRootContext } from '../../../../data/root.context';
import { FormSelectInput } from '../../../../forms/FormSelect';
import { FormStyledRadioButton } from '../../../../forms/FormStyledRadioButton';
import SubmitButton from '../../../../forms/FormSubmitButton';
import { MandateData } from '../../../newPurchase/investorDetailsSteps/Payment';
import { formatDate } from '../../../newPurchase/NewInvestmentDetails';
import { useGetEnachAotmDetailsMutation } from '../../../slice';
import { findLogo, inrCurrencyFormatter, maskAccountNumber } from '../../../utils';
import { setPaymentConfirmation } from '../context/NewMfsInvestorAction';
import { useNewMfsInvestorContext } from '../context/NewMfsInvestorContext';
import {
  useAllMandatesMutation,
  useAllPaymentModesMutation,
  useNewPurchaseDirectMutation,
  useNewPurchaseDirectSipMutation,
  useNewPurchaseSipMutation,
} from '../slice';
import { dateToMMDDYYYY, getFirstLetter, mapFrequencyToLetter } from '../utils';
import { ConfirmationProfile } from './ConfirmationProfile';
import { Nominee } from './NomineeDetails';

interface HolderDetails {
  pan: string;
  name: string;
  email: string;
  mobile: string;
  dob: string;
  gender: string;
  emailFamilyRelation: string;
  mobileFamilyRelation: string;
  category: string;
  nriAddress1?: string;
  nriAddress2?: string;
  nriCity?: string;
  nriState?: string;
  taxResidentOfOtherCountry: string;
  countryOfCitizenship: string;
  countryOfBirth: string;
  grossAnnualIncome: string;
  sourceOfWealth: string;
  occupation: string;
  countryOfTaxResident?: string;
  taxPayerIdentificationNumber?: string;
  politicallyExposedPerson: string;
  politicallyExposedRelative: string;
  jointHolderType: string;
  status: string;
}

export function MfsConfirmation() {
  const [loading, setLoading] = React.useState<boolean>(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast } = useRootContext();
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [transactionNumber, setTransactionNumber] = useState('');
  const [paymentModeOptions, setPaymnetModeOptions] = useState<any[]>([]);
  const [isPanBasedAotm, setIsPanBasedAotm] = useState(false);
  const [mandateData, setMandateData] = useState<MandateData>({
    kotmData: [],
    aotmData: [],
  });
  const [umrnOptions, setUmrnOptions] = useState<any[]>([]);
  const [selectedMandate, setSelectedMandate] = useState({
    label: '',
    value: '',
    mandateLimit: 0,
    startDate: '',
    endDate: '',
    bankAccountNumber: '',
    bankName: '',
  } as any);
  const {
    dispatch: contextDispatch,
    state: {
      isNomination,
      investmentDetails,
      investorBankDetails,
      investorDetails,
      fundName,
      schemeDetails,
      basicDetails,
      fatcaDetails,
      holderDetails,
      nomineeDetails,
      investorDetailsFormResponse,
      paymentModeConfirmation,
      sipDates,
    },
  } = useNewMfsInvestorContext();

  const { personalDetails } = investorDetailsFormResponse;

  const { modeOfTransaction, subBrokerCode } = investmentDetails || {};
  const { bankName, accountNumber } = investorBankDetails || {};
  const { schemeDesc, fund, planMode, optionDesc } = schemeDetails || {};

  const [newPurchaseDirect] = useNewPurchaseDirectMutation();
  const [newPurchaseSip] = useNewPurchaseSipMutation();
  const [newPurchaseDirectSip] = useNewPurchaseDirectSipMutation();
  const [getAllPaymentModes] = useAllPaymentModesMutation();
  const [getAllMandates] = useAllMandatesMutation();
  const [getAotmDetails] = useGetEnachAotmDetailsMutation();

  const refactoredHolderDetails = holderDetails?.map((holder: any) => {
    const cleanedHolder: Partial<HolderDetails> = {};
    for (const key in holder) {
      if (holder[key as keyof HolderDetails] !== '') {
        cleanedHolder[key as keyof HolderDetails] = holder[key as keyof HolderDetails];
      }
    }
    cleanedHolder.category = 'I';
    return cleanedHolder;
  });

  const nominees = nomineeDetails?.nominees?.map((nominee: Nominee) => {
    const {
      isMinor,
      isSameAsApplicant,
      guardianName,
      guardianPan,
      guardianRelation,
      guardianGender,
      dob,
      sharePercentage,
      ...rest
    } = nominee;

    const formattedDob = dob ? formatDate(dob as any, 'MM/dd/yyyy') : '';
    const sharePercentageToString = sharePercentage ? sharePercentage.toString() : '';
    const isNomineeMinor = isMinor === 'No' ? false : true;

    if (isMinor === 'No') {
      return {
        ...rest,
        dob: formattedDob,
        sharePercentage: sharePercentageToString,
        isMinor: isNomineeMinor,
      };
    } else {
      return {
        ...rest,
        guardianName,
        guardianPan,
        guardianRelation,
        guardianGender,
        dob: formattedDob,
        sharePercentage: sharePercentageToString,
        isMinor: isNomineeMinor,
      };
    }
  });

  const handleConfirmClick = () => {
    contextDispatch(setPaymentConfirmation(true));
  };

  const sipValidationSchama = {
    modeOfRegistration: Yup.string().required('Mode of Registration is required'),
    mandate: Yup.string().when('modeOfRegistration', {
      is: 'KOTM',
      then: Yup.string().required('Mandate is required'),
    }),
  };
  const lumpsumValidationSchama = {
    paymentMode: Yup.string().required('Payment mode is required'),
  };

  const validationSchema = Yup.object().shape({
    mandate: Yup.string().when('paymentMode', {
      is: 'KOTM',
      then: Yup.string().required('Mandate is required'),
    }),
    ...(modeOfTransaction !== 'Lumpsum' ? sipValidationSchama : {}),
    ...(modeOfTransaction !== 'SIP' ? lumpsumValidationSchama : {}),
  });

  const handleMandateChange = (e: any, values: any) => {
    const value = e.target.value;
    const selectedValue =
      values.paymentMode === 'KOTM' || values.modeOfRegistration === 'KOTM'
        ? mandateData?.kotmData?.find((mandate: any) => mandate.umrn === value)
        : mandateData?.aotmData?.find((mandate: any) => mandate.umnr === value);
    if (value !== 'createMandate') {
      setSelectedMandate({
        label: value,
        value: value,
        mandateLimit: selectedValue?.amount,
        startDate: selectedValue?.fromDate,
        endDate: selectedValue?.toDate,
        bankAccountNumber: selectedValue?.bnkacno,
        bankName: selectedValue?.bank,
      });
    } else {
      setSelectedMandate({
        label: '+ Create New Mandate',
        value: 'createMandate',
        startDate: '',
        endDate: '',
        mandateLimit: 0,
        bankAccountNumber: '',
        bankName: '',
      });
    }
  };

  const handleSubmit = async (values: any) => {
    const bankAddress = investorBankDetails?.branchAddress?.split(',');
    try {
      setLoading(true);
      const payloadData = {
        email: investorDetails?.email,
        mobile: investorDetails?.mobile,
        scheme: schemeDetails?.scheme,
        plan: schemeDetails?.plan,
        option: schemeDetails?.option,
        fund: schemeDetails?.fund,
        schemeName: `${schemeDetails?.schemeDesc} - ${schemeDetails?.planMode} - ${schemeDetails?.optionDesc}`,
        pan: personalDetails?.pan,
        combo: false,
        invCategory: investorDetails?.category,
        investorName: personalDetails?.name || investorDetails?.name,
        euin: investmentDetails?.euin,
        euinDeclaration: investmentDetails?.euin !== '' ? 'N' : 'Y',
        familyMobileFlag: investorDetails?.mobileFamilyDescription,
        familyEmailFlag: investorDetails?.emailFamilyDescription,
        amount:
          modeOfTransaction === 'Lumpsum'
            ? Number(investmentDetails?.investmentAmount)
            : Number(investmentDetails?.lumpsumAmount),
        subBroker: subBrokerCode,
        category: schemeDetails?.category,
        fundName: fundName,
        personalDetails: {
          modeOfHolding:
            basicDetails.investorType === 'M'
              ? '1'
              : investorDetails?.modeOfHolding === 'single'
              ? '1'
              : '4',
          name: investorDetails?.name,
          dob: formatDate(investorDetails?.dob, 'MM/dd/yyyy'),
          pan: investorDetails?.pan,
          email: investorDetails?.email,
          mobile: investorDetails?.mobile,
          ...(fatcaDetails?.taxResidentOtherThanIndia === 'Yes' && {
            countryOfTaxResident:
              fatcaDetails?.taxResidentOtherThanIndia === 'Yes'
                ? fatcaDetails?.taxResidentCountry
                : null,
          }),
          investorType: basicDetails.investorType,
          familyMobileFlag: investorDetails?.mobileFamilyDescription,
          familyEmailFlag: investorDetails?.emailFamilyDescription,
          category: investorDetails?.category,
          gender: getFirstLetter(investorDetails?.gender),
          status: personalDetails?.investorStatus,
          ...(investorDetails.category === '20' && {
            nriAddress1: investorDetails?.address1,
            nriAddress2: investorDetails?.address2,
            nriCity: investorDetails?.city,
            nriState: investorDetails?.state,
            nriCountry: investorDetails?.country,
            nriZipCode: investorDetails?.zipCode,
          }),
          ...(basicDetails.investorType === 'M' && {
            guardianName: investorDetails?.guardianName,
            guardianPan: investorDetails?.guardianPan,
            guardianDob: dateToMMDDYYYY(investorDetails?.guardianDob),
            guardianRelation: investorDetails?.guardianRelationWithMinor,
            guardianEmailDescription: investorDetails?.guardianEmailFamilyDescription,
            guardianMobileDescription: investorDetails?.guardianMobileFamilyDescription,
            guardianEmail: investorDetails?.guardianEmail,
            guardianMobile: investorDetails?.guardianMonNo,
            guardianCategory: investorDetails?.guardianCategory,
          }),
          taxPayerIdentificationNumber:
            fatcaDetails?.taxResidentOtherThanIndia === 'Yes'
              ? fatcaDetails?.foreignIdNo
              : investorDetails?.pan,
          seafarerFlag: false,
          countryOfBirth: fatcaDetails?.country,
          countryOfCitizenship: fatcaDetails?.birthPlace,
          countryOfCitizenShip: investorDetails.category === '20' ? 'yes' : 'no',
          grossAnnualIncome: fatcaDetails?.annualIncome,
          taxResidentOfOtherCountry: fatcaDetails?.taxResidentOtherThanIndia,
          occupation: fatcaDetails?.occupation,
          sourceOfWealth: fatcaDetails?.sourceOfWealth,
          politicallyExposedPerson: fatcaDetails?.politicallyExposedPerson,
          politicallyExposedRelative: fatcaDetails?.politicallyExposedRelative,
        },
        jointHolders:
          basicDetails.investorType === 'M' ||
          holderDetails === null ||
          holderDetails === undefined
            ? []
            : refactoredHolderDetails,
        nominees:
          basicDetails.investorType === 'M' || isNomination || !nomineeDetails?.nominees
            ? []
            : nominees || [],
        noNomineeDeclaration:
          basicDetails.investorType === 'M' || isNomination || !nomineeDetails?.nominees
            ? true
            : false,
        bankDetails: {
          ifsc: investorBankDetails?.ifscCode,
          bank: investorBankDetails?.bankName,
          accType: investorBankDetails?.accountType,
          existing: '',
          acceptTerms: 'Y',
          accNo: investorBankDetails?.accountNumber,
          address1: bankAddress[0]?.trim(),
          address2: bankAddress[1]?.trim(),
          address3: bankAddress[2]?.trim(),
          city: investorBankDetails?.bankBranch,
          pinCode: investorBankDetails?.branchPincode,
        },
        ...(modeOfTransaction !== 'Lumpsum' && {
          sipFrequency: mapFrequencyToLetter(investmentDetails?.frequency),
          sipStartDate: formatDate(investmentDetails?.startDate, 'MM/dd/yyyy'),
          sipEndDate: formatDate(investmentDetails?.endDate, 'MM/dd/yyyy'),
          sipDay: investmentDetails?.sipDay,
          noOfInstallments: Number(investmentDetails?.numberOfInstalments),
          modeOfRegistration: values?.modeOfRegistration,
          installmentAmount: Number(investmentDetails?.investmentAmount),
          perpetual: investmentDetails?.perpetual,
          remarks: 'Ok',
        }),
        ...(modeOfTransaction !== 'SIP' && {
          paymentDetails: {
            paymentMode: values?.paymentMode,
          },
          paymentMode: values?.paymentMode,
        }),
        createMandate: selectedMandate?.value === 'createMandate',
        umrn: selectedMandate?.value === 'createMandate' ? '' : selectedMandate.value,
        selectedMandates:
          values.paymentMode === 'KOTM' ||
          values.modeOfRegistration === 'KOTM' ||
          values.paymentMode === 'AOTM' ||
          values.modeOfRegistration === 'AOTM'
            ? selectedMandate
            : {},
      };

      let response;
      if (modeOfTransaction === 'Lumpsum') {
        response = await newPurchaseDirect(payloadData).unwrap();
      } else if (modeOfTransaction === 'SIP') {
        response = await newPurchaseSip(payloadData).unwrap();
      } else {
        response = await newPurchaseDirectSip(payloadData).unwrap();
      }
      if (response) {
        setTransactionNumber(response.ihno);
        setOpenDialogBox(true);
      }
    } catch (error) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getMandates = async () => {
      const payload = {
        fund: fund,
      };
      const response = await getAllPaymentModes(payload).unwrap();
      const options = Object.entries(response)
        .map(([key, value]) => {
          if (value === 'Y') {
            return { label: key?.toUpperCase(), value: key?.toUpperCase() };
          }
          return null;
        })
        .filter(Boolean);
      setPaymnetModeOptions(options);
    };
    getMandates();
  }, []);

  useEffect(() => {
    const getAotmStatus = async () => {
      try {
        const response: any = await getAotmDetails({
          fund: fund,
        }).unwrap();

        const { enach_aotm_basedon } = response;
        setIsPanBasedAotm(enach_aotm_basedon.toUpperCase() === 'PAN');
      } catch (error: any) {
        const message =
          (error as any).data?.message || (error as any).message || 'Unknown error';
        showToast(message, 'error');
      }
    };
    getAotmStatus();
  }, []);

  const getOtmMadatesLumpsum = async (paymentMode: any) => {
    try {
      const payload = {
        fund: fund,
        pan: investorDetails?.pan,
        trtype: 'NPL',
        folio: '',
        modeOfRegistration: paymentMode,
      };
      const res: MandateData = await getAllMandates(payload).unwrap();
      setMandateData(res);
      let filteredMandates: any[] = [];
      let options: any[] = [];
      const currentTime = new Date().getTime();
      const highestAmount = Math.max(
        Number(investmentDetails?.investmentAmount || 0),
        Number(investmentDetails?.lumpsumAmount || 0),
      );
      //for lumpsum checking highest investment amount should be less than or equal to mandate amount limit
      //mandate end date should be greater than current date
      //mandate start date should be less than or equal to current date
      if (paymentMode === 'KOTM') {
        filteredMandates =
          res?.kotmData?.filter(item => {
            const startDate = new Date(item.fromDate).getTime();
            const endDate = new Date(item.toDate).getTime();
            return (
              item.amount >= highestAmount &&
              startDate <= currentTime &&
              endDate > currentTime
            );
          }) || [];
        options = filteredMandates?.map((item: any) => {
          return { label: item.umrn, value: item.umrn };
        });
      } else {
        filteredMandates =
          res?.aotmData?.filter(item => {
            const startDate = new Date(item.fromDate).getTime();
            const endDate = new Date(item.toDate).getTime();
            return (
              item.amount >= highestAmount &&
              startDate <= currentTime &&
              endDate > currentTime
            );
          }) || [];
        options = filteredMandates.map((item: any) => {
          return { label: item.umnr, value: item.umnr };
        });
      }
      setUmrnOptions(options);
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    }
  };

  useEffect(() => {
    if (
      modeOfTransaction !== 'Lumpsum' &&
      investmentDetails?.modeOfRegistration !== 'ISIP'
    ) {
      const getPaymentOptions = async () => {
        const payload = {
          fund: fund,
          pan: investorDetails?.pan,
          trtype: 'NPL',
          folio: '',
          modeOfRegistration: investmentDetails?.modeOfRegistration,
        };
        const res: MandateData = await getAllMandates(payload).unwrap();
        setMandateData(res);
        let filteredMandates: any[] = [];
        let options: any[] = [];
        const investmentSipLatestDate = new Date(investmentDetails?.endDate).getTime();
        const investmentSipEarliestDate = new Date(
          investmentDetails?.startDate,
        ).getTime();
        const highestAmount = Math.max(
          Number(investmentDetails?.investmentAmount || 0),
          Number(investmentDetails?.lumpsumAmount || 0),
        );
        //checking for sip => mandate start date should be less than or equal to the sip start date
        //and mandate end date should be greater than sip end date
        if (investmentDetails?.modeOfRegistration === 'KOTM') {
          filteredMandates =
            res?.kotmData?.filter(item => {
              const startDate = new Date(item.fromDate).getTime();
              const endDate = new Date(item.toDate).getTime();
              return (
                item.amount >= highestAmount &&
                startDate <= investmentSipEarliestDate &&
                endDate > investmentSipLatestDate
              );
            }) || [];
          options = filteredMandates.map((item: any) => {
            return { label: item.umrn, value: item.umrn };
          });
        } else {
          filteredMandates =
            res?.aotmData?.filter(item => {
              const startDate = new Date(item.fromDate).getTime();
              const endDate = new Date(item.toDate).getTime();
              return (
                item.amount >= highestAmount &&
                startDate <= investmentSipEarliestDate &&
                endDate > investmentSipLatestDate
              );
            }) || [];
          options = filteredMandates.map((item: any) => {
            return { label: item.umnr, value: item.umnr };
          });
        }
        //For NRI i.e '20' dont allow to create Mandate and in case of AOTM also
        if (
          modeOfTransaction !== 'Lumpsum' &&
          investorDetails?.category !== '20' &&
          investmentDetails?.modeOfRegistration !== 'AOTM'
        ) {
          options.unshift({ label: '+ Create New Mandate', value: 'createMandate' });
        }
        setUmrnOptions(options);
      };
      getPaymentOptions();
    }
  }, []);

  const getMandateOptionsSip = async (registrationMode: any) => {
    if (registrationMode !== 'ISIP') {
      try {
        const payload = {
          fund: fund,
          pan: investorDetails?.pan,
          trtype: 'NPL',
          folio: '',
          modeOfRegistration: registrationMode,
        };
        const res: MandateData = await getAllMandates(payload).unwrap();
        setMandateData(res);
        let filteredMandates: any[] = [];
        let options: any[] = [];
        const currentTime = new Date().getTime();
        const investmentSipLatestDate = new Date(investmentDetails?.endDate).getTime();
        const investmentSipEarliestDate = new Date(
          investmentDetails?.startDate,
        ).getTime();
        const highestAmount = Math.max(
          Number(investmentDetails?.investmentAmount || 0),
          Number(investmentDetails?.lumpsumAmount || 0),
        );
        //checking for sip => mandate start date should be less than or equal to the sip start date
        //and mandate end date should be greater than sip end date
        if (registrationMode === 'KOTM') {
          filteredMandates =
            res?.kotmData?.filter(item => {
              const startDate = new Date(item.fromDate).getTime();
              const endDate = new Date(item.toDate).getTime();
              return (
                item.amount >= highestAmount &&
                startDate <= currentTime &&
                endDate > currentTime
              );
            }) || [];
          options = filteredMandates.map((item: any) => {
            return { label: item.umrn, value: item.umrn };
          });
        } else {
          filteredMandates =
            res?.aotmData?.filter(item => {
              const startDate = new Date(item.fromDate).getTime();
              const endDate = new Date(item.toDate).getTime();
              return (
                item.amount >= highestAmount &&
                startDate <= investmentSipEarliestDate &&
                endDate > investmentSipLatestDate
              );
            }) || [];
          options = filteredMandates.map((item: any) => {
            return { label: item.umnr, value: item.umnr };
          });
        }
        //For NRI i.e '20' dont allow to create Mandate and in case of AOTM also
        if (
          modeOfTransaction !== 'Lumpsum' &&
          investorDetails?.category !== '20' &&
          registrationMode !== 'AOTM'
        ) {
          options.unshift({ label: '+ Create New Mandate', value: 'createMandate' });
        }
        setUmrnOptions(options);
      } catch (error: any) {
        const message =
          (error as any).data?.message || (error as any).message || 'Unknown error';
        showToast(message, 'error');
      }
    }
  };

  const amcLogo = findLogo(fund);

  const getFilteredRegistrationModeOptions = () => {
    const isCategory11 = investorDetails?.category === '11';
    const isKOTM = investmentDetails?.modeOfRegistration === 'KOTM';
    if (
      isCategory11 &&
      ((investmentDetails?.modeOfRegistration !== 'ISIP' && isKOTM) ||
        (investmentDetails?.modeOfRegistration !== 'ISIP' && isPanBasedAotm))
    ) {
      return paymentModeOptions.filter(
        option => option.label === 'KOTM' || (isPanBasedAotm && option.label === 'AOTM'),
      );
    } else {
      return paymentModeOptions.filter(
        option =>
          option.label === 'KOTM' ||
          (isPanBasedAotm && option.label === 'AOTM') ||
          option.label === 'ISIP',
      );
    }
  };

  const getFilteredPaymentModeOptions = (values: any) => {
    if (
      selectedMandate.value === 'createMandate' ||
      values.modeOfRegistration === 'ISIP'
    ) {
      return paymentModeOptions?.filter(
        option =>
          option.label !== 'KOTM' && option.label !== 'ISIP' && option.label !== 'AOTM',
      );
    } else {
      return paymentModeOptions?.filter(option => {
        if (option.label === 'ISIP') {
          return false;
        }
        if (option.label === 'AOTM' && !isPanBasedAotm) {
          return false;
        }
        if (values.modeOfRegistration === 'KOTM' && values.mandate !== '') {
          return option.label !== 'AOTM';
        }
        if (values.modeOfRegistration === 'AOTM' && values.mandate !== '') {
          return option.label !== 'KOTM';
        }
        return true;
      });
    }
  };

  return (
    <Box
      sx={{
        padding: { xs: '0px 24px ', md: '0px 24px 0px 24px' },
        position: 'relative',
      }}
    >
      <Typography
        sx={{
          mb: 2,
          color: 'primary.main',
          fontSize: { xs: '14px', sm: '16px' },
          fontWeight: 500,
        }}
      >
        Please check the following details
      </Typography>
      <Grid
        container
        spacing={2}
      >
        {!paymentModeConfirmation ? (
          <Grid
            item
            xs={12}
            md={7.5}
            sx={{ order: { xs: 2, md: 1 } }}
          >
            <Investorcard
              sx={(theme: Theme) => ({
                padding: '30px 30px',
                // margin: '0px 0px 0px 24px',
                [theme.breakpoints.down('md')]: {
                  padding: '20px',
                },
                [theme.breakpoints.down('sm')]: {
                  boxShadow: 'unset',
                  border: 'unset',
                  padding: '0px',
                },
              })}
            >
              <Typography
                sx={{
                  color: 'primary.main',
                  fontWeight: 500,
                  mb: 2,
                  fontSize: { xs: '14px', sm: '16px', xl: '18px' },
                }}
              >
                Transaction Details
              </Typography>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={6}
                >
                  <Stack
                    rowGap={'10px'}
                    mb={'25px'}
                  >
                    <Typography
                      sx={{ color: 'text.labelColor', fontSize: { xl: '16px' } }}
                    >
                      Mode Of Transaction
                    </Typography>
                    <Typography
                      sx={{
                        color: 'text.valueColor',
                        fontWeight: 500,
                        fontSize: { xl: '16px' },
                      }}
                    >
                      {modeOfTransaction || 'NA'}
                    </Typography>
                  </Stack>
                </Grid>

                <Grid
                  item
                  xs={6}
                >
                  <Stack
                    rowGap={'10px'}
                    mb={'25px'}
                  >
                    <Typography sx={{ color: 'text.labelColor' }}>
                      Bank Account
                    </Typography>
                    <Typography
                      variant='subtitle2'
                      sx={{ color: 'text.valueColor' }}
                    >
                      {`${bankName} - ${maskAccountNumber(accountNumber)}` || 'NA'}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Divider
                orientation='horizontal'
                variant='fullWidth'
                flexItem
                sx={{
                  border: '1px dashed',
                  borderColor: 'text.borderColorDark',
                  mb: 2,
                }}
              />
              <Typography
                sx={{
                  color: 'primary.main',
                  fontWeight: 500,
                  mb: 2,
                  fontSize: { xs: '14px', sm: '16px', xl: '18' },
                }}
              >
                Scheme Details
              </Typography>
              <Box
                sx={{
                  bgcolor: 'text.boxColor',
                  padding: { xs: '20px 20px', lg: '30px 30px' },
                  mb: 2,
                  mx: { xs: '-20px', sm: 'unset' },
                }}
              >
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={1.5}
                    sm={1.5}
                    md={2}
                  >
                    <Box
                      sx={{
                        'borderRadius': '5px',
                        'border': '0.5px solid',
                        'borderColor': 'text.borderColorLight',
                        'boxShadow': '0px 4px 15px 0px rgba(0, 0, 0, 0.05)',
                        'padding': '4px 4px',
                        'display': 'flex',
                        'justifyContent': 'center',
                        'alignItems': 'center',
                        'width': '42px',
                        'height': '42px',
                        '& svg': {
                          width: '32px',
                          height: '32px',
                        },
                      }}
                    >
                      {amcLogo?.icon && <amcLogo.icon />}
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={10.5}
                    sm={10.5}
                    md={10}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={12}
                      >
                        <Stack mb={'25px'}>
                          <Typography
                            sx={{
                              color: 'text.labelColor',
                              fontSize: { xs: '12px', sm: '14px' },
                            }}
                          >
                            {fundName || 'NA'}
                          </Typography>
                          <Typography
                            sx={{
                              color: 'text.valueColor',
                              fontSize: { xs: '12px', sm: '14px', xl: '16px' },
                              fontWeight: 500,
                            }}
                          >
                            {`${schemeDesc} - ${planMode} - ${optionDesc}` || 'NA'}
                          </Typography>
                        </Stack>
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        sm={4}
                        md={6}
                        lg={4}
                      >
                        <Stack
                          rowGap={'10px'}
                          mb={'25px'}
                        >
                          <Typography
                            sx={{
                              color: 'text.labelColor',
                              fontSize: { xs: '12px', sm: '14px' },
                            }}
                          >
                            Investment Amount
                          </Typography>
                          <Typography
                            sx={{
                              color: 'text.valueColor',
                              fontSize: { xs: '12px', sm: '14px', xl: '16px' },
                              fontWeight: 500,
                            }}
                          >
                            ₹
                            {inrCurrencyFormatter(investmentDetails?.investmentAmount) ||
                              'NA'}
                          </Typography>
                        </Stack>
                      </Grid>
                      {modeOfTransaction !== 'Lumpsum' && (
                        <>
                          <Grid
                            item
                            xs={6}
                            sm={4}
                            md={6}
                            lg={4}
                          >
                            <Stack
                              rowGap={'10px'}
                              mb={'25px'}
                            >
                              <Typography sx={{ color: 'text.labelColor' }}>
                                SIP Day
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: { xs: '14px', xl: '16px' },
                                  color: 'text.valueColor',
                                  fontWeight: 500,
                                }}
                              >
                                {investmentDetails?.sipDay || 'NA'}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={4}
                            md={6}
                            lg={4}
                          >
                            <Stack
                              rowGap={'10px'}
                              mb={'25px'}
                            >
                              <Typography sx={{ color: 'text.labelColor' }}>
                                Number Of Installments
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: { xs: '14px', xl: '16px' },
                                  color: 'text.valueColor',
                                  fontWeight: 500,
                                }}
                              >
                                {investmentDetails?.numberOfInstalments || 'NA'}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={4}
                            md={6}
                            lg={4}
                          >
                            <Stack
                              rowGap={'10px'}
                              mb={'25px'}
                            >
                              <Typography sx={{ color: 'text.labelColor' }}>
                                Start Date
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: { xs: '14px', xl: '16px' },
                                  color: 'text.valueColor',
                                  fontWeight: 500,
                                }}
                              >
                                {formatDate(investmentDetails?.startDate, 'dd/MM/yyyy') ||
                                  'NA'}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={4}
                            md={6}
                            lg={4}
                          >
                            <Stack
                              rowGap={'10px'}
                              mb={'25px'}
                            >
                              <Typography sx={{ color: 'text.labelColor' }}>
                                End Date
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: { xs: '14px', xl: '16px' },
                                  color: 'text.valueColor',
                                  fontWeight: 500,
                                }}
                              >
                                {formatDate(investmentDetails?.endDate, 'dd/MM/yyyy') ||
                                  'NA'}
                              </Typography>
                            </Stack>
                          </Grid>
                        </>
                      )}
                      {modeOfTransaction === 'Lumpsum + SIP' && (
                        <Grid
                          item
                          xs={6}
                          sm={4}
                          md={6}
                          lg={4}
                        >
                          <Stack
                            rowGap={'10px'}
                            mb={'25px'}
                          >
                            <Typography sx={{ color: 'text.labelColor' }}>
                              Lumpsum Amount
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: { xs: '14px', xl: '16px' },
                                color: 'text.valueColor',
                                fontWeight: 500,
                              }}
                            >
                              ₹
                              {inrCurrencyFormatter(investmentDetails?.lumpsumAmount) ||
                                'NA'}
                            </Typography>
                          </Stack>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              <StepperNextButton
                sx={{ mt: 3, minWidth: { xs: '100%', sm: '200px' } }}
                onClick={handleConfirmClick}
              >
                Confirm
              </StepperNextButton>
            </Investorcard>
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            md={7.5}
            sx={{ order: { xs: 2, md: 1 } }}
          >
            <Investorcard
              sx={(theme: Theme) => ({
                padding: '30px 30px',
                // margin: '0px 0px 0px 24px',
                [theme.breakpoints.down('md')]: {
                  padding: '20px',
                },
                [theme.breakpoints.down('sm')]: {
                  boxShadow: 'unset',
                  border: 'unset',
                  padding: '0px',
                },
              })}
            >
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  sm={1.5}
                  md={1.2}
                >
                  <Box
                    sx={{
                      borderRadius: '5px',
                      border: '0.5px solid',
                      borderColor: 'text.borderColorLight',
                      boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.05)',
                      padding: '4px 4px',
                      display: 'flex',
                      width: '42px',
                      height: '42px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {amcLogo?.icon && <amcLogo.icon />}
                  </Box>
                </Grid>
                <Grid
                  item
                  sm={10.5}
                  md={10.8}
                  mt={1}
                >
                  <Typography
                    sx={{
                      fontSize: { sm: '16px', md: '20px' },
                      fontWeight: 500,
                      color: 'primary.main',
                    }}
                  >
                    {fundName}
                  </Typography>
                </Grid>
                <Grid
                  item
                  sm={12}
                >
                  <Divider
                    orientation='horizontal'
                    variant='fullWidth'
                    flexItem
                    sx={{
                      border: '1px dashed',
                      borderColor: 'text.borderColorDark',
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sm={12}
                >
                  <Stack
                    rowGap={'10px'}
                    mb={'25px'}
                  >
                    <Typography
                      sx={{
                        color: 'text.labelColor',
                        fontSize: { xs: '12px', sm: '14px' },
                      }}
                    >
                      Scheme
                    </Typography>
                    <Typography
                      sx={{
                        color: 'text.valueColor',
                        fontWeight: 500,
                        fontSize: { xs: '12px', sm: '14px', xl: '16px' },
                      }}
                    >
                      {`${schemeDesc} - ${planMode} - ${optionDesc}` || 'NA'}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={4}
                >
                  <Stack
                    rowGap={'10px'}
                    mb={'25px'}
                  >
                    <Typography
                      sx={{
                        color: 'text.labelColor',
                        fontSize: { xs: '12px', sm: '14px' },
                      }}
                    >
                      Investment Amount
                    </Typography>
                    <Typography
                      sx={{
                        color: 'text.valueColor',
                        fontWeight: 500,
                        fontSize: { xs: '12px', sm: '14px', xl: '16px' },
                      }}
                    >
                      ₹{inrCurrencyFormatter(investmentDetails?.investmentAmount) || 'NA'}
                    </Typography>
                  </Stack>
                </Grid>
                {modeOfTransaction !== 'Lumpsum' && (
                  <>
                    <Grid
                      item
                      sm={4}
                      xs={6}
                    >
                      <Stack
                        rowGap={'10px'}
                        mb={'25px'}
                      >
                        <Typography
                          sx={{
                            color: 'text.labelColor',
                            fontSize: { xs: '12px', sm: '14px' },
                          }}
                        >
                          SIP Day
                        </Typography>
                        <Typography
                          sx={{
                            color: 'text.valueColor',
                            fontWeight: 500,
                            fontSize: { xs: '12px', sm: '14px', xl: '16px' },
                          }}
                        >
                          {investmentDetails?.sipDay || 'NA'}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      sm={4}
                      xs={6}
                    >
                      <Stack
                        rowGap={'10px'}
                        mb={'25px'}
                      >
                        <Typography
                          sx={{
                            color: 'text.labelColor',
                            fontSize: { xs: '12px', sm: '14px' },
                          }}
                        >
                          Number Of Installments
                        </Typography>
                        <Typography
                          sx={{
                            color: 'text.valueColor',
                            fontWeight: 500,
                            fontSize: { xs: '12px', sm: '14px', xl: '16px' },
                          }}
                        >
                          {investmentDetails?.numberOfInstalments || 'NA'}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      sm={4}
                      xs={6}
                    >
                      <Stack
                        rowGap={'10px'}
                        mb={'25px'}
                      >
                        <Typography
                          sx={{
                            color: 'text.labelColor',
                            fontSize: { xs: '12px', sm: '14px' },
                          }}
                        >
                          Start Date
                        </Typography>
                        <Typography
                          sx={{
                            color: 'text.valueColor',
                            fontWeight: 500,
                            fontSize: { xs: '12px', sm: '14px', xl: '16px' },
                          }}
                        >
                          {formatDate(investmentDetails?.startDate, 'dd/MM/yyyy') || 'NA'}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      sm={4}
                      xs={6}
                    >
                      <Stack
                        rowGap={'10px'}
                        mb={'25px'}
                      >
                        <Typography
                          sx={{
                            color: 'text.labelColor',
                            fontSize: { xs: '12px', sm: '14px' },
                          }}
                        >
                          End Date
                        </Typography>
                        <Typography
                          sx={{
                            color: 'text.valueColor',
                            fontWeight: 500,
                            fontSize: { xs: '12px', sm: '14px', xl: '16px' },
                          }}
                        >
                          {formatDate(investmentDetails?.endDate, 'dd/MM/yyyy') || 'NA'}
                        </Typography>
                      </Stack>
                    </Grid>
                  </>
                )}
                {modeOfTransaction === 'Lumpsum + SIP' && (
                  <Grid
                    item
                    sm={4}
                    xs={6}
                  >
                    <Stack
                      rowGap={'10px'}
                      mb={'25px'}
                    >
                      <Typography
                        sx={{
                          color: 'text.labelColor',
                          fontSize: { xs: '12px', sm: '14px' },
                        }}
                      >
                        Lumpsum Amount
                      </Typography>
                      <Typography
                        sx={{
                          color: 'text.valueColor',
                          fontWeight: 500,
                          fontSize: { xs: '12px', sm: '14px', xl: '16px' },
                        }}
                      >
                        ₹{inrCurrencyFormatter(investmentDetails?.lumpsumAmount) || 'NA'}
                      </Typography>
                    </Stack>
                  </Grid>
                )}
              </Grid>
              <Formik
                initialValues={{
                  paymentMode: '',
                  modeOfRegistration: investmentDetails?.modeOfRegistration || '',
                  mandate: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue }) => {
                  return (
                    <Form>
                      {modeOfTransaction !== 'SIP' && (
                        <Box sx={{ mb: 3 }}>
                          <Typography
                            sx={{
                              color: 'text.valueColor',
                              mb: 2,
                              fontWeight: 500,
                              fontSize: { xs: '14px', sm: '16px' },
                            }}
                          >
                            {modeOfTransaction === 'Lumpsum'
                              ? 'Select Payment Mode *'
                              : 'Select Payment Mode (Lumpsum) *'}
                          </Typography>
                          {/* {for NewPurchase hide ISIP Payment mode} */}
                          <FormStyledRadioButton
                            options={getFilteredPaymentModeOptions(values)}
                            name='paymentMode'
                            handleChange={async e => {
                              if (
                                (modeOfTransaction === 'Lumpsum' &&
                                  e.target.value === 'KOTM') ||
                                e.target.value === 'AOTM'
                              ) {
                                setFieldValue('mandate', '');
                                getOtmMadatesLumpsum(e.target.value);
                              }
                            }}
                          />
                        </Box>
                      )}
                      <Grid
                        container
                        spacing={2}
                      >
                        {modeOfTransaction !== 'Lumpsum' && (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                          >
                            <FormSelectInput
                              name='modeOfRegistration'
                              label='Mode of Registration (SIP)'
                              required
                              options={getFilteredRegistrationModeOptions()}
                              onChange={async (e: any) => {
                                setFieldValue('mandate', '');
                                setFieldValue('paymentMode', '');
                                getMandateOptionsSip(e.target.value);
                              }}
                            />
                          </Grid>
                        )}
                        {(values.paymentMode === 'KOTM' ||
                          values.paymentMode === 'AOTM' ||
                          modeOfTransaction !== 'Lumpsum') &&
                          values?.modeOfRegistration !== 'ISIP' && (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                            >
                              <FormSelectInput
                                name='mandate'
                                label='Select Mandate'
                                required
                                options={umrnOptions}
                                onChange={e => handleMandateChange(e, values)}
                                onClick={() => {
                                  if (umrnOptions.length === 0) {
                                    showToast(
                                      'There are no valid mandates available, please select a different payment mode',
                                      'error',
                                    );
                                  }
                                }}
                              />
                            </Grid>
                          )}
                      </Grid>
                      <SubmitButton
                        sx={{
                          '&.MuiButtonBase-root': {
                            borderRadius: '5px',
                            minWidth: { xs: '100%', sm: '200px' },
                            mt: 5,
                          },
                        }}
                        label='Confirm'
                      />
                    </Form>
                  );
                }}
              </Formik>
            </Investorcard>
          </Grid>
        )}
        <Grid
          item
          md={4.5}
          xs={12}
          sx={{ order: { xs: 1, sm: 2 } }}
        >
          <ConfirmationProfile investorDetails={investorDetails} />
        </Grid>
      </Grid>
      <CustomDialog
        isVisible={openDialogBox}
        secondButtonHandler={() => {
          setOpenDialogBox(false);
          navigate('/transaction-dashboard');
        }}
        secondButtonTitle='Ok'
        firstButtonTitle=''
        showFirstButton={false}
        showSecondButton
        handleClose={() => {}}
        firstButtonHandler={() => {}}
      >
        <Box
          sx={{
            'textAlign': 'center',
            'pt': 2,
            '& .MuiTypography-root': {
              'fontSize': '16px',
              'color': '#293139',
              'fontWeight': '400',
              'opacity': '0.85',
              'my': 2,
              '& >*': {
                fontWeight: '600',
              },
            },
          }}
        >
          <img
            src={VerifiedIcon}
            alt='verified-icon'
          />

          <Typography variant='body1'>{`Transaction Submitted Successfully with batch number ${transactionNumber}`}</Typography>
        </Box>
      </CustomDialog>
    </Box>
  );
}
