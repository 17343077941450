import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { useDebounce } from '../../../hooks/useDebounce';
import { useRootContext } from '../../data/root.context';
import { useLazyAdminSearchEmployeesQuery } from '../slice';
import { EmployeeOrSubBroker, ManagerOption } from '../types';

interface EmployeeSearchAutoCompleteProps {
  textPlaceHolder: string;
  selectedOptionHandler: (selectedObj: EmployeeOrSubBroker | null) => void;
  disabled?: boolean;
  managerDefaultValue?: ManagerOption | null;
}

const EmployeeSearchAutoComplete: React.FC<EmployeeSearchAutoCompleteProps> = ({
  textPlaceHolder,
  selectedOptionHandler,
  disabled = false,
  managerDefaultValue = null,
}) => {
  const [options, setOptions] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState<string>(managerDefaultValue?.label || '');
  const [selectedValue, setSelectedValue] = useState<ManagerOption | null>(
    managerDefaultValue,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [adminSearchEmployees, adminSearchEmployeesValues] =
    useLazyAdminSearchEmployeesQuery();
  const { showToast } = useRootContext();

  const handleSearch = useDebounce(async (query: string) => {
    try {
      setLoading(true);
      const searchQuery = query.includes('-') ? query.split('-')[1].trim() : query.trim();
      const queryParams = { query: searchQuery };
      adminSearchEmployees(queryParams);
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    }
  }, 500);

  useEffect(() => {
    if (
      adminSearchEmployeesValues &&
      adminSearchEmployeesValues.isSuccess &&
      !adminSearchEmployeesValues.isFetching
    ) {
      const formattedData = adminSearchEmployeesValues?.data?.map(
        (item: { empId?: string; name: string; subbrokerCode?: string }) => {
          const id = item.empId || item.subbrokerCode;
          return {
            value: id,
            label: `${id} - ${item.name}`,
          };
        },
      );
      setOptions(formattedData);

      setLoading(false);

      // Clear inputValue if no matching options
      if (formattedData.length === 0) {
        setInputValue('');
      }
    }
    // on first managerDefaultValue
    if (
      managerDefaultValue?.label === inputValue &&
      adminSearchEmployeesValues &&
      adminSearchEmployeesValues.isSuccess &&
      !adminSearchEmployeesValues.isFetching
    ) {
      const selectedObject = adminSearchEmployeesValues?.data?.find(
        (item: { empId?: string; subbrokerCode?: string }) => {
          const id = item.empId || item.subbrokerCode;
          return id === selectedValue?.value;
        },
      );
      selectedOptionHandler(selectedObject);
    }
  }, [adminSearchEmployeesValues]);

  useEffect(() => {
    if (inputValue.length >= 3) {
      handleSearch(inputValue);
    } else {
      setOptions([]);
    }
  }, [inputValue]);

  const handleBlur = () => {
    const matchingOption = options.find(option => option.label === inputValue);
    if (!matchingOption) {
      selectedOptionHandler(null);
    }
  };

  return (
    <Autocomplete
      sx={{
        '& .MuiFormControl-root': {
          'my': '8px',
          '& .MuiInputBase-root ': {
            'padding': '6px 39px 6px 16px',
            'height': '49px',
            'fontSize': '13px',
            'fontWeight': 500,
            '& .MuiInputBase-input.MuiOutlinedInput-input': {
              'border': 'none',
              'padding': '0px',
              '&.Mui-disabled': {
                backgroundColor: 'text.lightGray',
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'text.borderColorLight',
              borderRadius: '8px',
            },
            '&.Mui-focused': {
              '& .MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px',
              },
            },
            '&.Mui-disabled': {
              backgroundColor: 'text.lightGray',
            },
          },
        },
      }}
      fullWidth
      forcePopupIcon={false}
      options={options}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={option => (typeof option === 'string' ? option : option.label)}
      value={selectedValue}
      onBlur={handleBlur}
      onChange={(_, newValue) => {
        if (newValue && typeof newValue !== 'string') {
          setSelectedValue(newValue);
          setInputValue(newValue.label);
          const selectedObject = adminSearchEmployeesValues?.data?.find(
            (item: { empId?: string; subbrokerCode?: string }) => {
              const id = item.empId || item.subbrokerCode;
              return id === newValue.value;
            },
          );
          selectedOptionHandler(selectedObject);
        } else {
          setSelectedValue(null);
          setInputValue('');
          selectedOptionHandler(null);
        }
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      loading={loading}
      disabled={disabled}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={textPlaceHolder}
          variant='outlined'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default EmployeeSearchAutoComplete;
