import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Collapse,
  Drawer,
  Grid,
  IconButton,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as KeyBoardRightArrowIcon } from '../../../assets/right-arrow-sm.svg';

const InvestorBox = styled(Box)(({ theme }) => ({
  borderRadius: '10px',
  border: `0.3px solid ${theme.palette.text.borderColorDark}`,
  backgroundColor: theme.palette.common.white,
  boxShadow: '0px 4px 25px 0px rgba(0, 0, 0, 0.05)',
}));
function CollapseData({ open }: { open: boolean }) {
  return (
    <Collapse
      in={open}
      timeout='auto'
      unmountOnExit
    >
      <Box
        sx={{
          padding: '10px 20px 10px 20px',
        }}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={8}
            sx={{ mb: 2 }}
          >
            <Typography
              sx={{ fontSize: '12px', fontWeight: 500, color: 'text.labelColor', mb: 1 }}
            >
              Scheme Name
            </Typography>
            <Typography sx={{ fontWeight: 500, color: 'text.valuelColor' }}>
              Axis Balanced Advantage Fund Regular Plan Growth
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ mb: 2 }}
          >
            <Typography
              sx={{ fontSize: '12px', fontWeight: 500, color: 'text.labelColor', mb: 1 }}
            >
              Folio Number
            </Typography>
            <Typography sx={{ fontWeight: 500, color: 'text.valuelColor' }}>
              788945632
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ mb: 2 }}
          >
            <Typography
              sx={{ fontSize: '12px', fontWeight: 500, color: 'text.labelColor', mb: 1 }}
            >
              Amount
            </Typography>
            <Typography sx={{ fontWeight: 500, color: 'text.valuelColor' }}>
              ₹ 5,500
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ mb: 2 }}
          >
            <Typography
              sx={{ fontSize: '12px', fontWeight: 500, color: 'text.labelColor', mb: 1 }}
            >
              No of Installments
            </Typography>
            <Typography sx={{ fontWeight: 500, color: 'text.valuelColor' }}>
              ₹ 7,500
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ mb: 2 }}
          >
            <Typography
              sx={{ fontSize: '12px', fontWeight: 500, color: 'text.labelColor', mb: 1 }}
            >
              Mode of Registration
            </Typography>
            <Typography sx={{ fontWeight: 500, color: 'text.valuelColor' }}>
              KOTM
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ mb: 2 }}
          >
            <Typography
              sx={{ fontSize: '12px', fontWeight: 500, color: 'text.labelColor', mb: 1 }}
            >
              Start Date
            </Typography>
            <Typography sx={{ fontWeight: 500, color: 'text.valuelColor' }}>
              02 Aug 2022
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ mb: 2 }}
          >
            <Typography
              sx={{ fontSize: '12px', fontWeight: 500, color: 'text.labelColor', mb: 1 }}
            >
              End Date
            </Typography>
            <Typography sx={{ fontWeight: 500, color: 'text.valuelColor' }}>
              02 Aug 2023
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ mb: 2 }}
          >
            <Typography
              sx={{ fontSize: '12px', fontWeight: 500, color: 'text.labelColor', mb: 1 }}
            >
              Biller ID
            </Typography>
            <Typography sx={{ fontWeight: 500, color: 'text.valuelColor' }}>
              0234/00123/05234
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Collapse>
  );
}
function ResponsiveRow() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setOpen(open);
    };

  return (
    <>
      <Box>
        <Drawer
          sx={{
            '& .MuiBackdrop-root': {
              top: '90px',
            },
            '& .MuiPaper-root': {
              top: '200px',
            },
          }}
          anchor='left'
          open={open}
          onClose={toggleDrawer(false)}
        >
          <Box
            role='presentation'
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '10px',
              }}
            >
              <Typography sx={{ fontSize: '16px', fontWeight: 600, ml: 1.2 }}>
                Raviteja Varma
              </Typography>
              <IconButton
                edge='end'
                color='inherit'
                onClick={toggleDrawer(false)}
                aria-label='close'
                sx={{ paddingRight: '20px' }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <CollapseData open={open} />
          </Box>
        </Drawer>
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 1.5,
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <IconButton sx={{ p: 0 }}>
              <KeyBoardRightArrowIcon onClick={toggleDrawer(!open)} />
            </IconButton>
            <Typography sx={{ fontWeight: 500, ml: 0.5, color: 'text.valueColor' }}>
              RaviTeja Varma
            </Typography>
          </Box>
        </Stack>
        <Grid
          container
          spacing={2}
          sx={{ pl: 2.5 }}
        >
          <Grid
            item
            xs={12}
          >
            <Typography sx={{ color: 'text.labelColor', fontWeight: 500 }}>
              KAJSP0921U
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Typography sx={{ color: 'text.labelColor', fontWeight: 500 }}>
              Kotak Infrastructure and Economic Reform Fund
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Typography sx={{ color: 'text.labelColor', fontWeight: 500 }}>
              6107363291
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Typography
              sx={{ color: 'text.labelColor', fontWeight: 500, textAlign: 'end' }}
            >
              ₹ 2,00,00,000
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Typography sx={{ color: 'text.labelColor', fontWeight: 500 }}>
              Biller ID:0234/00123/05234
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
export default function SIPMobile() {
  return (
    <InvestorBox
      sx={{
        'display': { md: 'none' },
        'my': '20px',
        '&>.MuiBox-root': {
          'padding': '15px 15px',
          '&:not(:last-child)': {
            borderBottom: '0.5px solid',
            borderColor: 'text.borderColorLight',
          },
        },
      }}
    >
      {' '}
      {Array.from({ length: 5 }, (_, i) => i + 1).map(index => (
        <ResponsiveRow key={index} />
      ))}
    </InvestorBox>
  );
}
