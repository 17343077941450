export const panRegExpression = /^([A-Z]{3}[HP])[A-Z]\d{4}[A-Z]$/;

export const shouldDisableTransactButton = (pan: string | undefined | null): boolean => {
  // If pan is null, undefined, or an empty string, the button should be disabled
  if (!pan) {
    return true;
  }

  // Test if the pan matches the regex pattern
  return !panRegExpression.test(pan);
};
