import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as ArrowDropDown } from '../../../../assets/select-dropdown-icon.svg';
import DataTable from '../../../common/DataTable';
import SearchFilterBox from '../../../common/SearchFilterBox';
import { TransactionResponse } from '../../../features/transactions/transactionSlice';
import StyledRadioButtonGroup from '../../../forms/FormStyledRadioButton';
import { DetailsFactory } from './detailsFactory';
import TableCollapsableFilter from './TableCollapsableFilter';
import TableFilter from './TableFilter';
import { DetailType, Filter } from './types';

type DetailsDataTableProps = {
  columns: string[];
  onSubmit: (data: any) => void;
  isFromEmailOrPhone?: string;
  showEmailPhoneCombination?: boolean;
  detailType: DetailType;
  initialFilter?: Filter;
  isRowDisabled?: (row: any, selected?: any) => boolean;
  disableSelectAll?: boolean;
  checkboxTooltip?: (
    isSelected: boolean,
    isDisabled: boolean,
  ) => React.ElementType | undefined;
  extraFilters?: () => JSX.Element;
  showNominees?: boolean;
  isNomineeForm?: boolean;
  hideHover?: boolean;
  paginationActive?: boolean;
  isIDCWform?: boolean;
};

const DetailsDataTable: React.FC<DetailsDataTableProps> = ({
  columns,
  onSubmit,
  isFromEmailOrPhone,
  showEmailPhoneCombination,
  detailType,
  initialFilter = {},
  isRowDisabled,
  disableSelectAll,
  checkboxTooltip,
  extraFilters: ExtraFilters,
  showNominees,
  isNomineeForm,
  hideHover,
  paginationActive,
  isIDCWform,
}) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [tableData, setTableData] = useState<any>([]);
  const dispatch = useDispatch();
  const [apiData, setApiData] = useState<any>();
  const [emailPhoneOptions, setEmailPhoneOptions] = useState<Filter[]>([]);
  const [selectedFilteredValue, setSelectedFilteredValue] = useState('');
  const [openCollapsableFilter, setOpenCollapsableFilter] = useState(false);
  const [selectedSchemeOption, setSelectedSchemeOption] = useState('R');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedEmailMobileCombination, setSelectedEmailMobileCombination] =
    useState<string>('');
  const [searchText, setSearchText] = useState('');
  const factory = new DetailsFactory().createDetail(detailType);
  const [api] = factory.getHook()();
  const { pan } = useSelector(
    (state: { transactions: TransactionResponse }) => state.transactions,
  );

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  useEffect(() => {
    let ignore = false;
    async function getDetails() {
      setLoading(true);
      const response: any = await factory.getResponse(api, { pan, fund: '' });
      const [tableData, emailPhoneCombination] = factory.getTableData(
        response?.data?.success,
        columns,
        {
          ...(isIDCWform && { schemeOption: 'R' }),
          ...initialFilter,
        },
      );
      if (!ignore) {
        //Removed repeated record from the table
        const uniqueData = Array.from(new Set(tableData.map(item => item.id))).map(id =>
          tableData.find(item => item.id === id),
        );
        setTableData(uniqueData);
        setEmailPhoneOptions(emailPhoneCombination);
        setSelectedEmailMobileCombination(JSON.stringify(emailPhoneCombination[0]));
        setApiData(response.data?.success);
      }
      setLoading(false);
    }
    getDetails();

    return () => {
      ignore = true;
    };
  }, []);

  function handleSubmit(ids: (string | number)[]) {
    const data = factory.createSubmissionData(apiData, ids);
    onSubmit(data);
  }

  const renderNominee = (item: any) => {
    const nominees = item.additionalData?.nominees || [];
    return (
      <>
        <Grid
          item
          xs={4}
        >
          <Stack
            direction='row'
            gap='10px'
          >
            <Typography variant='body1'>Nominee 1:</Typography>
            <Typography variant='body2'>{nominees[0]?.name || 'N/A'}</Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={4}
        >
          <Stack
            direction='row'
            gap='10px'
          >
            <Typography variant='body1'>Nominee 2:</Typography>
            <Typography variant='body2'>{nominees[1]?.name || 'N/A'}</Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={4}
        >
          <Stack
            direction='row'
            gap='10px'
          >
            <Typography variant='body1'>Nominee 3:</Typography>
            <Typography variant='body2'>{nominees[2]?.name || 'N/A'}</Typography>
          </Stack>
        </Grid>
      </>
    );
  };

  const filteredOptionHandler: (event: SelectChangeEvent<string>) => void = event => {
    const selectedValue: unknown = event.target.value;
    const [tableData, _] = factory.getTableData(apiData, columns, {
      [(isFromEmailOrPhone === 'mobile' ? 'email' : 'mobile')!]: selectedValue,
      ...initialFilter,
    });
    setTableData(tableData);
    setSelectedFilteredValue(String(selectedValue)); // Update selectedFilteredValue state with the selected value
    setPage(0);
  };

  const handleEmailPhoneOptionChanged = (filter: string) => {
    setSelectedEmailMobileCombination(filter);
    const [tableData, _] = factory.getTableData(apiData, columns, {
      ...(detailType === DetailType.Scheme ? { schemeOption: selectedSchemeOption } : {}),
      ...(filter ? JSON.parse(filter) : {}),
      ...initialFilter,
    });
    setSearchText('');
    setTableData(tableData);
    setPage(0);
  };

  const handleSearchTextChanged = (text: string) => {
    setSearchText(text);
    const [tableData, _] = factory.getTableData(apiData, columns, {
      ...(selectedEmailMobileCombination
        ? JSON.parse(selectedEmailMobileCombination)
        : {}),
      amc: text,
      ...(detailType === DetailType.Scheme ? { schemeOption: selectedSchemeOption } : {}),
      ...initialFilter,
    });
    setTableData(tableData);
    setPage(0);
  };

  const handleSchemeOptionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSchemeOption((event.target as HTMLInputElement).value);
    const [tableData, emailMobileCombinations] = factory.getTableData(apiData, columns, {
      schemeOption: event.target.value as 'R' | 'D',
      ...initialFilter,
    });
    setTableData(tableData);
    setEmailPhoneOptions(emailMobileCombinations);
    setSelectedEmailMobileCombination('');
    setSearchText('');
    setPage(0);
  };

  const handleIsRowDisabled = (row: any, selected?: (string | number)[]) => {
    const rowData = factory.createSubmissionData(apiData, [row.id])[0];
    const selectedData = factory.createSubmissionData(apiData, selected || []);
    return isRowDisabled?.(rowData, selectedData) || false;
  };

  return (
    <>
      {Boolean(showEmailPhoneCombination) && (
        <>
          <Typography
            sx={{
              mb: 2,
              color: 'primary.main',
              fontSize: { xs: '14px', sm: '16px' },
              fontWeight: 600,
            }}
          >
            Please select Email-Mobile number combination.
          </Typography>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={5}
            >
              <FormControl
                sx={{ mb: { xs: 2, sm: 4 } }}
                fullWidth
              >
                <Select
                  sx={{
                    '& .MuiSelect-select': {
                      borderRadius: '7px',
                      border: '1.5px solid',
                      borderColor: 'text.borderColorLight',
                      fontSize: '14px',
                      fontWeight: 500,
                      color: 'text.primary',
                      padding: '12px 14px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                  }}
                  fullWidth
                  IconComponent={ArrowDropDown}
                  displayEmpty
                  value={selectedEmailMobileCombination}
                  onChange={e => handleEmailPhoneOptionChanged(e.target.value)}
                >
                  <MenuItem value={''}>All</MenuItem>
                  {emailPhoneOptions.map((option, index) => (
                    <MenuItem
                      value={JSON.stringify(option)}
                      key={index}
                    >
                      {`${option.email}-${option.mobile}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </>
      )}
      {detailType === DetailType.Scheme && (
        <>
          <Typography sx={{ mb: 1, color: 'primary.main', fontWeight: 500 }}>
            Select IDCW Option:
          </Typography>
          <Box mb={4}>
            <StyledRadioButtonGroup
              options={[
                { label: 'Payout to Reinvest', value: 'R' },
                { label: 'Reinvest to Payout', value: 'D' },
              ]}
              value={selectedSchemeOption}
              handleChange={handleSchemeOptionChanged}
              errorText={selectedSchemeOption === '' ? 'Plese select option' : ''}
            />
          </Box>
        </>
      )}
      {ExtraFilters && <ExtraFilters />}
      <DataTable
        loading={loading}
        setLoading={setLoading}
        columns={columns}
        rows={tableData}
        isNomineeForm={isNomineeForm}
        renderDropdown={showNominees ? renderNominee : undefined}
        onSubmit={handleSubmit}
        filter={
          <TableFilter
            open={openCollapsableFilter}
            setOpen={setOpenCollapsableFilter}
            isFromEmailOrPhone={isFromEmailOrPhone}
            selectedFilteredValue={selectedFilteredValue}
            filterOptionChangeHandler={filteredOptionHandler}
            filteredOptions={emailPhoneOptions.map<string>(
              option => (isFromEmailOrPhone === 'mobile' ? option.email : option.mobile)!,
            )}
            searchText={searchText}
            onSearchTextChange={handleSearchTextChanged}
          />
        }
        collapsableFilter={<TableCollapsableFilter open={openCollapsableFilter} />}
        isRowDisabled={handleIsRowDisabled}
        disableSelectAll={disableSelectAll}
        checkboxTooltip={checkboxTooltip}
        hideNoData={tableData.length === 0}
        paginationActive={paginationActive}
        rowsPerPageOptions={[5, 10, 15]}
        count={tableData?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/* Mobile UI*/}
      <SearchFilterBox />
      <FormControlLabel
        sx={{
          'my': 2,
          'display': { md: 'none' },
          '& svg': {
            width: '14px',
            height: '14px',
          },
          '& .MuiTypography-root': { fontWeight: 500 },
        }}
        label='Select All'
        control={<Checkbox />}
      />
      {/* Mobile UI*/}
    </>
  );
};

export default DetailsDataTable;
