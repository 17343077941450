import { Box, Grid, Stack } from '@mui/material';

import { getDayFromMMDDYYYY } from '../../../utils';
import { CartLabel, CartValue } from '../../auth/login/styles/styledComponents';
import { MMddToDDMMYYYY } from '../reports/utils';
import { inrCurrencyFormatter2 } from '../utils';

const Cart = ({
  data,
  children,
  cartItemslength,
}: {
  data?: any;
  children?: React.ReactNode;
  cartItemslength?: boolean;
}) => {
  return (
    <Box
      sx={{
        'pb': 2,
        'mb': 3,
        '&:nth-of-type(n+2)': {
          borderTop: cartItemslength ? '1px solid ' : 'unset',
          borderColor: cartItemslength ? 'text.borderColorLight' : 'unset',
          pt: cartItemslength ? 3 : 'unset',
        },
      }}
    >
      {children}

      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={8}
        >
          <Stack gap='10px'>
            <CartLabel>SchemeName</CartLabel>
            <CartValue>{data?.schemeName || ''}</CartValue>
          </Stack>
        </Grid>
        {data?.amount && (
          <Grid
            item
            xs={2.5}
          >
            <Stack gap='10px'>
              <CartLabel>Amount :</CartLabel>
              <CartValue>{inrCurrencyFormatter2(data?.amount)}</CartValue>
            </Stack>
          </Grid>
        )}
        {data?.cartUnits && (
          <Grid
            item
            xs={1.5}
          >
            <Stack gap='10px'>
              <CartLabel>Units</CartLabel>
              <CartValue>{data?.cartUnits}</CartValue>
            </Stack>
          </Grid>
        )}
        <Grid
          item
          xs={8}
        >
          <Stack gap='10px'>
            <CartLabel>SWP Day</CartLabel>
            <CartValue>{getDayFromMMDDYYYY(data?.swpStartDate)}</CartValue>
          </Stack>
        </Grid>
        <Grid
          item
          xs={2.5}
        >
          <Stack gap='10px'>
            <CartLabel>Start date</CartLabel>
            <CartValue>{MMddToDDMMYYYY(data?.swpStartDate)}</CartValue>
          </Stack>
        </Grid>
        <Grid
          item
          xs={1.5}
        >
          <Stack gap='10px'>
            <CartLabel>End date</CartLabel>
            <CartValue>{MMddToDDMMYYYY(data?.swpEndDate)}</CartValue>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Cart;
