import { Theme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { generateAgentReferenceNo } from '../../../utils';
import { PageNavigator } from '../../common/BackToHomePage';
import DataTable from '../../common/DataTable';
import { useRootContext } from '../../data/root.context';
import { LoginResponse } from '../../features/login/loginSlice';
import { formatDate } from '../newPurchase/NewInvestmentDetails';
import { useGetAgentDetailsMutation } from '../slice';
import { AgentDetails } from '../types';
import { inrCurrencyFormatter2 } from '../utils';
import ReportsFilter from './ReportsFilter';
import {
  useNpsCompletedSubscribersMutation,
  useNpsContributedSubscribersMutation,
  useNpsPendingSubscribersMutation,
} from './slice';
const REPORTS_ENDPOINTS = {
  PENDING: '/NPSMFDAgentPendingSubscribers',
  COMPLETED: '/NPSMFDAgentCompletedSubscribers',
  CONTRIBUTIONS: '/NPSMFDAgentContributedSubscribers',
};
type RowType = {
  'Subscriber Name': string;
  'Subscriber PAN': string;
  'Mobile Number': string;
  'EMail ID': string;
  'POP Name': string;
  'Workflownumber': string;
  'Agent ReferenceNumber': string;
  'Pending At': string;
};
interface TableDataState {
  rows: RowType[];
  cols: string[];
}

interface ContributionRow {
  'Name': string;
  'PRAN': string;
  'PRAN Generation Date': string;
  'Contribution Type': string;
  'Contribution Amount': string;
  'POP Charges': string;
  'Tier Type': string;
  'Source': string;
}

interface DefaultRow {
  'Name': string;
  'PAN': string;
  'Mobile Number': string;
  'Email': string;
  'AckNumber': string;
}

type RowsType = ContributionRow | DefaultRow;

const MisReports = () => {
  const navigate = useNavigate();

  const {
    state: { type, heading },
  } = useLocation();
  const [startDate, setStartDate] = useState<Date | null>(
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
  );
  // const [getAllReportsData] = useGetAllReportsMutation();
  const [getOnlyPendingSubscribers] = useNpsPendingSubscribersMutation();
  const [getOnlyCompleteSubscribers] = useNpsCompletedSubscribersMutation();
  const [getOnlyContributedSubscribers] = useNpsContributedSubscribersMutation();
  const [getAgentDetails] = useGetAgentDetailsMutation();
  const [loading, setLoading] = React.useState<boolean>(true);

  const [agentDetails, setAgentDetails] = useState<AgentDetails | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [tableData, setTableData] = useState<TableDataState>({ rows: [], cols: [] });
  const initialTableData = useRef([]);
  const { showToast } = useRootContext();
  const [searchText, setSearchText] = useState('');
  const [allPendingAndCompletedData, setAllPendingAndCompletedData] = useState({
    pendingSubscribersData: [],
    completedSubscribersData: [],
    contributedSubscribersData: [],
    filteredPendingSubscribers: [],
    filteredCompletedSubscribers: [],
    filteredContributedSubscribers: [],
  });

  const { arn, distributorPan } = useSelector(
    (state: { login: LoginResponse }) => state.login,
  );
  const dispatch = useDispatch();
  const [currentLink, setCurrentLink] = useState('');
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const getRows = (): RowsType[] => {
    const formatAmount = (amount: number) => (amount ? `₹${amount.toFixed(2)}` : '-');
    const dataSource =
      type === 'PENDING'
        ? allPendingAndCompletedData?.filteredPendingSubscribers
        : type === 'COMPLETED'
        ? allPendingAndCompletedData?.filteredCompletedSubscribers
        : allPendingAndCompletedData?.filteredContributedSubscribers;

    if (type === 'CONTRIBUTIONS') {
      return (
        dataSource?.map(
          (row: any): ContributionRow => ({
            'Name': row['Subscriber Name'],
            'PRAN': row['PRAN'],
            'PRAN Generation Date': row['PRAN generation Date'],
            'Contribution Type': row['Contribution type'],
            'Contribution Amount': inrCurrencyFormatter2(row['Cont AMount']),
            'POP Charges': formatAmount(row['POP Charges']),
            'Tier Type': row['Tier Type'],
            'Source': row['Source'],
          }),
        ) || []
      );
    } else {
      return (
        dataSource?.map(
          (row: any): DefaultRow => ({
            'Name': row['SubscriberName']?.trim(),
            'PAN': row['PAN'] || 'NA',
            'Mobile Number': row['PhoneNumber'],
            'Email': row['EmailID'],
            'AckNumber': row['AckNumber'],
          }),
        ) || []
      );
    }
  };

  const fetchAgentDetails = async (agentRefNumber: string) => {
    const response: any = await getAgentDetails({
      agentRefNumber,
    }).unwrap();
    setAgentDetails(response.data);
  };
  const fetchPendingSubscriber = async (agentRefNumber: string) => {
    const payload = {
      AgentReferenceNumber: agentRefNumber,
      POP_Code: agentDetails?.POPCode,
      POP_SP_Code: agentDetails?.POP_SPCode,
      FromDate: formatDate(startDate, 'dd/MM/yyyy'),
      ToDate: formatDate(endDate, 'dd/MM/yyyy'),
    };
    const response = await getOnlyPendingSubscribers(payload).unwrap();
    return response?.data;
  };

  const fetchCompletedSubscriber = async (agentRefNumber: string) => {
    const payload = {
      AgentReferenceNumber: agentRefNumber,
      POP_Code: agentDetails?.POPCode,
      POP_SP_Code: agentDetails?.POP_SPCode,
      FromDate: formatDate(startDate, 'dd/MM/yyyy'),
      ToDate: formatDate(endDate, 'dd/MM/yyyy'),
    };
    const response = await getOnlyCompleteSubscribers(payload).unwrap();
    return response?.data;
  };

  const fetchContributedSubscriber = async (agentRefNumber: string) => {
    const payload = {
      AgentReferenceNumber: agentRefNumber,
      POP_Code: agentDetails?.POPCode,
      POP_SP_Code: agentDetails?.POP_SPCode,
      FromDate: formatDate(startDate, 'dd/MM/yyyy'),
      ToDate: formatDate(endDate, 'dd/MM/yyyy'),
    };
    const response = await getOnlyContributedSubscribers(payload).unwrap();
    return response?.data?.response?.ContributedSubscribers;
  };

  const createCsvLink = (data: any[]) => {
    if (!data || data.length === 0) return;
    const csv = [
      Object.keys(data[0]).join(','), // Header row
      ...data.map((row: RowType) =>
        Object.values(row)
          .map(value => JSON.stringify(value))
          .join(','),
      ),
    ].join('\r\n');

    const blob = new Blob(['\uFEFF' + csv], { type: 'text/csv;charset=utf-8' });
    return window.URL.createObjectURL(blob); // Return the URL for the CSV
  };
  const fetchSubscriberData = async (agentRefNumber: string) => {
    const fetchSubscribersList =
      type === 'PENDING'
        ? fetchPendingSubscriber
        : type === 'COMPLETED'
        ? fetchCompletedSubscriber
        : fetchContributedSubscriber;

    return await fetchSubscribersList(agentRefNumber);
  };
  const fetchData = async () => {
    const agentRefNumber = generateAgentReferenceNo(distributorPan, arn);
    try {
      setLoading(true);

      // Fetch agent details and subscribers data concurrently
      const [agentDetailsResponse, subscriberData] = await Promise.all([
        fetchAgentDetails(agentRefNumber as string),
        fetchSubscriberData(agentRefNumber as string),
      ]);
      let refactoredData;
      if (type !== 'CONTRIBUTIONS') {
        refactoredData = subscriberData?.map((item: any) => {
          const { PRAN, AccountType, UniqueAgentIdentifier, ...rest } = item;
          return rest;
        });
      } else {
        refactoredData = subscriberData?.map((item: any) => {
          const itemKeys = Object.keys(item);
          const keysToRemove = [
            'POP Name',
            'Agent Ref number',
            'Agent Name',
            'Contribution ID',
            'SCF Status',
            'SCF Upload date',
            'SCF Transaction ID',
            'Unitisation status',
            'Unitisation Date',
          ];
          const restKeys = itemKeys.filter(key => !keysToRemove.includes(key));
          const restItem: any = {};
          for (const key of restKeys) {
            restItem[key] = item[key];
          }
          return restItem;
        });
      }
      const typeKey =
        type === 'PENDING'
          ? 'pendingSubscribersData'
          : type === 'COMPLETED'
          ? 'completedSubscribersData'
          : 'contributedSubscribersData';
      const filterKey =
        type === 'PENDING'
          ? 'filteredPendingSubscribers'
          : type === 'COMPLETED'
          ? 'filteredCompletedSubscribers'
          : 'filteredContributedSubscribers';
      setAllPendingAndCompletedData({
        ...allPendingAndCompletedData,
        [typeKey]: subscriberData,
        [filterKey]: subscriberData,
      });
      // If there's valid data, create the CSV link
      const csvLink = createCsvLink(refactoredData);
      if (csvLink) {
        setCurrentLink(csvLink); // Set the link once it's created
      }
    } catch (error: any) {
      const message = error?.data?.message || error?.message || 'Unknown error';
      showToast(message, 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const dateChangeHandler = (newDate: Date, type: string) => {
    if (type === 'startDate') {
      setStartDate(newDate);
      setEndDate(null);
    } else {
      fetchData();
      setEndDate(newDate);
    }
  };
  const handleSearchTextChanged = (text: string) => {
    const lowerCaseText = text.toLowerCase();
    setSearchText(text);
    setPage(0);
    // Update filtered data based on type
    if (type === 'PENDING') {
      const filteredPending = allPendingAndCompletedData.pendingSubscribersData.filter(
        (rowData: any) =>
          rowData.SubscriberName.toLowerCase().includes(lowerCaseText) ||
          rowData.PAN.toLowerCase().includes(lowerCaseText) ||
          rowData.PhoneNumber.includes(lowerCaseText),
      );
      setAllPendingAndCompletedData(prev => ({
        ...prev,
        filteredPendingSubscribers: filteredPending,
      }));
    } else if (type === 'COMPLETED') {
      const filteredCompleted =
        allPendingAndCompletedData.completedSubscribersData.filter(
          (rowData: any) =>
            rowData.SubscriberName.toLowerCase().includes(lowerCaseText) ||
            rowData.PAN.toLowerCase().includes(lowerCaseText) ||
            rowData.PhoneNumber.includes(lowerCaseText),
        );
      setAllPendingAndCompletedData(prev => ({
        ...prev,
        filteredCompletedSubscribers: filteredCompleted,
      }));
    } else {
      const filteredContributed =
        allPendingAndCompletedData.contributedSubscribersData?.filter(
          (rowData: any) =>
            rowData['Subscriber Name']?.toLowerCase()?.includes(lowerCaseText) ||
            rowData['PRAN']?.toString()?.includes(lowerCaseText),
        );
      setAllPendingAndCompletedData(prev => ({
        ...prev,
        filteredContributedSubscribers: filteredContributed,
      }));
    }
  };

  const handleDownloadCSV = () => {
    if (downloadLinkRef.current) {
      downloadLinkRef.current.click();
    }
  };
  return (
    <React.Fragment>
      <PageNavigator
        sx={(theme: Theme) => ({
          [theme.breakpoints.down('md')]: {
            mt: 0,
          },
        })}
        title={`${heading} Reports`}
      />
      <DataTable
        loading={loading}
        setLoading={setLoading}
        columns={
          type !== 'CONTRIBUTIONS'
            ? ['Name', 'PAN', 'Mobile Number', 'Email', 'AckNumber']
            : [
                'Name',
                'PRAN',
                'PRAN Generation Date',
                'Contribution Type',
                'Contribution Amount',
                'POP Charges',
                'Tier Type',
                'Source',
              ]
        }
        rows={getRows() ?? []}
        // isSlicingNeeded={false}
        onSubmit={() => {}}
        disableSelectAll={true}
        hideUpdateButton={true}
        hidecheckbox={true}
        noDataText='No Data Found'
        hideNoData
        paginationActive={true}
        rowsPerPageOptions={[10, 15, 20]}
        count={getRows().length || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        filter={
          <ReportsFilter
            startDate={startDate}
            endDate={endDate}
            searchText={searchText}
            onSearchTextChange={handleSearchTextChanged}
            handleDateChange={dateChangeHandler}
            currentLink={currentLink}
            downloadLinkRef={downloadLinkRef}
            handleDownloadCSV={handleDownloadCSV}
            fileName={`${type}_SUBSCRIBERS`}
            isButtonDisabled={
              !(type === 'PENDING'
                ? allPendingAndCompletedData?.pendingSubscribersData.length > 0
                : type === 'COMPLETED'
                ? allPendingAndCompletedData?.completedSubscribersData.length > 0
                : allPendingAndCompletedData?.contributedSubscribersData.length > 0)
            }
          />
        }
      />
    </React.Fragment>
  );
};
export default MisReports;
