import { Box, Divider, Grid, Stack, Theme, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import VerifiedIcon from '../../../../assets/verified-cart-icon.png';
import { Investorcard } from '../../../auth/login/styles/styledComponents';
import CustomDialog from '../../../common/AllDialogs/CustomDialog';
import { useRootContext } from '../../../data/root.context';
import { LoginResponse } from '../../../features/login/loginSlice';
import { FormSelectInput } from '../../../forms/FormSelect';
import { FormStyledRadioButton } from '../../../forms/FormStyledRadioButton';
import SubmitButton from '../../../forms/FormSubmitButton';
import { StepperNextButton } from '../../../onboarding/styles/styledComponents';
import { ConfirmationProfile } from '../../mfs/newInvestorOnboarding/newMfsInvestorSteps/ConfirmationProfile';
import {
  useAllMandatesMutation,
  useAllPaymentModesMutation,
  useCheckoutCartMutation,
  useNewPurchaseDirectMutation,
  useNewPurchaseDirectSipMutation,
  useNewPurchaseSipMutation,
} from '../../mfs/newInvestorOnboarding/slice';
import {
  dateToMMDDYYYY,
  getFirstLetter,
  mapFrequencyToLetter,
} from '../../mfs/newInvestorOnboarding/utils';
import { useGetEnachAotmDetailsMutation } from '../../slice';
import {
  findLogo,
  getEarliestAndLatestSipDates,
  inrCurrencyFormatter,
} from '../../utils';
import { setPaymentConfirmation } from '../context/MfsNewPurchaseAction';
import { useMfsNewPurchaseContext } from '../context/MfsNewPurchaseContext';
import { formatDate } from '../NewInvestmentDetails';
import { Nominee } from './NomineeDetails';

interface HolderDetails {
  pan: string;
  name: string;
  email: string;
  mobile: string;
  dob: string;
  gender: string;
  emailFamilyRelation: string;
  mobileFamilyRelation: string;
  category: string;
  nriAddress1?: string;
  nriAddress2?: string;
  nriCity?: string;
  nriState?: string;
  taxResidentOfOtherCountry: string;
  countryOfCitizenship: string;
  countryOfBirth: string;
  grossAnnualIncome: string;
  sourceOfWealth: string;
  occupation: string;
  countryOfTaxResident?: string;
  taxPayerIdentificationNumber?: string;
  politicallyExposedPerson: string;
  politicallyExposedRelative: string;
  jointHolderType: string;
  status: string;
}

export interface AotmData {
  email: string;
  name: string;
  folio: number | null;
  entryDate: string | null;
  bnkacno: string;
  bank: string;
  amount: number;
  mobile: number | null;
  transactionDate: string;
  fromDate: string;
  toDate: string;
  fund: string;
  mode: string | null;
  umnr: string;
  ifsc: string;
}

export interface KotmData {
  email: string | null;
  name: string;
  folio: number | null;
  entryDate: string | null;
  bnkacno: string;
  bank: string;
  amount: number;
  mobile: number | null;
  transactionDate: string;
  fromDate: string;
  toDate: string;
  fund: string;
  mode: string | null;
  umrn: string;
  ifsc: string;
}

export interface MandateData {
  kotmData: KotmData[];
  aotmData: AotmData[];
}

export function Payment() {
  const [loading, setLoading] = React.useState<boolean>(true);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { showToast } = useRootContext();
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [transactionNumber, setTransactionNumber] = useState('');
  const [paymentModeOptions, setPaymnetModeOptions] = useState<any[]>([]);
  const [isPanBasedAotm, setIsPanBasedAotm] = useState(false);
  const [mandateData, setMandateData] = useState<MandateData>({
    kotmData: [],
    aotmData: [],
  });
  const [umrnOptions, setUmrnOptions] = useState<any[]>([]);
  const [selectedMandate, setSelectedMandate] = useState({
    label: '',
    value: '',
    mandateLimit: 0,
    startDate: '',
    endDate: '',
    bankAccountNumber: '',
    bankName: '',
  } as any);

  const {
    euin,
    arn,
    name: Dname,
    subArn,
  } = useSelector((state: { login: LoginResponse }) => state.login);

  const {
    dispatch: contextDispatch,
    state: {
      isNomination,
      investmentDetails,
      investorBankDetails,
      investorDetails,
      fundName,
      schemeDetails,
      fatcaDetails,
      holderDetails,
      nomineeDetails,
      investorDetailsFormResponse,
      cartSate,
      paymentModeConfirmation,
    },
  } = useMfsNewPurchaseContext();

  const { personalDetails } = investorDetailsFormResponse || {};

  const { subBrokerCode } = investmentDetails || {};
  const modeOfTransaction = state?.reinitiate
    ? state?.response?.transactionType === 'NPL'
      ? 'Lumpsum'
      : state?.response?.transactionType === 'SINI' && state?.response?.amount === 0
      ? 'SIP'
      : 'Lumpsum + SIP'
    : cartSate?.previousUrl === 'cart'
    ? cartSate?.cartItems[0]?.transactionType === 'NPL'
      ? 'Lumpsum'
      : cartSate?.cartItems[0]?.transactionType === 'SINI' &&
        cartSate?.cartItems[0]?.investmentAmount === 0
      ? 'SIP'
      : 'Lumpsum + SIP'
    : investmentDetails?.modeOfTransaction;

  const [newPurchaseDirect] = useNewPurchaseDirectMutation();
  const [newPurchaseSip] = useNewPurchaseSipMutation();
  const [newPurchaseDirectSip] = useNewPurchaseDirectSipMutation();
  const [getAllPaymentModes] = useAllPaymentModesMutation();
  const [checkoutCart] = useCheckoutCartMutation();
  const [getAllMandates] = useAllMandatesMutation();
  const [getAotmDetails] = useGetEnachAotmDetailsMutation();

  const payloadOb =
    !state?.reinitiate &&
    cartSate?.cartItems[0]?.payload &&
    JSON?.parse(cartSate?.cartItems[0]?.payload);

  const refactoredHolderDetails = holderDetails?.map((holder: any) => {
    const cleanedHolder: Partial<HolderDetails> = {};
    for (const key in holder) {
      if (holder[key as keyof HolderDetails] !== '') {
        cleanedHolder[key as keyof HolderDetails] = holder[key as keyof HolderDetails];
      }
    }
    cleanedHolder.category = 'I';
    return cleanedHolder;
  });

  const nominees = nomineeDetails?.nominees?.map((nominee: Nominee) => {
    const {
      isMinor,
      isSameAsApplicant,
      guardianName,
      guardianPan,
      guardianRelation,
      guardianGender,
      dob,
      sharePercentage,
      ...rest
    } = nominee;

    const formattedDob = dob ? formatDate(dob as any, 'MM/dd/yyyy') : '';
    const sharePercentageToString = sharePercentage ? sharePercentage.toString() : '';
    const isNomineeMinor = isMinor === 'No' ? false : true;

    if (isMinor === 'No') {
      return {
        ...rest,
        dob: formattedDob,
        sharePercentage: sharePercentageToString,
        isMinor: isNomineeMinor,
      };
    } else {
      return {
        ...rest,
        guardianName,
        guardianPan,
        guardianRelation,
        guardianGender,
        dob: formattedDob,
        sharePercentage: sharePercentageToString,
        isMinor: isNomineeMinor,
      };
    }
  });

  const sipValidationSchama = {
    modeOfRegistration: Yup.string().required('Mode of Registration is required'),
    mandate: Yup.string().when('modeOfRegistration', {
      is: (modeOfRegistration: string) =>
        modeOfRegistration === 'KOTM' || modeOfRegistration === 'AOTM',
      then: Yup.string().required('Mandate is required'),
    }),
  };
  const lumpsumValidationSchama = {
    paymentMode: Yup.string().required('Payment mode is required'),
  };

  const validationSchema = Yup.object().shape({
    mandate: Yup.string().when('paymentMode', {
      is: (paymentMode: string) => paymentMode === 'KOTM' || paymentMode === 'AOTM',
      then: Yup.string().required('Mandate is required'),
    }),
    ...(modeOfTransaction !== 'Lumpsum' ? sipValidationSchama : {}),
    ...(modeOfTransaction !== 'SIP' ? lumpsumValidationSchama : {}),
  });

  const handleMandateChange = (e: any, values: any) => {
    const value = e.target.value;

    const selectedValue =
      values.paymentMode === 'KOTM' || values.modeOfRegistration === 'KOTM'
        ? mandateData?.kotmData?.find((mandate: any) => mandate.umrn === value)
        : mandateData?.aotmData?.find((mandate: any) => mandate.umnr === value);

    if (value !== 'createMandate') {
      setSelectedMandate({
        label: value,
        value: value,
        mandateLimit: selectedValue?.amount,
        startDate: selectedValue?.fromDate,
        endDate: selectedValue?.toDate,
        bankAccountNumber: selectedValue?.bnkacno,
        bankName: selectedValue?.bank,
      });
    } else {
      setSelectedMandate({
        label: '+ Create New Mandate',
        value: 'createMandate',
        startDate: '',
        endDate: '',
        mandateLimit: 0,
        bankAccountNumber: '',
        bankName: '',
      });
    }
  };

  const handleSubmit = async (values: any) => {
    const bankAddress = investorBankDetails?.branchAddress?.split(',');
    try {
      setLoading(true);
      const cartPayload = {
        cartId: cartSate?.cartItems?.[0]?.cartId,
        pan: investorDetails?.pan,
        selectedCartItems: cartSate?.cartItemIds,
        personalDetails: {
          modeOfHolding:
            investorDetails?.invType === 'M'
              ? '1'
              : investorDetails?.modeOfHolding === 'single'
              ? '1'
              : '4',
          name: investorDetails?.name,
          dob: investorDetails?.dob,
          pan: investorDetails?.pan,
          email: investorDetails?.email,
          mobile: investorDetails?.mobile,
          ...(fatcaDetails?.taxResidentOtherThanIndia === 'Yes' && {
            taxPayerIdentificationNumber:
              fatcaDetails?.taxResidentOtherThanIndia === 'Yes'
                ? fatcaDetails?.foreignIdNo
                : investorDetails?.pan,
            countryOfTaxResident:
              fatcaDetails?.taxResidentOtherThanIndia === 'Yes'
                ? fatcaDetails?.taxResidentCountry
                : null,
          }),
          investorType: investorDetails?.invType,
          familyMobileFlag: investorDetails?.mobileFamilyDescription,
          familyEmailFlag: investorDetails?.emailFamilyDescription,
          category: investorDetails?.category,
          gender: getFirstLetter(investorDetails?.gender),
          status: personalDetails?.investorStatus,
          // seafarerFlag: investorDetails?.seaFarerFlag === 'Yes' ? true : false,
          ...(investorDetails.category === '20' && {
            nriAddress1: investorDetails?.address1,
            nriAddress2: investorDetails?.address2,
            nriCity: investorDetails?.city,
            nriState: investorDetails?.state,
            nriCountry: investorDetails?.country,
            nriZipCode: investorDetails?.zipCode,
          }),
          ...(investorDetails?.invType === 'M' && {
            guardianName: investorDetails?.guardianName,
            guardianPan: investorDetails?.guardianPan,
            guardianDob: investorDetails?.guardianDob,
            guardianRelation: investorDetails?.guardianRelationWithMinor,
            guardianEmailDescription: investorDetails?.guardianEmailFamilyDescription,
            guardianMobileDescription: investorDetails?.guardianMobileFamilyDescription,
            guardianEmail: investorDetails?.guardianEmail,
            guardianMobile: investorDetails?.guardianMonNo,
          }),
          countryOfBirth: fatcaDetails?.country,
          countryOfCitizenship: fatcaDetails?.birthPlace,
          countryOfCitizenShip: investorDetails.category === '20' ? 'yes' : 'no',
          grossAnnualIncome: fatcaDetails?.annualIncome,
          taxResidentOfOtherCountry: fatcaDetails?.taxResidentOtherThanIndia,
          occupation: fatcaDetails?.occupation,
          sourceOfWealth: fatcaDetails?.sourceOfWealth,
          politicallyExposedPerson: fatcaDetails?.politicallyExposedPerson,
          politicallyExposedRelative: fatcaDetails?.politicallyExposedRelative,
        },

        jointHolders:
          investorDetails?.invType === 'M' ||
          holderDetails === null ||
          holderDetails === undefined
            ? []
            : refactoredHolderDetails,
        nominees:
          investorDetails?.invType === 'M' || isNomination || !nomineeDetails?.nominees
            ? []
            : nominees || [],
        noNomineeDeclaration:
          investorDetails?.invType === 'M' || isNomination || !nomineeDetails?.nominees
            ? true
            : false,
        bankDetails: {
          ifsc: investorBankDetails?.ifscCode,
          bank: investorBankDetails?.bankName,
          accType: investorBankDetails?.accountType,
          existing: '',
          acceptTerms: 'Y',
          accNo: investorBankDetails?.accountNumber,
          address1: bankAddress[0]?.trim(),
          address2: bankAddress[1]?.trim(),
          address3: bankAddress[2]?.trim(),
          city: investorBankDetails?.bankBranch,
          pinCode: investorBankDetails?.branchPincode,
        },
        ...(modeOfTransaction !== 'Lumpsum' && {
          modeOfRegistration: values.modeOfRegistration,
        }),
        ...(modeOfTransaction !== 'SIP' && {
          paymentDetails: {
            paymentMode: values.paymentMode?.toUpperCase(),
          },
          paymentMode: values.paymentMode?.toUpperCase(),
        }),
        createMandate: selectedMandate?.value === 'createMandate',
        umrn: selectedMandate?.value === 'createMandate' ? '' : selectedMandate.value,
        selectedMandates:
          values.paymentMode === 'KOTM' ||
          values.modeOfRegistration === 'KOTM' ||
          values.paymentMode === 'AOTM' ||
          values.modeOfRegistration === 'AOTM'
            ? selectedMandate
            : {},
      };

      const payloadData = {
        email: investorDetails?.email,
        mobile: investorDetails?.mobile,
        scheme: state?.reinitiate ? state?.response?.scheme : schemeDetails?.scheme,
        planMode: state?.reinitiate ? state?.response?.planMode : schemeDetails?.planMode,
        optionDesc: state?.reinitiate
          ? state?.response?.optionDesc
          : schemeDetails?.optionDesc,
        plan: state?.reinitiate ? state?.response?.plan : schemeDetails?.plan,
        option: state?.reinitiate ? state?.response?.option : schemeDetails?.option,
        fund: state?.reinitiate ? state?.response?.fund : schemeDetails?.fund,
        schemeName: state?.reinitiate
          ? state?.response?.schemeName
          : `${schemeDetails?.schemeDesc} - ${schemeDetails?.planMode} - ${schemeDetails?.optionDesc}`,
        pan: state?.reinitiate ? state?.response?.pan : personalDetails?.pan,
        combo: false,
        invCategory: investorDetails?.category,
        investorName: investorDetails?.name,
        euin: state?.reinitiate ? state?.response?.euin : investmentDetails?.euin,
        euinDeclaration: state?.reinitiate
          ? state?.response?.euinDeclaration
          : investmentDetails?.euin !== ''
          ? 'N'
          : 'Y',
        familyMobileFlag: investorDetails?.mobileFamilyDescription,
        familyEmailFlag: investorDetails?.emailFamilyDescription,
        amount: state?.reinitiate
          ? state?.response?.amount
          : modeOfTransaction === 'Lumpsum'
          ? Number(investmentDetails?.investmentAmount)
          : Number(investmentDetails?.lumpsumAmount),
        subBroker: state?.reinitiate ? state?.response?.subBroker : subBrokerCode,
        category: state?.reinitiate ? state?.response?.category : schemeDetails?.category,
        fundName: state?.reinitiate ? state?.response?.fundName : fundName,
        personalDetails: {
          modeOfHolding:
            investorDetails?.invType === 'M'
              ? '1'
              : investorDetails?.modeOfHolding === 'single'
              ? '1'
              : '4',
          name: investorDetails?.name,
          dob: dateToMMDDYYYY(investorDetails?.dob),
          pan: investorDetails?.pan,
          email: investorDetails?.email,
          mobile: investorDetails?.mobile,
          ...(fatcaDetails?.taxResidentOtherThanIndia === 'Yes' && {
            countryOfTaxResident:
              fatcaDetails?.taxResidentOtherThanIndia === 'Yes'
                ? fatcaDetails?.taxResidentCountry
                : null,
          }),
          taxPayerIdentificationNumber:
            fatcaDetails?.taxResidentOtherThanIndia === 'Yes'
              ? fatcaDetails?.foreignIdNo
              : investorDetails?.pan,
          seafarerFlag: false,
          investorType: investorDetails?.invType,
          familyMobileFlag: investorDetails?.mobileFamilyDescription,
          familyEmailFlag: investorDetails?.emailFamilyDescription,
          category: investorDetails?.category,
          gender: getFirstLetter(investorDetails?.gender),
          status: personalDetails?.investorStatus,
          ...(investorDetails.category === '20' && {
            nriAddress1: investorDetails?.address1,
            nriAddress2: investorDetails?.address2,
            nriCity: investorDetails?.city,
            nriState: investorDetails?.state,
            nriCountry: investorDetails?.country,
            nriZipCode: investorDetails?.zipCode,
          }),
          ...(investorDetails?.invType === 'M' && {
            guardianName: investorDetails?.guardianName,
            guardianPan: investorDetails?.guardianPan,
            guardianDob: dateToMMDDYYYY(investorDetails?.guardianDob),
            guardianRelation: investorDetails?.guardianRelationWithMinor,
            guardianEmailDescription: investorDetails?.guardianEmailFamilyDescription,
            guardianMobileDescription: investorDetails?.guardianMobileFamilyDescription,
            guardianEmail: investorDetails?.guardianEmail,
            guardianMobile: investorDetails?.guardianMonNo,
            guardianCategory: investorDetails?.guardianCategory,
          }),
          countryOfBirth: fatcaDetails?.country,
          countryOfCitizenship: fatcaDetails?.birthPlace,
          countryOfCitizenShip: investorDetails.category === '20' ? 'yes' : 'no',
          grossAnnualIncome: fatcaDetails?.annualIncome,
          taxResidentOfOtherCountry: fatcaDetails?.taxResidentOtherThanIndia,
          occupation: fatcaDetails?.occupation,
          sourceOfWealth: fatcaDetails?.sourceOfWealth,
          politicallyExposedPerson: fatcaDetails?.politicallyExposedPerson,
          politicallyExposedRelative: fatcaDetails?.politicallyExposedRelative,
        },
        jointHolders:
          investorDetails?.invType === 'M' ||
          holderDetails === null ||
          holderDetails === undefined
            ? []
            : refactoredHolderDetails,
        nominees:
          investorDetails?.invType === 'M' || isNomination || !nomineeDetails?.nominees
            ? []
            : nominees || [],
        noNomineeDeclaration:
          investorDetails?.invType === 'M' || isNomination || !nomineeDetails?.nominees
            ? true
            : false,
        bankDetails: {
          ifsc: investorBankDetails?.ifscCode,
          bank: investorBankDetails?.bankName,
          accType: investorBankDetails?.accountType,
          existing: '',
          acceptTerms: 'Y',
          accNo: investorBankDetails?.accountNumber,
          address1: bankAddress[0]?.trim(),
          address2: bankAddress[1]?.trim(),
          address3: bankAddress[2]?.trim(),
          city: investorBankDetails?.bankBranch,
          pinCode: investorBankDetails?.branchPincode,
        },
        ...(modeOfTransaction !== 'Lumpsum' && {
          sipFrequency: state?.reinitiate
            ? state?.response?.sipFrequency
            : mapFrequencyToLetter(investmentDetails?.frequency),
          sipStartDate: state?.reinitiate
            ? state?.response?.sipStartDate
            : formatDate(investmentDetails?.startDate, 'MM/dd/yyyy'),
          sipEndDate: state?.reinitiate
            ? state?.response?.sipEndDate
            : formatDate(investmentDetails?.endDate, 'MM/dd/yyyy'),
          sipDay: state?.reinitiate ? state?.response?.sipDay : investmentDetails?.sipDay,
          noOfInstallments: state?.reinitiate
            ? state?.response?.noOfInstallments
            : Number(investmentDetails?.numberOfInstalments),
          modeOfRegistration: values?.modeOfRegistration,
          installmentAmount: state?.reinitiate
            ? state?.response?.installmentAmount
            : Number(investmentDetails?.investmentAmount),
          perpetual: state?.reinitiate
            ? state?.response?.perpetual
            : investmentDetails?.perpetual,
          remarks: '0',
        }),
        ...(modeOfTransaction !== 'SIP' && {
          paymentDetails: {
            paymentMode: values.paymentMode,
          },
          paymentMode: values.paymentMode,
        }),
        createMandate: selectedMandate?.value === 'createMandate',
        umrn:
          values.paymentMode === 'KOTM' ||
          values.modeOfRegistration === 'KOTM' ||
          values.paymentMode === 'AOTM' ||
          values.modeOfRegistration === 'AOTM'
            ? selectedMandate?.value === 'createMandate'
              ? ''
              : selectedMandate.value
            : '',
        selectedMandates:
          values.paymentMode === 'KOTM' ||
          values.modeOfRegistration === 'KOTM' ||
          values.paymentMode === 'AOTM' ||
          values.modeOfRegistration === 'AOTM'
            ? selectedMandate
            : {},
        ...(state?.reinitiate && {
          user_agent: state?.response?.userAgent,
          // distributorID: 'demouser',
          userAgent: state?.response?.userAgent,
          parentIhno: state?.response?.parentIhno,
          isEtfScheme: state?.response?.isEtfScheme,
          distributorID: state?.response?.distributorID,
        }),
      };

      let response;
      if (cartSate && cartSate?.previousUrl === 'cart') {
        response = await checkoutCart(cartPayload).unwrap();
      } else {
        if (modeOfTransaction === 'Lumpsum') {
          response = await newPurchaseDirect(payloadData).unwrap();
        } else if (modeOfTransaction === 'SIP') {
          response = await newPurchaseSip(payloadData).unwrap();
        } else {
          response = await newPurchaseDirectSip(payloadData).unwrap();
        }
      }

      if (response) {
        setTransactionNumber(response?.ihno ? response.ihno : response.batchId);
        setOpenDialogBox(true);
      }
    } catch (error) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const getPaymentOptions = async () => {
      setLoading(true);
      const payload = {
        fund: state?.reinitiate
          ? state?.response?.fund
          : cartSate?.previousUrl === 'cart'
          ? cartSate?.cartItems[0]?.fund
          : schemeDetails?.fund,
      };
      const response = await getAllPaymentModes(payload).unwrap();
      const options = Object.entries(response)
        .map(([key, value]) => {
          if (value === 'Y') {
            return { label: key?.toUpperCase(), value: key?.toUpperCase() };
          }
          return null;
        })
        .filter(Boolean);
      setPaymnetModeOptions(options);
      setLoading(false);
    };
    getPaymentOptions();
  }, []);

  useEffect(() => {
    const getAotmStatus = async () => {
      try {
        const response: any = await getAotmDetails({
          fund: state?.reinitiate
            ? state?.response?.fund
            : cartSate?.previousUrl === 'cart'
            ? cartSate?.cartItems[0]?.fund
            : schemeDetails?.fund,
        }).unwrap();

        const { enach_aotm_basedon } = response;
        setIsPanBasedAotm(enach_aotm_basedon.toUpperCase() === 'PAN');
      } catch (error: any) {
        const message =
          (error as any).data?.message || (error as any).message || 'Unknown error';
        showToast(message, 'error');
      }
    };
    getAotmStatus();
  }, []);

  const getOtmMadatesLumpsum = async (paymentMode: any) => {
    try {
      const payload = {
        fund: state?.reinitiate
          ? state?.response?.fund
          : cartSate?.previousUrl === 'cart'
          ? cartSate?.cartItems[0]?.fund
          : schemeDetails?.fund,
        pan: investorDetails?.pan,
        trtype: 'NPL',
        folio: '',
        modeOfRegistration: paymentMode,
      };
      const res: MandateData = await getAllMandates(payload).unwrap();
      setMandateData(res);
      let filteredMandates: any[] = [];
      let options: any[] = [];
      const currentTime = new Date().getTime();
      const cartHighestAmount = Math.max(
        ...(cartSate?.cartItems || []).map((cartItem: any) => {
          const payloadRes = JSON.parse(cartItem.payload);
          return Number(payloadRes.amount);
        }),
      );
      const highestAmount =
        cartSate?.previousUrl === 'cart'
          ? cartHighestAmount
          : Math.max(
              Number(
                state?.reinitiate
                  ? state?.response?.installmentAmount || state?.response?.amount
                  : investmentDetails?.investmentAmount || 0,
              ),
              Number(
                state?.reinitiate
                  ? state?.response?.amount
                  : investmentDetails?.lumpsumAmount || 0,
              ),
            );
      //for lumpsum checking highest investment amount should be less than or equal to mandate amount limit
      //mandate end date should be greater than current date
      //mandate start date should be less than or equal to current date
      if (paymentMode === 'KOTM') {
        //for kotm data
        filteredMandates =
          res?.kotmData?.filter(item => {
            const startDate = new Date(item.fromDate).getTime();
            const endDate = new Date(item.toDate).getTime();
            return (
              item.amount >= highestAmount &&
              startDate <= currentTime &&
              endDate > currentTime
            );
          }) || [];
        options = filteredMandates.map((item: any) => {
          return { label: item.umrn, value: item.umrn };
        });
      } else {
        //for aotm data
        filteredMandates =
          res?.aotmData?.filter(item => {
            const startDate = new Date(item.fromDate).getTime();
            const endDate = new Date(item.toDate).getTime();
            return (
              item.amount >= highestAmount &&
              startDate <= currentTime &&
              endDate > currentTime
            );
          }) || [];
        options = filteredMandates.map((item: any) => {
          return { label: item.umnr, value: item.umnr };
        });
      }
      setUmrnOptions(options);
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    }
  };

  useEffect(() => {
    const isModeNotIsip = state?.reinitiate
      ? state?.response?.modeOfRegistration !== 'ISIP'
      : cartSate?.previousUrl !== 'cart'
      ? investmentDetails?.modeOfRegistration !== 'ISIP'
      : payloadOb?.modeOfRegistration !== 'ISIP';
    if (modeOfTransaction !== 'Lumpsum' && isModeNotIsip) {
      const getPaymentOptions = async () => {
        const modeOfReg = state?.reinitiate
          ? state?.response?.modeOfRegistration || state?.response?.paymentMode
          : cartSate?.previousUrl !== 'cart'
          ? investmentDetails?.modeOfRegistration
          : payloadOb?.modeOfRegistration;
        const payload = {
          fund: state?.reinitiate
            ? state?.response?.fund
            : cartSate?.previousUrl === 'cart'
            ? cartSate?.cartItems[0]?.fund
            : schemeDetails?.fund,
          pan: investorDetails?.pan,
          trtype: 'NPL',
          folio: '',
          modeOfRegistration: modeOfReg,
        };
        const res: MandateData = await getAllMandates(payload).unwrap();
        setMandateData(res);
        let filteredMandates: any[] = [];
        let options: any[] = [];
        const cartHighestAmount = Math.max(
          ...(cartSate?.cartItems || []).map((cartItem: any) => {
            const payloadRes = JSON.parse(cartItem.payload);
            return Number(payloadRes.installmentAmount) < Number(payloadRes.amount)
              ? Number(payloadRes.amount)
              : Number(payloadRes.installmentAmount);
          }),
        );
        const sipDatesFormCart = getEarliestAndLatestSipDates(cartSate?.cartItems);
        const sipEarliestDateCart = new Date(sipDatesFormCart?.earliestDate).getTime();
        const sipLatestDateCart = new Date(sipDatesFormCart?.latestDate).getTime();
        const investmentSipLatestDate = new Date(
          state?.reinitiate ? state?.response?.sipEndDate : investmentDetails?.endDate,
        ).getTime();
        const investmentSipEarliestDate = new Date(
          state?.reinitiate
            ? state?.response?.sipStartDate
            : investmentDetails?.startDate,
        ).getTime();

        const sipEarliestDate =
          cartSate?.previousUrl === 'cart'
            ? sipEarliestDateCart
            : investmentSipEarliestDate;
        const sipLatestDate =
          cartSate?.previousUrl === 'cart' ? sipLatestDateCart : investmentSipLatestDate;
        const highestAmount =
          cartSate?.previousUrl === 'cart'
            ? cartHighestAmount
            : Math.max(
                Number(
                  state?.reinitiate
                    ? state?.response?.installmentAmount
                    : investmentDetails?.investmentAmount || 0,
                ),
                Number(
                  state?.reinitiate
                    ? state?.response?.amount
                    : investmentDetails?.lumpsumAmount || 0,
                ),
              );
        //checking for sip => mandate start date should be less than or equal to the sip start date
        //and mandate end date should be greater than longest sip end date
        if (modeOfReg === 'KOTM') {
          filteredMandates =
            res?.kotmData?.filter(item => {
              const startDate = new Date(item.fromDate).getTime();
              const endDate = new Date(item.toDate).getTime();
              return (
                item.amount >= highestAmount &&
                startDate <= sipEarliestDate &&
                endDate > sipLatestDate
              );
            }) || [];
          options = filteredMandates.map((item: any) => {
            return { label: item.umrn, value: item.umrn };
          });
        } else {
          filteredMandates =
            res?.aotmData?.filter(item => {
              const startDate = new Date(item.fromDate).getTime();
              const endDate = new Date(item.toDate).getTime();
              return (
                item.amount >= highestAmount &&
                startDate <= sipEarliestDate &&
                endDate > sipLatestDate
              );
            }) || [];
          options = filteredMandates.map((item: any) => {
            return { label: item.umnr, value: item.umnr };
          });
        }
        //For NRI i.e '20' dont allow to create Mandate
        if (
          modeOfTransaction !== 'Lumpsum' &&
          investorDetails?.category !== '20' &&
          modeOfReg !== 'AOTM'
        ) {
          options.unshift({ label: '+ Create New Mandate', value: 'createMandate' });
        }
        setUmrnOptions(options);
      };
      getPaymentOptions();
    }
  }, []);

  const getMandateOptionsSip = async (registrationMode: any) => {
    if (registrationMode !== 'ISIP') {
      try {
        const payload = {
          fund: state?.reinitiate
            ? state?.response?.fund
            : cartSate?.previousUrl === 'cart'
            ? cartSate?.cartItems[0]?.fund
            : schemeDetails?.fund,
          pan: investorDetails?.pan,
          trtype: 'NPL',
          folio: '',
          modeOfRegistration: registrationMode,
        };
        const res: MandateData = await getAllMandates(payload).unwrap();
        setMandateData(res);
        let filteredMandates: any[] = [];
        let options: any[] = [];
        const cartHighestAmount = Math.max(
          ...(cartSate?.cartItems || []).map((cartItem: any) => {
            const payloadRes = JSON.parse(cartItem.payload);
            return Number(payloadRes.installmentAmount) < Number(payloadRes.amount)
              ? Number(payloadRes.amount)
              : Number(payloadRes.installmentAmount);
          }),
        );
        const sipDatesFormCart = getEarliestAndLatestSipDates(cartSate?.cartItems);
        const sipEarliestDateCart = new Date(sipDatesFormCart?.earliestDate).getTime();
        const sipLatestDateCart = new Date(sipDatesFormCart?.latestDate).getTime();
        const investmentSipLatestDate = new Date(
          state?.reinitiate ? state?.response?.sipEndDate : investmentDetails?.endDate,
        ).getTime();
        const investmentSipEarliestDate = new Date(
          state?.reinitiate
            ? state?.response?.sipStartDate
            : investmentDetails?.startDate,
        ).getTime();

        const sipEarliestDate =
          cartSate?.previousUrl === 'cart'
            ? sipEarliestDateCart
            : investmentSipEarliestDate;
        const sipLatestDate =
          cartSate?.previousUrl === 'cart' ? sipLatestDateCart : investmentSipLatestDate;
        const highestAmount =
          cartSate?.previousUrl === 'cart'
            ? cartHighestAmount
            : Math.max(
                Number(
                  state?.reinitiate
                    ? state?.response?.installmentAmount
                    : investmentDetails?.investmentAmount || 0,
                ),
                Number(
                  state?.reinitiate
                    ? state?.response?.amount
                    : investmentDetails?.lumpsumAmount || 0,
                ),
              );
        //checking for sip => mandate start date should be less than or equal to the sip start date
        //and mandate end date should be greater than longest sip end date
        if (registrationMode === 'KOTM') {
          filteredMandates =
            res?.kotmData?.filter(item => {
              const startDate = new Date(item.fromDate).getTime();
              const endDate = new Date(item.toDate).getTime();
              return (
                item.amount >= highestAmount &&
                startDate <= sipEarliestDate &&
                endDate > sipLatestDate
              );
            }) || [];
          options = filteredMandates.map((item: any) => {
            return { label: item.umrn, value: item.umrn };
          });
        } else {
          filteredMandates =
            res?.aotmData?.filter(item => {
              const startDate = new Date(item.fromDate).getTime();
              const endDate = new Date(item.toDate).getTime();
              return (
                item.amount >= highestAmount &&
                startDate <= sipEarliestDate &&
                endDate > sipLatestDate
              );
            }) || [];
          options = filteredMandates.map((item: any) => {
            return { label: item.umnr, value: item.umnr };
          });
        }
        //For NRI i.e '20' dont allow to create Mandate
        if (
          modeOfTransaction !== 'Lumpsum' &&
          investorDetails?.category !== '20' &&
          registrationMode !== 'AOTM'
        ) {
          options.unshift({ label: '+ Create New Mandate', value: 'createMandate' });
        }
        setUmrnOptions(options);
      } catch (error: any) {
        const message =
          (error as any).data?.message || (error as any).message || 'Unknown error';
        showToast(message, 'error');
      }
    }
  };

  const fundForLogo = state?.reinitiate
    ? state?.response?.fund
    : cartSate?.previousUrl === 'cart'
    ? cartSate?.cartItems[0]?.fund
    : schemeDetails?.fund;

  const amcLogo = findLogo(fundForLogo);

  const handleConfirmClick = () => {
    contextDispatch(setPaymentConfirmation(true));
  };

  const getFilteredRegistrationModeOptions = () => {
    const isCategory11 = investorDetails?.category === '11';
    const isKOTM = state?.reinitiate
      ? state?.response?.modeOfRegistration === 'KOTM'
      : investmentDetails?.modeOfRegistration === 'KOTM' ||
        payloadOb?.modeOfRegistration === 'KOTM';

    if (
      isCategory11 &&
      ((state?.reinitiate
        ? state?.response?.modeOfRegistration !== 'ISIP'
        : investmentDetails?.modeOfRegistration !== 'ISIP' && isKOTM) ||
        (state?.reinitiate
          ? state?.response?.modeOfRegistration !== 'ISIP'
          : investmentDetails?.modeOfRegistration !== 'ISIP' && isPanBasedAotm))
    ) {
      return paymentModeOptions.filter(
        option => option.label === 'KOTM' || (isPanBasedAotm && option.label === 'AOTM'),
      );
    } else {
      return paymentModeOptions.filter(
        option =>
          option.label === 'KOTM' ||
          (isPanBasedAotm && option.label === 'AOTM') ||
          option.label === 'ISIP',
      );
    }
  };

  const getFilteredPaymentModeOptions = (values: any) => {
    if (
      selectedMandate.value === 'createMandate' ||
      values.modeOfRegistration === 'ISIP'
    ) {
      return paymentModeOptions?.filter(
        option =>
          option.label !== 'KOTM' && option.label !== 'ISIP' && option.label !== 'AOTM',
      );
    } else {
      return paymentModeOptions?.filter(option => {
        if (option.label === 'ISIP') {
          return false;
        }
        if (option.label === 'AOTM' && !isPanBasedAotm) {
          return false;
        }
        if (values.modeOfRegistration === 'KOTM' && values.mandate !== '') {
          return option.label !== 'AOTM';
        }
        if (values.modeOfRegistration === 'AOTM' && values.mandate !== '') {
          return option.label !== 'KOTM';
        }
        return true;
      });
    }
  };

  return (
    <Box sx={{ p: { xs: '0px 24px', md: '0px 0px 0px 24px' }, position: 'relative' }}>
      <Typography
        sx={{
          mb: 2,
          color: 'primary.main',
          fontSize: { xs: '14px', sm: '16px' },
          fontWeight: 500,
        }}
      >
        Please check the following details
      </Typography>

      <Grid
        container
        spacing={2}
      >
        {!paymentModeConfirmation ? (
          <Grid
            item
            xs={12}
            md={7.5}
            sx={{ order: { xs: 2, sm: 1 } }}
          >
            <Investorcard
              sx={(theme: Theme) => ({
                padding: '30px 30px',

                [theme.breakpoints.down('md')]: {
                  padding: '20px',
                },
                [theme.breakpoints.down('sm')]: {
                  boxShadow: 'unset',
                  border: 'unset',
                  padding: '0px',
                },
              })}
            >
              <Typography
                sx={{
                  mb: 2.5,
                  color: 'primary.main',
                  fontSize: { xs: '14px', sm: '16px', xl: '18px' },
                  fontWeight: 500,
                }}
              >
                Scheme Details
              </Typography>
              {cartSate?.previousUrl !== 'cart' ? (
                <Box
                  sx={{
                    bgcolor: 'text.boxColor',
                    padding: { xs: '20px 20px', md: '30px 30px' },
                    mb: 2,
                    mx: { xs: '-20px', sm: 'unset' },
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={2}
                      sm={1.2}
                      md={2.5}
                      lg={1.25}
                    >
                      <Box
                        sx={{
                          'borderRadius': '5px',
                          'border': '0.5px solid',
                          'borderColor': 'text.borderColorLight',
                          'boxShadow': '0px 4px 15px 0px rgba(0, 0, 0, 0.05)',
                          'padding': '4px 4px',
                          'display': 'flex',
                          'justifyContent': 'center',
                          'alignItems': 'center',
                          'width': '42px',
                          'height': '42px',
                          '& svg': {
                            width: '32px',
                            height: '32px',
                          },
                        }}
                      >
                        {amcLogo?.icon && <amcLogo.icon />}
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      sm={10.8}
                      md={9.5}
                      lg={10.75}
                    >
                      <Grid
                        container
                        spacing={2}
                      >
                        <Grid
                          item
                          xs={12}
                        >
                          <Stack sx={{ mb: '20px', rowGap: '5px' }}>
                            <Typography
                              sx={{
                                color: 'text.labelColor',
                                fontSize: { xs: '12px', sm: '14px' },
                              }}
                            >
                              {state?.reinitiate ? state?.response?.fundName : fundName}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: { xs: '14px', xl: '16px' },
                                color: 'text.valueColor',
                                fontWeight: 500,
                              }}
                            >
                              {state?.reinitiate
                                ? `${state?.response?.schemeName} - ${state?.response?.planMode} - ${state?.response?.optionDesc}`
                                : `${schemeDetails?.schemeDesc} - ${schemeDetails?.planMode} - ${schemeDetails?.optionDesc}` ||
                                  'NA'}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sm={4}
                        >
                          <Stack sx={{ rowGap: '5px' }}>
                            <Typography
                              sx={{
                                color: 'text.labelColor',
                                fontSize: { xs: '12px', sm: '14px' },
                              }}
                            >
                              {modeOfTransaction !== 'Lumpsum'
                                ? 'SIP Amount'
                                : 'Investment Amount'}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: { xs: '14px', xl: '16px' },
                                color: 'text.valueColor',
                                fontWeight: 500,
                              }}
                            >
                              ₹
                              {inrCurrencyFormatter(
                                state?.reinitiate
                                  ? state?.response?.transactionType === 'NPL'
                                    ? state?.response?.amount
                                    : state?.response?.sipamount
                                  : investmentDetails?.investmentAmount,
                              ) || 'NA'}
                            </Typography>
                          </Stack>
                        </Grid>

                        {modeOfTransaction !== 'Lumpsum' && (
                          <>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                            >
                              <Stack
                                rowGap={'5px'}
                                mb={'20px'}
                              >
                                <Typography sx={{ color: 'text.labelColor' }}>
                                  SIP Day
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: { xs: '14px', xl: '16px' },
                                    color: 'text.valueColor',
                                    fontWeight: 500,
                                  }}
                                >
                                  {state?.reinitiate
                                    ? state?.response?.sipDay
                                    : investmentDetails?.sipDay || 'NA'}
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                            >
                              <Stack
                                rowGap={'5px'}
                                mb={'20px'}
                              >
                                <Typography sx={{ color: 'text.labelColor' }}>
                                  Number Of Installments
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: { xs: '14px', xl: '16px' },
                                    color: 'text.valueColor',
                                    fontWeight: 500,
                                  }}
                                >
                                  {state?.reinitiate
                                    ? state?.response?.noOfInstallments
                                    : investmentDetails?.numberOfInstalments || 'NA'}
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                            >
                              <Stack
                                rowGap={'5px'}
                                mb={'20px'}
                              >
                                <Typography sx={{ color: 'text.labelColor' }}>
                                  Start Date
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: { xs: '14px', xl: '16px' },
                                    color: 'text.valueColor',
                                    fontWeight: 500,
                                  }}
                                >
                                  {state?.reinitiate
                                    ? state?.response?.sipStartDate
                                    : formatDate(
                                        investmentDetails?.startDate,
                                        'dd/MM/yyyy',
                                      ) || 'NA'}
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                            >
                              <Stack
                                rowGap={'5px'}
                                mb={'20px'}
                              >
                                <Typography sx={{ color: 'text.labelColor' }}>
                                  End Date
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: { xs: '14px', xl: '16px' },
                                    color: 'text.valueColor',
                                    fontWeight: 500,
                                  }}
                                >
                                  {state?.reinitiate
                                    ? state?.response?.sipEndDate
                                    : formatDate(
                                        investmentDetails?.endDate,
                                        'dd/MM/yyyy',
                                      ) || 'NA'}
                                </Typography>
                              </Stack>
                            </Grid>
                          </>
                        )}
                        {modeOfTransaction === 'Lumpsum + SIP' && (
                          <Grid
                            item
                            xs={12}
                            sm={4}
                          >
                            <Stack
                              rowGap={'5px'}
                              mb={'20px'}
                            >
                              <Typography
                                sx={{
                                  color: 'text.labelColor',
                                  fontSize: { xs: '12px', sm: '14px' },
                                }}
                              >
                                Lumpsum Amount
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: { xs: '14px', xl: '16px' },
                                  color: 'text.valueColor',
                                  fontWeight: 500,
                                }}
                              >
                                ₹
                                {state?.reinitiate
                                  ? state?.response?.amount
                                  : inrCurrencyFormatter(
                                      investmentDetails?.lumpsumAmount,
                                    ) || 'NA'}
                              </Typography>
                            </Stack>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <>
                  <Grid
                    container
                    spacing={2}
                    mb={2}
                  >
                    <Grid
                      item
                      xs={2}
                      md={1.2}
                    >
                      <Box
                        sx={{
                          'borderRadius': '5px',
                          'border': '0.5px solid',
                          'borderColor': 'text.borderColorLight',
                          'boxShadow': '0px 4px 15px 0px rgba(0, 0, 0, 0.05)',
                          'padding': '4px 4px',
                          'display': 'flex',
                          'width': '42px',
                          'height': '42px',
                          '& svg': {
                            width: '32px',
                            height: '32px',
                          },
                        }}
                      >
                        {amcLogo?.icon && <amcLogo.icon />}
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={10.8}
                      mt={1}
                    >
                      <Typography
                        sx={{
                          fontSize: '20px',
                          fontWeight: 500,
                          color: 'primary.main',
                        }}
                      >
                        {payloadOb.fundName}
                      </Typography>
                    </Grid>
                  </Grid>
                  {cartSate?.cartItems?.map((item: any, index: number) => {
                    const payloadObj = JSON.parse(item.payload);
                    const investmentDetails = {
                      investmentAmount: payloadObj.amount || 'NA',
                      installmentAmount: payloadObj.installmentAmount || 'NA',
                      schemeName: payloadObj.schemeName || 'NA',
                      planMode: payloadObj.planMode || 'NA',
                      optionDesc: payloadObj?.optionDesc || 'NA',
                      sipDay: payloadObj.sipDay || 'NA',
                      numberOfInstalments: payloadObj.noOfInstallments || 'NA',
                      startDate: payloadObj.sipStartDate || 'NA',
                      endDate: payloadObj.sipEndDate || 'NA',
                      lumpsumAmount: payloadObj.amount || 'NA',
                    };
                    return (
                      <Grid
                        container
                        spacing={2}
                        key={index}
                      >
                        <Grid
                          item
                          xs={12}
                        >
                          <Divider
                            orientation='horizontal'
                            variant='fullWidth'
                            flexItem
                            sx={{
                              border: '1px dashed',
                              borderColor: 'text.borderColorDark',
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                        >
                          <Stack
                            rowGap={'5px'}
                            mb={'20px'}
                          >
                            <Typography sx={{ color: 'text.labelColor' }}>
                              Scheme
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: { xs: '14px', xl: '16px' },
                                color: 'text.valueColor',
                                fontWeight: 500,
                              }}
                            >
                              {investmentDetails?.schemeName || 'NA'}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                        >
                          <Stack
                            rowGap={'5px'}
                            mb={'20px'}
                          >
                            <Typography sx={{ color: 'text.labelColor' }}>
                              {modeOfTransaction !== 'Lumpsum'
                                ? 'SIP Amount'
                                : 'Investment Amount'}
                            </Typography>
                            <Typography
                              variant='subtitle2'
                              sx={{ color: 'text.valueColor' }}
                            >
                              ₹
                              {modeOfTransaction === 'Lumpsum'
                                ? inrCurrencyFormatter(
                                    investmentDetails?.investmentAmount,
                                  )
                                : inrCurrencyFormatter(
                                    investmentDetails?.installmentAmount,
                                  ) || 'NA'}
                            </Typography>
                          </Stack>
                        </Grid>
                        {modeOfTransaction !== 'Lumpsum' && (
                          <>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                            >
                              <Stack
                                rowGap={'5px'}
                                mb={'20px'}
                              >
                                <Typography sx={{ color: 'text.labelColor' }}>
                                  SIP Day
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: { xs: '14px', xl: '16px' },
                                    color: 'text.valueColor',
                                    fontWeight: 500,
                                  }}
                                >
                                  {investmentDetails?.sipDay || 'NA'}
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                            >
                              <Stack
                                rowGap={'5px'}
                                mb={'20px'}
                              >
                                <Typography sx={{ color: 'text.labelColor' }}>
                                  Number Of Installments
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: { xs: '14px', xl: '16px' },
                                    color: 'text.valueColor',
                                    fontWeight: 500,
                                  }}
                                >
                                  {investmentDetails?.numberOfInstalments || 'NA'}
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                            >
                              <Stack
                                rowGap={'5px'}
                                mb={'20px'}
                              >
                                <Typography sx={{ color: 'text.labelColor' }}>
                                  Start Date
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: { xs: '14px', xl: '16px' },
                                    color: 'text.valueColor',
                                    fontWeight: 500,
                                  }}
                                >
                                  {investmentDetails?.startDate || 'NA'}
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                            >
                              <Stack
                                rowGap={'5px'}
                                mb={'20px'}
                              >
                                <Typography sx={{ color: 'text.labelColor' }}>
                                  End Date
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: { xs: '14px', xl: '16px' },
                                    color: 'text.valueColor',
                                    fontWeight: 500,
                                  }}
                                >
                                  {investmentDetails?.endDate || 'NA'}
                                </Typography>
                              </Stack>
                            </Grid>
                          </>
                        )}
                        {modeOfTransaction === 'Lumpsum + SIP' && (
                          <Grid
                            item
                            xs={12}
                            sm={4}
                          >
                            <Stack
                              rowGap={'5px'}
                              mb={'20px'}
                            >
                              <Typography sx={{ color: 'text.labelColor' }}>
                                Lumpsum Amount
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: { xs: '14px', xl: '16px' },
                                  color: 'text.valueColor',
                                  fontWeight: 500,
                                }}
                              >
                                ₹
                                {inrCurrencyFormatter(investmentDetails?.lumpsumAmount) ||
                                  'NA'}
                              </Typography>
                            </Stack>
                          </Grid>
                        )}
                      </Grid>
                    );
                  })}
                </>
              )}
              <StepperNextButton
                sx={{ mt: 3, minWidth: { xs: '100%', sm: '200px' } }}
                onClick={handleConfirmClick}
              >
                Confirm
              </StepperNextButton>
            </Investorcard>
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            sm={7.5}
            sx={{ order: { xs: 2, sm: 1 } }}
          >
            <Investorcard
              sx={(theme: Theme) => ({
                padding: '35px 35px',
                [theme.breakpoints.down('md')]: {
                  padding: '20px',
                },
                [theme.breakpoints.down('sm')]: {
                  boxShadow: 'unset',
                  border: 'unset',
                  padding: '0px',
                },
              })}
            >
              <Typography
                sx={{
                  mb: 2.5,
                  color: 'primary.main',
                  fontSize: { xs: '14px', sm: '16px', xl: '18px' },
                  fontWeight: 500,
                }}
              >
                Scheme Details
              </Typography>
              {cartSate?.previousUrl !== 'cart' ? (
                <>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      sm={1.2}
                      xs={2}
                    >
                      <Box
                        sx={{
                          'borderRadius': '5px',
                          'border': '0.5px solid',
                          'borderColor': 'text.borderColorLight',
                          'boxShadow': '0px 4px 15px 0px rgba(0, 0, 0, 0.05)',
                          'padding': '4px 4px',
                          'display': 'flex',
                          'justifyContent': 'center',
                          'alignItems': 'center',
                          'width': '42px',
                          'height': '42px',
                          '& svg': {
                            width: '32px',
                            height: '32px',
                          },
                        }}
                      >
                        {amcLogo?.icon && <amcLogo.icon />}
                      </Box>
                    </Grid>
                    <Grid
                      item
                      sm={10.8}
                      xs={10}
                      mt={1}
                    >
                      <Typography
                        variant='h5'
                        sx={{
                          color: 'primary.main',
                        }}
                      >
                        {state?.reinitiate ? state?.response?.fundName : fundName}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Divider
                    orientation='horizontal'
                    variant='fullWidth'
                    flexItem
                    sx={{
                      my: 2,
                      border: '1px dashed',
                      borderColor: 'text.borderColorDark',
                    }}
                  />

                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={7}
                    >
                      <Stack
                        rowGap={'5px'}
                        mb={'20px'}
                      >
                        <Typography sx={{ color: 'text.labelColor' }}>Scheme</Typography>
                        <Typography
                          sx={{
                            fontSize: { xs: '14px', xl: '16px' },
                            color: 'text.valueColor',
                            fontWeight: 500,
                          }}
                        >
                          {state?.reinitiate
                            ? `${state?.response?.schemeName} - ${state?.response?.planMode} - ${state?.response?.optionDesc}`
                            : `${schemeDetails?.schemeDesc} - ${schemeDetails?.planMode} - ${schemeDetails?.optionDesc}` ||
                              'NA'}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={5}
                    >
                      <Stack
                        rowGap={'5px'}
                        mb={'20px'}
                      >
                        <Typography sx={{ color: 'text.labelColor' }}>
                          {modeOfTransaction !== 'Lumpsum'
                            ? 'SIP Amount'
                            : 'Investment Amount'}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { xs: '14px', xl: '16px' },
                            color: 'text.valueColor',
                            fontWeight: 500,
                          }}
                        >
                          ₹
                          {inrCurrencyFormatter(
                            state?.reinitiate
                              ? state?.response?.transactionType === 'NPL'
                                ? state?.response?.amount
                                : state?.response?.sipamount
                              : investmentDetails?.investmentAmount,
                          ) || 'NA'}
                        </Typography>
                      </Stack>
                    </Grid>
                    {modeOfTransaction !== 'Lumpsum' && (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                        >
                          <Stack
                            rowGap={'5px'}
                            mb={'20px'}
                          >
                            <Typography sx={{ color: 'text.labelColor' }}>
                              SIP Day
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: { xs: '14px', xl: '16px' },
                                color: 'text.valueColor',
                                fontWeight: 500,
                              }}
                            >
                              {state?.reinitiate
                                ? state?.response?.sipDay
                                : investmentDetails?.sipDay || 'NA'}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                        >
                          <Stack
                            rowGap={'5px'}
                            mb={'20px'}
                          >
                            <Typography sx={{ color: 'text.labelColor' }}>
                              Number Of Installments
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: { xs: '14px', xl: '16px' },
                                color: 'text.valueColor',
                                fontWeight: 500,
                              }}
                            >
                              {state?.reinitiate
                                ? state?.response?.noOfInstallments
                                : investmentDetails?.numberOfInstalments || 'NA'}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                        >
                          <Stack
                            rowGap={'5px'}
                            mb={'20px'}
                          >
                            <Typography sx={{ color: 'text.labelColor' }}>
                              Start Date
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: { xs: '14px', xl: '16px' },
                                color: 'text.valueColor',
                                fontWeight: 500,
                              }}
                            >
                              {state?.reinitiate
                                ? state?.response?.sipStartDate
                                : formatDate(
                                    investmentDetails?.startDate,
                                    'dd/MM/yyyy',
                                  ) || 'NA'}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                        >
                          <Stack
                            rowGap={'5px'}
                            mb={'20px'}
                          >
                            <Typography sx={{ color: 'text.labelColor' }}>
                              End Date
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: { xs: '14px', xl: '16px' },
                                color: 'text.valueColor',
                                fontWeight: 500,
                              }}
                            >
                              {state?.reinitiate
                                ? state?.response?.sipEndDate
                                : formatDate(investmentDetails?.endDate, 'dd/MM/yyyy') ||
                                  'NA'}
                            </Typography>
                          </Stack>
                        </Grid>
                      </>
                    )}
                    {modeOfTransaction === 'Lumpsum + SIP' && (
                      <Grid
                        item
                        xs={12}
                        sm={4}
                      >
                        <Stack
                          rowGap={'5px'}
                          mb={'20px'}
                        >
                          <Typography sx={{ color: 'text.labelColor' }}>
                            Lumpsum Amount
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: { xs: '14px', xl: '16px' },
                              color: 'text.valueColor',
                              fontWeight: 500,
                            }}
                          >
                            ₹
                            {state?.reinitiate
                              ? state?.response?.amount
                              : inrCurrencyFormatter(investmentDetails?.lumpsumAmount) ||
                                'NA'}
                          </Typography>
                        </Stack>
                      </Grid>
                    )}
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    container
                    spacing={2}
                    mb={2}
                  >
                    <Grid
                      item
                      sm={1.2}
                    >
                      <Box
                        sx={{
                          'borderRadius': '5px',
                          'border': '0.5px solid',
                          'borderColor': 'text.borderColorLight',
                          'boxShadow': '0px 4px 15px 0px rgba(0, 0, 0, 0.05)',
                          'padding': '4px 4px',
                          'display': 'flex',
                          'width': '42px',
                          'height': '42px',
                          '& svg': {
                            width: '32px',
                            height: '32px',
                          },
                        }}
                      >
                        {amcLogo?.icon && <amcLogo.icon />}
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={10.8}
                      mt={1}
                    >
                      <Typography
                        sx={{
                          fontSize: '20px',
                          fontWeight: 500,
                          color: 'primary.main',
                        }}
                      >
                        {payloadOb.fundName}
                      </Typography>
                    </Grid>
                  </Grid>
                  {cartSate?.cartItems?.map((item: any, index: number) => {
                    const payloadObj = JSON.parse(item.payload);
                    const investmentDetails = {
                      investmentAmount: payloadObj.amount || 'NA',
                      installmentAmount: payloadObj.installmentAmount || 'NA',
                      schemeName: payloadObj.schemeName || 'NA',
                      planMode: payloadObj.planMode || 'NA',
                      optionDesc: payloadObj?.optionDesc || 'NA',
                      sipDay: payloadObj.sipDay || 'NA',
                      numberOfInstalments: payloadObj.noOfInstallments || 'NA',
                      startDate: payloadObj.sipStartDate || 'NA',
                      endDate: payloadObj.sipEndDate || 'NA',
                      lumpsumAmount: payloadObj.amount || 'NA',
                    };
                    return (
                      <Grid
                        container
                        spacing={2}
                        key={index}
                      >
                        <Grid
                          item
                          xs={12}
                        >
                          <Divider
                            orientation='horizontal'
                            variant='fullWidth'
                            flexItem
                            sx={{
                              border: '1px dashed',
                              borderColor: 'text.borderColorDark',
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                        >
                          <Stack
                            rowGap={'5px'}
                            mb={'20px'}
                          >
                            <Typography sx={{ color: 'text.labelColor' }}>
                              Scheme
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: { xs: '14px', xl: '16px' },
                                color: 'text.valueColor',
                                fontWeight: 500,
                              }}
                            >
                              {investmentDetails?.schemeName || 'NA'}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                        >
                          <Stack
                            rowGap={'5px'}
                            mb={'20px'}
                          >
                            <Typography sx={{ color: 'text.labelColor' }}>
                              {modeOfTransaction !== 'Lumpsum'
                                ? 'SIP Amount'
                                : 'Investment Amount'}
                            </Typography>
                            <Typography
                              variant='subtitle2'
                              sx={{ color: 'text.valueColor' }}
                            >
                              ₹
                              {modeOfTransaction === 'Lumpsum'
                                ? inrCurrencyFormatter(
                                    investmentDetails?.investmentAmount,
                                  )
                                : inrCurrencyFormatter(
                                    investmentDetails?.installmentAmount,
                                  ) || 'NA'}
                            </Typography>
                          </Stack>
                        </Grid>
                        {modeOfTransaction !== 'Lumpsum' && (
                          <>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                            >
                              <Stack
                                rowGap={'5px'}
                                mb={'20px'}
                              >
                                <Typography sx={{ color: 'text.labelColor' }}>
                                  SIP Day
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: { xs: '14px', xl: '16px' },
                                    color: 'text.valueColor',
                                    fontWeight: 500,
                                  }}
                                >
                                  {investmentDetails?.sipDay || 'NA'}
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                            >
                              <Stack
                                rowGap={'5px'}
                                mb={'20px'}
                              >
                                <Typography sx={{ color: 'text.labelColor' }}>
                                  Number Of Installments
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: { xs: '14px', xl: '16px' },
                                    color: 'text.valueColor',
                                    fontWeight: 500,
                                  }}
                                >
                                  {investmentDetails?.numberOfInstalments || 'NA'}
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                            >
                              <Stack
                                rowGap={'5px'}
                                mb={'20px'}
                              >
                                <Typography sx={{ color: 'text.labelColor' }}>
                                  Start Date
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: { xs: '14px', xl: '16px' },
                                    color: 'text.valueColor',
                                    fontWeight: 500,
                                  }}
                                >
                                  {investmentDetails?.startDate || 'NA'}
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={4}
                            >
                              <Stack
                                rowGap={'5px'}
                                mb={'20px'}
                              >
                                <Typography sx={{ color: 'text.labelColor' }}>
                                  End Date
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: { xs: '14px', xl: '16px' },
                                    color: 'text.valueColor',
                                    fontWeight: 500,
                                  }}
                                >
                                  {investmentDetails?.endDate || 'NA'}
                                </Typography>
                              </Stack>
                            </Grid>
                          </>
                        )}
                        {modeOfTransaction === 'Lumpsum + SIP' && (
                          <Grid
                            item
                            xs={12}
                            sm={4}
                          >
                            <Stack
                              rowGap={'5px'}
                              mb={'20px'}
                            >
                              <Typography sx={{ color: 'text.labelColor' }}>
                                Lumpsum Amount
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: { xs: '14px', xl: '16px' },
                                  color: 'text.valueColor',
                                  fontWeight: 500,
                                }}
                              >
                                ₹
                                {inrCurrencyFormatter(investmentDetails?.lumpsumAmount) ||
                                  'NA'}
                              </Typography>
                            </Stack>
                          </Grid>
                        )}
                      </Grid>
                    );
                  })}
                </>
              )}
              <Formik
                initialValues={{
                  paymentMode: state?.reinitiate ? state?.response?.paymentMode : '',
                  modeOfRegistration: state?.reinitiate
                    ? state?.response?.modeOfRegistration
                    : investmentDetails?.modeOfRegistration ||
                      payloadOb?.modeOfRegistration ||
                      '',
                  mandate: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue }) => {
                  return (
                    <Form>
                      {modeOfTransaction !== 'SIP' && (
                        <Box sx={{ mb: 3 }}>
                          <Typography
                            sx={{
                              mb: 2,
                              fontSize: { xs: '14px', xl: '16px' },
                              color: 'text.valueColor',
                              fontWeight: 500,
                            }}
                          >
                            {modeOfTransaction === 'Lumpsum'
                              ? 'Select Payment Mode *'
                              : 'Select Payment Mode (Lumpsum) *'}
                          </Typography>
                          {/* {for NewPurchase hide ISIP Payment mode} */}
                          <FormStyledRadioButton
                            options={getFilteredPaymentModeOptions(values)}
                            name='paymentMode'
                            handleChange={async e => {
                              if (
                                (modeOfTransaction === 'Lumpsum' &&
                                  e.target.value === 'KOTM') ||
                                e.target.value === 'AOTM'
                              ) {
                                setFieldValue('mandate', '');
                                getOtmMadatesLumpsum(e.target.value);
                              }
                            }}
                          />
                        </Box>
                      )}
                      <Grid
                        container
                        spacing={2}
                      >
                        {modeOfTransaction !== 'Lumpsum' && (
                          <Grid
                            item
                            sm={6}
                            xs={12}
                          >
                            <FormSelectInput
                              name='modeOfRegistration'
                              label='Mode of Registration (SIP)'
                              required
                              options={getFilteredRegistrationModeOptions()}
                              onChange={async (e: any) => {
                                setFieldValue('mandate', '');
                                setFieldValue('paymentMode', '');
                                getMandateOptionsSip(e.target.value);
                              }}
                            />
                          </Grid>
                        )}
                        {(values.paymentMode === 'KOTM' ||
                          values.paymentMode === 'AOTM' ||
                          modeOfTransaction !== 'Lumpsum') &&
                          values?.modeOfRegistration !== 'ISIP' && (
                            <Grid
                              item
                              sm={6}
                              xs={12}
                            >
                              <FormSelectInput
                                name='mandate'
                                label='Select Mandate'
                                required
                                options={umrnOptions}
                                onChange={e => handleMandateChange(e, values)}
                                onClick={() => {
                                  if (umrnOptions.length === 0) {
                                    showToast(
                                      'There are no valid mandates available, please select a different payment mode',
                                      'error',
                                    );
                                  }
                                }}
                              />
                            </Grid>
                          )}
                      </Grid>
                      <SubmitButton
                        sx={{
                          '&.MuiButtonBase-root': {
                            borderRadius: '5px',
                            minWidth: { xs: '100%', sm: '200px' },
                            mt: 5,
                          },
                        }}
                        label='Confirm'
                      />
                    </Form>
                  );
                }}
              </Formik>
            </Investorcard>
          </Grid>
        )}
        <Grid
          item
          md={4.25}
          xs={12}
          sx={{ order: { xs: 1, sm: 2 } }}
        >
          <ConfirmationProfile
            investmentDetails={investmentDetails}
            investorDetails={investorDetails}
          />
        </Grid>
      </Grid>
      <CustomDialog
        isVisible={openDialogBox}
        secondButtonHandler={() => {
          setOpenDialogBox(false);
          if (state?.reinitiate) {
            navigate('/transaction-dashboard/dashboard');
          } else {
            navigate('/transaction-dashboard');
          }
        }}
        secondButtonTitle='Ok'
        firstButtonTitle=''
        showFirstButton={false}
        showSecondButton
        handleClose={() => {}}
        firstButtonHandler={() => {}}
      >
        <Box
          sx={{
            'textAlign': 'center',
            'pt': 2,
            '& .MuiTypography-root': {
              'fontSize': '16px',
              'color': 'text.grayishBlue',
              'fontWeight': '400',
              'opacity': '0.85',
              'my': 2,
              '& >*': {
                fontWeight: '600',
              },
            },
          }}
        >
          <img
            src={VerifiedIcon}
            alt='verified-icon'
          />

          <Typography variant='body1'>{`Transaction Submitted Successfully with batch number ${transactionNumber}`}</Typography>
        </Box>
      </CustomDialog>
    </Box>
  );
}
