import { Box, Grid } from '@mui/material';
import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { ReactComponent as Edit_Icon } from '../../../assets/edit-icon-filled.svg';
import { StyledSubmitButton } from '../../auth/login/styles/styledComponents';
import { useCreateAdminDetailsMutation } from '../../auth/signup/slices';
import { statesList } from '../../common/constants';
import { startLoading, stopLoading } from '../../common/Loader/loaderSlice';
import { useRootContext } from '../../data/root.context';
import { LoginResponse } from '../../features/login/loginSlice';
import { FormSelectInput } from '../../forms/FormSelect';
import { FormStyledRadioButton } from '../../forms/FormStyledRadioButton';
import FormTextInput from '../../forms/FormTextInput';
import { StepperAddButton } from '../../onboarding/styles/styledComponents';
import L1AndL2ManagerFields from '../AutoComplete/ManagersFieldComponent';
import { ErrorComponent } from '../ErrorComponent';
import { useAddDistributorMutation, useUpdateDistributorMutation } from '../slice';
import {
  AddUserFields,
  EmployeeOrSubBroker,
  FIELD_NAMES_ADD_USERS,
  ManagerOption,
} from '../types';

interface AddUsersFormProps {
  showCancel?: boolean;
  cancelButtonHandler?: (isUsersAdded?: boolean) => void;
  isEditMode?: boolean;
  buttonTitle: string;
  isEditEnabled?: boolean;
  selectedRow?: any;
  isSignUpAdmin?: boolean;
  submitButtonLabel: string;
  isBigScreen?: boolean;
  L1ManagerDefaultValue?: ManagerOption | null;
  L2ManagerDefaultValue?: ManagerOption | null;
}
interface ErrorProps {
  error: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
}
const getValidationSchema = (arn: string) => {
  return Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .min(3, 'Name must be at least 3 characters long')
      .matches(/^[a-zA-Z\s]*$/, 'Name can only contain letters and spaces'),
    department: Yup.string().required('Department is required'),
    employeeOrSubBrokerId: Yup.string()
      .required('Employee ID is required')
      .test('notEqual', 'Employee ID cannot be the same as ARN', function (value) {
        return Boolean(arn) && !!arn && !!value ? arn.trim() !== value.trim() : true;
      }),
    mobileNo: Yup.string()
      .required('Mobile Number is required')
      .matches(
        /^[0-9]{10}$/,
        'Mobile number must be exactly 10 digits and contain only numbers',
      ),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    role: Yup.string().required('Role is required'),
    L2ManagerOrRM2Manager: Yup.string()
      .test(
        'notEqual',
        'L1 and L2 managers cannot be the same, choose another manager',
        function (value) {
          const { L1ManagerOrRM1Manager } = this.parent;
          return Boolean(L1ManagerOrRM1Manager) && !!L1ManagerOrRM1Manager && !!value
            ? L1ManagerOrRM1Manager !== value
            : true;
        },
      )
      .test(
        'L1RequiredIfL2Filled',
        'L1 Manager is required if L2 Manager is filled',
        function (value) {
          const { L1ManagerOrRM1Manager } = this.parent;
          return !value || !!L1ManagerOrRM1Manager;
        },
      ),
    state: Yup.string().required('State is required'),
    branch: Yup.string()
      .required('Branch is required')
      .matches(
        /^[a-zA-Z0-9\s\-\\/]*$/,
        'Branch name can only contain alphanumeric characters, spaces, hyphens, and slashes',
      ),
  });
};

const AddUsersForm: React.FC<AddUsersFormProps> = ({
  showCancel = false,
  isSignUpAdmin = false,
  cancelButtonHandler,
  isEditMode = false,
  buttonTitle,
  isEditEnabled = true,
  selectedRow,
  submitButtonLabel,
  isBigScreen,
  L1ManagerDefaultValue = null,
  L2ManagerDefaultValue = null,
}) => {
  const [L1ManagerDefaultValueState, setL1ManagerDefaultValueState] =
    useState<ManagerOption | null>(L1ManagerDefaultValue);
  const [L2ManagerDefaultValueState, setL2ManagerDefaultValueState] =
    useState<ManagerOption | null>(L2ManagerDefaultValue);
  const [addDistributor] = useAddDistributorMutation();
  const [updateDistributor] = useUpdateDistributorMutation();
  const [createAdminDetails] = useCreateAdminDetailsMutation();

  const formikRef = useRef<FormikProps<any>>(null);
  const { showToast } = useRootContext();
  const dispatch = useDispatch();

  const [dropDownData, setDropDownData] = useState<
    {
      Name: string;
    }[]
  >(statesList);
  const navigate = useNavigate();
  const [manager1 = {}, manager2 = {}] = selectedRow?.Manager || [];
  const { empId: manager1Id } = manager1;
  const { empId: manager2Id } = manager2;
  const isDisabled = () => isEditMode && !isEditEnabled;
  const initialValues: AddUserFields = isEditMode
    ? {
        role: selectedRow.role,
        name: selectedRow.name,
        department: selectedRow.department,
        employeeOrSubBrokerId: selectedRow.empId || selectedRow.subbrokerCode,
        mobileNo: selectedRow.mobile,
        email: selectedRow.email,
        L1ManagerOrRM1Manager: manager1Id,
        L2ManagerOrRM2Manager: manager2Id,
        state: selectedRow.officeState,
        branch: selectedRow.officeBranch,
      }
    : {
        role: 'EMPLOYEE',
        name: '',
        department: '',
        employeeOrSubBrokerId: '',
        mobileNo: '',
        email: '',
        L1ManagerOrRM1Manager: '',
        L2ManagerOrRM2Manager: '',
        state: '',
        branch: '',
      };
  const { arn } = useSelector((state: { login: LoginResponse }) => state.login);
  const submitButtonHandler = async (values: any) => {
    try {
      dispatch(startLoading());
      let payload: any;

      if (isSignUpAdmin) {
        payload = {
          spocName: values.name,
          spocDesignation: 'manager',
          spocEmail: values.email,
          spocMobile: values.mobileNo,
          countryCode: '+91',
          empId: values.employeeOrSubBrokerId,
          department: values.department,
          role: values?.role,
          officeState: values.state,
          officeBranch: values.branch,
        };

        const response = await createAdminDetails(payload).unwrap();

        navigate('../verify-otp', {
          state: { signUpAdmin: true, message: response.emailDescription, replace: true },
        });
      } else {
        if (isEditMode) {
          payload = {
            name: values.name,
            department: values.department,
            role: selectedRow.role,
            mobile: values.mobileNo,
            officeState: values.state,
            officeBranch: values.branch,
            ...(selectedRow.empId
              ? {
                  manager1EmpId: values.L1ManagerOrRM1Manager,
                  manager2EmpId: values.L2ManagerOrRM2Manager,
                }
              : {}),
            ...(selectedRow.subbrokerCode
              ? {
                  manager1SubbrokerCode: values.L1ManagerOrRM1Manager,
                  manager2SubbrokerCode: values.L2ManagerOrRM2Manager,
                }
              : {}),

            ...(selectedRow.empId ? { employeeId: selectedRow.empId } : {}),
            ...(selectedRow.subbrokerCode
              ? { subbrokerCode: selectedRow.subbrokerCode }
              : {}),
          };
        } else {
          payload = {
            name: values.name,
            department: values.department,
            role: values.role,
            mobile: values.mobileNo,
            email: values.email,
            officeState: values.state,
            officeBranch: values.branch,
            ...(values.role === 'EMPLOYEE'
              ? {
                  manager1EmpId: values.L1ManagerOrRM1Manager,
                  manager2EmpId: values.L2ManagerOrRM2Manager,
                  employeeId: values.employeeOrSubBrokerId,
                }
              : {}),
            ...(values.role === 'SUB BROKER'
              ? {
                  manager1SubbrokerCode: values.L1ManagerOrRM1Manager,
                  manager2SubbrokerCode: values.L2ManagerOrRM2Manager,
                  subbrokerCode: values.employeeOrSubBrokerId,
                }
              : {}),
            empType: values.role,
          };
        }
        const response = isEditMode
          ? await updateDistributor(payload).unwrap()
          : await addDistributor(payload).unwrap();
        if (cancelButtonHandler) {
          cancelButtonHandler(true);
        }
        showToast((response as { message: string }).message, 'success');
      }
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };
  const resetLaterFields = (
    values: AddUserFields,
    key: keyof AddUserFields,
    fieldValues: Partial<AddUserFields>,
  ): AddUserFields => {
    const index = FIELD_NAMES_ADD_USERS.findIndex(fieldName => fieldName === key);
    const updatedValues = FIELD_NAMES_ADD_USERS.slice(index).reduce<
      Partial<AddUserFields>
    >((acc, curr) => {
      return {
        ...acc,
        [curr]: '',
      };
    }, {});
    return { ...values, ...updatedValues, ...fieldValues };
  };
  return (
    <Box sx={{ padding: !isBigScreen ? '0px' : '0px 25px' }}>
      <Formik
        initialValues={initialValues}
        validationSchema={getValidationSchema(arn)}
        onSubmit={submitButtonHandler}
        innerRef={formikRef}
      >
        {({
          values,
          setFieldValue,
          setFieldTouched,
          errors,
          touched,
          setValues,
          setTouched,
        }) => {
          const clearTouchedFields = () => {
            setTouched({});
          };
          return (
            <Form>
              <Grid
                container
                sx={{ my: 1 }}
                spacing={2}
              >
                {!isEditMode && !isSignUpAdmin && (
                  <Grid
                    item
                    sm={12}
                  >
                    <FormStyledRadioButton
                      options={[
                        { label: 'Employee', value: 'EMPLOYEE' },
                        { label: 'Sub-Broker', value: 'SUB BROKER' },
                      ]}
                      name={'role'}
                      handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const newValues = resetLaterFields(values, 'role', {
                          role: event.target.value,
                        });
                        setValues(newValues);
                        clearTouchedFields();
                      }}
                    />
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormTextInput
                    name='name'
                    label='Name'
                    disabled={isDisabled()}
                    placeholder='Enter your Name'
                    onInput={(e: any) => {
                      if (e.target.value.length > 100) {
                        e.target.value = e.target.value.slice(0, 100);
                      }
                    }}
                    typeOfInput='singleSpace'
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormTextInput
                    name='employeeOrSubBrokerId'
                    typeOfInput='employeeOrSubBroker'
                    disabled={isEditMode}
                    label={values.role === 'EMPLOYEE' ? 'Employee ID' : 'Sub broker ID'}
                    placeholder={
                      values.role === 'EMPLOYEE'
                        ? 'Enter your Employee ID'
                        : 'Enter your Sub-Broker ID'
                    }
                    onBlur={e => {
                      setValues({
                        ...values,
                        employeeOrSubBrokerId: e.target.value.replace(/^-+|-+$/g, ''),
                      });
                      setFieldTouched('employeeOrSubBroker', true);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormTextInput
                    name='department'
                    label='Department'
                    placeholder='Enter your Department'
                    disabled={isEditMode}
                    typeOfInput='singleSpace'
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormTextInput
                    name='mobileNo'
                    type='number'
                    label='Mobile No.'
                    placeholder='Enter your Mobile no.'
                    disabled={isDisabled()}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormTextInput
                    name='email'
                    label='Email ID'
                    placeholder='Enter your Email'
                    disabled={isEditMode}
                  />
                </Grid>

                {!isSignUpAdmin && (
                  <>
                    {' '}
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      position={'relative'}
                    >
                      <L1AndL2ManagerFields
                        textPlaceHolder={'Search L1 Manager Employee ID'}
                        selectedOptionHandler={(
                          L1selectedManager: EmployeeOrSubBroker | null,
                        ) => {
                          const id =
                            L1selectedManager?.empId || L1selectedManager?.subbrokerCode;
                          setFieldValue('L1ManagerOrRM1Manager', id || '');
                          setL1ManagerDefaultValueState(null);
                        }}
                        disabled={isDisabled()}
                        managerDefaultValue={L1ManagerDefaultValueState}
                      />
                      {errors.L1ManagerOrRM1Manager && touched.L1ManagerOrRM1Manager && (
                        <ErrorComponent error={errors.L1ManagerOrRM1Manager} />
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      position={'relative'}
                    >
                      <L1AndL2ManagerFields
                        textPlaceHolder={'Search L2 Manager Employee ID'}
                        selectedOptionHandler={(
                          L2selectedManager: EmployeeOrSubBroker | null,
                        ) => {
                          const id =
                            L2selectedManager?.empId || L2selectedManager?.subbrokerCode;
                          setFieldValue('L2ManagerOrRM2Manager', id || '');
                          setL2ManagerDefaultValueState(null);
                        }}
                        disabled={isDisabled()}
                        managerDefaultValue={L2ManagerDefaultValueState}
                      />
                      {errors.L2ManagerOrRM2Manager && touched.L2ManagerOrRM2Manager && (
                        <ErrorComponent error={errors?.L2ManagerOrRM2Manager} />
                      )}
                    </Grid>
                  </>
                )}
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <FormSelectInput
                    name='state'
                    label='State'
                    disabled={isDisabled()}
                    options={dropDownData.map(item => ({
                      label: item.Name.trim(),
                      value: item.Name.trim(),
                    }))}
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                >
                  <FormTextInput
                    name='branch'
                    label='Branch'
                    disabled={isDisabled()}
                    typeOfInput='address'
                  />
                </Grid>
              </Grid>
              {/* TO SHOW SAVE BUTTON ONLY */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: isEditMode ? 'flex-start' : 'flex-end',
                  columnGap: '20px',
                }}
              >
                {showCancel && (
                  <StepperAddButton
                    sx={{
                      minWidth: { xs: 'unset', sm: '180px' },
                      flex: { xs: 1, sm: 'unset' },
                      fontWeight: 500,
                      my: 3,
                      ...(buttonTitle !== 'Edit'
                        ? {
                            backgroundClip: 'unset',
                            webkitBackgroundClip: 'unset',
                            WebkitTextFillColor: 'unset',
                            backgroundImage: `unset`,
                            color: 'text.primary',
                            border: '1px solid',
                            borderColor: 'text.borderColorLight',
                          }
                        : { color: 'primary.main' }),
                    }}
                    startIcon={buttonTitle === 'Edit' ? <Edit_Icon /> : null}
                    onClick={() => {
                      if (cancelButtonHandler) {
                        cancelButtonHandler(false);
                      }
                    }}
                  >
                    {buttonTitle}
                  </StepperAddButton>
                )}
                {isEditEnabled && (
                  <>
                    <StyledSubmitButton
                      sx={{
                        minWidth: { xs: 'unset', sm: '180px' },
                        flex: { xs: 1, sm: 'unset' },
                      }}
                      label={submitButtonLabel}
                    />
                  </>
                )}
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default AddUsersForm;
