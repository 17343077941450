import { Box, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import VerifiedIcon from '../../../../assets/verified-cart-icon.png';
import { resetAllSlices } from '../../../../store';
import {
  useCreateChangeRequestMutation,
  useGetAssetsQuery,
} from '../../../auth/signup/slices';
import CustomDialog from '../../../common/AllDialogs/CustomDialog';
import CustomLoader from '../../../common/CustomLoader';
import { useRootContext } from '../../../data/root.context';
import FormTextInput, { FormIconInput } from '../../../forms/FormTextInput';
import {
  useLazyGetpersonalDetailsQuery,
  useLazyGetprimaryDetailsQuery,
} from '../../../onboarding/slices';
import EditMobileComp, {
  ChangeMobileNumberComp,
  NewMobileNumberVerification,
} from './EditDistributorProfile';

export interface ProfileData {
  companySpocsName?: string;
  name: string;
  email: string;
  mobile: string;
  assetclass: string;
}

interface DisplayCardProps {
  data: ProfileData;
}

export interface ApiResponse {
  isAdmin: ApiResponse | null;
  token: string;
  isUsername: boolean;
  message: string;
}

const DisplayCard: React.FC<DisplayCardProps> = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast } = useRootContext();
  const [openOtpVerificationDialogBox, setOpenOtpVerificationDialogBox] = useState(false);
  const [changeMobileNoDialogBox, setChangeMobileNoDialogBox] = useState(false);
  const [newOtpVerificationDialog, setNewOtpVerificationDialog] = useState(false);
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [newMobileNumber, setNewMobileNumber] = useState('');
  const [newCountryCode, setNewCountryCode] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [responseMsg, setResponseMsg] = useState('');
  const [type, setType] = useState('mobile');
  const [response, setResponse] = useState<ApiResponse | null>(null);
  const [displayLoader, setDisplayLoader] = useState(false);

  const [getChangeDetails] = useCreateChangeRequestMutation();

  const initialValues = {
    name: data.name || data.companySpocsName,
    email: data.email,
    mobile: data.mobile,
    assetclass: data.assetclass,
  };

  const logoutHandler = () => {
    resetAllSlices(dispatch);
    navigate('/auth/login');
  };

  const handleEditClick = async (type: string) => {
    try {
      setDisplayLoader(true);
      const payload = {
        type: type === 'email' ? 'email' : 'mobile',
      };
      const res = await getChangeDetails(payload).unwrap();
      setResponse(res);
      setDisplayLoader(false);
      setOpenOtpVerificationDialogBox(true);
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
      setDisplayLoader(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          resetForm();
        }}
      >
        <Form>
          <Grid
            container
            spacing={2}
            sx={{ columnGap: { lg: '100px' } }}
          >
            {/* Block 1 */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={5}
            >
              <FormTextInput
                name='name'
                label='Name'
                disabled={true}
              />
            </Grid>
            <Grid
              item
              xs={12}
              lg={5}
              sm={12}
              md={6}
            >
              <FormIconInput
                name='email'
                label='Email Address'
                disabled={true}
                onClick={() => {
                  setType('email');
                  handleEditClick('email');
                }}
                inputIcon={'Edit'}
              />
            </Grid>

            {/* Block 2 */}
            <Grid
              item
              xs={12}
              lg={5}
              sm={12}
              md={6}
            >
              <FormIconInput
                name='mobile'
                label='Mobile Number'
                disabled={true}
                onClick={() => {
                  setType('mobile');
                  handleEditClick('mobile');
                }}
                inputIcon={'Edit'}
              />
            </Grid>
            <Grid
              item
              xs={12}
              lg={5}
              sm={12}
              md={6}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '500',
                  color: 'text.labelColor',
                  mt: '8px',
                }}
              >
                Empaneled Asset class
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: '13px' },
                  fontWeight: '500',
                  color: 'text.primary',
                }}
              >
                {data.assetclass}
              </Typography>
              {/* <FormTextInput name="assetclass" label="Asset Classes" disabled={true} /> */}
            </Grid>
            {displayLoader && (
              <Box>
                <CustomLoader display={displayLoader} />
              </Box>
            )}
          </Grid>
        </Form>
      </Formik>
      <CustomDialog
        maxWidth={'sm'}
        isVisible={openOtpVerificationDialogBox}
        handleClose={() => setOpenOtpVerificationDialogBox(false)}
        firstButtonHandler={() => {}}
        showFirstButton={false}
        showSecondButton={false}
        firstButtonTitle={''}
      >
        <EditMobileComp
          data={data}
          type={type}
          response={response}
          setOpenOtpVerificationDialogBox={setOpenOtpVerificationDialogBox}
          setChangeMobileNoDialogBox={setChangeMobileNoDialogBox}
        />
      </CustomDialog>
      <CustomDialog
        maxWidth={'sm'}
        isVisible={changeMobileNoDialogBox}
        handleClose={() => setChangeMobileNoDialogBox(false)}
        firstButtonHandler={() => {}}
        showFirstButton={false}
        showSecondButton={false}
        firstButtonTitle={''}
      >
        <ChangeMobileNumberComp
          setChangeMobileNoDialogBox={setChangeMobileNoDialogBox}
          setNewOtpVerificationDialog={setNewOtpVerificationDialog}
          setNewMobileNumber={setNewMobileNumber}
          setNewCountryCode={setNewCountryCode}
          setNewEmail={setNewEmail}
          type={type}
          response={response}
        />
      </CustomDialog>
      <CustomDialog
        maxWidth={'sm'}
        isVisible={newOtpVerificationDialog}
        handleClose={() => setNewOtpVerificationDialog(false)}
        firstButtonHandler={() => {}}
        showFirstButton={false}
        showSecondButton={false}
        firstButtonTitle={''}
      >
        <NewMobileNumberVerification
          setNewOtpVerificationDialog={setNewOtpVerificationDialog}
          newMob={newMobileNumber}
          newCountryCode={newCountryCode}
          newEmail={newEmail}
          setOpenSuccessPopup={setOpenSuccessPopup}
          type={type}
          response={response}
          setResponseMsg={setResponseMsg}
        />
      </CustomDialog>
      <CustomDialog
        maxWidth={'sm'}
        isVisible={openSuccessPopup}
        secondButtonHandler={() => {
          setOpenSuccessPopup(false);
          logoutHandler();
        }}
        secondButtonTitle='Ok & Logout'
        firstButtonTitle=''
        showFirstButton={false}
        showSecondButton
        handleClose={() => {}}
        firstButtonHandler={() => {}}
      >
        <Box
          sx={{
            'textAlign': 'center',
            'pt': 2,
            '& .MuiTypography-root': {
              'fontSize': '18px',
              'fontWeight': '500',
              'opacity': '0.85',
              'my': 2,
              '& >*': {
                fontWeight: '600',
              },
            },
          }}
        >
          <img
            src={VerifiedIcon}
            alt='verified-icon'
          />

          <Typography variant='body1'>{responseMsg}</Typography>
        </Box>
      </CustomDialog>
    </>
  );
};

interface PersonalDetailsProps {
  fromdistrubutorProfile: boolean;
  individual: boolean;
}
const PersonalDetails: React.FC<PersonalDetailsProps> = props => {
  const { fromdistrubutorProfile, individual } = props;
  const [profileData, setProfileData] = useState<ProfileData>({
    name: '',
    email: '',
    mobile: '',
    assetclass: '',
  });
  const [getpersonalDetails, personalDetailsValues] = useLazyGetpersonalDetailsQuery();
  const [getprimaryDetails, primaryDetailsValues] = useLazyGetprimaryDetailsQuery();
  const { data: allAssets, isLoading } = useGetAssetsQuery([]);
  const assetclassRef = useRef<string | null>(null);

  const { showToast } = useRootContext();
  useEffect(() => {
    if (allAssets && !isLoading) {
      const trueKeys = Object.keys(allAssets).filter(key => allAssets[key]);
      const uppercasedtrueKeys = trueKeys.map(str => str.toUpperCase());
      const assetClasses = uppercasedtrueKeys.join(', ');
      assetclassRef.current = assetClasses;

      setProfileData({ ...profileData, assetclass: assetClasses });
    }
  }, [isLoading, personalDetailsValues.isSuccess, primaryDetailsValues.isSuccess]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (individual) {
          await getpersonalDetails('');
        } else {
          await getprimaryDetails('');
        }
      } catch (error: any) {
        showToast((error.data as { message: string }).message, 'error');
      }
    };

    fetchData();
  }, [individual]);

  //individual
  useEffect(() => {
    if (
      individual &&
      personalDetailsValues &&
      personalDetailsValues.isSuccess &&
      !personalDetailsValues.isFetching
    ) {
      // setProfileData(prevProfileData => ({
      //     ...personalDetailsValues.data,
      //     assetclass: assetclassRef.current,
      // }));
      const profileDatafinal = {
        ...personalDetailsValues.data,
        assetclass: assetclassRef.current,
      };
      setProfileData(profileDatafinal);
    }
  }, [personalDetailsValues]);

  //company
  useEffect(() => {
    if (
      !individual &&
      primaryDetailsValues &&
      primaryDetailsValues.isSuccess &&
      !primaryDetailsValues.isFetching
    ) {
      const profileDatafinal = {
        ...primaryDetailsValues.data,
        assetclass: assetclassRef.current,
      };
      setProfileData(profileDatafinal);
    }
  }, [primaryDetailsValues]);
  return (
    <>
      <DisplayCard
        data={profileData}
        key={profileData.email}
      />
    </>
  );
};

export default PersonalDetails;
