export type Filter = {
  email?: string;
  mobile?: string;
  amc?: string;
  schemeOption?: 'R' | 'D';
  ifsc?: boolean;
};

export enum DetailType {
  Scheme,
  Investor,
  InvestorBank,
}

export type BankFormValues = {
  accountNo: string;
  confirmAccountNo?: string;
  defaultBankAccount?: any;
  ifsc: string;
  name: string;
  accountType: string;
  branch: string;
  city: string;
  pincode: string;
};
