import { Box, Grid, Typography } from '@mui/material';

import SwitchFromIcon from '../../../assets/switch-from.svg';
import SwitchToIcon from '../../../assets/switch-to.svg';
import { inrCurrencyFormatter2 } from '../utils';

export const FormData = ({
  label,
  value,
  children,
}: {
  label: string;
  value: any;
  children?: React.ReactNode;
}): JSX.Element => {
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '10px', mb: 1.5 }}>
        {children}
        <Typography
          sx={{
            color: 'text.labelColor',
            fontWeight: 400,
            fontSize: { xs: '14px', xl: '16px' },
          }}
        >
          {label}
        </Typography>
      </Box>
      <Typography
        sx={{
          color: 'text.valueColor',
          fontSize: { xs: '14px', xl: '16px' },
          fontWeight: 500,
        }}
      >
        {value}
      </Typography>
    </>
  );
};

const Confirmation = ({ data }: { data: any }) => {
  return (
    <>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sx={{ mb: 1.5 }}
        >
          <FormData
            label='Scheme From'
            value={data.switchOutSchemeName}
          >
            <img
              src={SwitchFromIcon}
              alt={'switch-from-icon'}
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </FormData>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mb: 1.5 }}
        >
          <FormData
            label='Scheme To'
            value={data.switchInSchemeName}
          >
            <img
              src={SwitchToIcon}
              alt={'switch-to-icon'}
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </FormData>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{ mb: 1.5 }}
        >
          <FormData
            label={data.units !== 0 ? 'Switch Units' : 'Switch Amount'}
            value={data.units !== 0 ? data.units : inrCurrencyFormatter2(data.amount)}
          />
        </Grid>
        {/* <Grid
        item
        xs={6}
        sx={{ mb: 1.5 }}
      >
        <FormData
          label=' Exit Load'
          value={'₹1'}
        />
      </Grid> */}
      </Grid>
    </>
  );
};

export default Confirmation;
