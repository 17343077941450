import { Grid, IconButton, Stack, Theme, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { ReactComponent as BackIcon } from '../../../../assets/back-icon-large.svg';
import {
  Investorcard,
  TransactionHeader,
} from '../../../auth/login/styles/styledComponents';
import CustomDialog from '../../../common/AllDialogs/CustomDialog';
import { useRootContext } from '../../../data/root.context';
import { FormSelectInput } from '../../../forms/FormSelect';
import SubmitButton from '../../../forms/FormSubmitButton';
import FormTextInput from '../../../forms/FormTextInput';
import { emailRegex } from '../../regex';
import { useLazyAllSchemeDetailsQuery, useSendKycMailtoInvestorMutation } from './slice';

interface KycDetails {
  kycType: string;
  amc: string;
  pan: string;
  name: string;
  emailId: string;
  countryCode: string;
  mobileNo: string;
}

const KycDetailsForm: React.FC = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { showToast } = useRootContext();
  const [allAmcOptions, setAllAmcOptions] = useState<any[]>([]);
  const [openDialogBox, setOpenDialogBox] = useState(false);

  const [getSchemes, getAllFundResponse] = useLazyAllSchemeDetailsQuery();
  const [sendKycMail] = useSendKycMailtoInvestorMutation();

  const initialValues: KycDetails = {
    kycType: '',
    amc: '',
    pan: state.pan || '',
    name: '',
    emailId: '',
    countryCode: '+91',
    mobileNo: '',
  };

  const validationSchema = Yup.object().shape({
    kycType: Yup.string().required('kyc Type is required'),
    amc: Yup.string().required('AMC is required'),
    pan: Yup.string().required('PAN is required'),
    name: Yup.string().required('Name is required'),
    emailId: Yup.string()
      .required('email Id is required')
      .matches(emailRegex, 'email must be a valid email'),
    mobileNo: Yup.string()
      .required('Mobile number is required')
      .matches(/^[6-9]{1}[0-9]{9}$/, 'Invalid Mobile Number'),
  });

  const handleSubmit = async (values: KycDetails, { resetForm }: FormikHelpers<any>) => {
    const kycPayload = {
      pan: values.pan,
      name: values.name,
      fund: values.amc,
      countrycode: values.countryCode,
      email: values.emailId,
      mobile: values.mobileNo,
    };

    try {
      const response = await sendKycMail(kycPayload).unwrap();
      if (response.message === 'Email sent successfully') {
        setOpenDialogBox(true);
        resetForm({
          values: {
            ...values,
            name: '',
            fund: '',
            countrycode: values.countryCode,
            email: '',
            mobile: '',
          },
        });
      }
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    }
  };

  const onContinueButtonClick = async (
    values: KycDetails,
    setValues: (values: KycDetails) => void,
    setErrors: (errors: FormikErrors<KycDetails>) => void,
  ) => {
    setOpenDialogBox(false);
    setValues({
      ...values,
      kycType: '',
      amc: '',
      name: '',
      emailId: '',
      mobileNo: '',
    });
    setErrors({
      kycType: '',
      amc: '',
      name: '',
      emailId: '',
      mobileNo: '',
    });
  };

  useEffect(() => {
    if (
      getAllFundResponse &&
      getAllFundResponse.isSuccess &&
      !getAllFundResponse.isFetching
    ) {
      const getAllSchemes = async () => {
        const allFunds = getAllFundResponse.data;
        const options = allFunds.map((item: any) => {
          return { label: item.fundName, value: item.fund };
        });
        setAllAmcOptions(options);
      };
      getAllSchemes();
    }
  }, [getAllFundResponse]);

  useEffect(() => {
    try {
      getSchemes('');
    } catch (error: any) {
      showToast(error?.data?.message, 'error');
    }
  }, []);

  return (
    <>
      <Stack
        direction='row'
        alignItems='center'
      >
        <IconButton sx={{ mb: 2, p: { xs: '0' } }}>
          <BackIcon
            onClick={() => {
              navigate('../new-investor-onboarding');
            }}
          />
        </IconButton>
        <TransactionHeader sx={{ mb: 2, ml: 1 }}>e-KYC Form</TransactionHeader>
      </Stack>
      <Investorcard
        sx={(theme: Theme) => ({
          padding: '35px 35px',
          [theme.breakpoints.down('sm')]: {
            boxShadow: 'unset',
            border: 'unset',
            padding: '0px',
          },
        })}
      >
        <Typography
          sx={{
            color: 'primary.main',
            fontSize: { xs: '14px', sm: '16px', xl: '18px' },
            fontWeight: 500,
            mb: 2,
          }}
        >
          {' '}
          Please enter the details to complete KYC
        </Typography>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setValues, setErrors, setFieldError }) => (
            <Form>
              <Grid
                container
                spacing={2}
                sx={{ columnGap: { xs: 'unset', md: '100px' } }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={5}
                >
                  <FormSelectInput
                    name='kycType'
                    label='KYC Type'
                    defaultValue={''}
                    options={[
                      {
                        label: 'Online IPV with Esign',
                        value: 'Online IPV with Esign',
                      },
                    ]}
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={5}
                >
                  <FormSelectInput
                    name='amc'
                    label='Select AMC'
                    defaultValue={''}
                    options={allAmcOptions}
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={5}
                >
                  <FormTextInput
                    name='pan'
                    label='PAN'
                    disabled
                    typeOfInput='alphanumeric'
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={5}
                >
                  <FormTextInput
                    name='name'
                    label='Name'
                    typeOfInput='singleSpace'
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={5}
                >
                  <FormTextInput
                    name='emailId'
                    label='Email ID'
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={5}
                >
                  <Stack flexDirection='row'>
                    <Box
                      sx={{
                        'width': { xs: '20%' },
                        '& .MuiInputBase-input ': {
                          borderRadius: '7px 0px 0px 7px',
                          borderRight: '0',
                        },
                      }}
                    >
                      <FormTextInput
                        name='countryCode'
                        disabled
                        required={false}
                        label='Country Code'
                      />
                    </Box>
                    <Box
                      sx={{
                        'width': { xs: '80%' },
                        '& .MuiInputBase-input ': {
                          borderRadius: '0px 7px 7px 0px',
                        },
                      }}
                    >
                      <FormTextInput
                        name='mobileNo'
                        label='Enter mobile number'
                        typeOfInput='number'
                      />
                    </Box>
                  </Stack>
                </Grid>
                <Grid
                  item
                  md={11}
                  xs={12}
                >
                  <Box
                    sx={{
                      'borderRadius': '7px',
                      'bgcolor': 'text.borderColorDark',
                      'padding': '20px 20px',
                      'mb': 3,
                      '& .MuiTypography-root': {
                        'color': 'text.labelColor',
                        'position': 'relative',
                        'ml': 2,
                        '&:not(last-child)': {
                          mb: 0.75,
                        },
                        '&::before': {
                          position: 'absolute',
                          content: '" "',
                          top: '8px',
                          left: '-16px',
                          height: '4px',
                          bgcolor: 'text.labelColor',
                          width: '4px',
                          borderRadius: '100%',
                        },
                      },
                    }}
                  >
                    <Typography>
                      This process will send and email to your Client for completing the
                      KYC process
                    </Typography>
                    <Typography>
                      Your Client has to upload relevant documents and record a video of
                      self by showing the documents
                    </Typography>
                    <Typography>
                      Please Inform and guide your client accordingly
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ display: 'flex', mt: 3 }}>
                <SubmitButton
                  sx={(theme: Theme) => ({
                    '&.MuiButtonBase-root': {
                      borderRadius: '5px',
                      minWidth: '200px',

                      [theme.breakpoints.down('sm')]: {
                        minWidth: '100%',
                      },
                    },
                  })}
                  label='Submit'
                />
              </Box>
              <CustomDialog
                isVisible={openDialogBox}
                firstButtonHandler={() => setOpenDialogBox(false)}
                secondButtonHandler={async () =>
                  onContinueButtonClick(values, setValues, setErrors)
                }
                firstButtonTitle='Cancel'
                secondButtonTitle='Continue'
                handleClose={() => {
                  setOpenDialogBox(false);
                }}
                showSecondButton={true}
              >
                <Typography
                  sx={{
                    fontWeight: 400,
                    color: 'text.labelColor',
                    my: 3,
                    fontSize: { xs: '16px', xl: '18px' },
                  }}
                >
                  An email will be triggered to investors email for uploading necessary
                  KYC documents and selfie video. please confirm
                </Typography>
              </CustomDialog>
            </Form>
          )}
        </Formik>
      </Investorcard>
    </>
  );
};

export default KycDetailsForm;
