import { Box, Divider, Grid, Stack, Theme, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Investorcard } from '../../../../auth/login/styles/styledComponents';
import { PageNavigator } from '../../../../common/BackToHomePage';
import { startLoading, stopLoading } from '../../../../common/Loader/loaderSlice';
import { useRootContext } from '../../../../data/root.context';
import { LoginResponse } from '../../../../features/login/loginSlice';
import FormCheckbox from '../../../../forms/FormCheckbox';
import FormDateInput from '../../../../forms/FormDateInput';
import { FormSelectInput } from '../../../../forms/FormSelect';
import { FormStyledRadioButton } from '../../../../forms/FormStyledRadioButton';
import SubmitButton from '../../../../forms/FormSubmitButton';
import FormTextInput from '../../../../forms/FormTextInput';
import EuinsFields from '../../../euinsComponent';
import {
  useGetEnachAotmDetailsMutation,
  // useGetEUINSQuery,
  useGetSchemaDetailsMutation,
  useGetSipStartandEndDatesMutation,
} from '../../../slice';
import { findLogo, handlePerpetualSipBoxChnage } from '../../../utils';
import { setInvestmentDetails } from '../context/NewMfsInvestorAction';
import { useNewMfsInvestorContext } from '../context/NewMfsInvestorContext';
import { useAllPaymentModesMutation } from '../slice';
import { mapFrequency } from '../utils';

interface Option {
  value: any;
  label: any;
}

interface InvestmentDetails {
  arnCode: string;
  subArnCode: string;
  subBrokerCode: string;
  euin: string;
  modeOfTransaction: string;
  investmentAmount: string;
  frequency: string;
  sipDay: string;
  numberOfInstalments: string;
  startDate: Date | null;
  endDate: Date | null;
  lumpsumAmount: string;
  perpetual: boolean;
  modeOfRegistration: string;
}

const MfsInvestmentDetails: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [schemaData, setSchemaData] = useState<any>({});
  const [lumpsumMinAmt, setLumpsumMinAmt] = useState<any>(0);
  const { showToast } = useRootContext();
  const [sipFrequencyOption, setSipFrequencyOptions] = useState<Option[]>([]);
  const [sipDays, setSipDays] = useState<Option[]>([]);
  const [isPanBasedAotm, setIsPanBasedAotm] = useState(false);
  const [paymentModeOptions, setPaymnetModeOptions] = useState<any[]>([]);
  const [selectedFrequencyAndSip, setSelectedFrequencyAndSip] = useState<{
    selectedFrequency: any;
    selectedSipDay: any;
    noOfInstallments: number;
  }>({
    selectedFrequency: null,
    selectedSipDay: null,
    noOfInstallments: 0,
  });
  const { arn, euin, subArn, subbrokerCode } = useSelector(
    (state: { login: LoginResponse }) => state.login,
  );

  const [getSipStartandEndDates] = useGetSipStartandEndDatesMutation();

  const {
    dispatch: contextDispatch,
    state: { schemeDetails, fundName, investmentDetails },
  } = useNewMfsInvestorContext();

  const [getSchemaDetails] = useGetSchemaDetailsMutation();
  const [getAllPaymentModes] = useAllPaymentModesMutation();
  const [getAotmDetails] = useGetEnachAotmDetailsMutation();
  // const { data: allEUINs } = useGetEUINSQuery([]);

  const { schemeDesc, planMode, optionDesc } = schemeDetails || {};

  React.useEffect(() => {
    const getPaymentOptions = async () => {
      const payload = {
        fund: schemeDetails?.fund,
      };
      const response = await getAllPaymentModes(payload).unwrap();
      const options = Object.entries(response)
        .map(([key, value]) => {
          if (value === 'Y') {
            return { label: key?.toUpperCase(), value: key?.toUpperCase() };
          }
          return null;
        })
        .filter(Boolean);
      setPaymnetModeOptions(options);
    };
    getPaymentOptions();
  }, []);

  React.useEffect(() => {
    const getAotmStatus = async () => {
      try {
        const response: any = await getAotmDetails({
          fund: schemeDetails?.fund,
        }).unwrap();

        const { enach_aotm_basedon } = response;
        setIsPanBasedAotm(enach_aotm_basedon.toUpperCase() === 'PAN');
      } catch (error: any) {
        const message =
          (error as any).data?.message || (error as any).message || 'Unknown error';
        showToast(message, 'error');
      }
    };
    getAotmStatus();
  }, []);

  const initialValues: InvestmentDetails = {
    arnCode: investmentDetails?.arnCode || arn || 'NA',
    subArnCode: investmentDetails?.subArnCode || subArn || '',
    subBrokerCode: investmentDetails?.subBrokerCode || subbrokerCode || '',
    euin: investmentDetails?.euin || euin || '',
    modeOfTransaction: investmentDetails?.modeOfTransaction || '',
    investmentAmount: investmentDetails?.investmentAmount || '',
    frequency: investmentDetails?.frequency || '',
    sipDay: investmentDetails?.sipDay || '',
    numberOfInstalments: investmentDetails?.numberOfInstalments || '',
    startDate: investmentDetails?.startDate || null,
    endDate: investmentDetails?.endDate || null,
    lumpsumAmount: investmentDetails?.lumpsumAmount || '',
    perpetual: investmentDetails?.perpetual || '',
    modeOfRegistration: investmentDetails?.lumpsumAmount || '',
  };

  const validationSchema = Yup.object().shape({
    modeOfTransaction: Yup.string().required('Mode of Transaction is required'),
    arnCode: Yup.string().required('ARN code is required'),
    subArnCode: Yup.string().test(
      'subArnCode',
      'Sub ARN code cannot be the same as ARN code',
      function (value) {
        const { arnCode } = this.parent;
        return value !== arnCode;
      },
    ),
    subBrokerCode: Yup.string().matches(
      /^[a-zA-Z0-9]+$/,
      'Sub broker code must contain only alphabets and numbers',
    ),
    investmentAmount: Yup.string()
      .required('Investment Amount is required')
      .min(1, 'Minimum investment amount should not be 0')
      .matches(/^[0-9]+$/, 'Investment Amount must contain only numbers'),
    frequency: Yup.string().when('modeOfTransaction', {
      is: (modeOfTransaction: string) =>
        modeOfTransaction === 'SIP' || modeOfTransaction === 'Lumpsum + SIP',
      then: Yup.string().required('SIP frequency is required'),
    }),
    sipDay: Yup.string().when('modeOfTransaction', {
      is: (modeOfTransaction: string) =>
        modeOfTransaction === 'SIP' || modeOfTransaction === 'Lumpsum + SIP',
      then: Yup.string().required('SIP Day is required'),
    }),
    numberOfInstalments: Yup.string().when('modeOfTransaction', {
      is: (modeOfTransaction: string) =>
        modeOfTransaction === 'SIP' || modeOfTransaction === 'Lumpsum + SIP',
      then: Yup.string()
        .required('Number of Installment is required')
        .matches(/^[0-9]+$/, 'Number of installments must contain only numbers'),
    }),
    startDate: Yup.date()
      .typeError('Invalid Date')
      .nullable()
      .when('modeOfTransaction', {
        is: (modeOfTransaction: string) =>
          modeOfTransaction === 'SIP' || modeOfTransaction === 'Lumpsum + SIP',
        then: Yup.date().typeError('Invalid Date').required('Start date is required'),
      }),
    endDate: Yup.date()
      .typeError('Invalid Date')
      .nullable()
      .when('modeOfTransaction', {
        is: (modeOfTransaction: string) =>
          modeOfTransaction === 'SIP' || modeOfTransaction === 'Lumpsum + SIP',
        then: Yup.date().typeError('Invalid Date').required('End date is required'),
      }),
    lumpsumAmount: Yup.string().when('modeOfTransaction', {
      is: 'Lumpsum + SIP',
      then: Yup.string()
        .required('Lumpsum Amount is required')
        .min(1, 'Minimum investment amount should not be 0')
        .matches(/^[0-9]+$/, 'Investment Amount must contain only numbers'),
    }),
  });

  const handleModeOfTransactionChange = (
    setFieldValue: (field: string, value: any) => void,
    setFieldError: (field: string, message: string | undefined) => void,
  ) => {
    setFieldError('investmentAmount', '');
    setFieldError('frequency', '');
    setFieldError('sipDay', '');
    setFieldError('numberOfInstalments', '');
    setFieldError('startDate', '');
    setFieldError('endDate', '');
    setFieldError('lumpsumAmount', '');

    setFieldValue('investmentAmount', '');
    setFieldValue('frequency', '');
    setFieldValue('sipDay', '');
    setFieldValue('numberOfInstalments', '');
    setFieldValue('startDate', null);
    setFieldValue('endDate', null);
    setFieldValue('lumpsumAmount', '');
    setFieldValue('modeOfRegistration', '');
    setFieldValue('perpetual', false);
  };

  const handleSubmit = (values: InvestmentDetails) => {
    contextDispatch(setInvestmentDetails(values));
    navigate('../new-investor-onboarding');
  };

  const validateMinInvestmentAmount = (values: InvestmentDetails) => {
    const errors: Record<string, any> = {};
    const minAmount = schemaData?.minTransactionAmount;

    const selectedFrequency = schemaData?.frequency?.find(
      (item: any) => mapFrequency(item.mode?.toUpperCase()) === values.frequency,
    );
    const minInstallments = selectedFrequency?.minInstallments || '';
    const minSipAmount = selectedFrequency?.minTransactionAmount;
    const isNjAmc =
      schemeDetails?.fund === '187'
        ? Number(values.lumpsumAmount) < lumpsumMinAmt
        : Number(values.lumpsumAmount) < minSipAmount;

    if (Number(values.investmentAmount) < minAmount) {
      errors.investmentAmount = `Minimum Investment Amount should be ${minAmount}`;
    }
    if (Number(values.investmentAmount) < minSipAmount) {
      errors.investmentAmount = `Minimum Investment Amount should be ${minSipAmount}`;
    }
    if (values.modeOfTransaction === 'Lumpsum + SIP' && isNjAmc) {
      errors.lumpsumAmount = `Minimum Lumpsum Amount should be ${
        schemeDetails?.fund === '187' ? lumpsumMinAmt : minSipAmount
      }`;
    }
    if (Number(values.numberOfInstalments) < minInstallments) {
      errors.numberOfInstalments = `Number of installments should be ${minInstallments}`;
    }
    return errors;
  };

  const handleSipFrequencyChange = (
    e: any,
    setFieldValue: (field: string, value: any) => void,
  ) => {
    setFieldValue('frequency', e.target.value);
    setSelectedFrequencyAndSip({
      ...selectedFrequencyAndSip,
      selectedFrequency: e.target.value,
      noOfInstallments: 0,
    });
    const sipCycle = schemaData?.frequency?.find(
      (item: any) => mapFrequency(item.mode.toUpperCase()) === e.target.value,
    );
    if (sipCycle) {
      const cycleArray = sipCycle.cycle?.split(',') || [];
      let sipDays = [];
      const isNumericSequence = cycleArray.every(
        (day: { trim: () => number }) => !isNaN(day.trim()),
      );
      if (!isNumericSequence) {
        sipDays = Array.from({ length: 28 }, (_, i) => {
          const day = (i + 1).toString();
          return {
            label: day,
            value: day,
          };
        });
      } else {
        sipDays =
          cycleArray?.map((day: string) => {
            const dayWithoutLeadingZero = parseInt(day, 10).toString();
            return {
              label: dayWithoutLeadingZero,
              value: dayWithoutLeadingZero,
            };
          }) || [];
      }
      setSipDays(sipDays);
    }
  };

  const handleModeOfRegistrationChange = (
    e: any,
    setFieldValue: (field: string, value: any) => void,
    values: InvestmentDetails,
  ) => {
    setFieldValue('modeOfRegistration', e?.target?.value);

    if (values.modeOfRegistration !== '') {
      setFieldValue('frequency', '');
      setFieldValue('sipDay', '');
      setFieldValue('numberOfInstalments', '');
      setFieldValue('startDate', null);
      setFieldValue('endDate', null);
      setFieldValue('perpetual', false);
    }
  };

  const amcLogo = findLogo(schemeDetails?.fund);

  const handleSipDayChange = async (
    e: any,
    values: InvestmentDetails,
    setFieldValue: any,
  ) => {
    try {
      dispatch(startLoading());
      const dayValue = e.target.value;
      const payload = {
        tenant: 'IRIS',
        trtype: 'SINI',
        folio: 0,
        fund: schemeDetails?.fund,
        scheme: schemeDetails?.scheme,
        investorStatus: '',
        investorDob: '',
        modeofregistration: values.modeOfRegistration,
        frequency: values.frequency,
        installments: Number(values.numberOfInstalments),
        day: Number(dayValue),
      };
      const response = await getSipStartandEndDates(payload).unwrap();
      if (response) {
        setFieldValue('startDate', response?.startDate);
        setFieldValue('endDate', response?.endDate);
      } else {
        setFieldValue('sipDay', '');
      }
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
      setFieldValue('sipDay', '');
      e.target.value = '';
    } finally {
      dispatch(stopLoading());
    }
  };

  return (
    <>
      <PageNavigator
        title='Investment Details'
        subtitle='Please enter the investment details'
        sx={(theme: Theme) => ({
          [theme.breakpoints.down('md')]: {
            mt: 0,
          },
        })}
      />
      <Investorcard
        sx={(theme: Theme) => ({
          padding: {
            xs: '0px',
            sm: '35px 35px',
            [theme.breakpoints.down('sm')]: {
              boxShadow: 'unset',
              border: 'unset',
            },
          },
        })}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
          validate={validateMinInvestmentAmount}
        >
          {({ values, setFieldValue, setFieldError, errors }) => {
            const selectedFrequency = schemaData?.frequency?.find(
              (item: any) => mapFrequency(item.mode?.toUpperCase()) === values.frequency,
            );
            const minInstallments = selectedFrequency?.minInstallments || '';
            const sipMinAmount = selectedFrequency?.minTransactionAmount || '';
            return (
              <Form>
                <Typography
                  sx={{
                    fontSize: {
                      xs: '14px',
                      sm: '16px',
                      xl: '18px',
                    },
                    fontWeight: 500,
                    color: { xs: 'text.valueColor', sm: 'primary.main' },
                    mb: 2,
                  }}
                >
                  Distributor Details
                </Typography>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                  >
                    <FormTextInput
                      name='arnCode'
                      label='ARN Code'
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                  >
                    <FormTextInput
                      name='subArnCode'
                      label='Sub ARN Code'
                      required={false}
                      disabled //TODO: Need to change subArn and subBroker disabled condtion based on user login.
                      typeOfInput='alphanumeric'
                    />
                  </Grid>
                  <Grid
                    item
                    sm={4}
                    md={3}
                    xs={12}
                  >
                    <FormTextInput
                      name='subBrokerCode'
                      label='Sub Broker Code'
                      required={false}
                      disabled
                      typeOfInput='alphanumeric'
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <EuinsFields
                      name='euin'
                      // allEUINs={allEUINs}
                      initialValue='yes'
                      defaultValue={values?.euin}
                    />
                  </Grid>
                </Grid>

                <Divider
                  orientation='horizontal'
                  variant='fullWidth'
                  flexItem
                  sx={{
                    border: '1px dashed ',
                    borderColor: 'text.borderColorDark',
                    my: 3,
                  }}
                />

                <Typography
                  sx={{
                    fontSize: {
                      xs: '14px',
                      sm: '16px',
                      xl: '18px',
                    },
                    fontWeight: 500,
                    color: { xs: 'text.valueColor', sm: 'primary.main' },
                    mb: 2,
                  }}
                >
                  Mode of Transaction *
                </Typography>
                <FormStyledRadioButton
                  options={[
                    { label: 'Lumpsum', value: 'Lumpsum' },
                    { label: 'SIP', value: 'SIP' },
                    { label: 'Lumpsum + SIP', value: 'Lumpsum + SIP' },
                  ].filter(mode =>
                    schemeDetails?.sipAllow && schemeDetails?.newPurAllow
                      ? true
                      : schemeDetails?.sipAllow
                      ? mode.value === 'SIP'
                      : mode.value === 'Lumpsum',
                  )}
                  name='modeOfTransaction'
                  handleChange={async (e: any) => {
                    try {
                      dispatch(startLoading());
                      const payload = {
                        fund: schemeDetails?.fund,
                        scheme: schemeDetails?.scheme,
                        plan: schemeDetails?.plan,
                        option: schemeDetails?.option,
                        trType: e.target.value == 'Lumpsum' ? 'NPL' : 'SIP',
                      };
                      const result: any = await getSchemaDetails(payload).unwrap();
                      setSchemaData(result);
                      if (result?.frequency?.length === 0) {
                        showToast('Scheme is not allowed for transaction', 'error');
                      }
                      if (result?.frequency) {
                        const uniqueModes = result.frequency
                          .map((option: { mode: any }) => option.mode)
                          .filter(
                            (mode: any, index: any, self: string | any[]) =>
                              self.indexOf(mode) === index,
                          );
                        const sipFrequency = uniqueModes.map((mode: any) => ({
                          label: mapFrequency(mode.toUpperCase()),
                          value: mapFrequency(mode.toUpperCase()),
                        }));
                        setSipFrequencyOptions(sipFrequency);
                      }
                      if (
                        e.target.value === 'Lumpsum + SIP' &&
                        schemeDetails?.fund === '187'
                      ) {
                        const resLumpsum: any = await getSchemaDetails({
                          fund: schemeDetails?.fund,
                          scheme: schemeDetails?.scheme,
                          plan: schemeDetails?.plan,
                          option: schemeDetails?.option,
                          trType: 'NPL',
                        }).unwrap();
                        setLumpsumMinAmt(resLumpsum?.minTransactionAmount);
                      }
                    } catch (error: any) {
                      showToast(
                        (error as { message: string })?.message ||
                          error?.data?.message ||
                          'Unknown error',
                        'error',
                      );
                    } finally {
                      dispatch(stopLoading());
                    }
                    handleModeOfTransactionChange(setFieldValue, setFieldError);
                  }}
                />

                <Divider
                  orientation='horizontal'
                  variant='fullWidth'
                  flexItem
                  sx={{
                    border: '1px dashed ',
                    borderColor: 'text.borderColorDark',
                    my: 3,
                  }}
                />
                <Typography
                  sx={{
                    fontSize: {
                      xs: '14px',
                      sm: '16px',
                      xl: '18px',
                    },
                    fontWeight: 500,
                    color: { xs: 'text.valueColor', sm: 'primary.main' },
                    mb: 2,
                  }}
                >
                  Scheme Details
                </Typography>
                <Box
                  sx={{
                    bgcolor: 'text.boxColor',
                    mx: { xs: '-20px', sm: 'unset' },
                    borderRadius: { xs: 'unset', sm: '10px' },
                    padding: { xs: '20px 20px', md: '35px 35px' },
                    mb: 2,
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={1}
                      lg={0.7}
                    >
                      <Stack
                        sx={{
                          flexDirection: { xs: 'row', sm: 'unset' },
                          columnGap: { xs: '15px', sm: 'unset' },
                        }}
                      >
                        <Box
                          sx={{
                            width: '42px',
                            height: '42px',
                            borderRadius: '5px',
                            border: '0.5px solid',
                            borderColor: 'text.borderColorLight',
                            boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.05)',
                            padding: '4px 4px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {amcLogo?.icon && <amcLogo.icon />}
                        </Box>
                        <Box sx={{ display: { sm: 'none' } }}>
                          <Typography
                            sx={{
                              fontSize: { xs: '12px', sm: '14px' },
                              fontWeight: 500,
                              color: 'text.labelColor',
                              mb: 1,
                            }}
                          >
                            {fundName}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: { xs: '14px', sm: '16px', xl: '18px' },
                              fontWeight: 500,
                              color: 'text.valueColor',
                            }}
                          >
                            {`${schemeDesc} - ${planMode} - ${optionDesc}`}
                          </Typography>
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={11}
                      lg={11.3}
                    >
                      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <Typography
                          sx={{
                            fontSize: { xs: '12px', sm: '14px' },
                            fontWeight: 500,
                            color: 'text.labelColor',
                            mb: 1,
                          }}
                        >
                          {fundName}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { xs: '14px', sm: '16px', xl: '18px' },
                            fontWeight: 500,
                            color: 'text.valueColor',
                          }}
                        >
                          {`${schemeDesc} - ${planMode} - ${optionDesc}`}
                        </Typography>
                      </Box>

                      <Grid
                        container
                        spacing={2}
                        sx={{ mt: { xs: '0px', sm: '26px' } }}
                        // columnGap={'55px'}
                      >
                        {values?.modeOfTransaction !== '' &&
                          values?.modeOfTransaction !== 'Lumpsum' && (
                            <>
                              <Grid
                                item
                                sm={6}
                                lg={4}
                                xs={12}
                              >
                                <Typography
                                  sx={{
                                    color: 'text.labelColor',
                                    mb: 0.5,
                                    fontWeight: 500,
                                    fontSize: { xs: '14px', xl: '16px' },
                                  }}
                                >
                                  Mode of registration
                                </Typography>
                                <FormSelectInput
                                  name='modeOfRegistration'
                                  label='Mode Of Registration'
                                  defaultValue={''}
                                  required
                                  options={
                                    isPanBasedAotm
                                      ? paymentModeOptions.filter(
                                          item =>
                                            item.label !== 'NETBANKING' &&
                                            item.label !== 'UPI',
                                        )
                                      : paymentModeOptions.filter(
                                          item =>
                                            item.label !== 'NETBANKING' &&
                                            item.label !== 'UPI' &&
                                            item.label !== 'AOTM',
                                        )
                                  }
                                  onChange={e =>
                                    handleModeOfRegistrationChange(
                                      e,
                                      setFieldValue,
                                      values,
                                    )
                                  }
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                              >
                                <Typography
                                  sx={{
                                    color: 'text.labelColor',
                                    mb: 0.5,
                                    fontWeight: 500,
                                    fontSize: { xs: '14px', xl: '16px' },
                                  }}
                                >
                                  Frequency
                                </Typography>

                                <FormSelectInput
                                  name='frequency'
                                  label='Frequency'
                                  defaultValue={''}
                                  required
                                  options={sipFrequencyOption}
                                  onChange={(e: any) => {
                                    setFieldValue('sipDay', '');
                                    setFieldValue('numberOfInstalments', '');
                                    setFieldValue('startDate', null);
                                    setFieldValue('endDate', null);
                                    setFieldValue('perpetual', false);
                                    handleSipFrequencyChange(e, setFieldValue);
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                              >
                                <Typography
                                  sx={{
                                    color: 'text.labelColor',
                                    mb: 0.5,
                                    fontWeight: 500,
                                    fontSize: { xs: '14px', xl: '16px' },
                                  }}
                                >
                                  No of Installments
                                </Typography>
                                <FormTextInput
                                  name='numberOfInstalments'
                                  label='Number Of Installments'
                                  required
                                  onChange={e => {
                                    setFieldValue('sipDay', '');
                                    setFieldValue('startDate', null);
                                    setFieldValue('endDate', null);
                                    setSelectedFrequencyAndSip({
                                      ...selectedFrequencyAndSip,
                                      noOfInstallments: Number(e.target.value),
                                    });
                                  }}
                                  onInput={(e: any) => {
                                    e.target.value = e.target.value.replace(
                                      /[^0-9]/g,
                                      '',
                                    );
                                    if (e.target.value.startsWith('0')) {
                                      e.target.value = e.target.value.replace(/^0+/, '');
                                    }
                                    if (e.target.value.length > 5) {
                                      e.target.value = e.target.value.slice(0, 5);
                                    }
                                    setFieldValue('numberOfInstalments', e.target.value);
                                  }}
                                  disabled={values?.perpetual}
                                  typeOfInput='number'
                                />

                                <Typography>
                                  Minimum Number of installments: {minInstallments}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                              >
                                <Typography
                                  sx={{
                                    color: 'text.labelColor',
                                    mb: 0.5,
                                    fontWeight: 500,
                                    fontSize: { xs: '14px', xl: '16px' },
                                  }}
                                >
                                  SIP Day
                                </Typography>
                                <FormSelectInput
                                  name='sipDay'
                                  label='SIP Day'
                                  defaultValue={''}
                                  required
                                  options={sipDays}
                                  onChange={e => {
                                    setSelectedFrequencyAndSip({
                                      ...selectedFrequencyAndSip,
                                      selectedSipDay: e.target.value,
                                    });
                                    handleSipDayChange(e, values, setFieldValue);
                                  }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                              >
                                <Typography
                                  sx={{
                                    color: 'text.labelColor',
                                    mb: 0.5,
                                    fontWeight: 500,
                                    fontSize: { xs: '14px', xl: '16px' },
                                  }}
                                >
                                  Start Date
                                </Typography>
                                <FormDateInput
                                  name='startDate'
                                  label='Start Date'
                                  required={true}
                                  viewOnly
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                lg={4}
                              >
                                <Typography
                                  sx={{
                                    color: 'text.labelColor',
                                    mb: 0.5,
                                    fontWeight: 500,
                                    fontSize: { xs: '14px', xl: '16px' },
                                  }}
                                >
                                  End Date
                                </Typography>
                                <FormDateInput
                                  name='endDate'
                                  label='End Date'
                                  required={true}
                                  viewOnly
                                />
                              </Grid>
                              {values.sipDay !== '' && (
                                <Grid
                                  item
                                  xs={12}
                                >
                                  <FormCheckbox
                                    sx={{
                                      '& .MuiFormControlLabel-root': {
                                        '& .MuiFormControlLabel-label': {
                                          fontSize: '14px',
                                        },
                                      },
                                    }}
                                    name='perpetual'
                                    label='Perpetual SIP'
                                    margin='dense'
                                    checked={values?.perpetual}
                                    onChange={e =>
                                      handlePerpetualSipBoxChnage(
                                        e,
                                        setFieldValue,
                                        values,
                                        selectedFrequencyAndSip,
                                      )
                                    }
                                  />
                                </Grid>
                              )}
                            </>
                          )}
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          lg={4}
                        >
                          <Typography
                            sx={{
                              color: 'text.labelColor',
                              mb: 0.5,
                              fontWeight: 500,
                              fontSize: { xs: '14px', xl: '16px' },
                            }}
                          >
                            Investment Amount
                          </Typography>
                          <FormTextInput
                            name='investmentAmount'
                            label='Investment Amount'
                            required
                            typeOfInput='number'
                            onInput={(e: any) => {
                              e.target.value = e.target.value.replace(/[^0-9]/g, '');
                              if (e.target.value.startsWith('0')) {
                                e.target.value = e.target.value.replace(/^0+/, '');
                              }
                              if (e.target.value.length > 18) {
                                e.target.value = e.target.value.slice(0, 18);
                              }
                              setFieldValue('investmentAmount', e.target.value);
                            }}
                          />
                          <Typography>
                            Minimum Amount:{' '}
                            {values.modeOfTransaction === 'Lumpsum'
                              ? schemaData?.minTransactionAmount
                              : sipMinAmount}
                          </Typography>
                        </Grid>
                        {values.modeOfTransaction === 'Lumpsum + SIP' && (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            lg={4}
                          >
                            <Typography
                              sx={{
                                color: 'text.labelColor',
                                mb: 0.5,
                                fontWeight: 500,
                                fontSize: { xs: '14px', xl: '16px' },
                              }}
                            >
                              Lumpsum Amount
                            </Typography>

                            <FormTextInput
                              name='lumpsumAmount'
                              label='Lumpsum Amount'
                              typeOfInput='number'
                              onInput={(e: any) => {
                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                if (e.target.value.startsWith('0')) {
                                  e.target.value = e.target.value.replace(/^0+/, '');
                                }
                                setFieldValue('lumpsumAmount', e.target.value);
                              }}
                            />
                            <Typography>
                              Minimum Amount:{' '}
                              {schemeDetails?.fund === '187'
                                ? lumpsumMinAmt
                                : sipMinAmount}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Stack
                  direction={'row'}
                  columnGap={'20px'}
                  mt={'26px'}
                >
                  <SubmitButton
                    sx={{
                      '&.MuiButtonBase-root': {
                        borderRadius: '5px',
                        minWidth: { xs: 'unset', sm: '200px' },
                        flex: { xs: 1, sm: 'unset' },
                        my: { xs: 'unset' },
                        padding: '8px 8px',
                      },
                    }}
                    label='Continue'
                  >
                    Continue
                  </SubmitButton>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      </Investorcard>
    </>
  );
};

export default MfsInvestmentDetails;
