import { Box, Grid, IconButton, Stack, Theme, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { ReactComponent as AlertIcon } from '../../../../assets/alert-icon.svg';
import { ReactComponent as BackIcon_Mobile } from '../../../../assets/back-icon.svg';
import { ReactComponent as BackIcon } from '../../../../assets/back-icon-large.svg';
import { ReactComponent as Clip_Icon } from '../../../../assets/clipper.svg';
import InfoIcon from '../../../../assets/info-fill.svg';

export const SUCCESS_RESPONSE_CODE = 10000;
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CustomDialog from '../../../common/AllDialogs/CustomDialog';
import { startLoading, stopLoading } from '../../../common/Loader/loaderSlice';
import { useRootContext } from '../../../data/root.context';
import FormDateInput from '../../../forms/FormDateInput';
import { FormStyledRadioButton } from '../../../forms/FormStyledRadioButton';
import SubmitButton from '../../../forms/FormSubmitButton';
import FormTextInput from '../../../forms/FormTextInput';
import {
  setBasicDetails,
  setFatcaDetails,
  setHolderDetails,
  setInvestorBankDetails,
  setInvestorDetails,
  setInvestorDetailsFromResponse,
  setIsNomination,
  setNomineeDetails,
  setStepNumber,
} from './context/NewMfsInvestorAction';
import { useNewMfsInvestorContext } from './context/NewMfsInvestorContext';
import { useInvestorDetailsMutation, useVerifyPanKycDetailsMutation } from './slice';

interface InvestorOnBoardingFormValues {
  investorType: string;
  panMinor: string;
  dateOfBirth: Date | null;
  guardianName: string;
  guardianPan: string;
}

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();
const currentDate = new Date().getDate();
const minYear = currentYear - 18;
const minDate = new Date(minYear, currentMonth, currentDate);

const steps = [
  {
    index: 1,
    title: 'Select Schemes First',
    subtitle: 'Choose schemes before onboarding the user to ensure a seamless experience',
    bgcolor: 'success.main',
  },
  {
    index: 2,
    title: 'Investor Folio Creation',
    subtitle: `Complete Investor onboarding: Fill Investor Details , FATCA ,
    nominee and Bank Details for seamless folio creation.`,
    bgcolor: 'text.primary',
  },
];

export default function NewInvestorOnBoarding() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast } = useRootContext();
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [openKycPopup, setOpenKycPopup] = useState(false);
  const [openGoBackPopup, setOpenGoBackPopup] = useState(false);
  const [invPan, setInvPan] = useState('');
  const [investorType, setInvestorType] = useState('I');
  const { dispatch: contextDispatch } = useNewMfsInvestorContext();
  const [verifyPanFraud] = useVerifyPanKycDetailsMutation();
  const [getData] = useInvestorDetailsMutation();

  const initialValues: InvestorOnBoardingFormValues = {
    investorType: 'I',
    panMinor: '',
    dateOfBirth: null,
    guardianName: '',
    guardianPan: '',
  };

  const validationSchema = Yup.object().shape({
    investorType: Yup.string().required('Investor Type is required'),
    panMinor: Yup.string()
      .when('investorType', {
        is: 'I',
        then: Yup.string().required('PAN is required'),
      })
      .matches(/^[A-Z]{3}[P][A-Z]{1}[0-9]{4}[A-Z]{1}$/, 'Please Enter Valid PAN'),
    dateOfBirth: Yup.date()
      .typeError('Invalid Date')
      .nullable()
      .max(new Date(), 'Date of Birth cannot be in the future')
      .min(minDate, 'You must be at least less than 18 years old')
      .when('investorType', {
        is: 'M',
        then: Yup.date().typeError('Invalid Date').required('Date of Birth is required'),
      }),
    guardianName: Yup.string().when('investorType', {
      is: 'M',
      then: Yup.string().required('Guardian Name is required'),
    }),
    guardianPan: Yup.string()
      .when('investorType', {
        is: 'M',
        then: Yup.string().required('Guardian PAN is required'),
      })
      .matches(/^[A-Z]{3}[P][A-Z]{1}[0-9]{4}[A-Z]{1}$/, 'Please Enter Valid PAN'),
  });

  useEffect(() => {
    contextDispatch(setInvestorDetails(null));
    contextDispatch(setHolderDetails(null));
    contextDispatch(setFatcaDetails(null));
    contextDispatch(setIsNomination(false));
    contextDispatch(setNomineeDetails(null));
    contextDispatch(setInvestorBankDetails(null));
  }, []);

  const handleSubmit = async (values: InvestorOnBoardingFormValues) => {
    const { panMinor, guardianPan, investorType } = values;
    contextDispatch(setBasicDetails(values));
    const pan = investorType === 'I' ? panMinor : guardianPan;
    setInvPan(pan);
    const panFraudBody = {
      pan: pan,
      isJointHolder: false,
    };
    try {
      dispatch(startLoading());
      const response = await verifyPanFraud(panFraudBody).unwrap();
      showToast((response as { message: string }).message, 'success');

      const cKycRequestBody = {
        pan: investorType === 'I' ? panMinor : guardianPan,
        mobile: '',
      };
      const invResponse = await getData(cKycRequestBody).unwrap();
      contextDispatch(setInvestorDetailsFromResponse(invResponse));
      navigate('new-investor-steps');
      contextDispatch(setStepNumber(0));
    } catch (error: any) {
      const erMsg = error?.data?.message;
      if (error?.data?.code === 'INVALID_PAN') {
        setOpenDialogBox(true);
      } else if (error?.data?.code === 'KYC_NOT_VERIFIED') {
        setOpenKycPopup(true);
      } else {
        showToast(erMsg || error.message || 'Unknown error', 'error');
      }
    } finally {
      dispatch(stopLoading());
    }
  };

  const onOkButtonClick = async () => {
    setOpenDialogBox(false);
    navigate('/transaction-dashboard');
  };

  const onContinueButtonClick = async () => {
    setOpenDialogBox(false);
    navigate('../kyc-details', {
      state: {
        pan: invPan,
      },
    });
  };

  const onGoAnywayButtonClick = async () => {
    setOpenGoBackPopup(false);
    navigate('../scheme-selection', { replace: true });
  };

  const onCancelClick = () => {
    setOpenGoBackPopup(false);
  };

  const validatePan = (values: any) => {
    const errors: Record<string, any> = {};
    if (values.guardianPan && values.guardianPan === values.panMinor) {
      errors.guardianPan = 'Guardian Pan and Minor PAN should not be same';
    }
    return errors;
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Stack
        sx={(theme: Theme) => ({
          flexDirection: 'row',
          alignItems: 'center',
          columnGap: '10px',
          mb: '12px',
          [theme.breakpoints.up(1920)]: {
            ml: '40px',
            mb: '40px',
          },
        })}
      >
        <IconButton
          onClick={() => setOpenGoBackPopup(true)}
          sx={{ display: { xs: 'none', sm: 'inline-flex' } }}
        >
          <BackIcon />
        </IconButton>
        <IconButton
          onClick={() => setOpenGoBackPopup(true)}
          sx={{ display: { xs: 'inline-flex', sm: 'none' } }}
        >
          <BackIcon_Mobile />
        </IconButton>

        <Typography
          variant='h4'
          sx={{ display: { xs: 'none', sm: 'block' } }}
        >
          Back
        </Typography>
        <Typography
          variant='h4'
          sx={{
            fontWeight: 700,
            color: 'primary.main',
            display: { xs: 'block', sm: 'none' },
          }}
        >
          New Investor
        </Typography>
      </Stack>
      <Grid
        container
        justifyContent={'space-between'}
        height={'100%'}
      >
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Box
            sx={(theme: Theme) => ({
              pr: { xs: 'unset', sm: '30px', md: '45px' },
              [theme.breakpoints.up(1920)]: {
                pr: 0,
                maxWidth: '800px',
                margin: '0 auto',
              },
            })}
          >
            <Box
              sx={{
                width: { xs: '100%', xl: '80%' },
                display: { xs: 'none', sm: 'block' },
              }}
            >
              <Typography
                sx={(theme: Theme) => ({
                  color: 'primary.main',
                  fontSize: { sm: '30px', lg: '50px', xl: '60px' },
                  fontWeight: 700,
                  mb: 3,
                  mt: 5,
                  lineHeight: { sm: '50px', md: 'initial' },
                  [theme.breakpoints.up(1920)]: {
                    mb: 6,
                  },
                })}
              >
                Efficiency meets trust in onboarding.
              </Typography>
              <Typography
                sx={(theme: Theme) => ({
                  maxWidth: '550px',
                  fontSize: { xs: '14px', sm: '16px', lg: '20px', xl: '22px' },
                  color: 'text.labelColor',
                  mb: { sm: 6.5, xl: 8 },
                  textAlign: { xs: 'center', md: 'unset' },
                  [theme.breakpoints.up(1920)]: {
                    maxWidth: '600px',
                    mb: 10,
                  },
                })}
              >
                Seamless onboarding: trust, efficiency define the investor journey for
                growth
              </Typography>
            </Box>
            <Box
              sx={(theme: Theme) => ({
                borderRadius: { xs: '10px', sm: '25px' },
                bgcolor: 'text.boxColor',
                padding: {
                  xs: '20px 30px',
                  lg: '50px 50px',
                  xl: '20px 60px 20px',
                },
                position: 'relative',
                mt: { xs: 5, sm: 0 },
                [theme.breakpoints.up(1920)]: {
                  py: 6,
                  px: 8,
                },
              })}
            >
              {/*Mobile UI */}
              <Box
                sx={{
                  display: { xs: 'block', sm: 'none' },
                  position: 'absolute',
                  top: '-20px',
                  transform: 'translateX(-50%)',
                  left: '50%',
                }}
              >
                <Clip_Icon />
              </Box>
              {/*Mobile UI */}
              <Grid
                container
                sx={{
                  'justifyContent': { xs: 'space-between', sm: 'unset' },
                  'position': 'relative',
                  '&:before': {
                    position: 'absolute',
                    bgcolor: 'text.stepperLine',
                    height: '1px',
                    content: '""',
                    top: '9px',
                    left: { xs: '75px', sm: 'unset' },
                    width: { xs: 'calc(100% - 160px)', sm: '100%' },
                  },
                }}
              >
                {steps.map((step, idx) => (
                  <Grid
                    item
                    sm={6}
                    sx={{ pr: { sm: 2 } }}
                    key={idx}
                  >
                    <Box
                      sx={{
                        'display': 'flex',
                        'justifyContent': 'center',
                        'alignItems': 'center',
                        'ml': { xs: step.index === 1 ? '55px' : 'auto', sm: 'unset' },
                        'mr': { xs: step.index === 2 ? '66px' : 'unset', sm: 'unset' },
                        'width': { xs: '18px', md: '22px' },
                        'height': { xs: '18px', md: '22px' },
                        'transform': 'rotate(-45deg)',
                        'borderRadius': '5px',
                        'bgcolor': step.bgcolor,
                        '& .MuiTypography-root': {
                          transform: 'rotate(45deg)',
                          textAlign: 'center',
                          fontSize: { xs: '12px', sm: '14px' },
                          fontWeight: 500,
                          color: 'common.white',
                        },
                      }}
                    >
                      <Typography>{step.index}</Typography>
                    </Box>
                    <Box sx={{ my: { xs: 'unset', sm: 4 } }}>
                      <Typography
                        sx={(theme: Theme) => ({
                          color: 'text.primary',
                          fontSize: { xs: '12px', sm: '16px', md: '20px' },
                          mb: { xs: 0, sm: 2 },
                          mt: { xs: 2, sm: 'unset' },
                          fontWeight: 500,
                          [theme.breakpoints.down('sm')]: {
                            color: step.index === 1 ? 'text.primary' : 'text.labelColor',
                          },
                        })}
                      >
                        {step.title}
                      </Typography>

                      <Typography
                        sx={{
                          display: { xs: 'none', sm: 'block' },
                          color: 'text.labelColor',
                        }}
                      >
                        {step.subtitle}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
        >
          <Box sx={{ position: 'absolute', top: 0, bottom: 0 }}>
            <Box
              component={'span'}
              sx={{
                borderLeft: {
                  xs: 'unset',
                  sm: '1px dashed',
                  display: 'inline-block',
                  height: '100vh',
                },
                borderColor: {
                  xs: 'unset',
                  sm: 'text.borderColorLight',
                },
              }}
            ></Box>
          </Box>
          <Box
            sx={(theme: Theme) => ({
              mt: { xs: 'unset', sm: 7 },

              pl: { xs: '0px', sm: '30px', md: '45px', xl: '0px' },
              maxWidth: '600px',
              [theme.breakpoints.up(1700)]: {
                mx: 'auto',
                maxWidth: '700px',
              },
            })}
          >
            <Typography
              variant='h3'
              sx={(theme: Theme) => ({
                display: { xs: 'none', sm: 'block' },
                [theme.breakpoints.up(1920)]: {
                  mb: 4,
                },
              })}
            >
              Investor Folio Creation
            </Typography>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
              validate={validatePan}
            >
              {({ values, setFieldValue, setFieldError, errors }) => {
                return (
                  <Form>
                    <Grid
                      container
                      sx={{ my: 1 }}
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={12}
                        mb={2}
                      >
                        <Typography
                          sx={{
                            color: 'text.labelColor',
                            mb: 2,
                            fontWeight: 500,
                            fontSize: { xs: '14px', sm: '16px', xl: '18px' },
                          }}
                        >
                          Select the type of Investor
                        </Typography>
                        <FormStyledRadioButton
                          options={[
                            { label: 'Individual', value: 'I' },
                            { label: 'Minor', value: 'M' },
                          ]}
                          name='investorType'
                          handleChange={e => {
                            setInvestorType(e.target.value);
                            setFieldValue('panMinor', '');
                            setFieldValue('dateOfBirth', '');
                            setFieldValue('guardianName', '');
                            setFieldValue('guardianPan', '');
                            setFieldError('panMinor', '');
                            setFieldError('dateOfBirth', '');
                            setFieldError('guardianName', '');
                            setFieldError('guardianPan', '');
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        sm={values.investorType === 'M' ? 6 : 12}
                        xs={12}
                      >
                        <FormTextInput
                          name='panMinor'
                          label='Enter PAN'
                          placeholder='Enter PAN'
                          required={values.investorType === 'M' ? false : true}
                          onChange={e => {
                            setFieldValue('panMinor', e.target.value.toUpperCase());
                            setFieldError('guardianName', '');
                            setFieldError('guardianPan', '');
                          }}
                        />
                      </Grid>
                      {values.investorType === 'M' && (
                        <>
                          <Grid
                            item
                            sm={6}
                            xs={12}
                          >
                            <FormDateInput
                              name='dateOfBirth'
                              placeholder='Date of Birth'
                              label='Date of Birth'
                              disableFuture
                              required
                              minDate={minDate}
                              defaultCalendarMonth={new Date()}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <Typography
                              sx={{
                                fontWeight: 600,
                                fontSize: { xs: '14px', sm: '16px', xl: '18px' },
                              }}
                            >
                              Guardian Details
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            sm={6}
                            xs={12}
                          >
                            <FormTextInput
                              name='guardianName'
                              label='Guardian Name'
                              typeOfInput='singleSpace'
                            />
                          </Grid>
                          <Grid
                            item
                            sm={6}
                            xs={12}
                          >
                            <FormTextInput
                              name='guardianPan'
                              label='Guardian PAN'
                              onChange={e => {
                                setFieldValue(
                                  'guardianPan',
                                  e.target.value.toUpperCase(),
                                );
                              }}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <SubmitButton
                      label='Verify'
                      sx={{ my: 3, minWidth: '100%' }}
                      fullWidth
                    />
                  </Form>
                );
              }}
            </Formik>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '10px',
                borderRadius: '7px',
                bgcolor: 'text.borderColorDark',
                padding: '16px 40px 16px 20px',
              }}
            >
              <img
                src={InfoIcon}
                alt='info-icon'
              />
              <Typography
                variant='subtitle2'
                sx={{ lineHeight: '22px', color: 'text.labelColor', fontWeight: 400 }}
              >
                Add the applicant(s) PAN and if the PAN is KRA verified, the contact
                details will be populated automatically. PAN will be checked against
                World-Check database for AML compliance.
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <CustomDialog
        isVisible={openDialogBox}
        firstButtonHandler={() => {}}
        secondButtonHandler={onOkButtonClick}
        firstButtonTitle=''
        secondButtonTitle='Ok'
        handleClose={() => {
          setOpenDialogBox(false);
        }}
        showFirstButton={false}
        showSecondButton={true}
      >
        <Typography
          variant='h5'
          sx={{ color: 'text.primary' }}
        >
          PAN is already registered
        </Typography>
        <Typography
          variant='subtitle1'
          sx={{
            fontWeight: 400,
            color: 'text.labelColor',
            my: 3,
          }}
        >
          The entered PAN is already registered with us.
        </Typography>
      </CustomDialog>
      <CustomDialog
        isVisible={openKycPopup}
        firstButtonHandler={() => setOpenKycPopup(false)}
        secondButtonHandler={onContinueButtonClick}
        firstButtonTitle='Cancel'
        secondButtonTitle='Continue'
        handleClose={() => {
          setOpenKycPopup(false);
        }}
        showSecondButton={true}
      >
        <Typography
          variant='h5'
          sx={{ color: 'text.primary' }}
        >
          PAN is not KYC Complaint
        </Typography>
        <Typography
          variant='subtitle1'
          sx={{
            fontWeight: 400,
            color: 'text.labelColor',
            my: 3,
          }}
        >
          This Client is not KYC compliant, do you want to go for KYC.
        </Typography>
      </CustomDialog>
      <CustomDialog
        isVisible={openGoBackPopup}
        firstButtonHandler={onCancelClick}
        secondButtonHandler={onGoAnywayButtonClick}
        firstButtonTitle='Cancel'
        secondButtonTitle='Go Back Anyway'
        handleClose={() => {
          setOpenGoBackPopup(false);
        }}
        showSecondButton={true}
      >
        <Stack
          display='flex'
          direction='row'
          alignItems='center'
          gap='10px'
        >
          <AlertIcon />
          <Typography variant='h5'>Selected Scheme Will be Lost!</Typography>
        </Stack>
        <Typography
          variant='subtitle1'
          sx={{ fontWeight: 400, color: 'text.labelColor', my: 3 }}
        >
          Going Back will result in the loss of your selected scheme. Are you sure you
          want to proceed?
        </Typography>
      </CustomDialog>
    </Box>
  );
}
