import { FrequencyData, MinInstallmentsAllowed } from './types';

export const APL_MODE_TYPES = [
  { label: 'Lumpsum', value: 'Lumpsum' },
  { label: 'SIP', value: 'SIP' },
  { label: 'Lumpsum + SIP', value: 'LumpsumAndSIP' },
];

function convertDate(dateString: string): string {
  return new Date(dateString).toISOString().split('T')[0];
}
export function filterMandatesForLumpsum(sipAmount: number, mandate: any): boolean {
  if (!mandate) return false;
  const { amount, fromDate, toDate } = mandate;

  if (amount < sipAmount) return false;

  return (
    new Date(convertDate(fromDate)).getTime() <= new Date().getTime() &&
    new Date(convertDate(toDate)).getTime() > new Date().getTime()
  );
}

export const filterPaymentModesForLumpsum = (allPaymentOptions: any) => {
  return allPaymentOptions.filter((method: any) => method.value !== 'ISIP');
};

export const initialRegistrationModesForSIP = (
  registrationModes: any[],
  isPanBasedAOTM: boolean,
) => {
  const data = registrationModes
    .filter(({ value }: { value: string }) => {
      if (isPanBasedAOTM) {
        return true;
      }
      // For folio 'NA'
      return value !== 'AOTM';
    })
    .filter(({ value }: { value: string }) => {
      return value !== 'NETBANKING' && value !== 'UPI';
    });
  return data;
};
// For confirm page when registration mode is preselected
export const filterRegistrationMethodsSIP = (
  registrationModes: any[],
  isPanBasedAOTM: boolean,
  selectedRegistrationMode: string,
  isNri: boolean,
) => {
  return registrationModes
    .filter(({ value }: { value: string }) => {
      if (isPanBasedAOTM) {
        return true;
      }
      // For folio 'NA'
      return value !== 'AOTM';
    })
    .filter(({ value }: { value: string }) => {
      return value !== 'NETBANKING' && value !== 'UPI';
    })
    .filter(({ value }: { value: string }) => {
      if (isNri) return true;
      if (selectedRegistrationMode === 'ISIP') return true;
      if (selectedRegistrationMode === value) {
        return true;
      } else {
        return false;
      }
    });
};

export const removeKotmFromPaymentModes = (paymentModes: any[]) => {
  return paymentModes.filter(({ value }: { value: string }) => {
    return value !== 'KOTM';
  });
};
export const removeOTMFromPaymentModes = (paymentModes: any[]) => {
  return paymentModes.filter(({ value }: { value: string }) => {
    return !value.includes('OTM');
  });
};
export const removeOtherOTMOptions = (
  paymentModes: any[],
  modeOfRegistration: string,
) => {
  return paymentModes.filter(({ value }: { value: string }) => {
    if (!value.includes('OTM')) {
      return true;
    }

    if (modeOfRegistration === 'KOTM' && value === 'KOTM') {
      return true;
    }

    if (modeOfRegistration === 'AOTM' && value === 'AOTM') {
      return true;
    }
    return false;
  });
};
export const areAllTheValuesEmpty = (values: any) =>
  !(values.sipDay && values.installments && values.frequency);

export function findMinInstallmentAllowed(
  paymentModes: MinInstallmentsAllowed[],
  mode: string,
  minInstallments?: number,
) {
  let result;

  if (!paymentModes) {
    return null; // Return null if no payment modes exist for the provided mode
  }
  if (paymentModes.length === 1) return paymentModes[0];
  if (minInstallments !== undefined) {
    // Find the exact match with the mode and minInstallments
    result = paymentModes.find(
      paymentMode =>
        paymentMode.mode.toUpperCase() === mode.toUpperCase() &&
        paymentMode.minInstallments === minInstallments,
    );

    if (result) {
      return result;
    }
  }

  const candidates = paymentModes
    .filter(
      paymentMode =>
        paymentMode.mode.toUpperCase() === mode.toUpperCase() &&
        paymentMode.minInstallments <= minInstallments!,
    )
    .sort((a, b) => b.minInstallments - a.minInstallments); // Sort ascending for closest lower value

  result = candidates[0]; // Return the closest lower value
  return result;
}
export const processFrequencyData = (frequencyData: FrequencyData[]) => {
  const frequencyDataObject: any = {};

  frequencyData?.forEach((entry: FrequencyData) => {
    // Normalize mode to uppercase to handle case-insensitive grouping
    const mode = entry.mode.toUpperCase();
    const { cycle, ...rest } = entry;

    // Parse the cycle string into an array of numbers, or create a default array
    const cycleArray = cycle
      .split(',')
      .map(item => parseInt(item.trim(), 10))
      .filter(num => !isNaN(num));

    const newCycle = cycleArray.length
      ? cycleArray
      : Array.from({ length: 28 }, (_, index) => index + 1);

    const newObj = {
      ...rest,
      cycle: newCycle,
    };

    // If the mode already exists, push the newObj to the existing array
    if (frequencyDataObject[mode]) {
      frequencyDataObject[mode].push(newObj);
    } else {
      // Initialize the array with the first entry for that mode
      frequencyDataObject[mode] = [newObj];
    }
  });

  return frequencyDataObject;
};
