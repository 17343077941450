import { Box, Button, IconButton, Typography } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import React, { ChangeEvent, forwardRef, useImperativeHandle, useRef } from 'react';

import { ReactComponent as DeleteIcon } from '../../assets/delete-icon.svg';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const UniqueVisuallyHiddenInput: React.FC<{
  id: string;
  accept?: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}> = ({ id, onChange, accept }) => (
  <VisuallyHiddenInput
    accept={accept}
    type='file'
    onChange={onChange}
  />
);

interface FileUploadProps {
  index: number;
  files: (File | null)[];
  setFiles: React.Dispatch<React.SetStateAction<(File | null)[]>>;
  accept?: string;
  includeSubmitAction?: boolean;
  fileName?: string;
  onUpload?: () => void;
  onSubmit?: () => void;
}

export const FileUpload: React.ForwardRefExoticComponent<
  FileUploadProps & React.RefAttributes<unknown>
> = forwardRef(
  (
    { index, files, setFiles, accept, includeSubmitAction = false, onSubmit, onUpload },
    ref,
  ) => {
    const hiddenFileInputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => ({ removeFile }));
    const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
      const fileUploaded = e.target.files && e.target.files[0];
      if (!fileUploaded) return;
      const newFiles = [...files];
      newFiles[index] = fileUploaded;
      setFiles(newFiles);
      onUpload?.();
    };

    const handleClick = () => {
      hiddenFileInputRef.current?.click();
    };

    const removeFile = () => {
      const OriginalFiles: (File | null)[] = files.map(f => f);
      OriginalFiles[index] = null;
      setFiles(OriginalFiles);
      if (hiddenFileInputRef.current) {
        hiddenFileInputRef.current.value = '';
      }
    };
    return (
      <Box
        key={index}
        sx={{
          borderRadius: '7px',
          border: '1px solid',
          borderColor: 'text.mainLight2',
          padding: '10px',
          minHeight: '250px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
        }}
      >
        <Box
          sx={(theme: Theme) => ({
            borderRadius: '7px',
            border: files[index]
              ? `0.6px dashed ${theme.palette.primary.main}`
              : `0.6px dashed ${theme.palette.text.shadyGray}`,
            background: files[index]
              ? 'rgba(32, 87, 166, 0.12)'
              : 'rgba(200, 200, 200, 0.12)',
            height: '160px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '40px',
            textAlign: 'center',
            position: 'relative',
          })}
        >
          <>
            <Typography
              variant='subtitle2'
              sx={{
                color: 'text.labelColor',
                wordBreak: 'break-word',
              }}
            >
              {files[index]?.name || 'No file chosen'}
            </Typography>
            {files[index] && (
              <IconButton
                onClick={() => removeFile()}
                sx={{ position: 'absolute', top: '5px', right: '5px' }}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Button
            variant='contained'
            component='label'
            sx={(theme: Theme) => ({
              'borderRadius': '5px',
              'backgroundColor': 'primary.main',
              'backgroundImage': 'linear-gradient(90deg, #215EAB 0%, #0FD1D6 100%)',
              'minWidth': { xs: '100%' },
              'color': theme.palette.common.white,
              'padding': '8px 15px',
              'fontSize': '14px',
              'fontWeight': '400',
              '&:hover': {
                backgroundColor: 'primary.main',
              },
            })}
            onClick={includeSubmitAction && files[index] ? onSubmit : handleClick}
          >
            {includeSubmitAction && files[index] ? (
              <>Submit</>
            ) : (
              <>
                Upload
                <UniqueVisuallyHiddenInput
                  id={`fileInput-${index}`}
                  onChange={e => handleUpload(e)}
                  accept={accept}
                />
              </>
            )}
          </Button>
        </Box>
      </Box>
    );
  },
);
