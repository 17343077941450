import { Grid } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { PageNavigator } from '../../common/BackToHomePage';
import InvestorProfile from '../../common/InvestorProfile';
import PanDetailsBox from '../../common/PanDetails';
import { useRootContext } from '../../data/root.context';
import { TransactionResponse } from '../../features/transactions/transactionSlice';
import { CommonTable } from '../../transactionDashboard/investorTable';
import { createConvertedOutputArray, rowCreationForSchemes } from '../common';
import { useListOfSchemesMutation } from '../slice';
import {
  investorSchemeDetailsColumns,
  RowData,
  Scheme,
  TransactionAllow,
} from '../types';

export default function Redeem() {
  const [loading, setLoading] = React.useState<boolean>(true);
  const navigate = useNavigate();
  const { showToast } = useRootContext();
  const [investorSchemes, setInvestorSchemes] = useState<Scheme[]>([]);
  const [investorDetailsList] = useListOfSchemesMutation();

  const { state } = useLocation();
  const dispatch = useDispatch();

  const { pan, name } = useSelector(
    (state: { transactions: TransactionResponse }) => state.transactions,
  );
  useEffect(() => {
    async function getInvestorDetailsSchemes() {
      if (!pan) return;
      try {
        setLoading(true);
        const response = await investorDetailsList({ pan }).unwrap();
        const filteredSchemes = response
          ?.map((item: any) => ({
            ...item,
            key: nanoid(),
          }))
          ?.filter((item: { redemptionAllow: any }) => item.redemptionAllow);
        setInvestorSchemes(filteredSchemes);
      } catch (error: any) {
        const message =
          (error as any).data?.message || (error as any).message || 'Unknown error';
        showToast(message, 'error');
      } finally {
        setLoading(false);
      }
    }

    getInvestorDetailsSchemes();
  }, []);

  const outputArray: RowData[] = rowCreationForSchemes(investorSchemes);

  const continueButtonHandler = (row: any) => {
    navigate('investment-details', {
      state: {
        previousURL: 'reedem',
        schemeDetails: row,
        isFromcart: state?.isFromCart ? true : false,
      },
    });
  };
  return (
    <>
      <PanDetailsBox
        name={name}
        value={pan}
      />
      <PageNavigator
        title='Redeem'
        subtitle='Please select the scheme you want to make redeem'
      />

      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={9}
        >
          <CommonTable
            loading={loading}
            setLoading={setLoading}
            rows={createConvertedOutputArray(
              investorSchemes.map((item: any) => ({
                fundName: item.amcName,
                schemeDesc: `${item.schemeName} - ${item.planMode} - ${item.optionDesc}`,
                folioNo: item.folio,
                availableAmount: item.nav * item.units,
                balanceUnits: item.units,
                exitLoad: item.exitLoad || '', //TODO: replace with exit load
                taxSaverFlag: item.taxsaverFlag,
                ...item,
              })),
              TransactionAllow['RED'],
            )} //TODO : optimise this line
            columns={Object.values(investorSchemeDetailsColumns) ?? []}
            start={0} // Starting page indexx
            limit={10} // Limit given
            searchPlaceholder={'Search by Name'}
            continueButtonHandler={continueButtonHandler}
            columnsToShow={Object.keys(investorSchemeDetailsColumns)}
            keyToCheck={TransactionAllow['RED']}
          />
        </Grid>
        <Grid
          item
          md={3}
        >
          <InvestorProfile />
        </Grid>
      </Grid>
    </>
  );
}
