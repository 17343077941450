import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import loadingReducer, {
  resetLoadingState,
} from './components/common/Loader/loaderSlice';
import authReducer, { resetAuthState } from './components/features/auth/authSlice';
import loginReducer, { resetLoginState } from './components/features/login/loginSlice';
import onboardingReducer, {
  resetOnboardingState,
} from './components/features/onboarding/onboardingSlice';
import transactionReducer, {
  resetTransactionState,
} from './components/features/transactions/transactionSlice';
import countriesAndStatesReducer from './components/features/utils/countriesAndStatesSlice';
import reportReducer, {
  resetReportState,
} from './components/transactions/reports/reportSlice';
import api from './service/api';

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['login', 'countriesAndStates', 'auth'],
};
// const dispatch = useDispatch();
const rootReducer = combineReducers({
  auth: authReducer,
  login: loginReducer,
  onboarding: onboardingReducer,
  transactions: transactionReducer,
  loading: loadingReducer,
  report: reportReducer,
  countriesAndStates: countriesAndStatesReducer,
  [api.reducerPath]: api.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const resetAllSlices = (dispatch: Dispatch<any>) => {
  dispatch(resetAuthState());
  dispatch(resetLoginState());
  dispatch(resetOnboardingState());
  dispatch(resetTransactionState());
  dispatch(resetLoadingState());
  dispatch(resetReportState());
  dispatch(api.util.resetApiState());
};

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      // Redux persist
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware),
});

export const persistor = persistStore(store);
