import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  SxProps,
  Typography,
} from '@mui/material';

const PopUpDialog = ({
  open,
  message_title,
  message_text,
  message_text_2,
  message_text_3,
  children,
  title_img,
  text_img,
  onClose,
  sx,
}: {
  open: boolean;
  // close: boolean;
  message_title?: string;
  message_text?: string;
  message_text_2?: string;
  message_text_3?: string;
  children?: React.ReactNode;
  text_img?: string;
  title_img?: any;
  onClose?: () => void;
  sx?: SxProps;
}) => {
  return (
    <Dialog
      open={open}
      // onClose={close}
      aria-labelledby='dialog-title'
      aria-describedby='dialog-description'
      sx={{
        'pb': 5,
        '& .MuiDialog-paper': {
          borderRadius: '10px',
          boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
        },
      }}
    >
      <DialogTitle
        id='dialog-title'
        sx={{
          background: '#2057A6',
          padding: '12px 24px',
        }}
      >
        {onClose ? (
          <IconButton
            aria-label='close'
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 16,
              top: 5,
              p: 0.7,
              color: theme => theme.palette.primary.light,
              borderRadius: 2,
            }}
            disableFocusRipple
          >
            <CloseIcon sx={{ width: 32, height: 32 }} />
          </IconButton>
        ) : null}
        {message_title && (
          <Typography
            variant='subtitle1'
            sx={{
              color: 'common.white',
              textAlign: 'center',
              fontSize: '14px',
              fontWeight: '500',
            }}
          >
            {message_title}
          </Typography>
        )}
        {title_img && <Box sx={{ textAlign: 'center' }}>{title_img}</Box>}
      </DialogTitle>
      <DialogContent
        sx={{
          padding: '20px 35px',
          ...sx,
        }}
      >
        {message_text && (
          <DialogContentText
            id='dialog-description'
            sx={{ pt: 2 }}
          >
            <Typography
              sx={{
                color: '#565656',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '18.2px',
              }}
            >
              {message_text}
            </Typography>
            <Typography
              sx={{
                margin: '10px 0px',
                color: '#2057A6',
                opacity: '0.8',
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '18.2px',
              }}
            >
              {message_text_2}
            </Typography>
            <Typography
              sx={{
                color: '#565656',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '18.2px',
              }}
            >
              {message_text_3}
            </Typography>
          </DialogContentText>
        )}

        {text_img && <DialogContentText id='dialog-description'></DialogContentText>}
        {children}
      </DialogContent>
    </Dialog>
  );
};

const ImagePopUpDialog = ({
  open,
  message_text,
  title_img,
  onClick,
  onClose,
  btn_text,
  sx,
}: {
  open: boolean;
  // close: boolean;
  message_text?: string;
  title_img?: any;
  onClick?: () => void;
  onClose: () => void;
  btn_text?: string;
  sx?: SxProps;
}) => {
  return (
    <Dialog
      open={open}
      // onClose={close}
      aria-labelledby='dialog-title'
      aria-describedby='dialog-description'
      sx={{
        'pb': 5,
        '& .MuiDialog-paper': {
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle id='dialog-title'>
        {onClose ? (
          <IconButton
            aria-label='close'
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 30,
              top: 20,
              p: 0.7,
              borderRadius: 2,
            }}
            disableFocusRipple
          >
            <CloseIcon sx={{ width: 23, height: 23 }} />
          </IconButton>
        ) : null}
        {/* {message_title && (
          <Typography
            variant="subtitle1"
            sx={{ color: 'text.buttonBGColor', textAlign: 'center', mt: 1.5 }}
          >
            {message_title + '!'}
          </Typography>
        )} */}
        {title_img && <Box sx={{ textAlign: 'center', pt: 3 }}>{title_img}</Box>}
      </DialogTitle>
      <DialogContent
        sx={{
          minWidth: 573,
          minHeight: 190,
          ...sx,
        }}
      >
        {message_text && (
          <DialogContentText
            id='dialog-description'
            sx={{ pt: 2, textAlign: 'center' }}
          >
            <Grid
              container
              xs={11}
              ml={3}
            >
              <Grid
                item
                xs={12}
              >
                <Typography
                  variant='h4'
                  sx={{ color: 'common.black', textAlign: 'center' }}
                >
                  {message_text + ' !'}
                </Typography>
              </Grid>
            </Grid>
            {btn_text && (
              <Box sx={{ pt: 6 }}>
                <Button
                  onClick={onClick}
                  sx={theme => ({
                    width: 165,
                    height: 41,
                    border: `2px solid ${theme.palette.text.buttonBGColor}`,
                    py: 1,
                  })}
                >
                  {btn_text}
                </Button>
              </Box>
            )}
          </DialogContentText>
        )}

        {/* {text_img && (
          <DialogContentText id="dialog-description">
            
          </DialogContentText>
        )} */}
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};

export { ImagePopUpDialog, PopUpDialog };
