import { TableCell } from '@mui/material';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { StepperNextButton } from '../../auth/login/styles/styledComponents';
import { useRootContext } from '../../data/root.context';
import { useAddSnapShotItemToCartMutation } from '../slice';
import { Order, TransactionHistory } from './';

export type TableActionProps = React.FC<{
  order: TransactionHistory['orders'][number];
  orderIndex: number;
  isPending: boolean;
  isDotNet: boolean;
  isCart: boolean;
  length: number;
  item: TransactionHistory;
  setOpenDialogBox: (open: boolean) => void;
}>;

type TableProps = React.ComponentProps<TableActionProps>;

type GetActionText = (args: {
  isPending: boolean;
  item: TransactionHistory;
  order: Order;
}) => string;

type GetActionLink = (
  args: Order,
  isDotNet: boolean,
) => {
  route: string;
  routeParams: RouteParamCart | RouteParamDotNet;
};

type GetAction = (args: {
  props: TableProps;
  navigate: ReturnType<typeof useNavigate>;
  dispatch: ReturnType<typeof useDispatch>;
  addSnapShotItemToCart: any; // TODO: Need to replace with actual type.
}) => () => void;

interface RouteParamDotNet {
  transaction: {
    ihno: string;
    fund: string;
    trtype: string;
  };
}

interface RouteParamCart {
  ihno: string;
  fundCode: string;
}

const TRANSACTIONS_MAP = [
  {
    route: '/re-initiate/swp',
    type: ['swp', 'SWP'],
  },
  {
    route: '/re-initiate/stp',
    type: ['stp', 'STP'],
  },
  {
    route: '/re-initiate/redemption',
    type: ['red', 'RED', 'REDEMPTION'],
  },
  {
    route: '/re-initiate/switch',
    type: ['switch', 'SWITCH'],
  },
  {
    route: '/re-initiate/additional-purchase',
    type: [
      'APL',
      'apl',
      'Additional Purchase',
      'ADD',
      'add',
      'Additional Purchase Lumpsum',
    ],
  },
  {
    route: '/re-initiate/new-purchase',
    type: ['NPL', 'npl', 'New Purchase', 'NEW PURCHASE', 'NEW', 'New Purchase Lumpsum'],
  },
  {
    route: '/re-initiate/sip',
    type: [
      'SIN',
      'SINI',
      'ISIP',
      'SIPN',
      'Additional Purchase SIP',
      'Additional Purchase SIP Lumpsum',
      'New Purchase SIP',
    ],
  },
];

// Table Action for each Row.
const TableAction: TableActionProps = function (props: any) {
  const { setOpenDialogBox } = props;
  const { actionLabel, tableAction, orderIndex, isCart, rowSpan } = useTableAction(props);

  if (orderIndex !== 0) {
    return null;
  }
  const openDialogBoxHandler = () => {
    setOpenDialogBox(true);
  };

  return (
    <TableCell
      className='table-cell'
      {...(isCart ? { rowSpan } : {})}
    >
      {actionLabel === 'NA' ? (
        'NA'
      ) : (
        <StepperNextButton
          sx={{ minWidth: '125px', padding: '8px 20px' }}
          onClick={() => {
            tableAction();
          }}
        >
          {actionLabel}
        </StepperNextButton>
      )}
    </TableCell>
  );
};

function useTableAction(props: React.ComponentProps<TableActionProps>) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addSnapShotItemToCart] = useAddSnapShotItemToCartMutation();

  const { isPending, order, item } = props;

  const actionLabel = useMemo(
    () => getActionText({ isPending, order, item }),
    [item.dco_ihno],
  );

  const action = getAction({ props, navigate, dispatch, addSnapShotItemToCart });

  return {
    actionLabel,
    orderIndex: props.orderIndex,
    // FOR Cart.
    isCart: Boolean(item.dco_cart_id) || item.orders.length > 1,
    tableAction: action,
    rowSpan: item.orders.length,
  };
}

const getActionText: GetActionText = function (args) {
  const { isPending, item, order } = args;
  const link = order.Link || order.link;
  const { dco_ihno } = order;

  const isValidDotNetTransaction = [
    'PURCHASE',
    'SIP PAUSE',
    'REDEMPTION',
    'SWITCH',
    'NEW PURCHASE',
    'SIP CANCELLATION',
  ].includes(order.investment_type?.toUpperCase());

  if (isPending && Boolean(link)) {
    return 'Copy Link';
  }
  if (isPending) {
    return 'NA';
  }
  if (!dco_ihno && !item.dco_cart_id) {
    return 'NA';
  }

  if (Boolean(item.dco_cart_id) || item.orders.length > 1) {
    return 'Add to Cart';
  }

  if (item.isDotNet && isValidDotNetTransaction) {
    return 're-initiate';
  }

  if (item.orders.length === 1 && item.isDotNet === false && !item.dco_cart_id) {
    return 'Re-initiate';
  }

  return 'NA';
};

const getActionLink: GetActionLink = function (args, isDotNet) {
  const { dco_fund, dco_ihno, investment_type } = args;

  const isValidDotNetTransaction = [
    'PURCHASE',
    'SIP PAUSE',
    'REDEMPTION',
    'SWITCH',
    'NEW PURCHASE',
    'SIP CANCELLATION',
  ].includes((investment_type as string)?.toUpperCase());

  if (isDotNet && isValidDotNetTransaction) {
    return {
      route: '/dashboard/re-initiate-transaction',
      routeParams: {
        transaction: {
          fund: dco_fund,
          ihno: dco_ihno,
          trtype: investment_type,
        },
      },
    };
  }

  const transaction = TRANSACTIONS_MAP.find(tr =>
    tr.type
      .map(s => s?.toUpperCase())
      .includes((investment_type as string)?.toUpperCase()),
  );

  const route = transaction ? transaction.route : '';
  return {
    route,
    routeParams: {
      fundCode: dco_fund,
      ihno: dco_ihno,
      trtype: investment_type,
    },
  };
};

const getAction: GetAction = function (args) {
  const { props, navigate, dispatch, addSnapShotItemToCart } = args;
  const { order, isDotNet, item, setOpenDialogBox } = props;

  const { showToast } = useRootContext();
  function reInitiate() {
    navigate('../re-initiate', {
      state: {
        item: item,
      },
    });
  }

  function copyLink() {
    try {
      const link = order.link || order.Link;
      navigator.clipboard.writeText(link as string);
      showToast('Link copied!!', 'success');
    } catch (error: any) {
      showToast(error, 'error');
    }
  }

  async function reInitiateCart() {
    try {
      const { orders } = item;
      let res;
      if (orders?.length > 0) {
        res = await addSnapShotItemToCart({
          orderIds: orders.map(entry => entry.orderId),
          ihno: '',
        });
      } else {
        res = await addSnapShotItemToCart({
          orderIds: [],
          ihno: item.dco_ihno,
        });
      }

      if ('error' in res) {
        const errorMessage = res.error.data?.message ?? 'An unknown error occurred';
        throw new Error(errorMessage);
      } else {
        setOpenDialogBox(true);
      }
    } catch (error) {
      if (error instanceof Error) {
        showToast(error.message, 'error');
      } else {
        showToast('An unknown error occurred', 'error');
      }
    }
  }

  if (props.isPending) {
    return copyLink;
  }

  if (item.dco_cart_id || item.orders.length > 1) {
    return reInitiateCart;
  }

  if (item.orders.length === 1) {
    return reInitiate;
  }

  return function () {
    showToast('Invalid Action', 'error');
  };
};

export default TableAction;
