export interface FormValues {
  arnCode: string;
  subArnCode: string;
  subBrokerCode: string;
  EUIN: string;
  modeType: 'Lumpsum' | 'SIP' | 'LumpsumAndSIP';
  investmentAmount: string;
  modeOfRegistration: string;
  frequency: string;
  sipDay: string;
  sipStartDate: Date | null;
  sipEndDate: Date | null; // Adjusted to accept Date or null
  installments: string;
  lumpsumAmount?: string; // Made lumpsumAmount optional based on modeType
  perpetual: boolean;
}

export interface FrequencyData {
  mode: string;
  minInstallments: number;
  minTransactionAmount: number;
  cycle: string;
  coolingPeriod: number;
}
export interface PaymentOption {
  label: string;
  value: string;
}
export interface MandateData {
  email: string;
  name: string;
  folio: number;
  entryDate: string;
  bnkacno: string;
  bank: string;
  amount: number;
  mobile: number;
  transactionDate: string;
  fromDate: string;
  toDate: string;
  fund: string;
  mode: null;
  umnr: string;
  ifsc: string;
}
export interface AllMandateData {
  kotmData: MandateData[];
  aotmData: MandateData[];
  umrnOptions: any[];
}
export interface Mandate {
  label: string;
  value: string;
  mandateLimit: number;
  startDate: string;
  endDate: string;
  bankAccountNumber: string;
  bankName: string;
}
export interface DropDownData {
  [key: string]: MinInstallmentsAllowed[];
}
export interface CheckoutPaymentFormValues {
  paymentMode?: string; // Selected Payment Option.
  umrn?: string; // Selected Mandate.
  modeOfRegistration?: string;
  selectedMandateObj?: Mandate;
  lumpsumUmrn?: string;
}
export interface FrequencyAndSipState {
  selectedFrequency: string | null;
  selectedSipDay: string | null;
  noOfInstallments: number;
  isPerpetual: boolean;
  selectedMode: string;
}
export interface SchemaData {
  category: string;
  code: string;
  fundName: string;
  schemeDesc: string;
  planDesc: string;
  isActive: string;
  description: string;
  type: string;
  planMode: string;
  nav: number;
  redemptionAllowed: boolean;
  swdAllowed: boolean;
  switchAllowed: boolean;
  stpiAllowed: boolean;
  minTransactionAmount: number;
  multiplesOf: number | null;
  frequency?: any;
  schemeName: string;
  optionDesc: string;
  scheme: string;
  plan: string;
  option: string;
}
export interface SelectedScheme {
  fund: string;
  amcName: string;
  folio: number;
  dob: string | null;
  investorName: string;
  mobile: string;
  email: string;
  schemeName: string;
  scheme: string;
  schemePlan: string;
  schemeOption: string;
  plan: string;
  option: string;
  planDesc: string;
  schemeDesc?: string;
  transactionSource: string;
  isDemat: boolean;
  taxsaverFlag: boolean;
  mcrid: boolean;
  category: string;
  modeOfHolding: string;
  mohDesc: string;
  units: number;
  nav: number;
  redemptionAllow: boolean;
  swdAllow: boolean;
  switchAllow: boolean;
  stpAllow: boolean;
  additionalPurAllow: boolean;
  newPurAllow: boolean;
}
export interface SchemesByFund {
  allSchemes: SchemaData[];
  categories: string[];
  selectedCategory: string;
}
export interface FolioType {
  label: any;
  value: any;
}
export interface ExistingAndNewFoliosType {
  existingFolios: FolioType[];
  newFolios: FolioType[];
}
export interface SelectedFolioDetails {
  selectedFolio: string;
  folioEmail: string;
  folioMobileNumber: string;
  folioInvestorName: string;
}
export interface MinInstallmentsAllowed {
  mode: string;
  minInstallments: number;
  minTransactionAmount: number;
  cycle: string[];
  coolingPeriod: number;
}
export const FIELD_NAMES = ['modeOfRegistration', 'umrn', 'paymentMode', 'lumpsumUmrn'];
