import { Box, CardMedia, Grid, Stack, Theme, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import InvestorProfileIcon from '../../../assets/investor-profile.png';
import VerifiedIcon from '../../../assets/verified-cart-icon.png';
import { Investorcard } from '../../auth/login/styles/styledComponents';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import { startLoading, stopLoading } from '../../common/Loader/loaderSlice';
import { useRootContext } from '../../data/root.context';
import { LoginResponse } from '../../features/login/loginSlice';
import { TransactionResponse } from '../../features/transactions/transactionSlice';
import FormDateInput from '../../forms/FormDateInput';
import { FormSelectInput } from '../../forms/FormSelect';
import SubmitButton from '../../forms/FormSubmitButton';
import Layout from '../Layout';
import { mapFrequency } from '../mfs/newInvestorOnboarding/utils';
import {
  useCancelSipMutation,
  useCancelStpMutation,
  useCancelSwpMutation,
  useGetCancelSipDatesMutation,
  useGetPauseSipDatesMutation,
  usePauseSipMutation,
} from '../slice';
import { inrCurrencyFormatter } from '../utils';
import CancellationReasonDialog from './CancellationReasonDialog';

type BasePayload = {
  fund: string;
  scheme: string;
  plan: string;
  option: string;
  ihno: number;
  branch: string;
  folio: number;
  mobile: string;
  email: string;
  transactionType: any;
  investorName: string;
  investorPan: string;
  frequency: string;
  euin: string;
  schemeName: string;
  remarks: string;
  amount: any;
};

type PausePayload = BasePayload & {
  sipStartDate: string;
  sipPauseStartDate: string;
  sipEndDate: string;
  noOfInstallments: any;
  sipPauseEndDate?: string;
};

type CancelSipPayload = BasePayload & {
  sipStartDate: string;
  remarks: string;
  sipEndDate: string;
};

type CancelStpPayload = BasePayload & {
  toScheme: string;
  toPlan: string;
  toOption: string;
  stpDay: string;
  // stpOption: string;
  toFolio: number;
  stpStartDate: string;
  stpEndDate: string;
  remarks: string;
};

type CancelSwpPayload = BasePayload & {
  swpStartDate: string;
  swpEndDate: string;
  remarks: string;
};
type PauseInitialValues = {
  startDate: Date | null;
  endDate: Date | null;
  pauseInstallments: string;
};
const validationSchema = Yup.object().shape({
  startDate: Yup.date()
    .transform((value, original: string) => {
      if (original) {
        const [day, month, year] = original.split('/');
        return new Date(`${year}-${month}-${day}`);
      }
      return value;
    })
    .typeError('Invalid date')
    .required('Start Date is required'),
  pauseInstallments: Yup.number().required('Pause Installments is required'),
  endDate: Yup.date()
    .transform((value, original: string) => {
      if (original) {
        const [day, month, year] = original.split('/');
        return new Date(`${year}-${month}-${day}`);
      }
      return value;
    })
    .typeError('Invalid date')
    .required('End Date is required'),
});
const Confirmation = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [cancelSipScheme] = useCancelSipMutation();
  const [cancelStpScheme] = useCancelStpMutation();
  const [cancelSwpScheme] = useCancelSwpMutation();
  const [pauseSipScheme] = usePauseSipMutation();
  const [getSipPauseDates] = useGetPauseSipDatesMutation();
  const [getSipCancelDates] = useGetCancelSipDatesMutation();
  const dispatch = useDispatch();
  const [allDates, setAllDates] = useState<any>([]);
  const [bodyContent, setBodyContent] = useState('');
  const [showCancellationDialogBox, setShowCancellationDialogBox] =
    useState<boolean>(false);
  const [selectedReason, setSelectedReason] = useState<string>('');
  const subtitle = `Please check the following details`;
  const isCancelType =
    state.trtType === 'SIPC' || state.trtType === 'STPC' || state.trtType === 'SWPC';

  const initialValues: PauseInitialValues = {
    startDate: null,
    endDate: null,
    pauseInstallments: '',
  };
  const {
    euin,
    arn,
    name: Dname,
    subArn,
  } = useSelector((state: { login: LoginResponse }) => state.login);

  const {
    pan: invPan,
    email,
    mobile,
    name,
  } = useSelector((state: { transactions: TransactionResponse }) => state.transactions);

  const { showToast } = useRootContext();
  const payload: BasePayload = {
    fund: state.payload?.schemeDetails?.fund || '',
    scheme: state.payload?.schemeDetails?.scheme || '',
    plan: state.payload?.schemeDetails?.plan || '',
    option: state.payload?.schemeDetails?.option || '',
    ihno: state.payload?.schemeDetails?.ihno || '',
    branch: state.payload?.schemeDetails?.branch || '',
    folio: state.payload?.schemeDetails?.folio || '',
    mobile:
      state?.payload?.investorDetails?.mobile || state?.investorDetails?.mobile || '',
    email: state?.payload?.investorDetails?.email || state?.investorDetails?.email || '',
    transactionType: state.trtType || '',
    investorName: name || '',
    investorPan: invPan || '',
    frequency: state.payload?.schemeDetails?.frequency || '',
    euin: euin || '',
    remarks: state.remarks || '',
    schemeName: state.payload?.schemeDetails?.schemeName || '',
    amount:
      Number(state.payload?.schemeDetails?.amount) ||
      Number(state.payload?.schemeDetails?.installmentAmount) ||
      Number(state.payload?.schemeDetails?.installment_amount) ||
      0,
  };
  const cancelPayload: CancelSipPayload = {
    ...payload,
    sipStartDate: format(
      new Date(
        state.payload.schemeDetails.startDate || state.payload.schemeDetails.start_date,
      ),
      'yyyy-MM-dd',
    ),
    sipEndDate: format(
      new Date(
        state.payload.schemeDetails.endDate || state.payload.schemeDetails.end_date,
      ),
      'yyyy-MM-dd',
    ),
  };

  const convertDateFormat = (dateString: string): string => {
    // Split the dateString into day, month, and year
    const [day, month, year] = dateString.split('/').map(Number);
    const date = new Date(year, month - 1, day);
    const formattedDate = date.toISOString().split('T')[0];

    return formattedDate;
  };
  const submitHandler = async (values: any) => {
    try {
      let res;
      dispatch(startLoading());
      if (state.trtType === 'PAUS') {
        // For PAUS
        const pausePayload: PausePayload = {
          ...payload,
          sipStartDate: state.payload.schemeDetails.startDate.split(' ')[0] || '',
          sipPauseStartDate: formatDate(values.startDate) || '', // Input Date
          sipEndDate: values.endDate,
          sipPauseEndDate: values.endDate || '',
          noOfInstallments: values.pauseInstallments,
        };
        res = await pauseSipScheme(pausePayload).unwrap();
      } else if (state.trtType === 'SIPC') {
        res = await cancelSipScheme(cancelPayload).unwrap();
      } else {
        setShowCancellationDialogBox(true);
      }
      const { message, ihno } = res;
      setBodyContent(`${message} with batch number ${ihno}`);
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

  useEffect(() => {
    async function fetchPauseDates() {
      try {
        dispatch(startLoading());
        const res = await getSipPauseDates({
          fund: state.payload.schemeDetails.fund,
          ihno: state.payload.schemeDetails.ihno,
        }).unwrap();
        const transformedArray = res.map((date: any) => ({
          label: date,
          value: date,
        }));
        setAllDates(transformedArray);
      } catch (error: any) {
        showToast((error.data as { message: string }).message, 'error');
      } finally {
        dispatch(stopLoading());
      }
    }
    if (!isCancelType) fetchPauseDates();
  }, []);

  const backButtonHandler = () => {
    setShowCancellationDialogBox(false);
  };
  const popupContent = (res: { message: string; ihno: number }) => {
    const { message, ihno } = res;
    setBodyContent(`${message} with batch number ${ihno}`);
  };
  const proceedButtonHandler = async () => {
    try {
      let res;
      dispatch(startLoading());
      if (state.trtType === 'STPC') {
        const stpPayload: CancelStpPayload = {
          ...cancelPayload,
          toScheme: state.payload.schemeDetails.toScheme,
          toPlan: state.payload.schemeDetails.toPlan,
          toOption: state.payload.schemeDetails.toOption,
          stpDay: format(new Date(state.payload.schemeDetails.startDate), 'yyyy-MM-dd'),
          // stpOption: 'string',
          toFolio: state.payload.schemeDetails.toAcno,
          stpStartDate: format(
            new Date(state.payload.schemeDetails.startDate),
            'yyyy-MM-dd',
          ),
          remarks: selectedReason,
          stpEndDate: format(new Date(state.payload.schemeDetails.endDate), 'yyyy-MM-dd'),
        };
        res = await cancelStpScheme(stpPayload).unwrap();
        popupContent(res);
      } else {
        const swpPayload: CancelSwpPayload = {
          ...cancelPayload,
          remarks: selectedReason,
          swpStartDate: format(
            new Date(
              state.payload.schemeDetails.startDate ||
                state.payload.schemeDetails.start_date,
            ),
            'yyyy-MM-dd',
          ),
          swpEndDate: format(
            new Date(
              state.payload.schemeDetails.endDate || state.payload.schemeDetails.end_date,
            ),
            'yyyy-MM-dd',
          ),
        };
        res = await cancelSwpScheme(swpPayload).unwrap();
        popupContent(res);
      }
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

  const DistributorProfile = () => {
    return (
      <Box sx={{ padding: '30px 20px' }}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography
            variant='subtitle1'
            sx={{ color: 'primary.main' }}
          >
            Distrubutor Profile
          </Typography>
          <CardMedia
            component='img'
            image={InvestorProfileIcon}
            alt='Mutual Fund Pooled Investment'
            sx={{ width: '44px', height: '40px' }}
          />
        </Stack>
        <Grid
          container
          spacing={2}
          my='10px'
        >
          <Grid
            item
            sm={6}
          >
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>ARN Code</Typography>
              <Typography
                variant='subtitle2'
                sx={{ color: 'text.valueColor' }}
              >
                {arn || 'NA'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            sm={6}
          >
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>ARN Name</Typography>
              <Typography
                variant='subtitle2'
                sx={{ color: 'text.valueColor' }}
              >
                {Dname || 'NA'}
              </Typography>
            </Stack>
          </Grid>
          <Grid
            item
            sm={6}
          >
            <Stack gap='5px'>
              <Typography sx={{ color: 'text.labelColor' }}>Sub ARN Code</Typography>
              <Typography
                variant='subtitle2'
                sx={{ color: 'text.valueColor' }}
              >
                {subArn || 'NA'}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const formatDate = (dateStr: string) => {
    if (dateStr === '') return;
    const [day, month, year] = dateStr.split('/');
    return `${year}-${month}-${day}`;
  };

  return (
    <Layout
      title='Confirmation'
      subtitle={subtitle}
      rightBottomComponent={state.trtType !== 'PAUS' && <DistributorProfile />}
      isNctTransaction
    >
      <Investorcard
        sx={theme => ({
          padding: '30px',
          [theme.breakpoints.down('md')]: {
            border: 'unset',
            boxShadow: 'unset',
            padding: '0px',
          },
        })}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={submitHandler}
          validationSchema={!isCancelType ? validationSchema : Yup.object()} // No validation required for cancel
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                {!isCancelType ? (
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: '14px',
                            xl: '16px',
                          },
                          fontWeight: 500,
                          mb: 1.5,
                          color: 'text.valueColor',
                        }}
                      >
                        Select Pause Start Date
                      </Typography>
                      <FormSelectInput
                        name='startDate'
                        label='SIP Pause Date'
                        options={allDates}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: '14px',
                            xl: '16px',
                          },
                          fontWeight: 500,
                          mb: 1.5,
                          color: 'text.valueColor',
                        }}
                      >
                        Select Pause Installments
                      </Typography>
                      <FormSelectInput
                        name='pauseInstallments'
                        label='Select Mode'
                        options={Array.from({ length: 6 }, (_, i) => ({
                          label: String(i + 1),
                          value: i + 1,
                        }))}
                        onChange={async e => {
                          if (values.startDate) {
                            try {
                              dispatch(startLoading());
                              const res = await getSipCancelDates({
                                startDate: formatDate(values.startDate.toString()),
                                installments: Number(e.target.value),
                                fund: state.payload.schemeDetails.fund,
                              }).unwrap();
                              if (res && res?.enddt) {
                                const endDate = res?.enddt;
                                setFieldValue('endDate', formatDate(endDate));
                              }
                            } catch (error: any) {
                              const message =
                                (error as any).data?.message ||
                                (error as any).message ||
                                'Unknown error';
                              showToast(message, 'error');
                            } finally {
                              dispatch(stopLoading());
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: '14px',
                            xl: '16px',
                          },
                          fontWeight: 500,
                          mb: 1.5,
                          color: 'text.valueColor',
                        }}
                      >
                        Sip Pause End Date
                      </Typography>
                      <FormDateInput
                        name='endDate'
                        placeholder='Sip Pause End Date'
                        label='Date'
                        viewOnly
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Typography
                      sx={{
                        color: 'primary.main',
                        mb: 2,
                        fontWeight: 500,
                        fontSize: { xs: '14px', sm: '16px', xl: '18px' },
                      }}
                    >
                      Scheme Details
                    </Typography>
                    <Grid
                      container
                      spacing={2}
                    >
                      <Grid
                        item
                        sm={12}
                        sx={{ mb: 2 }}
                      >
                        <Stack gap='10px'>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: '14px',
                              },
                              color: 'text.labelColor',
                            }}
                          >
                            {' '}
                            Scheme Name
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: '14px',
                                xl: '16px',
                              },

                              fontWeight: 500,
                              color: 'text.valueColor',
                            }}
                          >
                            {`${state.payload.schemeDetails?.schemeDesc} - ${state.payload.schemeDetails?.planMode} - ${state.payload.schemeDetails?.optionDesc}`}
                          </Typography>
                        </Stack>
                      </Grid>

                      <Grid
                        item
                        xs={6}
                        sm={4}
                        sx={{ mb: 2 }}
                      >
                        <Stack gap='10px'>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: '14px',
                              },
                              color: 'text.labelColor',
                            }}
                          >
                            {' '}
                            Folio
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: '14px',
                                xl: '16px',
                              },

                              fontWeight: 500,
                              color: 'text.valueColor',
                            }}
                          >
                            {' '}
                            {state.payload.schemeDetails.folio}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        sx={{ mb: 2 }}
                      >
                        <Stack gap='10px'>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: '14px',
                              },
                              color: 'text.labelColor',
                            }}
                          >
                            {' '}
                            Installment Amount
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: '14px',
                                xl: '16px',
                              },

                              fontWeight: 500,
                              color: 'text.valueColor',
                            }}
                          >
                            ₹
                            {inrCurrencyFormatter(
                              state.payload.schemeDetails.installmentAmount,
                            )}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        sx={{ mb: 2 }}
                      >
                        <Stack gap='10px'>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: '14px',
                              },
                              color: 'text.labelColor',
                            }}
                          >
                            {' '}
                            Frequency
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: '14px',
                                xl: '16px',
                              },

                              fontWeight: 500,
                              color: 'text.valueColor',
                            }}
                          >
                            {state.payload.schemeDetails.frequency.length > 1
                              ? state.payload.schemeDetails.frequency.length
                              : mapFrequency(state.payload.schemeDetails.frequency)}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        sx={{ mb: 2 }}
                      >
                        <Stack gap='10px'>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: '14px',
                              },
                              color: 'text.labelColor',
                            }}
                          >
                            {' '}
                            Registration Date
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: '14px',
                                xl: '16px',
                              },

                              fontWeight: 500,
                              color: 'text.valueColor',
                            }}
                          >
                            {format(
                              new Date(state.payload.schemeDetails.registerDate),
                              'dd/MM/yyyy',
                            )}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        sx={{ mb: 2 }}
                      >
                        <Stack gap='10px'>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: '14px',
                              },
                              color: 'text.labelColor',
                            }}
                          >
                            {' '}
                            From Date
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: '14px',
                                xl: '16px',
                              },

                              fontWeight: 500,
                              color: 'text.valueColor',
                            }}
                          >
                            {format(
                              new Date(
                                state.payload.schemeDetails.startDate ||
                                  state.payload.schemeDetails.start_date,
                              ),
                              'dd/MM/yyyy',
                            )}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        sx={{ mb: 2 }}
                      >
                        <Stack gap='10px'>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: '14px',
                              },
                              color: 'text.labelColor',
                            }}
                          >
                            {' '}
                            To Date
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: '14px',
                                xl: '16px',
                              },

                              fontWeight: 500,
                              color: 'text.valueColor',
                            }}
                          >
                            {format(
                              new Date(
                                state.payload.schemeDetails.endDate ||
                                  state.payload.schemeDetails.end_date,
                              ),
                              'dd/MM/yyyy',
                            )}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        sx={{ mb: 2 }}
                      >
                        <Stack gap='10px'>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: '14px',
                              },
                              color: 'text.labelColor',
                            }}
                          >
                            {' '}
                            Cancel Reason
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: {
                                xs: '14px',
                                xl: '16px',
                              },

                              fontWeight: 500,
                              color: 'text.valueColor',
                            }}
                          >
                            {state?.remarks}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </>
                )}
                <SubmitButton
                  label={
                    state.trtType === 'STPC' || state.trtType === 'SWPC'
                      ? 'Cancel'
                      : !isCancelType
                      ? 'Pause'
                      : 'Confirm'
                  }
                  sx={(theme: Theme) => ({
                    my: 3,
                    [theme.breakpoints.down(600)]: {
                      maxWidth: '100%',
                      width: '100%',
                    },
                  })}
                  fullWidth
                />
              </Form>
            );
          }}
        </Formik>
      </Investorcard>

      <CustomDialog
        isVisible={!!bodyContent}
        showFirstButton={false}
        firstButtonTitle=''
        firstButtonHandler={() => {}}
        secondButtonHandler={() => navigate('../')}
        secondButtonTitle='OK'
        handleClose={() => {}}
        showSecondButton
      >
        <Box
          sx={{
            'textAlign': 'center',
            'pt': 2,
            '& .MuiTypography-root': {
              'fontSize': '16px',
              'color': 'text.grayishBlue',
              'my': 2,
              '& >*': {
                fontWeight: '600',
              },
            },
          }}
        >
          <img
            src={VerifiedIcon}
            alt='verified-icon'
          />

          <Typography variant='body1'>{bodyContent}</Typography>
        </Box>
      </CustomDialog>
      <CancellationReasonDialog
        showDialog={showCancellationDialogBox}
        backButtonHandler={backButtonHandler}
        proceedButtonHandler={proceedButtonHandler}
        selectedReason={selectedReason}
        setSelectedReason={setSelectedReason}
      />
    </Layout>
  );
};

export default Confirmation;
