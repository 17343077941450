export interface Employee {
  name: string;
  empId: string;
  subbrokerCode: string;
  email: string;
  mobile: string;
  arn: string;
  euin: string;
  department: string;
  role: string;
  officeState: string;
  officeBranch: string;
  active: boolean;
  profileStatus: string;
  isAdmin: boolean;
  passwordChanged: boolean;
  Manager: any; // TODO: need to see the type
  disabledByAdmin: boolean;
}
export interface SelectedRow {
  'Name': string;
  'Role': string;
  'ID': string;
  'Mobile Number': string;
  'Email': string;
  'Status': string;
  'additionalData': Employee;
}
export interface TabValuesStates {
  tabValueMain: string;
}
export interface AddTabValuesStates {
  tabValuesUsers: string;
}
export interface AddUserFields {
  name: string;
  department: string;
  employeeOrSubBrokerId: string;
  mobileNo: string;
  email: string;
  role: string;
  L1ManagerOrRM1Manager: string;
  L2ManagerOrRM2Manager: string;
  state: string;
  branch: string;
}
export interface EmployeeOrSubBroker {
  name: string;
  empId: string | null;
  subbrokerCode: string | null;
  email: string;
  mobile: string;
  arn: string;
  euin: string | null;
  department: string;
  role: string;
  officeState: string;
  officeBranch: string;
  active: boolean;
  profileStatus: string;
  isAdmin: boolean;
  passwordChanged: boolean;
}
export const FIELD_NAMES_ADD_USERS = [
  'role',
  'name',
  'department',
  'employeeOrSubBrokerId',
  'mobileNo',
  'email',
  'L1ManagerOrRM1Manager',
  'L2ManagerOrRM2Manager',
  'state',
  'branch',
];
export interface ManagerOption {
  value: string | null;
  label: string;
}
