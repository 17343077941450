import jwtDecode from 'jwt-decode';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useRootContext } from '../../data/root.context';
import { LoginResponse, setToken } from '../../features/login/loginSlice';
import { useLazyRefreshTokenQuery } from '../signup/slices';

interface TokenPayload {
  exp: number;
}

const INACTIVITY_LIMIT = 5 * 60 * 1000;
const TOKEN_EXPIRY_THRESHOLD = 3 * 60 * 1000;

const useAuthManagement = () => {
  const [userIsActive, setUserIsActive] = useState(true);
  const inactivityTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useDispatch();
  const { showToast } = useRootContext();
  const [refreshToken] = useLazyRefreshTokenQuery();

  const { token } = useSelector((state: { login: LoginResponse }) => state.login);

  const resetInactivityTimer = useCallback(() => {
    if (inactivityTimeoutRef.current) {
      clearTimeout(inactivityTimeoutRef.current);
    }

    setUserIsActive(true);

    inactivityTimeoutRef.current = setTimeout(() => {
      setUserIsActive(false);
    }, INACTIVITY_LIMIT);
  }, []);

  const logoutUser = useCallback(() => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/auth/login';
  }, []);

  const checkTokenExpiry = useCallback(async () => {
    try {
      const tokenPayload: TokenPayload = jwtDecode(token);
      const expiryTime = tokenPayload.exp * 1000;
      const currentTime = Date.now();

      if (expiryTime <= currentTime) {
        // Token is expired, log out the user
        logoutUser();
        return;
      }

      if (expiryTime - currentTime <= TOKEN_EXPIRY_THRESHOLD) {
        // Token is close to expiring, try to refresh it
        if (userIsActive) {
          const res = await refreshToken('').unwrap();
          if (!res?.jwtToken) {
            logoutUser();
            return;
          }
          dispatch(setToken(res.jwtToken));
          resetInactivityTimer();
        } else {
          // User is inactive, log them out instead of refreshing the token
          logoutUser();
        }
      }
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
      // In case of any error during token validation or refresh, log out the user
      logoutUser();
    }
  }, [
    token,
    userIsActive,
    dispatch,
    logoutUser,
    refreshToken,
    resetInactivityTimer,
    showToast,
  ]);

  useEffect(() => {
    if (!token) return;

    const events = [
      'mousemove',
      'keypress',
      'click',
      'scroll',
      'touchstart',
      'touchend',
      'touchmove',
      'keydown',
      'keyup',
      'pointermove',
      'pointerdown',
      'pointerup',
      'dragstart',
      'dragend',
      'visibilitychange',
    ];

    const throttledResetInactivityTimer = throttle(resetInactivityTimer, 1000);

    events.forEach(event =>
      document.addEventListener(event, throttledResetInactivityTimer),
    );
    const intervalId = setInterval(checkTokenExpiry, 1 * 60 * 1000);

    return () => {
      events.forEach(event =>
        document.removeEventListener(event, throttledResetInactivityTimer),
      );
      if (inactivityTimeoutRef.current) {
        clearTimeout(inactivityTimeoutRef.current);
      }
      clearInterval(intervalId);
    };
  }, [token, resetInactivityTimer, checkTokenExpiry]);

  useEffect(() => {
    if (!token) return;
    resetInactivityTimer();
  }, [token, resetInactivityTimer]);

  return userIsActive;
};

// Utility function for throttling
function throttle(func: Function, limit: number) {
  let inThrottle: boolean;
  return function (this: any, ...args: any[]) {
    if (!inThrottle) {
      func.apply(this, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
}

export default useAuthManagement;
