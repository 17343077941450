import { Box, Divider, Grid, SelectChangeEvent, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Form, Formik, FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import VerifiedIcon from '../../../assets/verified-cart-icon.png';
import { useDebounce } from '../../../hooks/useDebounce';
import { calculateDifference } from '../../../utils';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import { startLoading, stopLoading } from '../../common/Loader/loaderSlice';
import { useRootContext } from '../../data/root.context';
import { LoginResponse } from '../../features/login/loginSlice';
import { TransactionResponse } from '../../features/transactions/transactionSlice';
import FormCheckbox from '../../forms/FormCheckbox';
import FormDateInput from '../../forms/FormDateInput';
import { FormSelectInput } from '../../forms/FormSelect';
import { FormStyledRadioButton } from '../../forms/FormStyledRadioButton';
import FormTextInput from '../../forms/FormTextInput';
import { FieldSubHeader3, MainHeader } from '../../onboarding/styles/styledComponents';
import { arnRegex } from '../common';
import SubmitCartButtons from '../common/SubmitCartButtons';
import EuinsFields from '../euinsComponent';
import { mapFrequency } from '../mfs/newInvestorOnboarding/utils';
import {
  useAddItemToCartMutation,
  useGetEnachAotmDetailsMutation,
  useGetPaymentModesByFundMutation,
  useGetSchemaDetailsMutation,
  useGetSipStartandEndDatesMutation,
} from '../slice';
import { AlpModeType, AplTrtType, ModeTypeToTrtType } from '../types';
import { getPerpetualSipEndDate } from '../utils';
import {
  APL_MODE_TYPES,
  findMinInstallmentAllowed,
  initialRegistrationModesForSIP,
  processFrequencyData,
} from './common';
import {
  DropDownData,
  FormValues,
  FrequencyAndSipState,
  MinInstallmentsAllowed,
  PaymentOption,
} from './types';

interface AdditionalPurchaseComponentProps {
  data?: any;
  isEditMode: boolean;
  isNewScheme?: boolean;
}
const validationSchema = Yup.object().shape({
  arnCode: Yup.string().matches(arnRegex, 'Invalid ARN Code'),
  subArnCode: Yup.string().matches(arnRegex, 'Invalid Sub ARN Code'),
  modeType: Yup.string().required('Mode of Transaction is required'),
  investmentAmount: Yup.string()
    .required('Investment Amount is required')
    .min(1, 'Minimum investment amount should not be 0')
    .matches(/^[0-9]+$/, 'Investment Amount must contain only numbers'),
  modeOfRegistration: Yup.string().when(['modeType', 'investmentAmount'], {
    is: (modeType: string, investmentAmount: string) =>
      modeType !== 'Lumpsum' && !!investmentAmount,
    then: Yup.string().required('Mode of Registration is required'),
  }),
  frequency: Yup.string().when(['modeType', 'investmentAmount'], {
    is: (modeType: string, investmentAmount: string) =>
      modeType !== 'Lumpsum' && !!investmentAmount,
    then: Yup.string().required('Frequency is required'),
  }),
  sipDay: Yup.string().when(['modeType', 'investmentAmount'], {
    is: (modeType: string, investmentAmount: string) =>
      modeType !== 'Lumpsum' && !!investmentAmount,
    then: Yup.string().required('SIP Day is required'),
  }),
  installments: Yup.string().when(['modeType', 'investmentAmount'], {
    is: (modeType: string, investmentAmount: string) =>
      modeType !== 'Lumpsum' && !!investmentAmount,
    then: Yup.string().required('No. of Installments is required'),
  }),
  lumpsumAmount: Yup.string().when('modeType', {
    is: (modeType: string) => modeType === 'LumpsumAndSIP',
    then: Yup.string()
      .required('Lumpsum Amount is required')
      .min(1, 'Minimum Lumpsum amount should not be 0')
      .matches(/^[0-9]+$/, 'Lumpsum Amount must contain only numbers'),
  }),
});
const FIELD_NAMES = [
  'modeType',
  'modeOfRegistration',
  'frequency',
  'installments',
  'sipDay',
  'sipStartDate',
  'sipEndDate',
  'perpetual',
  'lumpsumAmount',
  'investmentAmount',
];
const AdditionalPurchaseComponent: React.FC<AdditionalPurchaseComponentProps> = ({
  data,
  isEditMode,
  isNewScheme,
}) => {
  const [schemaData, setSchemaData] = useState<any>({});
  const [dropDownData, setDropDownData] = useState<DropDownData>({});
  const cartItem = data?.isFromCart;
  const { state } = useLocation();
  const { showToast } = useRootContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { arn, euin, subArn, subbrokerCode } = useSelector(
    (state: { login: LoginResponse }) => state.login,
  );
  const {
    email: reduxEmail,
    mobile: reduxMob,
    name: reduxName,
  } = useSelector((state: { transactions: TransactionResponse }) => state.transactions);
  const [allPaymentOptions, setAllPaymentOptions] = useState<PaymentOption[]>([]);
  const [openDialogBox, setOpenDialogBox] = useState(false);

  const [selectedTransactionCart, setSelectedTransactionCart] = useState<string>('APL');

  const parsePayload = cartItem?.[0]?.payload ? JSON.parse(cartItem[0].payload) : {};
  const initialValues: FormValues = {
    arnCode: arn,
    subArnCode: subArn || '',
    subBrokerCode: subbrokerCode || '',
    EUIN: cartItem?.[0]?.euin || parsePayload?.euin || '',
    modeType: AlpModeType[selectedTransactionCart as keyof typeof AlpModeType],
    investmentAmount: '',
    modeOfRegistration: data?.modeOfRegistration || '',
    frequency: '',
    sipDay: data?.sipDay || '',
    sipStartDate: null,
    sipEndDate: null,
    installments: data?.noOfInstallments || '',
    lumpsumAmount: data?.amount || '',
    perpetual: false,
  };

  const [selectedFrequencyAndSip, setSelectedFrequencyAndSip] =
    useState<FrequencyAndSipState>({
      selectedFrequency: null,
      selectedSipDay: null,
      noOfInstallments: 0,
      isPerpetual: false,
      selectedMode: 'Lumpsum',
    });
  const [getPaymentModesByFund] = useGetPaymentModesByFundMutation();
  const [getSchemaDetails] = useGetSchemaDetailsMutation();
  const [getAotmDetails] = useGetEnachAotmDetailsMutation();

  const [addALPItemToCart] = useAddItemToCartMutation();
  const formikRef = useRef<FormikProps<FormValues>>(null);

  const firstButtonTitle = 'Add to Cart';
  const secondButtonTitle = 'Save & Proceed';
  const [isPanBasedAotm, setIsPanBasedAotm] = useState<boolean>(false);
  const [getSipStartandEndDates] = useGetSipStartandEndDatesMutation();
  const [minDetailsObj, setMinDetailsObj] = useState<MinInstallmentsAllowed>(
    {} as MinInstallmentsAllowed,
  );
  const [hasAnyErrorOccured, setHasAnyErrorOccured] = useState<boolean>(false);
  const minTransactionAmountAllowed =
    formikRef?.current?.values.modeType === 'Lumpsum'
      ? schemaData?.minTransactionAmount
      : minDetailsObj?.minTransactionAmount;
  const [cartResponse, setCartResponse] = useState<any>({});
  const handleSubmit = async (values: any) => {
    const {
      EUIN,
      subBrokerCode,
      arnCode,
      subArnCode,
      modeType,
      investmentAmount,
      paymentMode,
      installments,
      modeOfRegistration,
      sipDay,
      frequency,
      lumpsumAmount,
    } = values;
    const {
      fundName,
      category,
      mobile,
      email,
      pan,
      folio,
      fund,
      scheme,
      plan,
      option,
      schemeDesc,
      amcName,
      investorName,
      name,
      folioNo,
      planMode,
      optionDesc,
      isNewScheme,
      schemeName,
    } = data;
    let payload;
    const commonPayload = {
      euin: EUIN,
      euinDeclaration: EUIN !== '' ? 'Y' : 'N',
      fundName: fundName || amcName,
      category: category || '',
      subBroker: subBrokerCode || '',
      arnCode: arnCode,
      subArn: subArnCode || '',
      remarks: '0',
    };
    const makeAdditionalPurchasePayload = {
      ...commonPayload,
      mobile: mobile || reduxMob || '',
      email: email || reduxEmail || '',
      investorName: investorName || name || reduxName || '',
      pan: pan || '',
      folio: folio || folioNo || '',
      fund: fund || '',
      scheme: scheme || '',
      plan: plan || '',
      option: option || '',
      planMode: planMode || '',
      optionDesc: optionDesc || '',
      schemeName: isNewScheme
        ? `${schemeDesc} - ${planMode} - ${optionDesc}`
        : `${schemeName} - ${planMode} - ${optionDesc}`,
      transactionType: 'APL',
      isPanBasedAotm,
      isNriInvestor: data?.investorCategory !== 'I',
      purchaseType: isNewScheme ? 'NEW' : 'EXISTING',
    };
    switch (modeType) {
      case 'Lumpsum':
        payload = {
          ...makeAdditionalPurchasePayload,
          modeType: modeType,
          amount: investmentAmount,
          paymentMode: paymentMode,
          createMandate: false,
          pioTransaction: false,
        };

        break;

      case 'SIP':
        payload = {
          ...makeAdditionalPurchasePayload,
          modeType,
          amount: 0,
          sipFrequency: frequency,
          sipStartDate: format(new Date(values?.sipStartDate as Date), 'MM/dd/yyyy'),
          sipEndDate: format(new Date(values?.sipEndDate as Date), 'MM/dd/yyyy'),
          noOfInstallments: installments,
          modeOfRegistration,
          installmentAmount: investmentAmount,
          sipDay,
        };
        break;

      case 'LumpsumAndSIP':
        payload = {
          ...makeAdditionalPurchasePayload,
          modeType,
          amount: lumpsumAmount,
          sipFrequency: frequency,
          sipStartDate: format(new Date(values?.sipStartDate as Date), 'MM/dd/yyyy'),
          sipEndDate: format(new Date(values?.sipEndDate as Date), 'MM/dd/yyyy'),
          noOfInstallments: installments,
          modeOfRegistration,
          installmentAmount: investmentAmount,
          paymentMode,
          sipDay,
        };
        break;

      default:
        break;
    }
    navigate('/transaction-dashboard/confirmation', {
      state: { payload, isEditMode: false },
    });
  };

  const handleGoToCart = () => {
    navigate('../../cart');
  };
  const handleAddAnotherTransaction = () => {
    setOpenDialogBox(false);
    navigate('../', {
      state: { cartItem: cartResponse?.cartItems, isFormCart: true },
      replace: true,
    });
  };
  const handleAddToCartClick = async () => {
    const errors: any = await formikRef.current?.validateForm();
    if (Object.keys(errors).length > 0) {
      formikRef.current?.setTouched(errors);
      return;
    }
    try {
      dispatch(startLoading());
      let payload: any = {
        euin: formikRef.current?.values?.EUIN || '',
        euinDeclaration: formikRef.current?.values?.EUIN !== '' ? 'N' : 'Y',
        subArn: formikRef.current?.values?.subArnCode,
        purchaseType: isNewScheme ? 'NEW' : 'EXISTING',
      };
      const sipDates = {
        sipFrequency: formikRef?.current?.values?.frequency as string,
        sipStartDate: format(
          new Date(formikRef?.current?.values?.sipStartDate as Date),
          'MM/dd/yy',
        ),
        sipEndDate: format(
          new Date(formikRef?.current?.values?.sipEndDate as Date),
          'MM/dd/yy',
        ),
        noOfInstallments: formikRef?.current?.values?.installments,
      };
      const schemeAndInvestorDetails = {
        pan: data?.pan,
        fundName: state?.schemeDetails?.isNewScheme
          ? schemaData?.fundName
          : data?.fundName || data?.amcName,
        folio: data?.folio || data?.folioNo,
        fund: data?.fund,
        scheme: data?.scheme,
        plan: data?.plan,
        option: data?.option,
        mobile: data?.mobile || reduxMob,
        email: data?.email || reduxEmail,
        schemeName: state?.schemeDetails?.isNewScheme
          ? `${schemaData?.schemeDesc} - ${schemaData?.planMode} - ${schemaData?.optionDesc}`
          : `${data?.schemeName} - ${data?.planMode} - ${data?.optionDesc}`,
        investorName: data?.investorName || data?.name || reduxName,
      };
      if (formikRef.current?.values?.modeType === 'Lumpsum') {
        payload = {
          ...payload,
          ...schemeAndInvestorDetails,
          amount: formikRef.current?.values?.investmentAmount,
          transactionType: 'APL',
          category: data?.category || '',
          purchaseType: isNewScheme ? 'NEW' : 'EXISTING', // For Lumpsum
        };
      } else if (formikRef.current?.values?.modeType === 'SIP') {
        payload = {
          ...payload,
          ...schemeAndInvestorDetails,
          category: data?.category,
          amount: 0,
          remarks: '0',
          ...sipDates,
          modeOfRegistration: formikRef?.current?.values?.modeOfRegistration,
          installmentAmount: formikRef?.current?.values?.investmentAmount,
          transactionType: 'ISIP',
          paymentMode: '',
          sipDay: formikRef?.current?.values.sipDay,
        };
      } else {
        payload = {
          ...payload,
          ...schemeAndInvestorDetails,
          category: data?.category,
          amount: formikRef?.current?.values?.lumpsumAmount,
          remarks: '0',
          ...sipDates,
          modeOfRegistration: formikRef?.current?.values?.modeOfRegistration,
          installmentAmount: formikRef?.current?.values?.investmentAmount,
          transactionType: 'SIN',
          sipDay: formikRef?.current?.values?.sipDay,
        };
      }
      const response = await addALPItemToCart(payload).unwrap();
      setCartResponse(response);
      setOpenDialogBox(true);
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

  const handleValidation = async (values: Record<string, any>) => {
    let errors: Record<string, any> = {};
    const minInstallments = minDetailsObj?.minInstallments;
    const minTransactionAmount =
      values.modeType === 'Lumpsum'
        ? schemaData?.minTransactionAmount
        : minDetailsObj?.minTransactionAmount;
    if (!Number(values?.installments) && values?.modeType !== 'Lumpsum') {
      errors.installments = `Installments are required`;
    } else if (minInstallments && minInstallments > values.installments) {
      errors.installments = `Min installments allowed ${minInstallments}`;
    }
    if (minTransactionAmount && Number(values.investmentAmount) < minTransactionAmount) {
      errors.investmentAmount = `Min installment amount allowed ${minTransactionAmount}`;
    }
    if (
      values.modeType === 'LumpsumAndSIP' &&
      Number(values.lumpsumAmount) < minTransactionAmount
    ) {
      errors.lumpsumAmount = `Min installment amount allowed ${minTransactionAmount}`;
    }
    return errors;
  };

  useEffect(() => {
    const fetchSchemaDetails = async () => {
      const payload = {
        fund: data?.fund,
        scheme: data?.scheme,
        plan: data?.plan,
        option: data?.option,
        trType:
          selectedFrequencyAndSip?.selectedMode === 'Lumpsum'
            ? isNewScheme
              ? 'NPL'
              : 'APL'
            : 'SIP',
      };

      const result: any = await getSchemaDetails(payload).unwrap();
      if (result.error) {
        throw new Error(result.error.data.message || result.error.message);
      }

      setSchemaData(result);
      if (result?.frequency) {
        const frequencyDataObject = processFrequencyData(result?.frequency);
        setDropDownData(frequencyDataObject);
      }
    };

    const fetchAllDetails = async () => {
      try {
        dispatch(startLoading());
        await fetchSchemaDetails();
      } catch (error: any) {
        setHasAnyErrorOccured(true);
        showToast((error.data as { message: string }).message, 'error');
      } finally {
        dispatch(stopLoading());
      }
    };

    if (data && selectedFrequencyAndSip?.selectedMode) {
      fetchAllDetails();
    }
  }, [data, selectedFrequencyAndSip?.selectedMode]);
  useEffect(() => {
    const getAotmStatus = async () => {
      const response: any = await getAotmDetails({ fund: data?.fund }).unwrap();
      if (response.error) {
        throw new Error(response.error.data.message);
      }
      const { enach_aotm_basedon } = response;
      setIsPanBasedAotm(enach_aotm_basedon.toUpperCase() === 'PAN');
    };

    const fetchRegistrationModes = async () => {
      const paymentModesResponse = await getPaymentModesByFund({
        fund: data?.fund,
      }).unwrap();

      if (paymentModesResponse.error) {
        throw new Error(paymentModesResponse.error.data.message);
      }

      const registrationOptions = Object.entries(paymentModesResponse)
        .filter(([_, value]) => value === 'Y')
        .map(([key, _]) => ({ label: key.toUpperCase(), value: key.toUpperCase() }));

      let filteredModes = initialRegistrationModesForSIP(
        registrationOptions,
        isPanBasedAotm,
      );
      setAllPaymentOptions(filteredModes);
    };
    const fetchData = async () => {
      try {
        await Promise.all([getAotmStatus(), fetchRegistrationModes()]);
      } catch (error: any) {
        setHasAnyErrorOccured(true);
        const message = error?.data?.message || error?.message || 'Unknown error';
        showToast(message, 'error');
      }
    };

    if (data?.fund) {
      fetchData();
    }
    if (data?.isFromCart && cartItem?.[0].transactionType) {
      setSelectedTransactionCart(cartItem?.[0].transactionType);
      setSelectedFrequencyAndSip({
        ...selectedFrequencyAndSip,
        selectedMode:
          AplTrtType[cartItem?.[0].transactionType as keyof typeof AplTrtType],
      });
    }
  }, [data?.fund]);

  const clearAllValues = (
    setFieldValue: (field: string, value: any) => void,
    allValues?: boolean,
  ) => {
    setFieldValue('sipDay', '');
    setFieldValue('installments', '');
    setFieldValue('frequency', '');
    setFieldValue('sipStartDate', null);
    setFieldValue('sipEndDate', null);
    setFieldValue('perpetual', false);
    if (allValues) {
      setFieldValue('investmentAmount', '');
      setFieldValue('lumpsumAmount', '');
    }
  };
  const handlePerpetualChange = (
    checked: boolean,
    setFieldValue: (field: string, value: any) => void,
    values: FormValues,
  ): void => {
    if (checked) {
      const end_date = getPerpetualSipEndDate(
        values.modeOfRegistration as string,
        values.sipStartDate as Date,
      );
      const selectedInstallments = calculateDifference(
        new Date(values?.sipStartDate as Date),
        new Date(end_date),
        mapFrequency(values?.frequency) as string,
      );

      setFieldValue('sipEndDate', end_date);
      setFieldValue('installments', selectedInstallments);
    } else {
      // clearing the values
      clearAllValues(setFieldValue);
      setFieldValue('modeOfRegistration', '');
    }
  };

  const resetLaterFields = (
    values: FormValues,
    key: keyof FormValues,
    fieldValues: Partial<FormValues>,
  ): FormValues => {
    const index = FIELD_NAMES.findIndex(fieldName => fieldName === key);
    const updatedValues = FIELD_NAMES.slice(index).reduce<Partial<FormValues>>(
      (acc, curr) => {
        return {
          ...acc,
          [curr]:
            curr === 'sipStartDate' ||
            curr === 'sipStartDate' ||
            curr === 'sipEndDate' ||
            curr === 'sipEndDate'
              ? null
              : '',
        };
      },
      {},
    );
    return { ...values, ...updatedValues, ...fieldValues };
  };

  const getSipData = useDebounce(async (values, setFieldValue) => {
    try {
      dispatch(startLoading());
      const { startDate, endDate } = await getSipStartandEndDates({
        fund: data?.fund,
        scheme: data?.scheme,
        tenant: 'IRIS',
        installments: Number(values?.installments),
        frequency: values.frequency,
        trtype: ModeTypeToTrtType[values?.modeType as keyof typeof ModeTypeToTrtType],
        day: values.sipDay,
        folio: data?.folio,
        modeofregistration: data?.modeOfRegistration,
      }).unwrap();

      setFieldValue('sipStartDate', startDate);
      setFieldValue('sipEndDate', endDate);
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  }, 500);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      innerRef={formikRef}
      validate={async values => handleValidation(values)}
      enableReinitialize
    >
      {({ values, setFieldValue, setValues, setTouched }) => {
        const clearTouchedFields = () => {
          setTouched({});
        };
        return (
          <Form>
            <Typography
              sx={{
                fontSize: {
                  xs: '14px',
                  xl: '18px',
                },
                fontWeight: 500,
                color: { xs: 'text.inputLabelText', sm: 'primary.main' },
                mb: 2,
              }}
            >
              Distributor Details
            </Typography>
            {/* Distributor details Grid */}
            <Grid
              container
              spacing={2}
              sx={{ mb: 2 }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
              >
                <FormTextInput
                  name='arnCode'
                  label='ARN Code'
                  disabled
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={6}
              >
                <FormTextInput
                  name='subArnCode'
                  label='Sub ARN Code'
                  required={false}
                  disabled //TODO: Need to change subArn and subBroker disabled condtion based on user login.
                  typeOfInput='alphanumeric'
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
              >
                <FormTextInput
                  name='subBrokerCode'
                  label='Sub Broker Code'
                  required={false}
                  disabled
                  typeOfInput='alphanumeric'
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={6}
              >
                <EuinsFields
                  name='EUIN'
                  defaultValue={values.EUIN}
                  disabled={!!data?.isFromCart && data?.isFromCart?.length > 0}
                />
              </Grid>
            </Grid>
            <Divider
              orientation='horizontal'
              variant='fullWidth'
              flexItem
              sx={{
                border: '0.5px dashed',
                borderColor: 'text.borderColorDark',
                my: 3,
                mx: -2,
              }}
            />

            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                sm={12}
              >
                <Typography
                  sx={{
                    color: 'text.inputLabelText',
                    mb: 3,
                    fontWeight: 500,
                    fontSize: { xs: '14px', xl: '16px' },
                  }}
                >
                  Mode of Transaction *
                </Typography>
                <Box sx={{ mb: 3 }}>
                  <FormStyledRadioButton
                    options={APL_MODE_TYPES}
                    name='modeType'
                    disabled={!!data?.isFromCart && data?.isFromCart?.length > 0}
                    handleChange={(e: any) => {
                      setSelectedFrequencyAndSip({
                        ...selectedFrequencyAndSip,
                        selectedMode: e.target.value,
                      });
                      const newValues = resetLaterFields(values, 'modeType', {
                        modeType: e.target.value,
                      });
                      setValues(newValues);
                      setMinDetailsObj({} as MinInstallmentsAllowed);
                      clearTouchedFields();
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid
              sx={{ position: 'relative' }}
              container
              spacing={2}
            >
              {values.modeType !== 'Lumpsum' && (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <FormSelectInput
                      name='modeOfRegistration'
                      label='Mode of Registration'
                      defaultValue={''}
                      options={allPaymentOptions}
                      onChange={event => {
                        const newValues = resetLaterFields(values, 'modeOfRegistration', {
                          modeOfRegistration: event.target.value as string,
                        });
                        setValues(newValues);
                        clearTouchedFields();
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <FormSelectInput
                      name='frequency'
                      label='Frequency'
                      defaultValue={''}
                      options={Object.keys(dropDownData).map(entry => ({
                        value: entry,
                        label: mapFrequency(entry.toUpperCase()),
                      }))}
                      onChange={(e: SelectChangeEvent<any>) => {
                        const newValues = resetLaterFields(values, 'frequency', {
                          frequency: e.target.value as string,
                        });
                        const sortedAllData = [...dropDownData[e.target.value]].sort(
                          (a, b) => a.minInstallments - b.minInstallments,
                        );
                        setMinDetailsObj(sortedAllData[0]);
                        setValues(newValues);
                        clearTouchedFields();
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormTextInput
                      name='installments'
                      label='No. of Installments'
                      disabled={values.perpetual}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const result = findMinInstallmentAllowed(
                          dropDownData[values?.frequency?.toUpperCase()],
                          values?.frequency,
                          Number(e.target.value),
                        );
                        if (result) {
                          setMinDetailsObj({
                            ...minDetailsObj,
                            minTransactionAmount: result?.minTransactionAmount,
                          });
                        }
                        const newValues = resetLaterFields(values, 'installments', {
                          installments: e.target.value as string,
                        });
                        setValues(newValues);
                        clearTouchedFields();
                      }}
                      typeOfInput='number'
                    />

                    {Object.keys(minDetailsObj || {})?.length > 0 && (
                      <Typography>
                        Minimum Number of installments:
                        {minDetailsObj?.minInstallments || 0}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <FormSelectInput
                      name='sipDay'
                      label='SIP Day'
                      defaultValue={''}
                      //from first object
                      options={dropDownData[values?.frequency]?.[0]?.cycle?.map(
                        entry => ({
                          value: entry,
                          label: entry.toString(),
                        }),
                      )}
                      onChange={(e: SelectChangeEvent<any>) => {
                        const newValues = resetLaterFields(values, 'sipDay', {
                          sipDay: e.target.value as string,
                        });
                        setValues(newValues);
                        getSipData(newValues, setFieldValue);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <FormDateInput
                      name='sipStartDate'
                      placeholder='SIP Start Date'
                      label='SIP Start Date'
                      // value={areAllTheValuesEmpty(values) ? '' : values.sipStartDate}
                      viewOnly
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <FormDateInput
                      name='sipEndDate'
                      placeholder='SIP End Date'
                      label='SIP End Date'
                      viewOnly
                      // value={areAllTheValuesEmpty(values) ? '' : values.sipEndDate}
                    />
                  </Grid>
                  {values?.frequency &&
                  values.sipDay &&
                  values?.sipStartDate &&
                  values?.sipEndDate ? (
                    <Grid
                      item
                      xs={12}
                    >
                      <FormCheckbox
                        name='perpetual'
                        label='Perpetual SIP'
                        margin='dense'
                        onChange={e =>
                          handlePerpetualChange(e.target.checked, setFieldValue, values)
                        }
                      />
                    </Grid>
                  ) : null}
                  {values.modeType === 'LumpsumAndSIP' && (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                    >
                      <FormTextInput
                        name='lumpsumAmount'
                        label='Lumpsum Amount'
                        typeOfInput='number'
                        onInput={(e: any) => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, '');
                          if (e.target.value.startsWith('0')) {
                            e.target.value = e.target.value.replace(/^0+/, '');
                          }
                          if (e.target.value.length > 18) {
                            e.target.value = e.target.value.slice(0, 18);
                          }
                          setFieldValue('lumpsumAmount', e.target.value);
                        }}
                      />
                      <Typography>
                        Minimum Lumpsum Amount allowed: {minTransactionAmountAllowed || 0}
                      </Typography>
                    </Grid>
                  )}
                </>
              )}
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
              >
                <FormTextInput
                  name='investmentAmount'
                  label={
                    values.modeType === 'Lumpsum'
                      ? 'Lumpsum Amount'
                      : 'SIP Investment Amount'
                  }
                  typeOfInput='number'
                  onInput={(e: any) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    if (e.target.value.startsWith('0')) {
                      e.target.value = e.target.value.replace(/^0+/, '');
                    }
                    if (e.target.value.length > 18) {
                      e.target.value = e.target.value.slice(0, 18);
                    }
                    setFieldValue('investmentAmount', e.target.value);
                  }}
                />
                <Typography>
                  Minimum{' '}
                  {values.modeType === 'Lumpsum'
                    ? 'Lumpsum Amount'
                    : 'SIP Investment Amount'}{' '}
                  allowed: {minTransactionAmountAllowed || 0}
                </Typography>
              </Grid>
            </Grid>
            <Divider
              orientation='horizontal'
              variant='fullWidth'
              flexItem
              sx={{
                border: '0.5px dashed',
                borderColor: 'text.borderColorDark',
                mt: 3,
                mx: -2,
              }}
            />
            <SubmitCartButtons
              onAddToCartClick={handleAddToCartClick}
              firstButtonTitle={firstButtonTitle}
              secondButtonTitle={secondButtonTitle}
              disableCondition={hasAnyErrorOccured}
            />
            {openDialogBox && (
              <CustomDialog
                isVisible={true}
                firstButtonHandler={handleGoToCart}
                secondButtonHandler={handleAddAnotherTransaction}
                firstButtonTitle='Go to cart'
                secondButtonTitle='Add another Transaction'
                handleClose={() => {}}
                showSecondButton={true}
              >
                <Box sx={{ textAlign: 'center', pt: 2 }}>
                  <img
                    src={VerifiedIcon}
                    alt='verified-icon'
                  />
                  <MainHeader sx={{ fontWeight: 500, mb: 2 }}>Added To Cart</MainHeader>
                  <FieldSubHeader3 sx={{ fontSize: '16px' }}>
                    Scheme is added to investor cart
                  </FieldSubHeader3>
                </Box>
              </CustomDialog>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
export default AdditionalPurchaseComponent;
