import { Box, Grid, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import VerifiedIcon from '../../../../assets//verified-cart-icon.png';
import { useDebounce } from '../../../../hooks/useDebounce';
import { generateAgentReferenceNo } from '../../../../utils';
import {
  Detailslabel,
  DetailsValue,
  Investorcard,
} from '../../../auth/login/styles/styledComponents';
import CustomDialog from '../../../common/AllDialogs/CustomDialog';
import { PageNavigator } from '../../../common/BackToHomePage';
import InvestorProfile from '../../../common/InvestorProfile';
import { startLoading, stopLoading } from '../../../common/Loader/loaderSlice';
import PanDetailsBox from '../../../common/PanDetails';
import { useRootContext } from '../../../data/root.context';
import { LoginResponse } from '../../../features/login/loginSlice';
import { TransactionResponse } from '../../../features/transactions/transactionSlice';
import { FormStyledRadioButton } from '../../../forms/FormStyledRadioButton';
import SubmitButton from '../../../forms/FormSubmitButton';
import FormTextInput from '../../../forms/FormTextInput';
import { AdditionalPurchaseFormValues, AgentDetails, ServiceCharges } from '../../types';
import {
  useGetAgentDetailsMutation,
  useGetServiceDetailsMutation,
  useSendSubContributionEmailMutation,
} from '../investorOnBoarding/slice';

const validationSchema = Yup.object().shape({
  lumpsumAmount: Yup.number()
    .required('Lumpsum Amount is required')
    .when('accountType', {
      is: '2', // If accountType is '2'
      then: Yup.number()
        .min(250, 'Minimum amount for TIER I & II is ₹250')
        .required('Lumpsum Amount is required'),
      otherwise: Yup.number()
        .min(500, 'Minimum amount for TIER I is ₹500')
        .required('Lumpsum Amount is required'),
    }),
});

export default function AdditionalPurchase() {
  const [getAgentDetails] = useGetAgentDetailsMutation();
  const [getServiceDetails] = useGetServiceDetailsMutation();
  const formikRef = useRef<FormikProps<AdditionalPurchaseFormValues>>(null);
  const [agentDetails, setAgentDetails] = useState<AgentDetails | null>(null);
  const [serviceCharges, setServiceCharges] = useState<ServiceCharges | null>(null);
  const [sendSubContributionEmail] = useSendSubContributionEmailMutation();
  const { showToast } = useRootContext();
  const [requestResponse, setRequestResponse] = useState<string>('');
  const [hasAnyErrorOccured, setHasAnyErrorOccured] = useState<boolean>(false);

  const { arn } = useSelector((state: { login: LoginResponse }) => state.login);
  const [link, setLink] = useState('');
  const navigate = useNavigate();

  const initialValues: AdditionalPurchaseFormValues = {
    accountType: '',
    fundManager: '',
    lumpsumAmount: '',
  };
  const {
    name,
    pan,
    MFS,
    NPS,
    email,
    mobile,
    pran,
    uniqueAgentIdentifier,
    ackNumber,
    accountType,
  } = useSelector((state: { transactions: TransactionResponse }) => state.transactions);
  const dispatch = useDispatch();
  const submitHandler = async (
    values: AdditionalPurchaseFormValues,
    helpers: FormikHelpers<AdditionalPurchaseFormValues>,
  ) => {
    try {
      const params = {
        Pran: selectedSubscriber?.PRAN,
        ContributionType: 'S',
        AccounType: Number(selectedSubscriber?.AccountType),
        TotalAmount: Number(values?.lumpsumAmount),
        POP_Code: agentDetails?.POPCode,
        POP_SP_Code: agentDetails?.POP_SPCode,
        AgentReferenceNumber: generateAgentReferenceNo(pan, arn),
        EmailID: selectedSubscriber?.EmailID,
        PhoneNumber: selectedSubscriber?.PhoneNumber,
        PAN: selectedSubscriber?.PAN,
        SubscriberName: selectedSubscriber?.SubscriberName,
      };
      const response = await sendSubContributionEmail(params).unwrap();
      setRequestResponse(response?.message);
      setLink(response?.data?.tinyUrl);
    } catch (error: any) {
      setHasAnyErrorOccured(true);
      showToast((error.data as { message: string }).message, 'error');
    }
    helpers.setSubmitting(false);
  };
  const selectedSubscriber = {
    SubscriberName: name,
    PRAN: pran,
    EmailID: email,
    PhoneNumber: mobile,
    PAN: pan,
    AckNumber: ackNumber,
    UniqueAgentIdentifier: uniqueAgentIdentifier,
    AccountType: accountType,
  };
  // Get the service Info as soon as we enter the amount.
  const fetchServiceData = async (amount: number) => {
    const params = {
      AccountType: Number(selectedSubscriber?.AccountType),
      Amount: Number(amount),
      PranNo: selectedSubscriber?.PRAN,
      POP_SPCode: agentDetails?.POP_SPCode,
    };
    try {
      const response = await getServiceDetails(params).unwrap();
      setServiceCharges(response?.data);
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    }
  };
  const fetchData = async () => {
    try {
      const response: any = await getAgentDetails({
        agentRefNumber: generateAgentReferenceNo(pan, arn),
      }).unwrap();

      setAgentDetails(response.data);

      if (formikRef.current) {
        formikRef.current.setFieldValue('fundManager', response?.data?.TPI_ClientName);
        formikRef.current.setFieldValue('accountType', selectedSubscriber?.AccountType);
      }
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  function copyLink() {
    try {
      navigator.clipboard.writeText(link as string);
      showToast('Link copied!!', 'success');
    } catch (error: any) {
      showToast(error, 'error');
    }
  }
  const handleBackButton = () => {
    if (MFS && NPS) {
      navigate('../../asset-selection');
    } else {
      navigate('../../');
    }
  };
  const handleValue = useDebounce(async (amount: number) => {
    try {
      dispatch(startLoading());
      await fetchServiceData(amount);
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  }, 500);
  return (
    <>
      <PanDetailsBox
        name={name}
        value={pan}
      />
      <PageNavigator
        title='Additional Purchase'
        backHandler={handleBackButton}
      />

      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          md={9}
          xs={12}
        >
          <Investorcard sx={{ padding: { xs: '20px 20px', sm: '30px 30px' } }}>
            <Typography
              sx={{
                fontSize: { xs: '14px', sm: '16px', xl: '18px' },
                mb: 2.5,
                fontWeight: 500,
                color: 'primary.main',
              }}
            >
              Please enter the below details.
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: '14px', xl: '16px' },
                mb: 1.5,
                fontWeight: 500,
                color: 'text.valueColor',
              }}
            >
              Select account Type
            </Typography>

            <Formik
              initialValues={initialValues}
              onSubmit={submitHandler}
              innerRef={formikRef}
              validationSchema={validationSchema}
            >
              {({ values }) => (
                <Form>
                  <FormStyledRadioButton
                    name={'accountType'}
                    options={[
                      { label: 'TIER I', value: '1' },
                      { label: 'TIER I & II', value: '2' },
                    ]}
                    disabled={true}
                  />

                  <Grid
                    container
                    spacing={2}
                    my={'16px'}
                  >
                    <Grid
                      item
                      sm={6}
                      xs={12}
                    >
                      <FormTextInput
                        name='fundManager'
                        label='Pension Funds Manager'
                        disabled
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormTextInput
                        name='lumpsumAmount'
                        label='Lumpsum Amount'
                        onInput={(e: any) => {
                          e.target.value = e.target.value
                            .replace(/[^0-9.]/g, '')
                            .replace(/^(\.)/, '')
                            .replace(/(\..*?)\..*/g, '$1')
                            .replace(/(\.\d{2})\d+/g, '$1');
                          if (e.target.value.length > 10) {
                            e.target.value = e.target.value.slice(0, 10);
                          }
                        }}
                        onChange={async e => {
                          const value = +e.target.value;

                          const isValueEnteredValid =
                            selectedSubscriber?.AccountType == '2'
                              ? Number(value) >= 250
                              : Number(value) >= 500;
                          if (isValueEnteredValid) {
                            handleValue(value);
                          }
                        }}
                        placeholder={
                          selectedSubscriber?.AccountType == '2'
                            ? `Minimum Amount ₹.250`
                            : `Minimum Amount ₹.500`
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      sm={6}
                      xs={12}
                    >
                      {values?.lumpsumAmount !== '' && serviceCharges && (
                        <Box
                          sx={{
                            'display': 'flex',
                            'justifyContent': 'space-between',
                            '& .MuiTypography-root ': {
                              mb: 1,
                            },
                          }}
                        >
                          <Box>
                            <Detailslabel>Service Charge</Detailslabel>
                            <Detailslabel>CGST Amount</Detailslabel>
                            <Detailslabel>IGST Amount</Detailslabel>
                            <Detailslabel>SGST Amount</Detailslabel>
                            <Detailslabel>Total Contribution Amount</Detailslabel>
                            <Detailslabel>POP Charges</Detailslabel>
                            <Detailslabel>Total Amount Payable</Detailslabel>
                          </Box>
                          <Box>
                            <DetailsValue>₹ {serviceCharges?.Servicecharge}</DetailsValue>
                            <DetailsValue>₹ {serviceCharges?.CGSTAmount}</DetailsValue>
                            <DetailsValue>₹ {serviceCharges?.IGSTAmount}</DetailsValue>
                            <DetailsValue>₹ {serviceCharges?.SGSTAmount}</DetailsValue>
                            <DetailsValue>
                              ₹ {serviceCharges?.Totalcontributionamount}
                            </DetailsValue>
                            <DetailsValue>₹ {serviceCharges?.POPCharges}</DetailsValue>
                            <DetailsValue>
                              ₹ {serviceCharges?.TotalAmountPayble}
                            </DetailsValue>
                          </Box>
                        </Box>
                      )}
                    </Grid>
                  </Grid>

                  <SubmitButton
                    sx={{
                      '&.MuiButtonBase-root': {
                        borderRadius: '5px',
                        minWidth: { xs: '100%', sm: '200px' },
                        mt: 0,
                      },
                    }}
                    label='Submit'
                    disabled={hasAnyErrorOccured}
                  >
                    Submit
                  </SubmitButton>
                </Form>
              )}
            </Formik>
          </Investorcard>
        </Grid>
        <Grid
          item
          md={3}
        >
          <InvestorProfile />
        </Grid>
      </Grid>
      {requestResponse !== '' && (
        <CustomDialog
          isVisible={true}
          firstButtonTitle='Copy Link'
          firstButtonHandler={copyLink}
          secondButtonHandler={() => {
            setLink('');
            navigate('../../');
          }}
          secondButtonTitle='OK'
          showSecondButton
          handleClose={() => {}}
        >
          <Box
            sx={{
              'textAlign': 'center',
              'pt': 2,
              '& .MuiTypography-root': {
                'fontSize': '16px',
                'color': 'text.grayishBlue',
                'my': 2,
                '& >*': {
                  fontWeight: '600',
                },
              },
            }}
          >
            <img
              src={VerifiedIcon}
              alt='verified-icon'
            />

            <Typography
              sx={{
                fontWeight: 600,
              }}
            >
              {requestResponse}. Transaction Submitted Succesfully!
              <br />
              <Typography
                component='span'
                sx={{ fontWeight: 'bold' }}
              >
                Link: {link}
              </Typography>
            </Typography>
          </Box>
        </CustomDialog>
      )}
    </>
  );
}
