import { Box, Grid, Stack, Typography } from '@mui/material';

import { StyledTypography } from '../../transactions/common/helper';
import { mapFrequency } from '../../transactions/mfs/newInvestorOnboarding/utils';
import { MMddToDDMMYYYY } from '../../transactions/reports/utils';
import { inrCurrencyFormatter2 } from '../../transactions/utils';

const NewPurchaseConfirmation = ({ data }: { data?: any }) => {
  const amount = data?.transactionType === 'NPL' ? data?.amount : data?.installmentAmount;

  return (
    <Box
      sx={{
        'pb': 2,
        'mb': 3,
        '&:last-child': {
          border: 'unset',
        },
      }}
    >
      <Grid
        container
        spacing={3}
        sx={{
          'justifyContent': 'space-between',
          'my': 1,
          '& .MuiTypography-root.MuiTypography-body2': {
            color: 'text.valueColor',
            fontSize: '14px',
            fontWeight: 500,
          },
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Stack gap='5px'>
            <StyledTypography>Fund Name</StyledTypography>
            <Typography variant='body2'>{data?.fundName}</Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Stack gap='5px'>
            <StyledTypography>Scheme Name</StyledTypography>
            <Typography variant='body2'>{data?.schemeName}</Typography>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Stack gap='5px'>
            <StyledTypography>
              {data?.transactionType === 'NPL' ? 'Amount:' : 'Sip Amount'}
            </StyledTypography>
            <Typography variant='body2'>{inrCurrencyFormatter2(amount)}</Typography>
          </Stack>
        </Grid>
        {data?.transactionType !== 'NPL' && (
          <>
            <Grid
              item
              xs={6}
            >
              <Stack gap='5px'>
                <StyledTypography>SIP Day:</StyledTypography>
                <Typography variant='body2'>{data?.sipDay}</Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Stack gap='5px'>
                <StyledTypography>SIP Frequency:</StyledTypography>
                <Typography variant='body2'>
                  {mapFrequency(data?.sipFrequency)}
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Stack gap='5px'>
                <StyledTypography>No. Of Installments:</StyledTypography>
                <Typography variant='body2'>{data?.noOfInstallments}</Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Stack gap='5px'>
                <StyledTypography>Start Date:</StyledTypography>
                <Typography variant='body2'>
                  {MMddToDDMMYYYY(data?.sipStartDate)}
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs={6}
            >
              <Stack gap='5px'>
                <StyledTypography>End Date:</StyledTypography>
                <Typography variant='body2'>
                  {MMddToDDMMYYYY(data?.sipEndDate)}
                </Typography>
              </Stack>
            </Grid>
            {data?.transactionType === 'SIN' && data?.investmentAmount !== 0 && (
              <Grid
                item
                xs={6}
              >
                <Stack gap='5px'>
                  <StyledTypography>Lumpsum Amount:</StyledTypography>
                  <Typography variant='body2'>
                    {inrCurrencyFormatter2(data?.amount)}
                  </Typography>
                </Stack>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
};

export default NewPurchaseConfirmation;
