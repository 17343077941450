import { Box, Grid, Theme, Typography } from '@mui/material';
import { format, isValid } from 'date-fns';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import VerifiedIcon from '../../../../../assets/verified-cart-icon.png';
import useUserPermission from '../../../../../hooks/useUserPermission';
import {
  Investorcard,
  // StepperAddButton,
} from '../../../../auth/login/styles/styledComponents';
import CustomDialog from '../../../../common/AllDialogs/CustomDialog';
import { useRootContext } from '../../../../data/root.context';
import FormDateInput from '../../../../forms/FormDateInput';
import { FormSelectInput } from '../../../../forms/FormSelect';
import { FormStyledRadioButton } from '../../../../forms/FormStyledRadioButton';
import SubmitButton from '../../../../forms/FormSubmitButton';
import { FormPasswordInput } from '../../../../forms/FormTextInput';
import Layout from '../../../Layout';
import { useListOfSchemesMutation } from '../../../slice';
import { useSoapReportMutation } from '../../slice';
import {
  getDatesForCurrentFinancialYr,
  handleDetailsOptionChange,
  handleEndDateChange,
  validateDates,
} from '../../utils';

interface SOA {
  statementType: string;
  asOnDate: any;
  detailedOption: string;
  startDate: Date | null;
  endDate: Date | null;
  amc: string;
  folio: string;
  scheme: string;
  soaType: string;
  password: string;
  confirmPassword: string;
}

export interface Option {
  value: any;
  label: any;
}

export default function Soa() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { showToast } = useRootContext();
  const pan: string = useSelector((state: any) => state.transactions.pan);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [allSchemeList, setAllSchemeList] = useState<any[]>([]);
  const [amcOptions, setAmcOptions] = useState<Option[]>([]);
  const [folioOptions, setFolioOptions] = useState<Option[]>([]);
  const [schemeOptions, setSchemeOptions] = useState<Option[]>([]);
  const [responseMsg, setResponseMsg] = useState<string>('');

  const [getMailBackSoaStatus] = useSoapReportMutation();
  const [listOfSchemes] = useListOfSchemesMutation();
  const { showDashboard } = useUserPermission();

  const initialValues: SOA = {
    statementType: 'Summary',
    asOnDate: new Date().toISOString().split('T')[0] || null,
    detailedOption: 'Current Financial Year',
    startDate: getDatesForCurrentFinancialYr().startDate || null,
    endDate: getDatesForCurrentFinancialYr().endDate || null,
    amc: '',
    folio: '',
    scheme: '',
    soaType: '',
    password: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    statementType: Yup.string().required('Statement type is required'),
    asOnDate: Yup.date()
      .typeError('Invalid Date')
      .when('statementType', {
        is: 'Summary',
        then: Yup.date().typeError('Invalid Date').required('As on date is required'),
      }),
    detailedOption: Yup.string().when('statementType', {
      is: 'Detailed',
      then: Yup.string().required('Detailed option is required'),
    }),
    startDate: Yup.date()
      .nullable()
      .typeError('Invalid Date')
      .when('statementType', {
        is: 'Detailed',
        then: Yup.date()
          .nullable()
          .typeError('Invalid Date')
          .required('Start date is required'),
      }),
    endDate: Yup.date()
      .nullable()
      .typeError('Invalid Date')
      .when('statementType', {
        is: 'Detailed',
        then: Yup.date()
          .nullable()
          .typeError('Invalid Date')
          .required('End date is required'),
      }),
    amc: Yup.string().required('Amc is required'),
    folio: Yup.string().required('Folio is required'),
    scheme: Yup.string().required('Scheme is required'),
    soaType: Yup.string().required('SOA Type is required'),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[A-Za-z0-9!@#$%^&*()_+]+$/,
        'Password should contain at least 1 Upper Case alphabet(A-Z).1 Special Character and 1 Numeric Value (0-9)',
      ),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .test(
        'passwordMatch',
        'The Password and confirm Password do not match',
        function (value) {
          return value === this.parent.password;
        },
      ),
  });

  useEffect(() => {
    async function fetchData() {
      if (!pan) return;
      try {
        const data: any[] = await listOfSchemes({ pan: pan.toUpperCase() }).unwrap();
        setAllSchemeList(data);
        const fundNames: Option[] = data?.map((item: any) => ({
          label: item.amcName,
          value: item.fund,
        }));
        const uniqueAmc: Option[] = Array.from(
          new Set(fundNames?.map(option => option.label)),
        )
          ?.map(label => fundNames?.find(option => option.label === label))
          ?.filter((value): value is Option => value !== undefined);
        setAmcOptions(uniqueAmc);
      } catch (error: any) {
        showToast(error.message, 'error');
      }
    }
    fetchData();
  }, []);

  const handleAmcChange = (e: any, setFieldValue: any) => {
    setFieldValue('folio', '');
    setFieldValue('scheme', '');
    setFieldValue('password', '');
    setFieldValue('confirmPassword', '');
    const selectedAmc = e?.target?.value;
    const filteredFolios = allSchemeList
      ?.filter(item => item?.fund === selectedAmc)
      ?.map(item => item?.folio);
    const uniqueFolios = [...new Set(filteredFolios)];
    const folioOptions: Option[] = uniqueFolios?.map(folio => ({
      label: folio.toString(),
      value: folio.toString(),
    }));
    if (folioOptions.length === 0) {
      showToast(`No Folio's were found`, 'error');
    }
    setFolioOptions(folioOptions);
  };

  const handleFolioChange = (e: any, setFieldValue: any) => {
    setFieldValue('scheme', '');
    const selectedFolio = e?.target?.value;
    const filteredSchemes = allSchemeList
      ?.filter(item => item?.folio?.toString() === selectedFolio)
      ?.map(item => `${item?.schemeName} - ${item?.planMode} - ${item?.optionDesc}`);
    const uniqueSchemes = [...new Set(filteredSchemes)];
    const schemeOptions: Option[] = uniqueSchemes?.map(scheme => ({
      label: scheme,
      value: scheme,
    }));
    setSchemeOptions(schemeOptions);
  };

  const handleSubmit = async (values: SOA) => {
    const startDate = values.startDate ? new Date(values.startDate) : null;
    const endDate = values.endDate ? new Date(values.endDate) : null;
    if (!startDate || !isValid(startDate)) {
      showToast('Invalid start date format', 'error');
      return;
    }
    if (!endDate || !isValid(endDate)) {
      showToast('Invalid end date format', 'error');
      return;
    }
    const params = {
      fund: values.amc,
      stDt:
        values.statementType === 'Summary'
          ? '01/01/1970'
          : format(startDate, 'MM/dd/yyyy'),
      endDt:
        values.statementType === 'Summary'
          ? values.asOnDate
          : format(endDate, 'MM/dd/yyyy'),
      pan: pan,
      freq: values.soaType === 'Single SOA' ? 'S' : 'M',
      zipPwd: values.password,
      acno: values.folio.toString(),
      reportFlag: values.statementType === 'Summary' ? 'T' : 'F',
    };
    try {
      const response: any = await getMailBackSoaStatus(params).unwrap();
      if (response?.message) {
        setResponseMsg(response?.message);
        setOpenDialogBox(true);
      }
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    }
  };

  return (
    <>
      <Layout
        title='SOA Report'
        isNctTransaction
      >
        <Investorcard
          sx={(theme: Theme) => ({
            padding: '30px 30px',
            [theme.breakpoints.down('sm')]: {
              boxShadow: 'unset',
              border: 'unset',
              padding: '0px',
            },
          })}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validate={values => validateDates(values)}
          >
            {({ values, setFieldValue, errors, setFieldError, resetForm }) => {
              return (
                <Form>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography
                        sx={{
                          mb: 2,
                          fontSize: { xs: '14px', xl: '16px' },
                          fontWeight: 500,
                          color: 'text.valueColor',
                        }}
                      >
                        SOA
                      </Typography>
                      <FormStyledRadioButton
                        options={[
                          { label: 'Summary', value: 'Summary' },
                          { label: 'Detailed', value: 'Detailed' },
                        ]}
                        name='statementType'
                        handleChange={e => {
                          resetForm();
                          setFieldValue('statementType', e.target.value);
                        }}
                      />
                    </Grid>
                    {values.statementType === 'Detailed' ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          mt={2}
                        >
                          <Typography
                            sx={{
                              mb: 2,
                              fontSize: { xs: '14px', xl: '16px' },
                              fontWeight: 500,
                              color: 'text.valueColor',
                            }}
                          >
                            Detailed Option
                          </Typography>
                          <FormStyledRadioButton
                            options={[
                              {
                                label: 'Current Financial Year',
                                value: 'Current Financial Year',
                              },
                              {
                                label: 'Previous Financial Year',
                                value: 'Previous Financial Year',
                              },
                              {
                                label: 'Specified Period',
                                value: 'Specified Period',
                              },
                            ]}
                            name='detailedOption'
                            handleChange={e =>
                              handleDetailsOptionChange(e, setFieldValue)
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          xs={12}
                        >
                          <FormDateInput
                            name='startDate'
                            label='Start Date'
                            required={true}
                            viewOnly={values.detailedOption !== 'Specified Period'}
                            disableFuture
                            disabled
                          />
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          xs={12}
                        >
                          <FormDateInput
                            name='endDate'
                            label='End Date'
                            required={true}
                            viewOnly={
                              values.detailedOption !== 'Specified Period' ||
                              values.startDate === null
                            }
                            disableFuture
                            disabled
                            onChange={e =>
                              handleEndDateChange(
                                e,
                                values,
                                setFieldError,
                                setFieldValue,
                                showToast,
                              )
                            }
                          />
                        </Grid>
                      </>
                    ) : (
                      <Grid
                        item
                        sm={6}
                        xs={12}
                      >
                        <FormDateInput
                          name='asOnDate'
                          label='As Date On'
                          required={true}
                          viewOnly
                        />
                      </Grid>
                    )}
                    <Grid
                      item
                      sm={6}
                      xs={12}
                    >
                      <FormSelectInput
                        name='amc'
                        label='Select AMC'
                        defaultValue={''}
                        options={amcOptions}
                        required
                        onChange={e => handleAmcChange(e, setFieldValue)}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={6}
                      xs={12}
                    >
                      <FormSelectInput
                        name='folio'
                        label='Select Folio'
                        defaultValue={''}
                        options={folioOptions}
                        required
                        onChange={e => handleFolioChange(e, setFieldValue)}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={6}
                      xs={12}
                    >
                      <FormSelectInput
                        name='scheme'
                        label='Select Scheme'
                        defaultValue={''}
                        options={schemeOptions}
                        required
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <Typography
                        sx={{
                          mb: 2,
                          fontSize: { xs: '14px', xl: '16px' },
                          fontWeight: 500,
                          color: 'text.valueColor',
                        }}
                      >
                        SOA Type
                      </Typography>
                      <FormStyledRadioButton
                        options={[
                          { label: 'Single SOA', value: 'Single SOA' },
                          { label: 'Multi SOA', value: 'Multi SOA' },
                        ]}
                        name='soaType'
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormPasswordInput
                        name='password'
                        label='Self Extraction Password'
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                    >
                      <FormPasswordInput
                        name='confirmPassword'
                        label='Confirm Self Extraction Password'
                      />
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '20px',
                      justifyContent: { sm: 'center', lg: 'flex-start' },
                    }}
                  >
                    {/* {TODO: Need to integrate the download functionality once got the API} */}
                    {/* <StepperAddButton
                      sx={(theme: Theme) => ({
                        '&.MuiButtonBase-root': {
                          borderRadius: '5px',
                          minWidth: '260px',
                          my: 3,

                          [theme.breakpoints.down('sm')]: {
                            flex: 1,
                            minWidth: 'unset',
                          },
                        },
                      })}
                    >
                      Download
                    </StepperAddButton> */}
                    <SubmitButton
                      sx={(theme: Theme) => ({
                        '&.MuiButtonBase-root': {
                          borderRadius: '5px',
                          minWidth: '200px',

                          [theme.breakpoints.down('sm')]: {
                            flex: 1,
                            minWidth: 'unset',
                          },
                        },
                      })}
                      label='Submit'
                    />
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Investorcard>

        <CustomDialog
          isVisible={openDialogBox}
          showFirstButton={false}
          firstButtonHandler={() => {}}
          firstButtonTitle=''
          showSecondButton
          secondButtonTitle='Continue'
          secondButtonHandler={() => navigate('/transaction-dashboard/mis/mfs-reports')}
          handleClose={() => {}}
        >
          <Box
            sx={{
              'textAlign': 'center',
              'pt': 2,
              '& img': {
                maxWidth: { xs: '17%', md: '70%' },
              },
            }}
          >
            <img
              src={VerifiedIcon}
              alt='verified-icon'
            />
            <Typography
              variant='h3'
              sx={{ color: 'primary.main', my: 2 }}
            >
              Successfully submitted your request
            </Typography>
            <Typography variant='subtitle1'>{responseMsg}</Typography>
          </Box>
        </CustomDialog>
      </Layout>
    </>
  );
}
