import { Box, Divider, Grid, Typography } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import VerifiedIcon from '../../../assets/verified-cart-icon.png';
import { useDebounce } from '../../../hooks/useDebounce';
import { getEnumValueFromString } from '../../../hooks/useSip';
import { calculateDifference } from '../../../utils';
import CustomDialog from '../../common/AllDialogs/CustomDialog';
import CustomLoader from '../../common/CustomLoader';
import { startLoading, stopLoading } from '../../common/Loader/loaderSlice';
import { useRootContext } from '../../data/root.context';
import FormCheckbox from '../../forms/FormCheckbox';
import FormDateInput from '../../forms/FormDateInput';
import { FormSelectInput } from '../../forms/FormSelect';
import { FormStyledRadioButton } from '../../forms/FormStyledRadioButton';
import FormTextInput from '../../forms/FormTextInput';
import { arnRegex } from '../common';
import SubmitCartButtons from '../common/SubmitCartButtons';
import EuinsFields from '../euinsComponent';
import { useSchemeByfundMutation } from '../mfs/newInvestorOnboarding/slice';
import { mapFrequency } from '../mfs/newInvestorOnboarding/utils';
import { formatDate } from '../newPurchase/NewInvestmentDetails';
import {
  // useGetEUINSQuery,
  useGetSchemaDetailsMutation,
  useGetSipStartandEndDatesMutation,
  useListOfSchemesMutation,
  useModifyItemInCartMutation,
} from '../slice';
import { AlpModeType, Investment, ModeTypeToTrtType } from '../types';
import { getPerpetualSipEndDate } from '../utils';
import { findMinInstallmentAllowed, processFrequencyData } from './common';
import {
  DropDownData,
  ExistingAndNewFoliosType,
  MinInstallmentsAllowed,
  SchemaData,
  SchemesByFund,
  SelectedScheme,
} from './types';

interface FormValues {
  selectedAmc: string;
  schemeName: string;
  folioNumber: string;
  modeType: string;
  schemeType: string;
  modeOfRegistration: string;
  frequency: string;
  installments: number;
  sipDay: string;
  sipStartDate: Date | string | null;
  sipEndDate: Date | string | null;
  installmentAmount: string;
  amount: number;
  category: string;
  perpetual: boolean;
  euin: string;
}
const FIELD_NAMES = [
  'frequency',
  'installments',
  'sipDay',
  'sipStartDate',
  'sipEndDate',
  'perpetual',
];
const ALL_FIELD_NAMES = [
  'schemeType',
  'folioNumber',
  'schemeName',
  'frequency',
  'installments',
  'sipDay',
  'sipStartDate',
  'sipEndDate',
  'installmentAmount',
];
const validationSchema = Yup.object().shape({
  arnCode: Yup.string().matches(arnRegex, 'Invalid ARN Code'),
  subArnCode: Yup.string().matches(arnRegex, 'Invalid Sub ARN Code'),
  frequency: Yup.string().when(['modeType', 'installmentAmount'], {
    is: (modeType: string, installmentAmount: string) =>
      modeType !== 'Lumpsum' && installmentAmount,
    then: Yup.string().required('Frequency is required'),
  }),
  sipDay: Yup.string().when(['modeType', 'installmentAmount'], {
    is: (modeType: string, installmentAmount: string) =>
      modeType !== 'Lumpsum' && installmentAmount,
    then: Yup.string().required('SIP Day is required'),
  }),
  installments: Yup.string().when(['modeType', 'installmentAmount'], {
    is: (modeType: string, installmentAmount: string) =>
      modeType !== 'Lumpsum' && installmentAmount,
    then: Yup.string().required('No. of Installments is required'),
  }),
  folioNumber: Yup.string().required('Folio Number is required'),
  schemeType: Yup.string().required('Scheme Type is required'),
  schemeName: Yup.string().required('Scheme Name is required'),
  category: Yup.string().when('schemeType', {
    is: 'new',
    then: Yup.string().required('Category is required'),
  }),
  amount: Yup.string().when('modeType', {
    is: (modeType: string) => modeType === 'Lumpsum' || modeType === 'LumpsumAndSIP',
    then: Yup.string()
      .required('Lumpsum amount is required')
      .matches(/^\d+(\.\d{1,2})?$/, 'Invalid amount'),
    otherwise: Yup.string().notRequired(), // No validation error for 'SIP'
  }),
  installmentAmount: Yup.string().when('modeType', {
    is: (modeType: string) => modeType === 'SIP' || modeType === 'LumpsumAndSIP',
    then: Yup.string()
      .required('Investment Amount is required')
      .min(1, 'Minimum Investment amount should not be 0')
      .matches(/^[0-9]+$/, 'Investment Amount must contain only numbers'),
  }),
});
interface InvestmentDetailsFormProps {
  data: any;
  handleCancelModifyingCart?: () => void;
}

const EditTransactionAPL: React.FC<InvestmentDetailsFormProps> = ({
  data,
  handleCancelModifyingCart,
}) => {
  const pan: string = useSelector((state: any) => state.transactions.pan);
  const [investorSchemes, setInvestorSchemes] = useState<Investment[]>([]);
  const [investorDetailsList] = useListOfSchemesMutation();
  const [selectedScheme, setSelectedScheme] = useState<SelectedScheme | {}>({});
  const formikRef = useRef<FormikProps<FormValues>>(null);
  const navigate = useNavigate();
  const [schemesByFund, setSchemesByFund] = useState<SchemesByFund>({
    allSchemes: [],
    categories: [],
    selectedCategory: data?.category, // initializing with selected Category
  });
  const [schemaData, setSchemaData] = useState<SchemaData | {}>({});
  const [frequencyType, setFrequencyType] = useState(data?.sipFrequency);
  const [tempFolio, setTempFolio] = useState(data?.folio);

  const [getSchemesByFund] = useSchemeByfundMutation();
  const [getSchemaDetails] = useGetSchemaDetailsMutation();
  const { showToast } = useRootContext();
  const [sipDay, setSipDay] = useState<string>(data?.sipDay || '');
  const firstRender = useRef<boolean>(false);
  const [installments, setInstallments] = useState<number>(data?.noOfInstallments || 0);
  const [modifyItemInCart] = useModifyItemInCartMutation(); // Modify items in cart
  const [isLoading, setIsLoading] = useState<boolean>();
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false);
  const initialInvestorSchemes = useRef<Investment[]>([]);
  const isSchemeChanged = useRef<boolean>(false);
  const [isExisting, setIsExisting] = useState<boolean>(
    data?.purchaseType === 'EXISTING',
  );
  const [hasAnyErrorOccured, setHasAnyErrorOccured] = useState<boolean>(false);
  const [getSipStartandEndDates] = useGetSipStartandEndDatesMutation();
  const [minDetailsObj, setMinDetailsObj] = useState<MinInstallmentsAllowed>( // Min installments which will be allowed
    {} as MinInstallmentsAllowed,
  );
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState<FormValues>({
    selectedAmc: '',
    schemeName: '',
    folioNumber: tempFolio || '', // folios mapped.
    modeType: '',
    schemeType: '',
    modeOfRegistration: '',
    frequency: '',
    installments: 0,
    sipDay: String(sipDay) || '',
    sipStartDate: null,
    sipEndDate: null,
    installmentAmount: '',
    amount: 0, // Lumpsum amount
    category: '',
    perpetual: false,
    euin: '',
  });
  const [isPerpetual, setIsPerpetual] = useState(false);
  const [existingAndNewFolios, setExistingAndNewFolios] =
    useState<ExistingAndNewFoliosType>({
      existingFolios: [],
      newFolios: [],
    });
  // const { data: allEUINs } = useGetEUINSQuery([]);
  const isExistingScheme = data?.purchaseType === 'EXISTING';
  const minTransactionAmountAllowed =
    formikRef?.current?.values.modeType === 'Lumpsum'
      ? (schemaData as SchemaData)?.minTransactionAmount
      : minDetailsObj?.minTransactionAmount;
  const getFolioDropDownOptions = (schemesArr: any[]) => {
    return schemesArr
      ?.filter(inv => inv.fund === data?.fund)
      .map(item => ({ label: item.folio, value: item.folio }));
  };
  const frequencyModes: DropDownData = useMemo(() => {
    const modes = (schemaData as SchemaData)?.frequency;
    const frequencyDataObject = processFrequencyData(modes);
    return frequencyDataObject;
  }, [(schemaData as SchemaData)?.frequency]);

  useEffect(() => {
    async function getInvestorDetailsSchemes() {
      const response = await investorDetailsList({
        pan: pan,
      }).unwrap();
      if (response.error) {
        throw new Error(response.error.data.message);
      }
      const filteredSchemes = response?.filter(
        (item: { additionalPurAllow: boolean }) => item.additionalPurAllow,
      );
      setInvestorSchemes(filteredSchemes);
      initialInvestorSchemes.current = response;
      if (isExistingScheme) {
        const [scheme] =
          filteredSchemes?.filter(
            (inv: Investment) => inv.fund === data?.fund && inv.folio === data?.folio,
          ) || [];
        setSelectedScheme(scheme); // initialize the selected scheme
      }
      setExistingAndNewFolios({
        ...existingAndNewFolios,
        existingFolios: getFolioDropDownOptions(filteredSchemes),
        newFolios: getFolioDropDownOptions(initialInvestorSchemes?.current),
      });
    }
    const fetchSchemesByFund = async () => {
      const resFund = await getSchemesByFund({
        funds: [data?.fund],
        investorPan: '',
        start: 0,
        end: 20000,
      }).unwrap();
      if (resFund.error) {
        throw new Error(resFund.error.data.message);
      }
      const filteredData = resFund.filter((row: any) => row.additionalPurAllow === true);
      const uniqueCategories = [
        ...new Set(filteredData.map((item: any) => item.category)),
      ];

      if (!isExistingScheme) {
        const [scheme] =
          filteredData?.filter(
            (inv: any) =>
              inv.fund === data?.fund &&
              inv.category === data?.category &&
              inv.scheme === data?.scheme &&
              inv.option === data?.option &&
              inv.plan === data?.plan,
          ) || [];
        setSelectedScheme(scheme);
      }

      setSchemesByFund({
        ...schemesByFund,
        allSchemes: filteredData,
        categories: uniqueCategories as string[],
      });
    };

    const fetchData = async () => {
      try {
        await Promise.all([getInvestorDetailsSchemes(), fetchSchemesByFund()]);
      } catch (error: any) {
        const message = error?.data?.message || error?.message || 'Unknown error';
        showToast(message, 'error');
        setHasAnyErrorOccured(true);
      }
    };

    if (data?.fund && pan) {
      fetchData();
    }
  }, [data]);

  const isValidDate = (dateString: string | undefined): boolean => {
    const date = new Date(dateString as string);
    return date instanceof Date && !isNaN(date.getTime());
  };

  useEffect(() => {
    const scheme = (selectedScheme as SelectedScheme)?.scheme;

    const fetchSchemaDetails = async () => {
      try {
        dispatch(startLoading());
        const payload = {
          fund: data?.fund,
          plan: (selectedScheme as SelectedScheme)?.plan,
          option: (selectedScheme as SelectedScheme)?.option,
          trType: data?.transactionType !== 'APL' ? 'SIP' : isExisting ? 'APL' : 'NPL',
          scheme,
        };

        const result = await getSchemaDetails(payload).unwrap();
        setSchemaData(result);

        if (!isSchemeChanged.current) {
          // For setting the initial page.
          let startDate,
            endDate = null;
          if (data?.transactionType !== 'APL') {
            const response = await getSipStartandEndDates({
              fund: data?.fund,
              scheme: data?.scheme,
              tenant: 'IRIS',
              frequency: frequencyType,
              installments: installments,
              trtype: AlpModeType[data?.transactionType as keyof typeof AlpModeType],
              day: sipDay,
            }).unwrap();
            startDate = response?.startDate;
            endDate = response?.endDate;
          }
          const schemeDesc = (selectedScheme as SelectedScheme)?.schemeDesc;
          const planDesc = (selectedScheme as SelectedScheme)?.planDesc;

          setInitialValues({
            ...initialValues,
            selectedAmc: data?.fundName || '',
            schemeName: !isExistingScheme ? `${schemeDesc} - ${planDesc}` : scheme,
            folioNumber: data?.folio || '', // Folios mapped.
            modeType: AlpModeType[data?.transactionType as keyof typeof AlpModeType],
            schemeType: isExistingScheme ? 'existing' : 'new',
            modeOfRegistration: data?.modeOfRegistration || '',
            frequency: frequencyType,
            installments: installments,
            sipDay: sipDay,
            // TODO: need to change the format of dates
            ...(data?.transactionType !== 'APL' && {
              sipStartDate: isValidDate(data?.sipStartDate)
                ? data.sipStartDate
                : isValidDate(startDate)
                ? startDate
                : null,
              sipEndDate: isValidDate(data?.sipEndDate)
                ? data.sipEndDate
                : isValidDate(endDate)
                ? endDate
                : null,
            }),
            installmentAmount: data?.sipAmount || '',
            amount: data?.amount || 0, // Lumpsum amount
            category: !isExistingScheme ? schemesByFund?.selectedCategory : '',
            perpetual: data?.perpetual || false,
            euin: data?.euin || '',
          });
        }
      } catch (error: any) {
        setHasAnyErrorOccured(true);

        const message =
          (error as any).data?.message || (error as any).message || 'Unknown error';
        showToast(message, 'error');
      } finally {
        dispatch(stopLoading());
      }
    };

    if (data?.fund && scheme) {
      fetchSchemaDetails();
    }
  }, [selectedScheme]);

  function getInvestment(fund?: string, folio?: number): any[] {
    if (fund && folio) {
      return investorSchemes?.filter(inv => inv.fund === fund && inv.folio === folio);
    } else if (fund) {
      const data =
        formikRef?.current?.values?.schemeType == 'new'
          ? initialInvestorSchemes?.current?.filter(inv => inv.fund === fund)
          : investorSchemes?.filter(inv => inv.fund === fund);
      return data;
    } else {
      return investorSchemes;
    }
  }
  const handleSubmit = async (values: FormValues) => {
    try {
      setIsLoading(true);

      const {
        fundName,
        mobile,
        email,
        pan,
        fund,
        investorName,
        TRACEID,
        folioBanks,
        cartId,
        cartItemId,
        modeOfRegistration,
        invCategory,
      } = data;
      const { folioNumber, amount, installments, installmentAmount, sipDay } = values;
      const scheme = (selectedScheme as SelectedScheme)?.scheme;
      const option = (selectedScheme as SelectedScheme)?.option;
      const plan = (selectedScheme as SelectedScheme)?.plan;
      const schemeName = (selectedScheme as SelectedScheme)?.schemeName;
      const category = (selectedScheme as SelectedScheme)?.category;

      let payload;
      const commonPayload = {
        investorName,
        pan,
        fund,
        fundName,
        scheme,
        plan,
        option,
        modeOfRegistration,
        schemeName,
        category,
        sipDay,
        mobile,
        email,
        TRACEID,
        folioBanks,
        cartId,
        cartItemId,
        invCategory,
        purchaseType: values?.schemeType === 'existing' ? 'EXISTING' : 'NEW',
      };
      switch (values.modeType) {
        case 'Lumpsum':
          payload = {
            ...commonPayload,
            folio: folioNumber,
            amount,
            transactionType: ModeTypeToTrtType.Lumpsum,
            type: values?.schemeType === 'existing' ? 'EXISTING' : 'NEW',
          };
          break;

        case 'SIP':
          payload = {
            ...commonPayload,
            folio: folioNumber,
            sipFrequency: getEnumValueFromString(values?.frequency),
            sipStartDate: formatDate(
              new Date(values?.sipStartDate as Date),
              'MM/dd/yyyy',
            ),
            sipEndDate: formatDate(new Date(values?.sipEndDate as Date), 'MM/dd/yyyy'),
            noOfInstallments: installments,
            remarks: '0',
            transactionType: ModeTypeToTrtType.SIP,
            installmentAmount,
            amount: 0,
          };
          break;
        case 'LumpsumAndSIP':
          payload = {
            ...commonPayload,
            remarks: '0',
            installmentAmount,
            amount,
            folio: folioNumber,
            sipFrequency: getEnumValueFromString(values?.frequency),
            sipStartDate: formatDate(
              new Date(values?.sipStartDate as Date),
              'MM/dd/yyyy',
            ),
            sipEndDate: formatDate(new Date(values?.sipEndDate as Date), 'MM/dd/yyyy'),
            noOfInstallments: installments,
            transactionType: ModeTypeToTrtType.LumpsumAndSIP,
          };
          break;
        default:
          break;
      }
      await modifyItemInCart(payload).unwrap();
      setOpenDialogBox(true);
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    } finally {
      setIsLoading(false);
      if (handleCancelModifyingCart) {
        handleCancelModifyingCart();
      }
    }
  };

  const clearAllValues = (setFieldValue: (field: string, value: any) => void) => {
    setFieldValue('category', '');
    setFieldValue('schemeName', '');
    setFieldValue('folioNumber', '');
    setFieldValue('frequency', '');
    setFieldValue('sipDay', '');
    setFieldValue('installments', '');
    setFieldValue('installmentAmount', '');
    setFieldValue('amount', '');
    setFieldValue('sipStartDate', null);
    setFieldValue('sipEndDate', null);
  };

  const handlePerpetualChange = (
    checked: boolean,
    setFieldValue: (field: string, value: any) => void,
    values: FormValues,
  ) => {
    if (checked) {
      const end_date = getPerpetualSipEndDate(
        values?.modeOfRegistration as string,
        values?.sipStartDate as Date,
      );
      const selectedInstallments = calculateDifference(
        values?.sipStartDate as Date,
        end_date,
        mapFrequency(frequencyType) as string,
      );
      setFieldValue('sipEndDate', end_date);
      setFieldValue('installments', selectedInstallments);
      setInstallments(selectedInstallments as number);
    } else {
      // clearing the values
      setFieldValue('sipDay', '');
      setFieldValue('installments', '');
      setFieldValue('sipStartDate', null);
      setFieldValue('endDate', null);
    }
    setIsPerpetual(checked);
  };
  const handleValidation = async (values: Record<string, any>) => {
    let errors: Record<string, any> = {};
    const minInstallments = minDetailsObj?.minInstallments;

    const minTransactionAmount =
      (data?.transactionType === 'APL'
        ? (schemaData as SchemaData)?.minTransactionAmount
        : minDetailsObj?.minTransactionAmount) || 0;
    if (Number(values.amount) < minTransactionAmount && values?.modeType !== 'SIP') {
      // Lumpsum amount
      errors.amount = `Min Lumpsum amount allowed ${minTransactionAmount}`;
    }
    if (minInstallments > values.installments) {
      errors.installments = `Min installments allowed ${minInstallments}`;
    }
    if (
      Number(values.installmentAmount) < minTransactionAmount &&
      values?.modeType !== 'Lumpsum'
    ) {
      errors.installmentAmount = `Min installment amount allowed ${minTransactionAmount}`;
    }
    return errors;
  };

  const getSipData = useDebounce(async (values, setFieldValue) => {
    try {
      dispatch(startLoading());
      const { startDate, endDate } = await getSipStartandEndDates({
        fund: data?.fund,
        scheme: data?.scheme,
        tenant: 'IRIS',
        installments: values?.installments,
        frequency: values.frequency,
        trtype: ModeTypeToTrtType[values?.modeType as keyof typeof ModeTypeToTrtType],
        day: values.sipDay,
      }).unwrap();

      setFieldValue('sipStartDate', startDate);
      setFieldValue('sipEndDate', endDate);
    } catch (error: any) {
      showToast((error.data as { message: string }).message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  }, 500);
  const resetLaterFields = (
    values: FormValues,
    key: keyof FormValues,
    fieldValues: Partial<FormValues>,
  ): FormValues => {
    const index = FIELD_NAMES.findIndex(fieldName => fieldName === key);
    const updatedValues = FIELD_NAMES.slice(index).reduce<Partial<FormValues>>(
      (acc, curr) => {
        return {
          ...acc,
          [curr]:
            curr === 'sipStartDate' ||
            curr === 'sipStartDate' ||
            curr === 'sipEndDate' ||
            curr === 'sipEndDate'
              ? null
              : '',
        };
      },
      {},
    );
    return { ...values, ...updatedValues, ...fieldValues };
  };
  const resetAllFields = (
    values: FormValues,
    key: keyof FormValues,
    fieldValues: Partial<FormValues>,
  ): FormValues => {
    const index = ALL_FIELD_NAMES.findIndex(fieldName => fieldName === key);
    const updatedValues = ALL_FIELD_NAMES.slice(index).reduce<Partial<FormValues>>(
      (acc, curr) => {
        return {
          ...acc,
          [curr]:
            curr === 'sipStartDate' ||
            curr === 'sipStartDate' ||
            curr === 'sipEndDate' ||
            curr === 'sipEndDate'
              ? null
              : '',
        };
      },
      {},
    );
    return { ...values, ...updatedValues, ...fieldValues };
  };
  return (
    <Formik<FormValues>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      innerRef={formikRef}
      enableReinitialize
      validate={async values => handleValidation(values)}
    >
      {({ values, setFieldValue, errors, setValues, setTouched }) => {
        const clearTouchedFields = () => {
          setTouched({});
        };
        return (
          <Form>
            <Grid
              container
              spacing={2}
              sx={{ mb: 2 }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
              >
                <FormTextInput
                  name='arnCode'
                  label='ARN Code'
                  disabled
                  value={data?.arnCode}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={6}
              >
                <FormTextInput
                  name='subArnCode'
                  label='Sub ARN Code'
                  required={false}
                  disabled
                  value={data?.subArn}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
              >
                <FormTextInput
                  name='subBrokerCode'
                  label='Sub Broker Code'
                  required={false}
                  disabled
                  value={data?.subArnCode}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={6}
              >
                <EuinsFields
                  name='euin'
                  // allEUINs={allEUINs}
                  initialValue={data?.euin !== '' ? 'yes' : 'no'}
                  disabled
                  defaultValue={data?.euin || ''}
                />
              </Grid>
            </Grid>
            <Divider
              orientation='horizontal'
              variant='fullWidth'
              flexItem
              sx={{
                border: '1px dashed',
                borderColor: 'text.borderColorDark',
                my: 3,
              }}
            />
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Typography
                  sx={{
                    color: 'text.inputLabelText',
                    mb: 1,
                    fontWeight: 500,
                    fontSize: { xs: '14px', xl: '16px' },
                  }}
                >
                  Selected AMC
                </Typography>
                <FormTextInput
                  name='selectedAmc'
                  label='selectedAmc'
                  disabled
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Typography
                  sx={{
                    color: 'text.inputLabelText',
                    mb: 2,
                    fontWeight: 500,
                    fontSize: { xs: '14px', xl: '16px' },
                  }}
                >
                  Scheme Type
                </Typography>
                <FormStyledRadioButton
                  name='schemeType'
                  options={[
                    { label: 'Existing', value: 'existing' },
                    { label: 'New', value: 'new' },
                  ]}
                  handleChange={event => {
                    isSchemeChanged.current = true;
                    // clearAllValues(setFieldValue);
                    setIsExisting(event.target.value === 'existing');
                    const newValues = resetAllFields(values, 'schemeType', {
                      schemeType: event.target.value,
                    });
                    setValues(newValues);
                    setMinDetailsObj({} as MinInstallmentsAllowed);
                    clearTouchedFields();
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Typography
                  sx={{
                    color: 'text.inputLabelText',
                    mb: 1,
                    fontWeight: 500,
                    fontSize: { xs: '14px', xl: '16px' },
                  }}
                >
                  Folio
                </Typography>
                <FormSelectInput
                  name='folioNumber'
                  label='folioNumber' // folios for data fund.
                  options={
                    values?.schemeType === 'existing'
                      ? existingAndNewFolios?.existingFolios
                        ? existingAndNewFolios?.existingFolios
                        : []
                      : existingAndNewFolios.newFolios
                      ? existingAndNewFolios.newFolios
                      : []
                  }
                  // defaultValue={data?.folio || ''}
                />
              </Grid>
              {values.schemeType === 'new' && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Typography
                    sx={{
                      color: 'text.inputLabelText',
                      mb: 1,
                      fontWeight: 500,
                      fontSize: { xs: '14px', xl: '16px' },
                    }}
                  >
                    Category
                  </Typography>

                  <FormSelectInput
                    name='category'
                    label='Category'
                    options={schemesByFund.categories.map((item: any) => ({
                      label: item,
                      value: item,
                    }))}
                    onChange={(e: any) =>
                      setSchemesByFund({
                        ...schemesByFund,
                        selectedCategory: e.target.value,
                      })
                    }
                  />
                </Grid>
              )}

              <Grid
                item
                xs={12}
                sm={6}
                sx={{ mb: 1.25 }}
              >
                <Typography
                  sx={{
                    color: 'text.inputLabelText',
                    mb: 1,
                    fontWeight: 500,
                    fontSize: { xs: '14px', xl: '16px' },
                  }}
                >
                  Scheme
                </Typography>

                <FormSelectInput
                  name='schemeName'
                  label='Select Scheme'
                  options={
                    values?.category && values?.schemeType === 'new'
                      ? schemesByFund.allSchemes
                          .filter((item: any) => item.category === values?.category)
                          .map(scheme => ({
                            label: `${scheme.schemeName || scheme.schemeDesc} - ${
                              scheme.planMode
                            } - ${scheme.optionDesc}`,
                            value: `${scheme.scheme}_${scheme.plan}_${scheme.option}`,
                          }))
                      : getInvestment(data?.fund, (values as any)?.folioNumber).map(
                          data => ({
                            value: data.scheme.toString(),
                            label: data.schemeName.toString(),
                          }),
                        )
                  }
                  defaultValue={values.schemeName}
                  onChange={(e: any) => {
                    let selectedScheme: any = {};

                    if (values?.category && values?.schemeType === 'new') {
                      selectedScheme = schemesByFund.allSchemes.find(
                        (scheme: any) =>
                          `${scheme.scheme}_${scheme.plan}_${scheme.option}` ===
                          e.target.value,
                      );
                    } else {
                      selectedScheme = getInvestment(
                        data?.fund,
                        (values as any)?.folioNumber,
                      ).find(item => item.scheme === e.target.value);
                    }
                    setSelectedScheme(selectedScheme);
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
            >
              {data?.transactionType !== 'APL' && (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <FormTextInput
                      name='modeOfRegistration'
                      label={'Mode of Registration'}
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <FormSelectInput
                      name='frequency'
                      label='Frequency'
                      defaultValue={values?.frequency}
                      options={Object.keys(frequencyModes).map(entry => ({
                        value: entry,
                        label: mapFrequency(entry.toUpperCase()),
                      }))}
                      onChange={(e: any) => {
                        setFrequencyType(e.target.value);
                        setInstallments(0);
                        const newValues = resetLaterFields(values, 'frequency', {
                          frequency: e.target.value,
                        });
                        const sortedAllData = [...frequencyModes[e.target.value]].sort(
                          (a, b) => a.minInstallments - b.minInstallments,
                        );
                        setMinDetailsObj(sortedAllData[0]);
                        setValues(newValues);
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormTextInput
                      name='installments'
                      label='No. of Installments'
                      disabled={values.perpetual}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const result = findMinInstallmentAllowed(
                          frequencyModes[values?.frequency as string],
                          values?.frequency,
                          Number(event.target.value),
                        );
                        if (result) {
                          setMinDetailsObj({
                            ...minDetailsObj,
                            minTransactionAmount: result?.minTransactionAmount,
                          });
                        }
                        const newValues = resetLaterFields(values, 'installments', {
                          installments: Number(event.target.value),
                        });
                        setValues(newValues);
                      }}
                    />
                    {values?.frequency &&
                      Object.keys(minDetailsObj || {})?.length > 0 && (
                        <Typography>
                          Minimum Number of installments:
                          {minDetailsObj?.minInstallments || 0}
                        </Typography>
                      )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <FormSelectInput
                      name='sipDay'
                      // defaultValue={values?.sipDay}
                      label='SIP Day'
                      options={frequencyModes[values?.frequency]?.[0]?.cycle?.map(
                        entry => ({
                          value: entry,
                          label: entry.toString(),
                        }),
                      )}
                      onChange={e => {
                        setSipDay(e.target.value as string);
                        const newValues = resetLaterFields(values, 'sipDay', {
                          sipDay: e.target.value as string,
                        });
                        setValues(newValues);
                        // if (
                        //   (Object.keys(errors)?.length === 1 && errors?.sipDay) ||
                        //   Object.keys(errors).length === 0
                        // ) {
                        // }
                        getSipData(newValues, setFieldValue);
                        // else {
                        //   showToast('Please fix the all form errors', 'error');
                        //   return;
                        // }
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <FormDateInput
                      name='sipStartDate'
                      placeholder='SIP Start Date'
                      label='SIP Start Date'
                      disabled={true}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <FormDateInput
                      name='sipEndDate'
                      placeholder='SIP End Date'
                      label='SIP End Date'
                      disabled={true}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                  >
                    <FormTextInput
                      name='installmentAmount'
                      placeholder='Installment Amount'
                      label='Enter Installment Amount'
                      value={values.installmentAmount}
                      onInput={(e: any) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        if (e.target.value.startsWith('0')) {
                          e.target.value = e.target.value.replace(/^0+/, '');
                        }
                        if (e.target.value.length > 18) {
                          e.target.value = e.target.value.slice(0, 18);
                        }
                        setFieldValue('installmentAmount', e.target.value);
                      }}
                    />
                    <Typography>
                      Minimum SIP Amount allowed: {minTransactionAmountAllowed || 0}
                    </Typography>
                  </Grid>
                </>
              )}

              {data?.transactionType !== 'ISIP' && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <FormTextInput
                    name='amount'
                    placeholder='Lumpsum Amount'
                    label='Lumpsum Amount'
                    value={values.amount}
                    onInput={(e: any) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                      if (e.target.value.startsWith('0')) {
                        e.target.value = e.target.value.replace(/^0+/, '');
                      }
                      if (e.target.value.length > 18) {
                        e.target.value = e.target.value.slice(0, 18);
                      }
                      setFieldValue('amount', e.target.value);
                    }}
                  />
                  <Typography>
                    Minimum Lumpsum Amount allowed: {minTransactionAmountAllowed || 0}
                  </Typography>
                </Grid>
              )}
              {values?.frequency &&
              values?.sipDay &&
              values.sipStartDate &&
              values?.sipEndDate ? (
                <Grid
                  item
                  xs={12}
                >
                  <FormCheckbox
                    name='perpetual'
                    label='Perpetual SIP'
                    margin='dense'
                    onChange={e =>
                      handlePerpetualChange(e.target.checked, setFieldValue, values)
                    }
                  />
                </Grid>
              ) : null}
            </Grid>

            {isLoading && <CustomLoader display={isLoading} />}

            <SubmitCartButtons
              onAddToCartClick={handleCancelModifyingCart}
              firstButtonTitle={'Got to cart'}
              secondButtonTitle={'Update'}
              disableCondition={!data || hasAnyErrorOccured}
            />
            {openDialogBox && (
              <CustomDialog
                isVisible={true}
                showFirstButton={false}
                firstButtonTitle=''
                firstButtonHandler={() => {}}
                secondButtonHandler={() => navigate('../')}
                secondButtonTitle='OK'
                handleClose={() => {}}
                showSecondButton
              >
                <Box
                  sx={{
                    'textAlign': 'center',
                    'pt': 2,
                    '& .MuiTypography-root': {
                      'fontSize': '16px',
                      'color': 'text.grayishBlue',
                      'my': 2,
                      '& >*': {
                        fontWeight: '600',
                      },
                    },
                  }}
                >
                  <img
                    src={VerifiedIcon}
                    alt='verified-icon'
                  />

                  <Typography variant='body1'>
                    {'Details Updated Successfully!'}
                  </Typography>
                </Box>
              </CustomDialog>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
export default EditTransactionAPL;
