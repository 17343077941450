import { Box, Grid, Stack } from '@mui/material';

import { CartLabel, CartValue } from '../../auth/login/styles/styledComponents';
import { MMddToDDMMYYYY } from '../reports/utils';
import { inrCurrencyFormatter } from '../utils';

const Cart = ({
  data,
  children,
  cartItemslength,
}: {
  data?: any;
  children?: React.ReactNode;
  cartItemslength?: boolean;
}) => {
  const trtType = data?.transactionType;
  return (
    <Box
      sx={{
        'pb': 2,
        'mb': 3,
        '&:nth-of-type(n+2)': {
          borderTop: cartItemslength ? '1px solid ' : 'unset',
          borderColor: cartItemslength ? 'text.borderColorLight' : 'unset',
          pt: cartItemslength ? 3 : 'unset',
        },
      }}
    >
      {children}

      <Grid
        container
        spacing={3}
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
        >
          <Stack gap='10px'>
            <CartLabel>{data?.fundName}</CartLabel>
            <CartValue>{data?.schemeName}</CartValue>
          </Stack>
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
        >
          <Stack gap='10px'>
            <CartLabel>Folio Number</CartLabel>
            <CartValue>{data?.folio}</CartValue>
          </Stack>
        </Grid>
        <Grid
          item
          xs={6}
          sm={2}
        >
          <Stack gap='10px'>
            <CartLabel>{trtType === 'APL' ? 'Lumpsum Amount' : 'SIP Amount'}</CartLabel>
            {/*INFO for SIN: sipAmount: investmentAmount: */}
            <CartValue>
              ₹{' '}
              {trtType === 'APL'
                ? inrCurrencyFormatter(data?.cartAmount)
                : inrCurrencyFormatter(data?.sipAmount)}
            </CartValue>
          </Stack>
        </Grid>
        {data?.transactionType !== 'APL' && (
          <>
            <Grid
              item
              xs={6}
              lg={3}
            >
              <Stack gap='10px'>
                <CartLabel>No. Of Installments:</CartLabel>
                <CartValue>{data?.noOfInstallments}</CartValue>
              </Stack>
            </Grid>
            {data?.sipStartDate && data?.sipStartDate !== 'Invalid' && (
              <Grid
                item
                xs={6}
                lg={3}
              >
                <Stack gap='10px'>
                  <CartLabel>Start Date:</CartLabel>
                  <CartValue>{MMddToDDMMYYYY(data?.sipStartDate)}</CartValue>
                </Stack>
              </Grid>
            )}
            {data?.sipEndDate && data?.sipEndDate !== 'Invalid' && (
              <Grid
                item
                xs={6}
                lg={3}
              >
                <Stack gap='10px'>
                  <CartLabel>End Date:</CartLabel>
                  <CartValue>{MMddToDDMMYYYY(data?.sipEndDate)}</CartValue>
                </Stack>
              </Grid>
            )}
            {data?.investmentAmount !== 0 && (
              <Grid
                item
                xs={6}
                lg={3}
              >
                <Stack gap='10px'>
                  <CartLabel>Lumpsum Amount:</CartLabel>
                  <CartValue>₹ {inrCurrencyFormatter(data?.amount)}</CartValue>
                </Stack>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
};

export default Cart;
