import { toFixedToNumber } from '../../common/constants';

interface PortfolioItem {
  name: string;
  value: number;
  percentage: number;
  inr: number;
}

export function calculatePortfolioItem(
  name: string,
  totalInvestedValue: number,
  classAmount: number,
): PortfolioItem {
  const safeClassAmount =
    classAmount !== undefined && classAmount !== null ? classAmount : 0;
  const value = totalInvestedValue
    ? toFixedToNumber(safeClassAmount / totalInvestedValue, 2)
    : 0;

  return {
    name,
    value,
    percentage: value,
    inr: toFixedToNumber(safeClassAmount, 2),
  };
}

export const RequestTypes = {
  UPDATE_NOMINEE: 'nominee_update',
  UPDATE_EMAIL: 'email_update',
  UPDATE_MOBILE: 'mobile_update',
  UPDATE_DATAENTRY: 'dataEntry_update',
  FOLIO_CONSOLIDATION: 'folio_consolidation',
  UPDATE_IDCWOPTION: 'idcw_update',
  UPDATE_IFSC: 'ifsc_update',
  UPDATE_FATCA: 'fatca_update',
  UPDATE_MINOR_TO_MAJOR: 'update_Minor_To_Major',
  UPDATE_FORM15GH: 'form15GH_update',
  UPDATE_MULTIBANK: 'multibank_update',
  UPDATE_BANK_DETAILS: 'update_bank_details',
};

export const getServiceRequestTypeDescription = (reqType: string) => {
  switch (reqType) {
    case RequestTypes.UPDATE_EMAIL:
      return 'Change/Update Email Address';
    case RequestTypes.UPDATE_MOBILE:
      return 'Change/Update Mobile number';
    case RequestTypes.UPDATE_NOMINEE:
      return 'Update Nominee details';
    case RequestTypes.UPDATE_BANK_DETAILS:
      return 'Update bank account details';
    case RequestTypes.UPDATE_IDCWOPTION:
      return 'Update idcw option';
    case RequestTypes.UPDATE_MULTIBANK:
      return 'Add/delete multi bank manadtes';
    case RequestTypes.UPDATE_FATCA:
      return 'Update Fatca declaration';
    default:
      return '';
  }
};
