// import SearchIcon from '@mui/icons-material/Search';
import {
  alpha,
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Stack,
  styled,
  TablePagination,
  Theme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ShimmerTable } from 'react-shimmer-effects';

import { ReactComponent as KeyBoardArrowUpIcon } from '../../assets/keyboard-arrow-up.svg';
import { ReactComponent as KeyBoardRightArrowIcon } from '../../assets/keyboard-right-arrow.svg';
import { ReactComponent as NoDataIcon } from '../../assets/no-data-table.svg';
import { ReactComponent as SearchIcon } from '../../assets/search-icon.svg';
import { StepperNextButton } from '../auth/login/styles/styledComponents';
import CollapsibleContainer from '../common/CollapsibleContainer';
import SearchComponent from '../common/SearchComponent';
import { nonCommercialTransactionTypes } from '../transactions/types';
import { inrCurrencyFormatter2 } from '../transactions/utils';
type RowData = Record<string, string | number>;
function createData(
  name: string,
  pan: string,
  mobile: number,
  email: string,
  assetsEmpaneled: string,
) {
  return {
    name,
    pan,
    mobile,
    email,
    assetsEmpaneled,
  };
}
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.text.tableBgColor,
    color: theme.palette.common.white,
  },
}));

interface StyledTableRowProps {
  isSelected?: boolean;
}

const StyledTableRow = styled(TableRow)<StyledTableRowProps>(({ theme, isSelected }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const Search = styled('div')(({ theme }) => ({
  'position': 'relative',
  'borderRadius': theme.shape.borderRadius,
  'backgroundColor': alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  'marginLeft': 8,
  'width': '100%',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  zIndex: 1,
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
interface CommonTableProps {
  noDataText?: string;
  isSelected?: boolean;
  rows: RowData[]; // Define rows as an array of RowData objects
  columns: string[]; // Define columns as an array of strings
  start?: number;
  limit?: number;
  searchPlaceholder?: string;
  continueButtonHandler: (row: RowData) => void;
  hideAmcDropdown?: boolean;
  useColumns?: boolean;
  columnsToShow: string[];
  keyToCheck?: string;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  'color': 'inherit',
  '& .MuiInputBase-input': {
    padding: '10.5px 8px',
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    // transition: theme.transitions.create('width'),
    // width: '350px',
    borderRadius: '5px',
    fontWeight: '500',
    fontSize: '16px',
    color: theme.palette.text.labelColor,
    border: `1px solid ${theme.palette.text.borderColorLight}`,
    [theme.breakpoints.up('sm')]: {
      //   width: '12ch',
      //   '&:focus': {
      //     width: '20ch',
      //   },
    },
  },
}));
interface RowProps {
  row: RowData;
  continueButtonHandler: (row: RowData) => void;
  isSelected: boolean;
  onRowClick: () => void;
  columnsToShow: string[];
  keyToCheck: string;
  children?: React.ReactNode; // Optional children prop
  colspan?: number;
  hideRadioButton?: boolean;
}
function Row(props: RowProps) {
  const {
    row,
    continueButtonHandler,
    isSelected,
    onRowClick,
    columnsToShow,
    keyToCheck,
    children,
    colspan,
    hideRadioButton,
  } = props;

  return (
    <>
      <StyledTableRow
        isSelected={isSelected}
        sx={(theme: Theme) => ({
          'borderBottom': !isSelected
            ? `1px solid ${theme.palette.text.borderColorLight}`
            : 'unset',
          'bgcolor': isSelected ? 'text.lightBlue' : 'common.white',
          'borderLeft': isSelected ? `5px solid ${theme.palette.primary.main}` : 'unset',
          '& .MuiTableCell-root': {
            '& .MuiTypography-root': isSelected
              ? {
                  'backgroundClip': 'text',
                  'webkitBackgroundClip': 'text',
                  'WebkitTextFillColor': 'transparent',
                  'fontWeight': '500',
                  'backgroundImage': `linear-gradient(180deg,  ${theme.palette.text.darkBlue} 0%,  ${theme.palette.text.gradientSkyBlue} 100%)`,
                  '& .MuiIconButton-root ': {
                    '& path': {
                      stroke: 'primary.main',
                    },
                  },
                }
              : { color: 'text.valueColor' },
          },
        })}
        onClick={() => onRowClick()}
      >
        {!hideRadioButton && (
          <StyledTableCell>
            <IconButton
              aria-label='expand row'
              size='small'
            >
              <Radio checked={isSelected} />
            </IconButton>
          </StyledTableCell>
        )}
        {children && <StyledTableCell colSpan={colspan}>{children}</StyledTableCell>}
        {Object.keys(row).map(
          (key, index) =>
            columnsToShow.includes(key) && ( // show row only if columns exists
              <StyledTableCell key={index}>
                <Typography>
                  {key === 'availableAmount'
                    ? `${inrCurrencyFormatter2(row[key]) || 'NA'}`
                    : row[key] || 'NA'}
                </Typography>
              </StyledTableCell>
            ),
        )}
      </StyledTableRow>
      <TableRow
        sx={(theme: Theme) => ({
          borderBottom: isSelected
            ? `1px solid ${theme.palette.text.borderColorLight}`
            : 'unset',
          borderLeft: isSelected ? `5px solid ${theme.palette.primary.main}` : 'unset',
          bgcolor: !isSelected ? 'common.white' : 'text.lightBlue',
        })}
      >
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={8}
        >
          <Collapse
            in={isSelected}
            timeout='auto'
            unmountOnExit
          >
            <Stack
              direction='row'
              justifyContent='flex-end'
              m='10px 0 20px'
            >
              <StepperNextButton
                disabled={keyToCheck ? !row[keyToCheck] : false}
                onClick={() => continueButtonHandler(row)}
                sx={{ minWidth: '215px' }}
              >
                Continue
              </StepperNextButton>
            </Stack>
          </Collapse>
        </StyledTableCell>
      </TableRow>
    </>
  );
}

// Exporting rows
export const rows = [
  createData(
    'Bhuvneshwar Kumar',
    'HBAPD2421N',
    9777664123,
    'bhuvi04@gmail.com',
    'MF, NPS',
  ),
  createData(
    'Bhuvneshwar Kumar',
    'HBAPD2421N',
    9777664123,
    'bhuvi04@gmail.com',
    'MF, NPS',
  ),
  createData(
    'Bhuvneshwar Kumar',
    'HBAPD2421N',
    9777664123,
    'bhuvi04@gmail.com',
    'MF, NPS',
  ),
  createData(
    'Bhuvneshwar Kumar',
    'HBAPD2421N',
    9777664123,
    'bhuvi04@gmail.com',
    'MF, NPS',
  ),
];
export const SwitchCollapsibleTable = () => {
  const [handleOpen, setHandleOpen] = useState(false);
  return (
    <>
      <Box sx={{ width: '100%', display: { xs: 'none', md: 'block' } }}>
        <Paper
          sx={{
            width: '100%',
            mb: 2,
            borderRadius: '15px',
            border: '0.3px solid',
            borderColor: 'text.borderColorDark',
            background: 'common.white',
            boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.05)',
          }}
        >
          <Box
            sx={{
              'padding': '20px',
              'display': 'flex',
              'alignItems': 'center',
              'gap': '10px',
              'borderBottom': '1px solid',
              'borderColor': 'text.borderColorLight',
              '& .MuiTypography-root ': {
                borderRadius: '7px',
                background: 'linear-gradient(90deg, #1D6CB0 0%, #10C1D0 100%)',
                display: 'inline-block',
                padding: '5px 10px',
                color: 'common.white',
                fontSize: '15px',
                fontWeight: '500',
                cursor: 'pointer',
                // color:'#6C737F',
                // border:'0.5px solid #cdcdcd'
              },
            }}
          >
            <Typography component='span'>Equity</Typography>
            <Typography component='span'>Debt</Typography>
            <Typography component='span'>Hybrid</Typography>
            <Typography component='span'>Solution Oriented</Typography>
          </Box>
          <Box
            sx={{
              padding: '20px',
              borderBottom: '1px solid',
              borderColor: 'text.borderColorLight',
            }}
          >
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                sx={{ '& .MuiInputBase-input': { width: '100%' } }}
                fullWidth
                placeholder='Search by Name'
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
          </Box>
          <Box
            onClick={() => setHandleOpen(!handleOpen)}
            sx={{
              'padding': '20px',
              'cursor': 'pointer',
              'bgcolor': handleOpen ? 'text.lightBlue' : 'common.white',
              'borderColor': handleOpen ? 'primary.main' : 'unset',
              'border': handleOpen ? '5px solid' : 'unset',
              '&:not(last-child)': {
                borderBottom: '1px solid',
                borderColor: 'text.borderColorLight',
              },
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                aria-label='expand'
                size='small'
              >
                {handleOpen ? <KeyBoardArrowUpIcon /> : <KeyBoardRightArrowIcon />}
              </IconButton>
              <Typography>Axis Growth Opportunities Fund Direct Growth</Typography>
              <Typography>Axis Growth Opportunities Fund Direct Growth</Typography>
              <Typography>Axis Growth Opportunities Fund Direct Growth</Typography>
            </Box>
            <Collapse
              in={handleOpen}
              timeout='auto'
              unmountOnExit
            >
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <StepperNextButton>Continue</StepperNextButton>
              </Box>
            </Collapse>
          </Box>
          <Box
            onClick={() => setHandleOpen(!handleOpen)}
            sx={{ padding: '20px', cursor: 'pointer' }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                aria-label='expand'
                size='small'
              >
                {handleOpen ? <KeyBoardArrowUpIcon /> : <KeyBoardRightArrowIcon />}
              </IconButton>
              <Typography>Axis Growth Opportunities Fund Direct Growth</Typography>
              <Typography>Axis Growth Opportunities Fund Direct Growth</Typography>
              <Typography>Axis Growth Opportunities Fund Direct Growth</Typography>
            </Box>
            <Collapse
              in={handleOpen}
              timeout='auto'
              unmountOnExit
            >
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <StepperNextButton>Continue</StepperNextButton>
              </Box>
            </Collapse>
          </Box>
        </Paper>
      </Box>
    </>
  );
};
export const CommonTable: React.FC<CommonTableProps> = ({
  isSelected,
  noDataText = ' You currently have no Investments for this transaction',
  rows = [],
  columns = [],
  start = 0, // Starting page indexx
  limit = 10, // Limit given
  searchPlaceholder = 'Search by Name',
  continueButtonHandler,
  hideAmcDropdown,
  useColumns,
  columnsToShow,
  keyToCheck = '',
  loading,
  setLoading,
}) => {
  const isFirstInitialization = React.useRef(true);
  const [filteredRows, setFilteredRows] = useState<RowData[]>(rows);
  if (
    isFirstInitialization.current &&
    JSON.stringify(filteredRows.length) !== JSON.stringify(rows.length)
  ) {
    isFirstInitialization.current = false;
    setFilteredRows(rows);
  }
  const location = useLocation();
  const cartState = location.state;
  const [page, setPage] = useState(start);
  const [rowsPerPage, setRowsPerPage] = useState(limit);
  const [selectedAmc, setSelectedAmc] = useState(
    cartState?.isFromCart || cartState?.isFormCart ? cartState?.cartItem[0]?.fund : 'All',
  );
  const showAllSchemes = nonCommercialTransactionTypes.find(
    trType => trType === cartState?.cartItem[0]?.transactionType,
  );
  const [text, setText] = useState<string>('');
  const fundMap: Record<string, boolean> = {};
  const [selectedRow, setSelectedRow] = useState<string>(''); // Track selected row index
  // const { loading }: any = useSelector((state: any) => state.loading);
  // Function to handle row selection
  const handleRowSelection = (key: string) => {
    setSelectedRow(key);
  };

  const amcDropDownList: { label: string; value: string }[] = rows.reduce(
    (acc: { label: string; value: string }[], current: RowData) => {
      if (!fundMap[current.fund]) {
        fundMap[current.fund] = true;
        acc.push({ label: String(current.fundName), value: String(current.fund) });
      }
      return acc;
    },
    [],
  );
  useEffect(() => {
    const filterByAMC = (rows: RowData[]) => {
      if (!selectedAmc || selectedAmc === 'All') {
        return rows;
      } else {
        return rows.filter(item => item?.fund === selectedAmc);
      }
    };

    const filterByText = (rows: RowData[]) => {
      return rows.filter(
        item =>
          item?.fundName
            ?.toString()
            ?.toLowerCase()
            ?.includes(text?.toLowerCase() ?? '') ||
          item?.schemeDesc
            ?.toString()
            ?.toLowerCase()
            ?.includes(text?.toLowerCase() ?? '') ||
          item?.folio?.toString()?.includes(text ?? ''),
      );
    };

    const filteredRows = filterByText(filterByAMC(rows));
    setFilteredRows(filteredRows);
    setPage(0);
  }, [selectedAmc, rows, text]);

  // Handlers to change the states
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const onChangeSearchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const transformedObj =
    amcDropDownList &&
    Array.isArray(amcDropDownList) &&
    amcDropDownList.reduce((acc: Record<string, string>, { label, value }) => {
      if (label && value != null) {
        acc[label] = value;
      }
      return acc;
    }, {});
  return (
    <>
      <Box sx={{ width: '100%', display: { xs: 'none', md: 'block' } }}>
        <Paper
          sx={{
            width: '100%',
            mb: 2,
            borderRadius: '15px',
            border: '0.3px solid',
            borderColor: 'text.borderColorDark',
            bgcolor: 'common.white',
            boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.05)',
          }}
        >
          <Box sx={{ padding: '16px' }}>
            <Grid
              container
              spacing={2}
            >
              {!hideAmcDropdown && (
                <Grid
                  item
                  sm={3}
                >
                  <FormControl
                    fullWidth
                    sx={{
                      'mb': 1,
                      '& .MuiFormLabel-root.MuiInputLabel-root': {
                        fontSize: '14px',
                        fontWeight: 500,
                        color: 'text.labelColor',
                        top: '-2px',
                      },
                      '& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused': {
                        color: 'text.labelColor',
                        fontSize: '14px',
                      },
                      '& .MuiInputBase-root': {
                        'fontSize': '14px',
                        'color': 'text.labelColor',
                        'fontWeight': 500,
                        '& .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input.Mui-disabled':
                          {
                            backgroundColor: 'text.borderColorDark',
                            color: 'text.primary',
                            WebkitTextFillColor: 'unset',
                          },
                      },
                      '& .MuiInputBase-root, & .MuiInputBase-root-MuiFilledInput-root-MuiSelect-root':
                        {
                          'border': 'none',
                          'backgroundColor': 'unset !important',
                          '&:before, &:after, &:hover': {
                            borderBottom: 'none !important',
                          },
                          '&.Mui-focused': {
                            backgroundColor: 'unset',
                          },
                        },
                      '& .MuiInputBase-root-MuiFilledInput-root-MuiSelect-root.Mui-focused':
                        {
                          '&:hover': {
                            '&:before': {
                              border: 'none',
                            },
                          },
                        },
                      '& .MuiInputBase-root-MuiFilledInput-root-MuiSelect-root:hover': {
                        'backgroundColor': 'unset',
                        '&:before': {
                          borderBottom: 'none',
                        },
                      },
                      '& .MuiSelect-select ,&:focus': {
                        borderRadius: '7px',
                        border: '1px solid',
                        borderColor: 'text.borderColorLight',
                        backgroundColor: 'common.white',
                        paddingBottom: '4px',
                        paddingTop: '20px',
                      },
                    }}
                    variant='filled'
                  >
                    <InputLabel
                      id='amc-select-label'
                      sx={{
                        'color': 'text.labelColor',
                        'fontSize': '14px',
                        'fontWeight': '500',
                        'top': '2px',
                        'left': '2px',
                        '&.Mui-focused': {
                          color: 'inherit',
                          fontSize: '12px',
                          fontWeight: '500',
                        },
                      }}
                    >
                      AMC
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId='amc-select-label'
                      id='amc-select'
                      value={selectedAmc}
                      onChange={(e: any) => {
                        setSelectedAmc(e.target.value);
                      }}
                      disabled={
                        !showAllSchemes &&
                        (cartState?.isFromCart || cartState?.isFormCart)
                      }
                    >
                      <MenuItem value='All'>All</MenuItem>
                      {amcDropDownList.map((option, index) => (
                        <MenuItem
                          value={option.value}
                          key={index}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              <Grid
                item
                sm={!hideAmcDropdown ? 9 : 12}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <SearchComponent
                    text={text}
                    onChangeSearchHandler={onChangeSearchHandler}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
          {loading && filteredRows.length === 0 ? (
            <ShimmerTable
              row={10}
              col={5}
            />
          ) : (
            <>
              <TableContainer>
                <Table
                  aria-label='collapsible table'
                  sx={(theme: Theme) => ({
                    '& .MuiTableCell-root ': {
                      padding: '16px 12px',
                      [theme.breakpoints.between(1440, 1600)]: {
                        padding: '16px 16px',
                      },
                    },
                  })}
                >
                  <TableHead
                    sx={{
                      '& .MuiTableCell-root.MuiTableCell-head': {
                        fontSize: '14px',
                        color: 'text.labelColor',
                        fontWeight: '500',
                        textAlign: 'left',
                        whiteSpace: { lg: 'nowrap' },
                      },
                    }}
                  >
                    <StyledTableRow>
                      {columns.map((column, index) => (
                        <StyledTableCell
                          sx={{ bgcolor: 'text.tableBgColor' }}
                          key={index}
                        >
                          {column}
                        </StyledTableCell>
                      ))}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody
                    sx={(theme: Theme) => ({
                      '& .MuiTableRow-root': {
                        'cursor': filteredRows.length === 0 ? 'unset' : 'pointer',
                        '& .MuiTableCell-root ': {
                          'verticalAlign': 'baseline',
                          '& .MuiButtonBase-root': {
                            '&.MuiRadio-root': {
                              '&.Mui-checked': {
                                color: 'primary.main',
                              },
                              '& .MuiSvgIcon-root': {
                                width: '16px',
                                height: '16px',
                              },
                            },
                          },
                        },
                      },
                    })}
                  >
                    {filteredRows
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        return (
                          <Row
                            key={row?.key}
                            row={row}
                            continueButtonHandler={continueButtonHandler}
                            isSelected={selectedRow === row?.key} // isSelected prop to Row component
                            onRowClick={() => handleRowSelection(row?.key as any)}
                            columnsToShow={columnsToShow}
                            keyToCheck={keyToCheck}
                          />
                        );
                      })}
                    {!loading && filteredRows.length === 0 && (
                      <Row
                        row={{}}
                        isSelected={false}
                        onRowClick={() => {}}
                        colspan={8}
                        continueButtonHandler={() => {}}
                        columnsToShow={[]}
                        keyToCheck={''}
                        hideRadioButton={true}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            maxWidth: '400px',
                            margin: '5% auto',
                          }}
                        >
                          <NoDataIcon />
                          <Typography
                            sx={{
                              fontSize: { xs: '14px', sm: '16px', xl: '18px' },
                              fontWeight: 500,
                              color: 'text.labelColor',
                              my: 4,
                            }}
                          >
                            {noDataText}1
                          </Typography>
                        </Box>
                      </Row>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component='div'
                count={filteredRows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Paper>
      </Box>
      <CollapsibleContainer
        noDataText={noDataText}
        loading={loading}
        setLoading={setLoading}
        rows={filteredRows}
        columnsToShow={columnsToShow}
        continueButtonHandler={continueButtonHandler}
        amcDropDownList={{ All: 'All', ...transformedObj }}
        onChangeSearchHandler={value => setText(value)}
        text={text}
        dropDownHandler={(value: string) => {
          setSelectedAmc(value);
        }}
        selectedDropdownValue={selectedAmc}
      />
    </>
  );
};
