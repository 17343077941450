import {
  Avatar,
  Box,
  Chip,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { Form, Formik, FormikErrors } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import Female from '../../../../../assets/female.svg';
import Male from '../../../../../assets/male.svg';
import Others from '../../../../../assets/others.svg';
import { DetailsValue, ErrorText } from '../../../../auth/login/styles/styledComponents';
import CustomDialog from '../../../../common/AllDialogs/CustomDialog';
import { CustomizedCheckbox } from '../../../../common/checkbox';
import { startLoading, stopLoading } from '../../../../common/Loader/loaderSlice';
import { useRootContext } from '../../../../data/root.context';
import { LoginResponse } from '../../../../features/login/loginSlice';
import FormDateInput from '../../../../forms/FormDateInput';
import { FormSelectInput } from '../../../../forms/FormSelect';
import { FormStyledRadioButton } from '../../../../forms/FormStyledRadioButton';
import SubmitButton from '../../../../forms/FormSubmitButton';
import FormTextInput from '../../../../forms/FormTextInput';
import { useAadharPanSeedingMutation } from '../../../slice';
import { maskAccountNumber, maskEmail } from '../../../utils';
import { familyDescriptionOptions, guardianRelationWithM } from '../../constants';
import {
  setHolderDetails,
  setInvestorDetails,
  setStepNumber,
} from '../context/NewMfsInvestorAction';
import { useNewMfsInvestorContext } from '../context/NewMfsInvestorContext';
import { useInvestorDetailsMutation, useVerifyPanKycDetailsMutation } from '../slice';
import { dateToDDMMYYYY, getFirstLetter } from '../utils';

const gendersList = {
  Male: Male,
  Female: Female,
  Others: Others,
};

interface InvestorDetailValues {
  modeOfHolding: string;
  category: string;
  pan: string;
  mobile: string;
  email: string;
  name: string;
  emailFamilyDescription: string;
  mobileFamilyDescription: string;
  dob: Date | null;
  gender: string;
  seaFarerFlag: string;
  address1: string;
  residentOfUsOrCanada: boolean;
  address2: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  countryCode: string;
  guardianPan: string;
  guardianDob: Date | null;
  guardianName: string;
  guardianEmail: string;
  guardianCategory: string;
  guardianMonNo: string;
  guardianEmailFamilyDescription: string;
  guardianMobileFamilyDescription: string;
  guardianRelationWithMinor: string;
  panOfJointHolder1: string;
  categoryOfJointHolder1: string;
  nameOfJointHolder1: string;
  emailIdOfJointHolder1: string;
  mobileNoOfJointHolder1: string;
  countryCodeH1: string;
  dobOfJointHolder1: Date | null;
  genderOfJointHolder1: string;
  jointHolder1EmailFamilyDescription: string;
  jointHolder1MobileFamilyDescription: string;
  h1Address1: string;
  h1Address2: string;
  h1City: string;
  h1State: string;
  h1Country: string;
  h1ZipCode: string;
  panOfJointHolder2: string;
  categoryOfJointHolder2: string;
  nameOfJointHolder2: string;
  emailIdOfJointHolder2: string;
  mobileNoOfJointHolder2: string;
  countryCodeH2: string;
  dobOfJointHolder2: Date | null;
  genderOfJointHolder2: string;
  jointHolder2EmailFamilyDescription: string;
  jointHolder2MobileFamilyDescription: string;
  h2Address1: string;
  h2Address2: string;
  h2City: string;
  h2State: string;
  h2Country: string;
  h2ZipCode: string;
}

export const InvestorDetails: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast } = useRootContext();
  const [jointHolderPanErr, setJointHolderPanErr] = useState('');
  const [jointHolder2PanErr, setJointHolder2PanErr] = useState('');
  const [isMaskEmailH1, setIsMaskEmailH1] = useState(true);
  const [isMaskEmailH2, setIsMaskEmailH2] = useState(true);
  const [isMaskMobileH1, setIsMaskMobileH1] = useState(true);
  const [isMaskMobileH2, setIsMaskMobileH2] = useState(true);
  const [fieldType, setFieldType] = useState('');
  const [openUniqueEmailDialogBox, setOpenUniqueEmailDialogBox] = useState(false);

  const { mobile: distributorMobile, email: distributorEmail } = useSelector(
    (state: { login: LoginResponse }) => state.login,
  );
  const distMobile = distributorMobile?.split('+91')?.[1];

  const {
    dispatch: contextDispatch,
    state: {
      basicDetails,
      investorDetailsFormResponse,
      investorDetails,
      holderDetails,
      investmentDetails,
    },
  } = useNewMfsInvestorContext();

  const [getData] = useInvestorDetailsMutation();
  const [verifyPanFraud] = useVerifyPanKycDetailsMutation();
  const [aadharPanSeeding] = useAadharPanSeedingMutation();

  const { countries } = useSelector((state: any) => state.countriesAndStates);

  const countryList = countries?.map((country: any) => ({
    value: country.name,
    label: country.name,
  }));

  const { investorType, guardianPan, guardianName, panMinor, dateOfBirth } =
    basicDetails || {};
  const { personalDetails } = investorDetailsFormResponse || {};

  const [holder1 = {}, holder2 = {}] = holderDetails || [];

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const currentDate = new Date().getDate();
  let minDate: Date | undefined;
  let maxDate: Date | undefined;
  if (investorType === 'M') {
    const minYear = currentYear - 18;
    minDate = new Date(minYear, currentMonth, currentDate);
  } else {
    maxDate = new Date(currentYear - 18, currentMonth, currentDate);
  }

  const {
    dob: resDob,
    email: resEmail,
    mobile: resMob,
    name: resName,
    modeOfHolding,
  } = personalDetails || {};

  const modeOfHoldingRes =
    modeOfHolding !== '' && modeOfHolding === '2' ? 'anyone' : 'single';

  const isIndividual = investorType === 'I';
  const isMinor = investorType === 'M';

  const initialValues: InvestorDetailValues = {
    modeOfHolding: investorDetails?.modeOfHolding || modeOfHoldingRes || 'single',
    category: investorDetails?.category || '11',
    pan: investorDetails?.pan || panMinor || '',
    mobile: investorDetails?.mobile || (isIndividual && resMob) || '',
    email: investorDetails?.email || (isIndividual && resEmail) || '',
    name: investorDetails?.name || (isIndividual && resName) || '',
    emailFamilyDescription: investorDetails?.emailFamilyDescription || 'SE',
    mobileFamilyDescription: investorDetails?.mobileFamilyDescription || 'SE',
    dob: dateOfBirth || investorDetails?.dob || (isIndividual && resDob) || null,
    gender: investorDetails?.gender || '',
    seaFarerFlag: investorDetails?.seaFarerFlag || '',
    address1: investorDetails?.address1 || '',
    residentOfUsOrCanada: true,
    address2: investorDetails?.address2 || '',
    city: investorDetails?.city || '',
    state: investorDetails?.state || '',
    country: investorDetails?.country || '',
    zipCode: investorDetails?.zipCode || '',
    countryCode: investorDetails?.countryCode || '+91',
    guardianPan: investorDetails?.guardianPan || guardianPan || '',
    guardianDob: investorDetails?.guardianDob || (isMinor && resDob) || null,
    guardianName: investorDetails?.guardianName || (isMinor && resName) || '',
    guardianEmail: investorDetails?.guardianEmail || (isMinor && resEmail) || '',
    guardianCategory: investorDetails?.guardianCategory || '',
    guardianMonNo: investorDetails?.guardianMonNo || (isMinor && resMob) || '',
    guardianEmailFamilyDescription:
      investorDetails?.guardianEmailFamilyDescription || 'SE',
    guardianMobileFamilyDescription:
      investorDetails?.guardianMobileFamilyDescription || '',
    guardianRelationWithMinor: investorDetails?.guardianRelationWithMinor || '',
    panOfJointHolder1: holder1?.pan || '',
    categoryOfJointHolder1: holder1?.category || '11',
    nameOfJointHolder1: holder1?.name || '',
    emailIdOfJointHolder1: holder1?.email || '',
    mobileNoOfJointHolder1: holder1?.mobile || '',
    countryCodeH1: holder1?.mobile || '+91',
    dobOfJointHolder1: holder1?.dob || null,
    genderOfJointHolder1: holder1?.gender || '',
    jointHolder1EmailFamilyDescription: holder1?.emailFamilyDescription || 'SE',
    jointHolder1MobileFamilyDescription: holder1?.mobileFamilyDescription || 'SE',
    h1Address1: holder1?.nriAddress1 || '',
    h1Address2: holder1?.nriAddress2 || '',
    h1City: holder1?.nriCity || '',
    h1State: holder1?.nriState || '',
    h1Country: '',
    h1ZipCode: '',
    panOfJointHolder2: holder2?.pan || '',
    categoryOfJointHolder2: holder2?.category || '11',
    nameOfJointHolder2: holder2?.name || '',
    emailIdOfJointHolder2: holder2?.email || '',
    mobileNoOfJointHolder2: holder2?.mobile || '',
    countryCodeH2: holder2?.mobile || '+91',
    dobOfJointHolder2: holder2?.dob || null,
    genderOfJointHolder2: holder2?.gender || '',
    jointHolder2EmailFamilyDescription: holder2?.emailFamilyDescription || 'SE',
    jointHolder2MobileFamilyDescription: holder2?.mobileFamilyDescription || 'SE',
    h2Address1: holder2?.nriAddress1 || '',
    h2Address2: holder2?.nriAddress2 || '',
    h2City: holder2?.nriCity || '',
    h2State: holder2?.nriState || '',
    h2Country: '',
    h2ZipCode: '',
  };

  const isHolder2NriAddressRequired = (
    mode: string,
    panOfJointHolder2: string,
    categoryOfJointHolder2: string,
  ) =>
    mode === 'anyone' &&
    categoryOfJointHolder2 === '20' &&
    panOfJointHolder2 &&
    panOfJointHolder2.length === 10;

  const isHolder2FieldsRequired = (mode: string, panOfJointHolder2: string) =>
    mode === 'anyone' && panOfJointHolder2 && panOfJointHolder2.length === 10;

  const minorFlagSchema = {
    emailFamilyDescription: Yup.string().required('Email family Desc is required'),
    mobileFamilyDescription: Yup.string().required('Mobile family desc is required'),
  };

  const individualValidationSchema = (invType: string) => ({
    modeOfHolding: Yup.string().required('Mode of Holding is required'),
    category: Yup.string().required('Category is required'),
    pan:
      investorType === 'I'
        ? Yup.string()
            .required('PAN of Primary Holder is required')
            .matches(/^[A-Z]{3}[PFCHT][A-Z]{1}[0-9]{4}[A-Z]{1}$/, 'Invalid PAN')
        : Yup.string()
            .matches(/^[A-Z]{3}[PFCHT][A-Z]{1}[0-9]{4}[A-Z]{1}$/, 'Invalid PAN')
            .nullable(),
    name: Yup.string()
      .trim()
      .required(`${investorType === 'M' ? 'Minor Name' : 'Name'} is required`)
      .matches(
        /^[a-zA-Z]+(\s[a-zA-Z]+)*$/,
        'Name cannot contain only spaces or special characters',
      )
      .min(4, 'Invalid name. please enter valid name'),
    email: Yup.string()
      .required('Email is required')
      .matches(
        /^[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        'email must be a valid email',
      ),
    countryCode: Yup.string()
      .required('Country Code is required')
      .matches(/^\+[0-9]{1,3}$/, 'Please enter a valid Country Code')
      .max(5, 'Please enter a valid Country Code'),
    mobile: Yup.string()
      .required('Mobile Number is required')
      .matches(/^[6-9]{1}[0-9]{9}$/, 'Invalid Mobile Number'),
    dob: Yup.date()
      .typeError('Invalid Date')
      .min(new Date(1900, 0, 1), 'Please enter a valid date after 01/01/1900')
      .nullable()
      .required('Date of birth required')
      .max(
        invType === 'I'
          ? new Date(new Date().setFullYear(new Date().getFullYear() - 18))
          : new Date(),
        invType === 'I' ? 'Individual Date of Birth should be 18 year old' : '',
      ),
    gender: Yup.string().required('Gender is required'),
    address1: Yup.string().when('category', {
      is: '20',
      then: Yup.string().required('Address 1 is required'),
    }),
    address2: Yup.string().when('category', {
      is: '20',
      then: Yup.string().required('Address 2 is required'),
    }),
    city: Yup.string().when('category', {
      is: '20',
      then: Yup.string().required('City is required'),
    }),
    state: Yup.string().when('category', {
      is: '20',
      then: Yup.string().required('State is required'),
    }),
    country: Yup.string().when('category', {
      is: '20',
      then: Yup.string().required('Country is required'),
    }),
    zipCode: Yup.string().when('category', {
      is: '20',
      then: Yup.string()
        .required('ZipCode is required')
        .matches(/^[0-9]+$/, 'Please enter valid ZipCode'),
    }),
    panOfJointHolder1: Yup.string().when('modeOfHolding', {
      is: 'anyone',
      then: Yup.string()
        .required('PAN of joint holder 1 is required')
        .matches(/^[A-Z]{3}[P][A-Z]{1}[0-9]{4}[A-Z]{1}$/, 'Invalid PAN'),
    }),
    categoryOfJointHolder1: Yup.string().when('modeOfHolding', {
      is: 'anyone',
      then: Yup.string().required('Category of joint holder 1 is required'),
    }),
    nameOfJointHolder1: Yup.string().when('modeOfHolding', {
      is: 'anyone',
      then: Yup.string()
        .trim()
        .required('Name of joint holder is required')
        .matches(
          /^[a-zA-Z]+(\s[a-zA-Z]+)*$/,
          'Name cannot contain only spaces or special characters',
        )
        .min(4, 'Invalid holder name. please enter valid name'),
    }),
    emailIdOfJointHolder1: Yup.string().when('modeOfHolding', {
      is: 'anyone',
      then: Yup.string()
        .required('Email id of joint holder is required')
        .matches(
          /^[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          'email must be a valid email',
        ),
    }),
    mobileNoOfJointHolder1: Yup.string().when('modeOfHolding', {
      is: 'anyone',
      then: Yup.string()
        .required('Mobile No of joint holder is required')
        .matches(/^[6-9]{1}[0-9]{9}$/, 'Invalid Mobile Number'),
    }),
    countryCodeH1: Yup.string().when('modeOfHolding', {
      is: 'anyone',
      then: Yup.string()
        .required('Country Code is required')
        .matches(/^\+[0-9]{1,3}$/, 'Please enter a valid Country Code')
        .max(5, 'Please enter a valid Country Code'),
    }),
    dobOfJointHolder1: Yup.date()
      .typeError('Invalid Date')
      .when('modeOfHolding', {
        is: 'anyone',
        then: Yup.date()
          .typeError('Invalid Date')
          .min(new Date(1900, 0, 1), 'Please enter a valid date after 01/01/1900')
          .required('DOB of joint holder is required')
          .max(
            new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
            'Holder should be at least 18 years old',
          ),
        otherwise: Yup.date().typeError('Invalid Date').nullable(),
      }),
    genderOfJointHolder1: Yup.string().when('modeOfHolding', {
      is: 'anyone',
      then: Yup.string().required('Gender of joint holder is required'),
    }),

    jointHolder1EmailFamilyDescription: Yup.string().when('modeOfHolding', {
      is: 'anyone',
      then: Yup.string().required('Email family description of joint holder is required'),
    }),

    jointHolder1MobileFamilyDescription: Yup.string().when('modeOfHolding', {
      is: 'anyone',
      then: Yup.string().required(
        'Mobile family description of joint holder is required',
      ),
    }),
    h1Address1: Yup.string().when(['categoryOfJointHolder1', 'modeOfHolding'], {
      is: (categoryOfJointHolder1: string, modeOfHolding: string) =>
        modeOfHolding === 'anyone' && categoryOfJointHolder1 === '20',
      then: Yup.string().required('Address 1 is required'),
    }),
    h1Address2: Yup.string().when(['categoryOfJointHolder1', 'modeOfHolding'], {
      is: (categoryOfJointHolder1: string, modeOfHolding: string) =>
        modeOfHolding === 'anyone' && categoryOfJointHolder1 === '20',
      then: Yup.string().required('Address 2 is required'),
    }),
    h1City: Yup.string().when(['categoryOfJointHolder1', 'modeOfHolding'], {
      is: (categoryOfJointHolder1: string, modeOfHolding: string) =>
        modeOfHolding === 'anyone' && categoryOfJointHolder1 === '20',
      then: Yup.string().required('City is required'),
    }),
    h1State: Yup.string().when(['categoryOfJointHolder1', 'modeOfHolding'], {
      is: (categoryOfJointHolder1: string, modeOfHolding: string) =>
        modeOfHolding === 'anyone' && categoryOfJointHolder1 === '20',
      then: Yup.string().required('State is required'),
    }),
    h1Country: Yup.string().when(['categoryOfJointHolder1', 'modeOfHolding'], {
      is: (categoryOfJointHolder1: string, modeOfHolding: string) =>
        modeOfHolding === 'anyone' && categoryOfJointHolder1 === '20',
      then: Yup.string().required('Country is required'),
    }),
    h1ZipCode: Yup.string().when(['categoryOfJointHolder1', 'modeOfHolding'], {
      is: (categoryOfJointHolder1: string, modeOfHolding: string) =>
        modeOfHolding === 'anyone' && categoryOfJointHolder1 === '20',
      then: Yup.string()
        .required('ZipCode is required')
        .matches(/^[0-9]+$/, 'Please enter valid ZipCode'),
    }),
    panOfJointHolder2: Yup.string().when('modeOfHolding', {
      is: 'anyone',
      then: Yup.string().matches(
        /^[A-Z]{3}[P][A-Z]{1}[0-9]{4}[A-Z]{1}$/,
        'Only individual PAN is allowed',
      ),
    }),
    categoryOfJointHolder2: Yup.string().when(['modeOfHolding', 'panOfJointHolder2'], {
      is: isHolder2FieldsRequired,
      then: Yup.string().required('Category of joint holder 2 is required'),
    }),
    nameOfJointHolder2: Yup.string().when(['modeOfHolding', 'panOfJointHolder2'], {
      is: isHolder2FieldsRequired,
      then: Yup.string()
        .trim()
        .required('Name of joint holder is required')
        .matches(
          /^[a-zA-Z]+(\s[a-zA-Z]+)*$/,
          'Name cannot contain only spaces or special characters',
        )
        .min(4, 'Invalid holder name. please enter valid name'),
    }),
    emailIdOfJointHolder2: Yup.string().when(['modeOfHolding', 'panOfJointHolder2'], {
      is: isHolder2FieldsRequired,
      then: Yup.string()
        .required('Email id of joint holder is required')
        .matches(
          /^[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          'email must be a valid email',
        ),
    }),
    mobileNoOfJointHolder2: Yup.string().when(['modeOfHolding', 'panOfJointHolder2'], {
      is: isHolder2FieldsRequired,
      then: Yup.string()
        .required('Mobile No of joint holder is required')
        .matches(/^[6-9]{1}[0-9]{9}$/, 'Invalid Mobile Number'),
    }),
    countryCodeH2: Yup.string().when(['modeOfHolding', 'panOfJointHolder2'], {
      is: isHolder2FieldsRequired,
      then: Yup.string()
        .required('Country Code is required')
        .matches(/^\+[0-9]{1,3}$/, 'Please enter a valid Country Code')
        .max(5, 'Please enter a valid Country Code'),
    }),
    jointHolder2EmailFamilyDescription: Yup.string().when(
      ['modeOfHolding', 'panOfJointHolder2'],
      {
        is: isHolder2FieldsRequired,
        then: Yup.string().required(
          'Email family description of joint holder is required',
        ),
      },
    ),
    jointHolder2MobileFamilyDescription: Yup.string().when(
      ['modeOfHolding', 'panOfJointHolder2'],
      {
        is: isHolder2FieldsRequired,
        then: Yup.string().required(
          'Mobile family description of joint holder is required',
        ),
      },
    ),
    dobOfJointHolder2: Yup.date()
      .typeError('Invalid Date')
      .when(['modeOfHolding', 'panOfJointHolder2'], {
        is: isHolder2FieldsRequired,
        then: Yup.date()
          .typeError('Invalid Date')
          .min(new Date(1900, 0, 1), 'Please enter a valid date after 01/01/1900')
          .required('DOB of joint holder is required')
          .max(
            new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
            'Holder should be at least 18 years old',
          ),
        otherwise: Yup.date().typeError('Invalid Date').nullable(),
      }),
    genderOfJointHolder2: Yup.string().when(['modeOfHolding', 'panOfJointHolder2'], {
      is: isHolder2FieldsRequired,
      then: Yup.string().required('Gender of joint holder is required'),
    }),
    h2Address1: Yup.string().when(
      ['modeOfHolding', 'panOfJointHolder2', 'categoryOfJointHolder2'],
      {
        is: isHolder2NriAddressRequired,
        then: Yup.string().required('Address 1 is required'),
      },
    ),
    h2Address2: Yup.string().when(
      ['modeOfHolding', 'panOfJointHolder2', 'categoryOfJointHolder2'],
      {
        is: isHolder2NriAddressRequired,
        then: Yup.string().required('Address 2 is required'),
      },
    ),
    h2City: Yup.string().when(
      ['modeOfHolding', 'panOfJointHolder2', 'categoryOfJointHolder2'],
      {
        is: isHolder2NriAddressRequired,
        then: Yup.string().required('City is required'),
      },
    ),
    h2State: Yup.string().when(
      ['modeOfHolding', 'panOfJointHolder2', 'categoryOfJointHolder2'],
      {
        is: isHolder2NriAddressRequired,
        then: Yup.string().required('State is required'),
      },
    ),
    h2Country: Yup.string().when(
      ['modeOfHolding', 'panOfJointHolder2', 'categoryOfJointHolder2'],
      {
        is: isHolder2NriAddressRequired,
        then: Yup.string().required('Country is required'),
      },
    ),
    h2ZipCode: Yup.string().when(
      ['modeOfHolding', 'panOfJointHolder2', 'categoryOfJointHolder2'],
      {
        is: isHolder2NriAddressRequired,
        then: Yup.string()
          .required('ZipCode is required')
          .matches(/^[0-9]+$/, 'Please enter valid ZipCode'),
      },
    ),
    ...(invType === 'I' ? minorFlagSchema : {}),
  });

  const minorValidationSchema = {
    guardianPan: Yup.string()
      .required('Guardian PAN is required')
      .matches(/^[A-Z]{3}[P][A-Z]{1}[0-9]{4}[A-Z]{1}$/, 'Only individual PAN is allowed'),
    guardianName: Yup.string()
      .trim()
      .required('Guardian name is required')
      .matches(
        /^[a-zA-Z]+(\s[a-zA-Z]+)*$/,
        'Name cannot contain only spaces or special characters',
      )
      .min(4, 'Invalid Guardian name. please enter valid name'),
    guardianCategory: Yup.string().required('Guardian category is required'),
    guardianDob: Yup.date()
      .typeError('Invalid Date')
      .min(new Date(1900, 0, 1), 'Please enter a valid date after 01/01/1900')
      .required('Guardian DOB is required')
      .nullable(),
    guardianEmailFamilyDescription: Yup.string().required(
      'Guardian email family description is required',
    ),
    guardianMobileFamilyDescription: Yup.string().required(
      'Guardian mobile family description is required',
    ),
    guardianEmail: Yup.string()
      .required('Guardian email is required')
      .matches(
        /^[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        'Invalid Email',
      ),
    guardianMonNo: Yup.string()
      .required('guardian mobile number is required')
      .matches(/^[6-9]{1}[0-9]{9}$/, 'Invalid Mobile Number'),
    guardianRelationWithMinor: Yup.string().required(
      'Guardian ralationship with minor is required',
    ),
  };

  const getValidationSchema = (investorType: string) => {
    const individualSchema = individualValidationSchema(investorType);
    if (investorType === 'M') {
      return Yup.object().shape({ ...individualSchema, ...minorValidationSchema });
    }
    return Yup.object().shape(individualSchema);
  };

  const validationSchema = getValidationSchema(investorType);

  const handleCheckboxChange = (e: any, setFieldValue: any) => {
    setFieldValue('residentOfUsOrCanada', e.target.checked);
  };

  const handleMinorPanChanged = async (
    e: any,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
    values: InvestorDetailValues,
    setValues: (values: InvestorDetailValues) => void,
    setErrors: (errors: FormikErrors<InvestorDetailValues>) => void,
  ) => {
    const panValue = e.target.value.toUpperCase();
    setFieldValue('pan', panValue);
    if (panValue.length === 10 && panValue !== values.guardianPan) {
      const panFraudBody = {
        pan: panValue,
        isJointHolder: true,
      };
      try {
        dispatch(startLoading());
        const response = await verifyPanFraud(panFraudBody).unwrap();
        showToast((response as { message: string }).message, 'success');

        const cKycRequestBody = {
          pan: panValue,
          mobile: '',
        };
        const minorResponse = await getData(cKycRequestBody).unwrap();
        const { name, dob, email, mobile } = minorResponse.personalDetails || {};

        setValues({
          ...values,
          pan: panValue,
          name: name,
          email: email,
          mobile: mobile,
          dob: dob,
        });
      } catch (error: any) {
        showToast(error && error.data?.message, 'error');
      } finally {
        dispatch(stopLoading());
      }
    } else {
      setErrors({
        name: '',
        email: '',
        mobile: '',
        dob: '',
        emailFamilyDescription: '',
        mobileFamilyDescription: '',
        gender: '',
      });
      setValues({
        ...values,
        name: '',
        pan: panValue,
        email: '',
        mobile: '',
        dob: null,
        emailFamilyDescription: '',
        mobileFamilyDescription: '',
        gender: '',
      });
    }
  };

  const handlePrimaryHolderPanChanged = async (
    e: any,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
    values: InvestorDetailValues,
    setValues: (values: InvestorDetailValues) => void,
    setErrors: (errors: FormikErrors<InvestorDetailValues>) => void,
  ) => {
    const panValue = e.target.value.toUpperCase();
    setFieldValue('panOfJointHolder1', panValue);

    if (panValue.length === 10 && panValue !== values.pan) {
      const panFraudBody = {
        pan: e.target.value,
        isJointHolder: true,
      };
      try {
        dispatch(startLoading());
        const response = await verifyPanFraud(panFraudBody).unwrap();
        showToast((response as { message: string }).message, 'success');

        const cKycRequestBody = {
          pan: e.target.value,
          mobile: '',
        };
        const jointHolder1Response = await getData(cKycRequestBody).unwrap();
        const { name, dob, email, mobile } = jointHolder1Response.personalDetails || {};
        setValues({
          ...values,
          panOfJointHolder1: panValue,
          nameOfJointHolder1: name,
          emailIdOfJointHolder1: email,
          mobileNoOfJointHolder1: mobile,
          dobOfJointHolder1: dob,
        });
        setJointHolderPanErr('');
      } catch (error: any) {
        showToast(error && error.data?.message, 'error');
        setJointHolderPanErr(error && error.data?.message);
      } finally {
        dispatch(stopLoading());
      }
    } else {
      setErrors({
        nameOfJointHolder1: '',
        emailIdOfJointHolder1: '',
        mobileNoOfJointHolder1: '',
        dobOfJointHolder1: '',
        jointHolder1EmailFamilyDescription: '',
        jointHolder1MobileFamilyDescription: '',
        genderOfJointHolder1: '',
      });
      setValues({
        ...values,
        nameOfJointHolder1: '',
        panOfJointHolder1: panValue,
        emailIdOfJointHolder1: '',
        mobileNoOfJointHolder1: '',
        dobOfJointHolder1: null,
        jointHolder1EmailFamilyDescription: '',
        jointHolder1MobileFamilyDescription: '',
        genderOfJointHolder1: '',
      });
    }
  };

  const handleSecondHolderPanChanged = async (
    e: any,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
    values: InvestorDetailValues,
    setValues: (values: InvestorDetailValues) => void,
    setErrors: (errors: FormikErrors<InvestorDetailValues>) => void,
  ) => {
    const panValue = e.target.value.toUpperCase();
    setFieldValue('panOfJointHolder2', panValue);
    if (
      panValue.length === 10 &&
      panValue !== values.pan &&
      panValue !== values.panOfJointHolder1
    ) {
      const panFraudBody = {
        pan: panValue,
        isJointHolder: true,
      };
      try {
        dispatch(startLoading());
        const response = await verifyPanFraud(panFraudBody).unwrap();
        showToast((response as { message: string }).message, 'success');

        const cKycRequestBody = {
          pan: panValue,
          mobile: '',
        };
        const jointHolder2Response = await getData(cKycRequestBody).unwrap();
        const { name, dob, email, mobile } = jointHolder2Response.personalDetails || {};

        setValues({
          ...values,
          panOfJointHolder2: panValue,
          nameOfJointHolder2: name,
          emailIdOfJointHolder2: email,
          mobileNoOfJointHolder2: mobile,
          dobOfJointHolder2: dob,
        });
        setJointHolder2PanErr('');
      } catch (error: any) {
        showToast(error && error.data?.message, 'error');
        setJointHolder2PanErr(error && error.data?.message);
      } finally {
        dispatch(stopLoading());
      }
    } else {
      setErrors({
        nameOfJointHolder2: '',
        emailIdOfJointHolder2: '',
        mobileNoOfJointHolder2: '',
        dobOfJointHolder2: '',
        jointHolder2EmailFamilyDescription: '',
        jointHolder2MobileFamilyDescription: '',
        genderOfJointHolder2: '',
      });
      setValues({
        ...values,
        nameOfJointHolder2: '',
        panOfJointHolder2: panValue,
        emailIdOfJointHolder2: '',
        mobileNoOfJointHolder2: '',
        dobOfJointHolder2: null,
        jointHolder2EmailFamilyDescription: '',
        jointHolder2MobileFamilyDescription: '',
        genderOfJointHolder2: '',
      });
    }
  };

  const getStatus = (category: string) => (category === '20' ? 'NRI' : 'RI');
  const getInvStatus = (category: string) => (category ? getStatus(category) : '');

  const handleSubmit = async (values: InvestorDetailValues) => {
    try {
      dispatch(startLoading());
      const payload = {
        pan: values.pan,
        PanName: values.name,
        PanDOB: dateToDDMMYYYY(values.dob),
        folio: '',
        trType: 'New',
        fund: investmentDetails?.fund,
        scheme: '',
        plan: '',
        guardianPan: values.guardianPan,
        guardianPanDOB:
          values.guardianDob !== null ? dateToDDMMYYYY(values.guardianDob) : null,
        guardianPanName: values.guardianName,
        jointHolder1Pan: values.panOfJointHolder1,
        jointHolder1PanName: values.nameOfJointHolder1,
        jointHolder1PanDOB:
          values.dobOfJointHolder1 !== null
            ? dateToDDMMYYYY(values.dobOfJointHolder1)
            : null,
        jointHolder2Pan: values.panOfJointHolder2,
        jointHolder2PanName: values.nameOfJointHolder2,
        jointHolder2PanDOB:
          values.dobOfJointHolder2 !== null
            ? dateToDDMMYYYY(values.dobOfJointHolder2)
            : null,
        invStatus: getInvStatus(values.category),
        JH1_invStatus:
          values.panOfJointHolder1 !== ''
            ? getInvStatus(values.categoryOfJointHolder1)
            : '',
        JH2_invStatus:
          values.panOfJointHolder2 !== ''
            ? getInvStatus(values.categoryOfJointHolder2)
            : '',
        G_invStatus:
          values.guardianPan !== '' ? getInvStatus(values.guardianCategory) : '',
      };
      const panCheckResponse = await aadharPanSeeding(payload);
      const errorMessage = validateResponse(panCheckResponse);
      if (errorMessage) {
        showToast(errorMessage, 'error');
        return;
      }
      const {
        panOfJointHolder1,
        nameOfJointHolder1,
        emailIdOfJointHolder1,
        mobileNoOfJointHolder1,
        dobOfJointHolder1,
        genderOfJointHolder1,
        jointHolder1EmailFamilyDescription,
        jointHolder1MobileFamilyDescription,
        categoryOfJointHolder1,
        h1Address1,
        h1Address2,
        h1City,
        h1State,
        h1Country,
        h1ZipCode,
        panOfJointHolder2,
        categoryOfJointHolder2,
        nameOfJointHolder2,
        emailIdOfJointHolder2,
        mobileNoOfJointHolder2,
        dobOfJointHolder2,
        genderOfJointHolder2,
        jointHolder2EmailFamilyDescription,
        jointHolder2MobileFamilyDescription,
        h2Address1,
        h2Address2,
        h2City,
        h2Country,
        h2State,
        h2ZipCode,
        ...primaryHolderDetails
      } = values;
      const investorDetailsData = { ...primaryHolderDetails };
      if (values.modeOfHolding === 'anyone') {
        const holderDetails = [
          {
            pan: panOfJointHolder1,
            name: nameOfJointHolder1,
            email: emailIdOfJointHolder1,
            mobile: mobileNoOfJointHolder1,
            dob: dobOfJointHolder1,
            gender: genderOfJointHolder1,
            emailFamilyRelation: jointHolder1EmailFamilyDescription,
            mobileFamilyRelation: jointHolder1MobileFamilyDescription,
            category: categoryOfJointHolder1,
            nriAddress1: h1Address1,
            nriAddress2: h1Address2,
            nriCity: h1City,
            nriState: h1State,
            jointHolderType: 'I',
            status: 'I',
          },
        ];

        if (panOfJointHolder2 && panOfJointHolder2.length === 10) {
          holderDetails.push({
            pan: panOfJointHolder2,
            name: nameOfJointHolder2,
            email: emailIdOfJointHolder2,
            mobile: mobileNoOfJointHolder2,
            dob: dobOfJointHolder2,
            gender: genderOfJointHolder2,
            emailFamilyRelation: jointHolder2EmailFamilyDescription,
            mobileFamilyRelation: jointHolder2MobileFamilyDescription,
            category: categoryOfJointHolder2,
            nriAddress1: h2Address1,
            nriAddress2: h2Address2,
            nriCity: h2City,
            nriState: h2State,
            jointHolderType: 'I',
            status: 'I',
          });
        }
        contextDispatch(setHolderDetails(holderDetails));
      }
      contextDispatch(setInvestorDetails(investorDetailsData));
      contextDispatch(setStepNumber(1));
    } catch (error: any) {
      showToast(error && error.data?.message, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

  function validateResponse(response: any) {
    if (response.Transaction_allow === 'Y') return;
    let errorMessage = '';
    if (response.PASFlag === 'N') {
      errorMessage = response.PASRemarks;
    } else if (response.PANFlag === 'N') {
      errorMessage = response.PANRemarks;
    } else if (response.PANNameMatch === 'N') {
      errorMessage = 'Entered Primary name does not match with PAN record';
    } else if (response.JH1_panFlag === 'N') {
      errorMessage = response.JH1_panRemarks;
    } else if (response.JH2_panFlag === 'N') {
      errorMessage = response.JH2_panRemarks;
    } else if (response.G_panFlag === 'N') {
      errorMessage = response.G_panRemarks;
    } else if (response.JH1_PANNameMatch === 'N') {
      errorMessage = 'Entered Joint Holder 1 name does not match with PAN record';
    } else if (response.JH2_PANNameMatch === 'N') {
      errorMessage = 'Entered Joint Holder 2 name does not match with PAN record';
    } else if (response.G_PANNameMatch === 'N') {
      errorMessage = 'Entered Guardian name does not match with PAN record';
    } else if (response.PanDOBMatch === 'N') {
      errorMessage = 'Entered Primary DOB does not match with PAN record';
    } else if (response.JH1_PanDOBMatch === 'N') {
      errorMessage = 'Entered Joint Holder 1 DOB does not match with PAN record';
    } else if (response.JH2_PanDOBMatch === 'N') {
      errorMessage = 'Entered Joint Holder 2 DOB does not match with PAN record';
    } else if (response.G_PanDOBMatch === 'N') {
      errorMessage = 'Entered Guardian DOB does not match with PAN record';
    }
    return errorMessage;
  }

  const validateHolderPan = (values: InvestorDetailValues) => {
    const errors: Record<string, any> = {};

    if (values.guardianPan && values.guardianPan === values.pan) {
      errors.pan = 'Minor PAN and Guardian PAN Should not be same';
    }

    if (values.panOfJointHolder1 && values.panOfJointHolder1 === values.pan) {
      errors.panOfJointHolder1 = 'Primary PAN and Joint Holder PAN Should not be same';
    }

    if (values.panOfJointHolder2 && values.panOfJointHolder2 === values.pan) {
      errors.panOfJointHolder2 = 'Primary PAN and Joint Holder PAN Should not be same';
    }

    if (values.guardianName && values.guardianName === values.name) {
      errors.name = 'Minor Name and Guardian Name should not be the same';
    }

    if (values.name && values.name === values.guardianName) {
      errors.guardianName = 'Minor Name and Guardian Name should not be the same';
    }

    if (
      values.panOfJointHolder2 &&
      values.panOfJointHolder2 === values.panOfJointHolder1
    ) {
      errors.panOfJointHolder2 = 'Joint Holder PAN Should not be same';
    }
    return errors;
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validate={validateHolderPan}
      >
        {({
          values,
          setFieldValue,
          errors,
          touched,
          setFieldError,
          setValues,
          setErrors,
        }) => {
          const { gender, genderOfJointHolder1, genderOfJointHolder2 } = errors;
          return (
            <Form>
              <Box
                sx={{
                  padding: { xs: '0px 24px ', md: '0px 0px 0px 24px' },
                  position: 'relative',
                }}
              >
                {investorType !== 'M' ? (
                  <Box sx={{ mb: 3 }}>
                    <Typography
                      sx={{
                        fontSize: { xs: '14px', xl: '16px' },
                        mb: 2,
                        color: 'text.valueColor',
                        fontWeight: 500,
                      }}
                    >
                      Mode of Holding
                    </Typography>
                    <FormStyledRadioButton
                      options={[
                        { label: 'Single', value: 'single' },
                        {
                          label: 'Anyone or Serviour',
                          value: 'anyone',
                        },
                      ]}
                      name='modeOfHolding'
                      handleChange={() => {
                        contextDispatch(setHolderDetails(null));
                      }}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      borderRadius: '5px',
                      bgcolor: 'text.boxColorLight',
                      padding: '10px 20px',
                      my: 3,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: '14px', xl: '16px' },
                        mb: 2,
                        color: 'text.valueColor',
                        fontWeight: 500,
                      }}
                    >
                      Minor Details
                    </Typography>
                  </Box>
                )}
                <Box sx={{ mb: 3 }}>
                  <Typography
                    sx={{
                      fontSize: { xs: '14px', xl: '16px' },
                      mb: 2,
                      color: 'text.valueColor',
                      fontWeight: 500,
                    }}
                  >
                    Category
                  </Typography>
                  <FormStyledRadioButton
                    options={[
                      { label: 'Resident of India', value: '11' },
                      ...(investorType === 'I' ? [{ label: 'NRI', value: '20' }] : []),
                    ]}
                    name='category'
                    handleChange={() => {
                      setFieldValue('residentOfUsOrCanada', true);
                    }}
                  />
                </Box>

                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormTextInput
                      name='pan'
                      label={investorType === 'M' ? 'Minor PAN' : 'PAN of primary Holder'}
                      disabled={investorType === 'I'}
                      onChange={e => {
                        handleMinorPanChanged(
                          e,
                          setFieldValue,
                          values,
                          setValues,
                          setErrors,
                        );
                      }}
                      typeOfInput='alphanumeric'
                      required={investorType === 'I'}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormTextInput
                      name='name'
                      label={investorType === 'M' ? 'Minor Name' : 'Name'}
                      typeOfInput='singleSpace'
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormTextInput
                      name='email'
                      label='Email ID'
                      onChange={(e: any) => {
                        setFieldType('email');
                        if (e.target.value?.trim() === distributorEmail?.trim()) {
                          setOpenUniqueEmailDialogBox(true);
                          setFieldValue('email', '');
                        }
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormTextInput
                      name='countryCode'
                      placeholder='Enter country Code'
                      label='Enter country Code'
                      disabled={values?.category !== '20'}
                      onInput={(e: any) => {
                        e.target.value = e.target.value.replace(/[^0-9+]/g, '');
                        if (e.target.value.length > 4) {
                          e.target.value = e.target.value.slice(0, 4);
                        }
                        setFieldValue('countryCode', e.target.value);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormTextInput
                      name='mobile'
                      placeholder='Enter mobile number'
                      label='Enter mobile number'
                      typeOfInput='number'
                      onInput={(e: any) => {
                        if (e.target.value.length > 10) {
                          e.target.value = e.target.value.slice(0, 10);
                        }
                      }}
                      onChange={(e: any) => {
                        setFieldType('mobile');
                        if (e.target.value?.trim() === distMobile?.trim()) {
                          setOpenUniqueEmailDialogBox(true);
                          setFieldValue('mobile', '');
                        }
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormSelectInput
                      name='emailFamilyDescription'
                      label='Email Family Description'
                      options={familyDescriptionOptions}
                      required={investorType === 'I'}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormSelectInput
                      name='mobileFamilyDescription'
                      label='Mobile Family Description'
                      options={familyDescriptionOptions}
                      required={investorType === 'I'}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormDateInput
                      name='dob'
                      label='Date of Birth'
                      disableFuture
                      minDate={
                        investorType === 'I'
                          ? new Date(1900, 0, 1)
                          : new Date(currentYear - 18, currentMonth, currentDate)
                      }
                      maxDate={
                        investorType === 'I'
                          ? new Date(currentYear - 18, currentMonth, currentDate)
                          : new Date()
                      }
                      defaultCalendarMonth={
                        investorType === 'I'
                          ? new Date(currentYear - 18, currentMonth, currentDate)
                          : new Date()
                      }
                      disabled={investorType === 'M'}
                      required
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ mb: 2 }}
                  >
                    <DetailsValue sx={{ mb: 2 }}>Gender *</DetailsValue>
                    <Stack
                      direction='row'
                      spacing={2}
                      sx={{
                        '& .MuiButtonBase-root.MuiChip-root': {
                          'padding': '10px 5px',
                          'borderRadius': '44px',
                          '& .MuiChip-label': {
                            fontSize: '14px',
                            fontWeight: 500,
                          },
                        },
                      }}
                    >
                      {Object.keys(gendersList)?.map(gender => (
                        <Chip
                          key={gender}
                          avatar={
                            <Avatar
                              alt={gender.toLowerCase()}
                              src={(gendersList as Record<string, string>)[gender]}
                            />
                          }
                          label={gender}
                          onClick={async () => setFieldValue('gender', gender)}
                          variant='outlined'
                          sx={{
                            'border': '1px solid',
                            'borderColor':
                              values.gender === gender
                                ? 'text.navyBlue'
                                : 'text.borderColorLight',
                            'backgroundColor':
                              values.gender === gender
                                ? 'text.boxColorLight'
                                : 'common.white',
                            '& .MuiChip-label': {
                              color:
                                values.gender === gender ? 'primar.main' : 'text.primary',
                            },
                          }}
                        />
                      ))}
                    </Stack>
                    {typeof gender === 'string' && touched.gender && (
                      <ErrorText>{gender}</ErrorText>
                    )}
                  </Grid>
                  {values.category === '20' && (
                    <>
                      <Grid
                        item
                        xs={12}
                      >
                        <DetailsValue sx={{ fontWeight: 600 }}>
                          Overseas address
                        </DetailsValue>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                      >
                        <FormTextInput
                          name='address1'
                          label='Address 1'
                          typeOfInput='alphanumeric'
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                      >
                        <FormTextInput
                          name='address2'
                          label='Address 2'
                          typeOfInput='alphanumeric'
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                      >
                        <FormTextInput
                          name='city'
                          label='City'
                          typeOfInput='singleSpace'
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                      >
                        <FormTextInput
                          name='state'
                          label='State'
                          typeOfInput='singleSpace'
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                      >
                        <FormSelectInput
                          name='country'
                          label='Country'
                          options={countryList.filter(
                            (item: any) => item.label !== 'India',
                          )}
                          required
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                      >
                        <FormTextInput
                          name='zipCode'
                          label='ZipCode'
                          typeOfInput='number'
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <FormControlLabel
                          control={
                            <CustomizedCheckbox
                              checked={values.residentOfUsOrCanada}
                              onChange={e => handleCheckboxChange(e, setFieldValue)}
                            />
                          }
                          label='I am not a resident of US/Canada'
                        />
                      </Grid>
                    </>
                  )}
                  {investorType === 'M' && (
                    <>
                      <Grid
                        item
                        xs={12}
                      >
                        <Box
                          sx={{
                            borderRadius: '5px',
                            bgcolor: 'text.boxColorLight',
                            padding: '10px 20px',
                            my: 3,
                          }}
                        >
                          <DetailsValue sx={{ fontSize: '16px' }}>
                            Guardian Details
                          </DetailsValue>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                      >
                        <FormTextInput
                          name='guardianName'
                          label='Guardian Name'
                          typeOfInput='singleSpace'
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                      >
                        <FormTextInput
                          name='guardianPan'
                          label='Guardian PAN'
                          disabled={guardianPan?.length}
                          typeOfInput='alphanumeric'
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                      >
                        <FormDateInput
                          name='guardianDob'
                          label='Guardian Date of Birth'
                          disableFuture
                          minDate={
                            investorType === 'M'
                              ? new Date(1900, 0, 1)
                              : new Date(currentYear - 18, currentMonth, currentDate)
                          }
                          maxDate={
                            investorType === 'M'
                              ? new Date(currentYear - 18, currentMonth, currentDate)
                              : new Date()
                          }
                          defaultCalendarMonth={
                            investorType === 'I'
                              ? new Date(currentYear - 18, currentMonth, currentDate)
                              : new Date()
                          }
                          required
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                      >
                        <FormSelectInput
                          name='guardianCategory'
                          label='Guardian Category'
                          options={[
                            { label: 'Resident of India', value: '11' },
                            { label: 'NRI', value: '20' },
                          ]}
                          required
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                      >
                        <FormTextInput
                          name='guardianEmail'
                          label='Guardian Email ID'
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                      >
                        <FormSelectInput
                          name='guardianEmailFamilyDescription'
                          label='Guardian Email Family Description'
                          options={familyDescriptionOptions}
                          required
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                      >
                        <Stack flexDirection='row'>
                          <Box
                            sx={{
                              width: { xs: '20%', sm: '30%', md: '25%', lg: '15%' },
                            }}
                          >
                            <FormTextInput
                              sx={{
                                '& .MuiInputBase-input ': {
                                  borderRadius: '7px 0px 0px 7px',
                                  borderRight: '0',
                                },
                              }}
                              name='countryCode'
                              disabled
                              required={false}
                            />
                          </Box>
                          <Box
                            sx={{
                              'width': { xs: '80%', sm: '70%', md: '75%', lg: '85%' },
                              '& .MuiInputBase-input ': {
                                borderRadius: '0px 7px 7px 0px',
                              },
                            }}
                          >
                            <FormTextInput
                              name='guardianMonNo'
                              placeholder='Guardian mobile number'
                              label='Guardian mobile number'
                              typeOfInput='number'
                              onInput={(e: any) => {
                                if (e.target.value.length > 10) {
                                  e.target.value = e.target.value.slice(0, 10);
                                }
                              }}
                            />
                          </Box>
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                      >
                        <FormSelectInput
                          name='guardianMobileFamilyDescription'
                          label='Guardian Mobile Family Description'
                          options={familyDescriptionOptions}
                          required
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                      >
                        <FormSelectInput
                          name='guardianRelationWithMinor'
                          label='Guardian Relationship with Minor'
                          options={guardianRelationWithM}
                          required
                        />
                      </Grid>
                    </>
                  )}
                  {values.modeOfHolding === 'anyone' && (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                      >
                        <FormTextInput
                          name='panOfJointHolder1'
                          label='PAN of joint holder1'
                          onChange={e => {
                            setIsMaskEmailH1(true);
                            setIsMaskMobileH1(true);
                            handlePrimaryHolderPanChanged(
                              e,
                              setFieldValue,
                              values,
                              setValues,
                              setErrors,
                            );
                          }}
                          typeOfInput='alphanumeric'
                        />
                      </Grid>
                      {values?.panOfJointHolder1?.length === 10 &&
                        values?.panOfJointHolder1 !== values.pan &&
                        jointHolderPanErr === '' && (
                          <>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                            >
                              <FormTextInput
                                name='nameOfJointHolder1'
                                label='Name'
                                typeOfInput='singleSpace'
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                            >
                              <FormTextInput
                                name='emailIdOfJointHolder1'
                                label='Email ID'
                                value={
                                  isMaskEmailH1
                                    ? maskEmail(values.emailIdOfJointHolder1)
                                    : values.emailIdOfJointHolder1
                                }
                                onChange={e => {
                                  const newValue = e.target.value;
                                  if (isMaskEmailH1 && newValue !== '') {
                                    setIsMaskEmailH1(false);
                                    setFieldValue('emailIdOfJointHolder1', '');
                                  }
                                  if (!isMaskEmailH1) {
                                    setFieldValue('emailIdOfJointHolder1', newValue);
                                  }
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                            >
                              <FormTextInput
                                name='countryCodeH1'
                                placeholder='Enter country Code'
                                label='Enter country Code'
                                disabled={values?.categoryOfJointHolder1 !== '20'}
                                onInput={(e: any) => {
                                  e.target.value = e.target.value.replace(/[^0-9+]/g, '');
                                  if (e.target.value.length > 4) {
                                    e.target.value = e.target.value.slice(0, 4);
                                  }
                                  setFieldValue('countryCodeH1', e.target.value);
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                            >
                              <FormTextInput
                                name='mobileNoOfJointHolder1'
                                label='Enter mobile number'
                                value={
                                  isMaskMobileH1
                                    ? maskAccountNumber(values.mobileNoOfJointHolder1)
                                    : values.mobileNoOfJointHolder1
                                }
                                onChange={e => {
                                  const newValue = e.target.value;
                                  if (isMaskMobileH1 && newValue !== '') {
                                    setIsMaskMobileH1(false);
                                    setFieldValue('mobileNoOfJointHolder1', '');
                                  }
                                  if (!isMaskMobileH1) {
                                    setFieldValue('mobileNoOfJointHolder1', newValue);
                                  }
                                }}
                                typeOfInput='number'
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                            >
                              <FormSelectInput
                                name='jointHolder1EmailFamilyDescription'
                                label='Email Family Description'
                                options={familyDescriptionOptions}
                                required
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                            >
                              <FormSelectInput
                                name='jointHolder1MobileFamilyDescription'
                                label='Mobile Family Description'
                                options={familyDescriptionOptions}
                                required
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                            >
                              <FormDateInput
                                name='dobOfJointHolder1'
                                label='Date of Birth'
                                disableFuture
                                minDate={minDate}
                                maxDate={maxDate}
                                defaultCalendarMonth={
                                  new Date(currentYear - 18, currentMonth, currentDate)
                                }
                                required
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sx={{ mb: 2 }}
                            >
                              <Typography
                                sx={{
                                  fontSize: { xs: '14px', xl: '16px' },
                                  mb: 2,
                                  color: 'text.valueColor',
                                  fontWeight: 500,
                                }}
                              >
                                Category
                              </Typography>
                              <FormStyledRadioButton
                                options={[
                                  { label: 'Resident of India', value: '11' },
                                  { label: 'NRI', value: '20' },
                                ]}
                                name='categoryOfJointHolder1'
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sx={{ mb: 2 }}
                            >
                              <Typography
                                sx={{
                                  fontSize: { xs: '14px', xl: '16px' },
                                  mb: 2,
                                  color: 'text.valueColor',
                                  fontWeight: 500,
                                }}
                              >
                                Gender
                              </Typography>
                              <Stack
                                direction='row'
                                spacing={2}
                                sx={{
                                  '& .MuiButtonBase-root.MuiChip-root': {
                                    'padding': '10px 5px',
                                    'borderRadius': '44px',
                                    '& .MuiChip-label': {
                                      fontSize: '14px',
                                      fontWeight: 500,
                                    },
                                  },
                                }}
                              >
                                {Object.keys(gendersList)?.map(gender => (
                                  <Chip
                                    key={gender}
                                    avatar={
                                      <Avatar
                                        alt={gender.toLowerCase()}
                                        src={
                                          (gendersList as Record<string, string>)[gender]
                                        }
                                      />
                                    }
                                    label={gender}
                                    onClick={async () =>
                                      setFieldValue(
                                        'genderOfJointHolder1',
                                        getFirstLetter(gender),
                                      )
                                    }
                                    variant='outlined'
                                    sx={{
                                      'border': '1px solid',
                                      'borderColor':
                                        values.genderOfJointHolder1 ===
                                        getFirstLetter(gender)
                                          ? 'text.navyBlue'
                                          : 'text.borderColorLight',
                                      'backgroundColor':
                                        values.genderOfJointHolder1 ===
                                        getFirstLetter(gender)
                                          ? 'text.boxColorLight'
                                          : 'common.white',
                                      '& .MuiChip-label': {
                                        color:
                                          values.genderOfJointHolder1 ===
                                          getFirstLetter(gender)
                                            ? 'primary.main'
                                            : 'text.primary',
                                      },
                                    }}
                                  />
                                ))}
                              </Stack>
                              {typeof genderOfJointHolder1 === 'string' &&
                                touched.genderOfJointHolder1 && (
                                  <ErrorText>{genderOfJointHolder1}</ErrorText>
                                )}
                            </Grid>
                            {values.categoryOfJointHolder1 === '20' && (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                >
                                  <DetailsValue sx={{ fontWeight: 600 }}>
                                    Overseas address
                                  </DetailsValue>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                >
                                  <FormTextInput
                                    name='h1Address1'
                                    label='Address 1'
                                    typeOfInput='alphanumeric'
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                >
                                  <FormTextInput
                                    name='h1Address2'
                                    label='Address 2'
                                    typeOfInput='alphanumeric'
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                >
                                  <FormTextInput
                                    name='h1City'
                                    label='City'
                                    typeOfInput='singleSpace'
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                >
                                  <FormTextInput
                                    name='h1State'
                                    label='State'
                                    typeOfInput='singleSpace'
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                >
                                  <FormSelectInput
                                    name='h1Country'
                                    label='Country'
                                    options={countryList.filter(
                                      (item: any) => item.label !== 'India',
                                    )}
                                    required
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                >
                                  <FormTextInput
                                    name='h1ZipCode'
                                    label='ZipCode'
                                    typeOfInput='number'
                                  />
                                </Grid>
                              </>
                            )}
                            <Grid
                              item
                              xs={12}
                              sm={6}
                            >
                              <FormTextInput
                                name='panOfJointHolder2'
                                label='PAN of joint holder2'
                                onChange={e => {
                                  setIsMaskEmailH2(true);
                                  setIsMaskMobileH2(true);
                                  handleSecondHolderPanChanged(
                                    e,
                                    setFieldValue,
                                    values,
                                    setValues,
                                    setErrors,
                                  );
                                }}
                                required={false}
                                typeOfInput='alphanumeric'
                              />
                            </Grid>
                            {values?.panOfJointHolder2?.length === 10 &&
                              jointHolder2PanErr === '' &&
                              values?.panOfJointHolder2 !== values.pan &&
                              values?.panOfJointHolder2 !== values.panOfJointHolder1 && (
                                <>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                  >
                                    <FormTextInput
                                      name='nameOfJointHolder2'
                                      label='Name'
                                      typeOfInput='singleSpace'
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                  >
                                    <FormTextInput
                                      name='emailIdOfJointHolder2'
                                      label='Email ID'
                                      value={
                                        isMaskEmailH2
                                          ? maskEmail(values.emailIdOfJointHolder2)
                                          : values.emailIdOfJointHolder2
                                      }
                                      onChange={e => {
                                        const newValue = e.target.value;
                                        if (isMaskEmailH2 && newValue !== '') {
                                          setIsMaskEmailH2(false);
                                          setFieldValue('emailIdOfJointHolder2', '');
                                        }
                                        if (!isMaskEmailH2) {
                                          setFieldValue(
                                            'emailIdOfJointHolder2',
                                            newValue,
                                          );
                                        }
                                      }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                  >
                                    <FormTextInput
                                      name='countryCodeH2'
                                      placeholder='Enter country Code'
                                      label='Enter country Code'
                                      disabled={values?.categoryOfJointHolder2 !== '20'}
                                      onInput={(e: any) => {
                                        e.target.value = e.target.value.replace(
                                          /[^0-9+]/g,
                                          '',
                                        );
                                        if (e.target.value.length > 4) {
                                          e.target.value = e.target.value.slice(0, 4);
                                        }
                                        setFieldValue('countryCodeH2', e.target.value);
                                      }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                  >
                                    <FormTextInput
                                      name='mobileNoOfJointHolder2'
                                      label='Enter mobile number'
                                      value={
                                        isMaskMobileH2
                                          ? maskAccountNumber(
                                              values.mobileNoOfJointHolder2,
                                            )
                                          : values.mobileNoOfJointHolder2
                                      }
                                      onChange={e => {
                                        const newValue = e.target.value;
                                        if (isMaskMobileH2 && newValue !== '') {
                                          setIsMaskMobileH2(false);
                                          setFieldValue('mobileNoOfJointHolder2', '');
                                        }
                                        if (!isMaskMobileH2) {
                                          setFieldValue(
                                            'mobileNoOfJointHolder2',
                                            newValue,
                                          );
                                        }
                                      }}
                                      typeOfInput='number'
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                  >
                                    <FormSelectInput
                                      name='jointHolder2EmailFamilyDescription'
                                      label='Email Family Description'
                                      options={familyDescriptionOptions}
                                      required
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                  >
                                    <FormSelectInput
                                      name='jointHolder2MobileFamilyDescription'
                                      label='Mobile Family Description'
                                      options={familyDescriptionOptions}
                                      required
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                  >
                                    <FormDateInput
                                      name='dobOfJointHolder2'
                                      label='Date of Birth'
                                      disableFuture
                                      minDate={minDate}
                                      maxDate={maxDate}
                                      defaultCalendarMonth={
                                        new Date(
                                          currentYear - 18,
                                          currentMonth,
                                          currentDate,
                                        )
                                      }
                                      required
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={{ mb: 2 }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: { xs: '14px', xl: '16px' },
                                        mb: 2,
                                        color: 'text.valueColor',
                                        fontWeight: 500,
                                      }}
                                    >
                                      Category
                                    </Typography>
                                    <FormStyledRadioButton
                                      options={[
                                        { label: 'Resident of India', value: '11' },
                                        { label: 'NRI', value: '20' },
                                      ]}
                                      name='categoryOfJointHolder2'
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sx={{ mb: 2 }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: { xs: '14px', xl: '16px' },
                                        mb: 2,
                                        color: 'text.valueColor',
                                        fontWeight: 500,
                                      }}
                                    >
                                      Gender
                                    </Typography>
                                    <Stack
                                      direction='row'
                                      spacing={2}
                                      sx={{
                                        '& .MuiButtonBase-root.MuiChip-root': {
                                          'padding': '10px 5px',
                                          'borderRadius': '44px',
                                          '& .MuiChip-label': {
                                            fontSize: '14px',
                                            fontWeight: 500,
                                          },
                                        },
                                      }}
                                    >
                                      {Object.keys(gendersList)?.map(gender => (
                                        <Chip
                                          key={gender}
                                          avatar={
                                            <Avatar
                                              alt={gender.toLowerCase()}
                                              src={
                                                (gendersList as Record<string, string>)[
                                                  gender
                                                ]
                                              }
                                            />
                                          }
                                          label={gender}
                                          onClick={async () =>
                                            setFieldValue(
                                              'genderOfJointHolder2',
                                              getFirstLetter(gender),
                                            )
                                          }
                                          variant='outlined'
                                          sx={{
                                            'border': '1px solid',
                                            'borderColor':
                                              values.genderOfJointHolder2 ===
                                              getFirstLetter(gender)
                                                ? 'text.navyBlue'
                                                : 'text.borderColorLight',
                                            'backgroundColor':
                                              values.genderOfJointHolder2 ===
                                              getFirstLetter(gender)
                                                ? 'text.boxColorLight'
                                                : 'common.white',
                                            '& .MuiChip-label': {
                                              color:
                                                values.genderOfJointHolder2 ===
                                                getFirstLetter(gender)
                                                  ? 'primary.main'
                                                  : 'text.primary',
                                            },
                                          }}
                                        />
                                      ))}
                                    </Stack>
                                    {typeof genderOfJointHolder2 === 'string' &&
                                      touched.genderOfJointHolder2 && (
                                        <ErrorText>{genderOfJointHolder2}</ErrorText>
                                      )}
                                  </Grid>
                                  {values.categoryOfJointHolder2 === '20' && (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                      >
                                        <DetailsValue sx={{ fontWeight: 600 }}>
                                          Overseas address
                                        </DetailsValue>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                      >
                                        <FormTextInput
                                          name='h2Address1'
                                          label='Address 1'
                                          typeOfInput='alphanumeric'
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                      >
                                        <FormTextInput
                                          name='h2Address2'
                                          label='Address 2'
                                          typeOfInput='alphanumeric'
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                      >
                                        <FormTextInput
                                          name='h2City'
                                          label='City'
                                          typeOfInput='singleSpace'
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                      >
                                        <FormTextInput
                                          name='h2State'
                                          label='State'
                                          typeOfInput='singleSpace'
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                      >
                                        <FormSelectInput
                                          name='h2Country'
                                          label='Country'
                                          options={countryList.filter(
                                            (item: any) => item.label !== 'India',
                                          )}
                                          required
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                      >
                                        <FormTextInput
                                          name='h2ZipCode'
                                          label='ZipCode'
                                          typeOfInput='number'
                                        />
                                      </Grid>
                                    </>
                                  )}
                                </>
                              )}
                          </>
                        )}
                    </>
                  )}
                </Grid>
                <SubmitButton
                  sx={{
                    '&.MuiButtonBase-root': {
                      borderRadius: '5px',
                      minWidth: { xs: '100%', sm: '200px' },
                      flex: { xs: '1', sm: 'unset' },
                      mt: 5,
                    },
                  }}
                  label='Proceed'
                  disabled={!values.residentOfUsOrCanada}
                >
                  Proceed
                </SubmitButton>
              </Box>
            </Form>
          );
        }}
      </Formik>
      <CustomDialog
        isVisible={openUniqueEmailDialogBox}
        secondButtonHandler={() => {
          setOpenUniqueEmailDialogBox(false);
        }}
        firstButtonHandler={() => {}}
        firstButtonTitle=''
        secondButtonTitle='OK'
        handleClose={() => {}}
        showFirstButton={false}
        showSecondButton={true}
      >
        <Box
          sx={{
            'textAlign': 'center',
            'pt': 2,
            '& .MuiTypography-root': {
              'fontSize': '16px',
              'color': 'text.valueColor',
              'fontWeight': '400',
              'opacity': '0.85',
              'my': 2,
              '& >*': {
                fontWeight: '600',
              },
            },
          }}
        >
          <Typography variant='body1'>
            {`${
              fieldType === 'email' ? 'Email' : 'Mobile No'
            } cannot be same as distributor ${
              fieldType === 'email' ? 'Email' : 'Mobile No'
            }`}
          </Typography>
        </Box>
      </CustomDialog>
    </>
  );
};
