import {
  Box,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Theme,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as AlertIcon } from '../../../../../assets/alert-icon.svg';
import { ReactComponent as CloseIcon } from '../../../../../assets/close-icon.svg';
import { ReactComponent as BankDetailsIcon } from '../../../../../assets/NPS-CKYC/bank-details.svg';
import { ReactComponent as BankActiveIcon } from '../../../../../assets/NPS-CKYC/bank-details-active.svg';
import { ReactComponent as CompletedIcon } from '../../../../../assets/NPS-CKYC/completed-icon.svg';
import { ReactComponent as ConfirmationActiveIcon } from '../../../../../assets/NPS-CKYC/confirmation-active.svg';
import { ReactComponent as ConfirmationIcon } from '../../../../../assets/NPS-CKYC/confirmation-icon.svg';
import { ReactComponent as FatcaIcon } from '../../../../../assets/NPS-CKYC/fatca-details.svg';
import { ReactComponent as FatcaActiveIcon } from '../../../../../assets/NPS-CKYC/fatca-details-active.svg';
import { ReactComponent as InvestorActiveIcon } from '../../../../../assets/NPS-CKYC/investor-details-active.svg';
import { ReactComponent as NomineeDetailsIcon } from '../../../../../assets/NPS-CKYC/nominee-details.svg';
import { ReactComponent as NomineeActiveIcon } from '../../../../../assets/NPS-CKYC/nominee-details-active.svg';
import { ReactComponent as DropdownIcon } from '../../../../../assets/select-dropdown-icon.svg';
import { DetailsHeader } from '../../../../auth/login/styles/styledComponents';
import { useLazyGetCountriesListQuery } from '../../../../auth/signup/slices';
import CustomDialog from '../../../../common/AllDialogs/CustomDialog';
import { PageNavigator } from '../../../../common/BackToHomePage';
import { Breadcrumb } from '../../../../common/breadcrumbs';
import { onboardingStepsCount } from '../../../../common/constants';
import { useRootContext } from '../../../../data/root.context';
import { setCountries } from '../../../../features/utils/countriesAndStatesSlice';
import { setStepNumber } from '../context/NewMfsInvestorAction';
import { useNewMfsInvestorContext } from '../context/NewMfsInvestorContext';
import BankDetails from './BankDetails';
import { MfsConfirmation } from './Confirmation';
import FatcaDetails from './FatcaDetails';
import { InvestorDetails } from './InvestorDetails';
import NomineeForm from './NomineeDetails';

const steps = [
  {
    inactiveIcon: <InvestorActiveIcon />,
    activeIcon: <InvestorActiveIcon />,
    label: 'Step 1',
    description: 'Investor Details',
    component: <InvestorDetails />,
  },
  {
    inactiveIcon: <FatcaIcon />,
    activeIcon: <FatcaActiveIcon />,
    label: 'Step 2',
    description: 'FATCA',
    component: <FatcaDetails />,
  },
  {
    inactiveIcon: <NomineeDetailsIcon />,
    activeIcon: <NomineeActiveIcon />,
    label: 'Step 3',
    description: 'Nominee Details',
    component: <NomineeForm />,
  },
  {
    inactiveIcon: <BankDetailsIcon />,
    activeIcon: <BankActiveIcon />,
    label: 'Step 4',
    description: 'Investor Bank Details',
    component: <BankDetails />,
  },
  {
    inactiveIcon: <ConfirmationIcon />,
    activeIcon: <ConfirmationActiveIcon />,
    label: 'Step 5',
    description: 'Confirmation',
    component: <MfsConfirmation />,
  },
];

function ProgressMobileStepper() {
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const {
    dispatch: contextDispatch,
    state: { pan, currentStep },
  } = useNewMfsInvestorContext();

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          top: '90px',
          left: 0,
          width: '100%',
          zIndex: 2,
          mb: 4,
          display: { xs: 'block', md: 'none' },
        }}
      >
        <Box
          sx={{
            bgcolor: 'text.babyBlue',
            padding: '20px 10px 20px 20px',
            borderBottom: '1px solid',
            borderColor: 'text.borderColorLight',
          }}
        >
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Stack
              direction={'row'}
              columnGap={'15px'}
            >
              {steps[currentStep].activeIcon}
              <Stack>
                <DetailsHeader sx={{ fontSize: '14px', mb: 1 }}>
                  {steps[currentStep].label} of {steps.length}
                </DetailsHeader>
                <Typography
                  sx={{ fontSize: '16px', color: 'primary.main', fontWeight: 600 }}
                >
                  {steps[currentStep].description}
                </Typography>
              </Stack>
            </Stack>
            <IconButton onClick={handleDrawerOpen}>
              <DropdownIcon />
            </IconButton>
          </Stack>
        </Box>
        <LinearProgress
          sx={{
            'bgcolor': 'transparent',
            'height': '3px',
            'width': '100%',
            'position': 'absolute',
            'bottom': '1px',
            '& .MuiLinearProgress-bar': {
              borderRadius: '0px 23px 31px 0px',
            },
          }}
          variant={'determinate'}
          value={(currentStep + 1) * (100 / onboardingStepsCount.mfs)}
        />
      </Box>

      <Drawer
        anchor='bottom'
        sx={{
          'display': { xs: 'block', md: 'none' },
          '& .MuiPaper-root ': {
            borderRadius: '25px 25px 0px 0px',
            backgroundColor: 'common.white',
          },
        }}
        open={drawerOpen}
        onClose={handleDrawerClose}
      >
        <Box role='presentation'>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: { xs: '10px 25px', sm: '0px 25px' },
              borderBottom: '0.5px solid',
              borderColor: 'text.borderColorLight',
              marginBottom: '20px',
            }}
          >
            <Typography
              sx={{
                color: 'primary.main',
                fontWeight: { xs: 500, sm: 700 },
                fontSize: { xs: '16px', sm: '18px' },
              }}
            >
              New Investor
            </Typography>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ maxWidth: 400, padding: { xs: '20px 20px' } }}>
            <Stepper
              activeStep={currentStep}
              orientation='vertical'
              sx={{
                '& .MuiStepLabel-root ': {
                  padding: '0px',
                },
                '& .MuiStepConnector-root ': {
                  'marginLeft': '20px',
                  '& .MuiStepConnector-line': {
                    minHeight: '45px',
                  },
                },
              }}
            >
              {steps.map((step, index) => {
                const isCompleted = index < currentStep;
                return (
                  <Step key={step.label}>
                    <StepLabel
                      icon={
                        isCompleted ? (
                          <CompletedIcon />
                        ) : index === currentStep ? (
                          step.activeIcon
                        ) : (
                          step.inactiveIcon
                        )
                      }
                    >
                      <Typography
                        variant='subtitle2'
                        sx={{
                          color: 'text.labelColor',
                        }}
                      >
                        {step.label}
                      </Typography>
                      <Typography
                        variant='subtitle1'
                        sx={{
                          color: 'text.labelColor',
                          fontWeight: 700,
                        }}
                      >
                        {step.description}
                      </Typography>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}

const NewMfsInvestorSteps = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showToast } = useRootContext();
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [getCountriesList, countriesListResponse] = useLazyGetCountriesListQuery();

  const { countries } = useSelector((state: any) => state.countriesAndStates);
  const {
    dispatch: contextDispatch,
    state: {
      pan,
      currentStep,
      paymentModeConfirmation,
      investorDetails,
      fatcaDetails,
      isNomination,
      nomineeDetails,
      investorBankDetails,
    },
  } = useNewMfsInvestorContext();

  const onGoAnywayButtonClick = () => {
    setOpenDialogBox(false);
    navigate('..', { replace: true });
  };

  const onCancelClick = () => {
    setOpenDialogBox(false);
  };

  const handleBackClicked = (steps: any) => {
    switch (steps[currentStep].description) {
      case 'FATCA':
        return contextDispatch(setStepNumber(0));
      case 'Nominee Details':
        return contextDispatch(setStepNumber(1));
      case 'Investor Bank Details':
        return contextDispatch(setStepNumber(2));
      case 'Confirmation':
        return contextDispatch(setStepNumber(3));
      case 'Bank Mandate Confirmation':
        return contextDispatch(setStepNumber(3));
      default:
        return setOpenDialogBox(true);
    }
  };

  const handleStepperClicked = (index: number) => {
    switch (index) {
      case 1:
        if (!investorDetails) {
          return;
        }
        break;
      case 2:
        if (!fatcaDetails) {
          return;
        }
        break;
      case 3:
        if (!isNomination && !nomineeDetails) {
          return;
        }
        break;
      case 4:
        if (!investorBankDetails) {
          return;
        }
        break;
      default:
        break;
    }
    contextDispatch(setStepNumber(index));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!countries?.length) {
          const data = await getCountriesList({ all: true }).unwrap();
          dispatch(setCountries(data));
        }
      } catch (error: any) {
        showToast((error.data as { message: string }).message, 'error');
      }
    };
    fetchData();
  }, []);

  return (
    <Box>
      <ProgressMobileStepper />
      <Grid
        container
        spacing={2}
        sx={{ mt: { xs: '85px', sm: 0 } }}
      >
        <Grid
          item
          md={3}
          sx={{
            bgcolor: 'text.babyBlue',
            display: { xs: 'none', md: 'block' },
          }}
        >
          <Box sx={{ padding: '50px 20px' }}>
            <Stepper
              activeStep={currentStep}
              orientation='vertical'
              sx={{
                '& .MuiStepLabel-root ': {
                  padding: '0px',
                },
                '& .MuiStepConnector-root ': {
                  'marginLeft': '20px',
                  '& .MuiStepConnector-line': {
                    minHeight: '65px',
                  },
                },
              }}
            >
              {steps.map((step, index) => {
                const isCompleted = index < currentStep;
                return (
                  <Step key={step.label}>
                    <StepLabel
                      icon={
                        isCompleted ? (
                          <CompletedIcon />
                        ) : index === currentStep ? (
                          step.activeIcon
                        ) : (
                          step.inactiveIcon
                        )
                      }
                      onClick={() => handleStepperClicked(index)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <Typography
                        sx={{
                          color:
                            index === currentStep ? 'text.dodgerBlue' : 'text.labelColor',
                          fontSize: '12px',
                          fontWeight: 500,
                          cursor: 'pointer',
                        }}
                      >
                        {step.label}
                      </Typography>
                      <Typography
                        sx={{
                          color:
                            index === currentStep ? 'text.dodgerBlue' : 'text.labelColor',
                          fontSize: { sm: '16px', xl: '18px' },
                          fontWeight: 700,
                          cursor: 'pointer',
                        }}
                      >
                        {step.description}
                      </Typography>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
        </Grid>
        {/* Right side (70%) */}
        <Grid
          item
          md={currentStep > 3 ? 9 : 8}
          xs={12}
        >
          <Box>
            <Breadcrumb
              title='Home'
              subtitle='New Investor'
              otherHeading='New Folio Creation'
              onClick={() => setOpenDialogBox(true)}
            />
            <PageNavigator
              sx={(theme: Theme) => ({
                [theme.breakpoints.between(600, 900)]: {
                  mt: '75px',
                },
                [theme.breakpoints.down(600)]: {
                  my: 2,
                  ml: '20px',
                },
              })}
              backHandler={() => {
                if (currentStep === 0) {
                  setOpenDialogBox(true);
                } else {
                  contextDispatch(setStepNumber(currentStep - 1));
                }
              }}
              title={steps[currentStep].description}
            />

            {steps[currentStep].component}
          </Box>
        </Grid>
      </Grid>
      <CustomDialog
        isVisible={openDialogBox}
        firstButtonHandler={onCancelClick}
        secondButtonHandler={onGoAnywayButtonClick}
        firstButtonTitle='Cancel'
        secondButtonTitle='Go Back Anyway'
        handleClose={() => {
          setOpenDialogBox(false);
        }}
        showSecondButton={true}
      >
        <Stack
          display='flex'
          direction='row'
          alignItems='center'
          gap='10px'
        >
          <AlertIcon />
          <Typography
            variant='h5'
            sx={{ color: 'text.primary' }}
          >
            Data Not Saved, Will be Lost
          </Typography>
        </Stack>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 400,
            color: 'text.labelColor',
            my: 3,
          }}
        >
          Going Back will result in the loss of your entered data. Are you sure you want
          to proceed?
        </Typography>
      </CustomDialog>
    </Box>
  );
};

export default NewMfsInvestorSteps;
