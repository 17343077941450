import { AmcWiseBreakup, COLUMNS_AMC_WISE } from './types';

export const createHeadCells = (
  dataArr: AmcWiseBreakup[],
  columnsToShow: typeof COLUMNS_AMC_WISE,
) => {
  if (!dataArr.length) return [];

  const order = Object.keys(columnsToShow);

  const newArr = Object.keys(dataArr[0])
    .filter(item => columnsToShow[item as keyof typeof COLUMNS_AMC_WISE])
    .map(item => {
      let gridProps = {};

      if (item === 'fundName') {
        gridProps = { sm: 6 };
      } else if (item === 'percentage' || item === 'aum') {
        gridProps = { sm: item === 'percentage' ? 3.5 : 2.5 };
      }
      return {
        id: item,
        numeric: true,
        disablePadding: false,
        label: columnsToShow[item as keyof typeof COLUMNS_AMC_WISE],
        gridProps,
      };
    })
    .sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id));

  return newArr;
};
