import { Box, Grid, Theme, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import VerifiedIcon from '../../../../../assets/verified-cart-icon.png';
import useUserPermission from '../../../../../hooks/useUserPermission';
import { Investorcard } from '../../../../auth/login/styles/styledComponents';
import CustomDialog from '../../../../common/AllDialogs/CustomDialog';
import { useRootContext } from '../../../../data/root.context';
import { FormStyledRadioButton } from '../../../../forms/FormStyledRadioButton';
import SubmitButton from '../../../../forms/FormSubmitButton';
import FormTextInput, { FormPasswordInput } from '../../../../forms/FormTextInput';
import Layout from '../../../Layout';
import { dateToDDMMYYYY } from '../../../mfs/newInvestorOnboarding/utils';
import { useNetWorthReportMutation } from '../../slice';

export interface NetworthReportsType {
  networthReport: string;
  pan: string;
  emailId: string;
  password: string;
  confirmPassword: string;
}

export default function NetworthReport() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { showDashboard } = useUserPermission();

  const { showToast } = useRootContext();
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [responseMsg, setResponseMsg] = useState<string>('');

  const [getNetworthStatement] = useNetWorthReportMutation();

  const initialValues: NetworthReportsType = {
    networthReport: 'Email',
    pan: '',
    emailId: '',
    password: '',
    confirmPassword: '',
  };

  const validationSchema = Yup.object().shape({
    networthReport: Yup.string().required('Networth Report is required'),
    pan: Yup.string().when('networthReport', {
      is: 'PAN',
      then: Yup.string()
        .required('PAN is required')
        .matches(/^[A-Z]{3}[P][A-Z]{1}[0-9]{4}[A-Z]{1}$/, 'Invalid PAN'),
    }),
    emailId: Yup.string().when('networthReport', {
      is: 'Email',
      then: Yup.string()
        .required('Email ID is required')
        .matches(
          /^(\w[-._+]{0,1})*\w+@((\w)+[-]{0,1})*(\w)+(\.\w+)+\w$/,
          'email must be a valid email',
        ),
    }),
    password: Yup.string().when('networthReport', {
      is: 'Email',
      then: Yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters long')
        .matches(
          /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[A-Za-z0-9!@#$%^&*()_+]+$/,
          'Password should contain at least 1 Upper Case alphabet(A-Z).1 Special Character and 1 Numeric Value (0-9)',
        ),
    }),
    confirmPassword: Yup.string().when('networthReport', {
      is: 'Email',
      then: Yup.string()
        .required('Confirm Password is required')
        .test(
          'passwordMatch',
          'The Password and confirm Password do not match',
          function (value) {
            return value === this.parent.password;
          },
        ),
    }),
  });

  const handleSubmit = async (values: NetworthReportsType) => {
    const params = {
      fund: '0',
      email: values.networthReport === 'Email' ? values.emailId : '',
      password: values.networthReport === 'Email' ? values.password : values.pan,
      pageType: values.networthReport,
      fromDate: '01/01/1970',
      toDate: dateToDDMMYYYY(new Date()),
      pan: values.networthReport === 'Email' ? '' : values.pan,
      dateNow: dateToDDMMYYYY(new Date()),
    };
    try {
      const response: any = await getNetworthStatement(params).unwrap();
      if (response?.message) {
        setResponseMsg(response?.message);
        setOpenDialogBox(true);
      }
    } catch (error: any) {
      const message =
        (error as any).data?.message || (error as any).message || 'Unknown error';
      showToast(message, 'error');
    }
  };

  return (
    <>
      <Layout
        title='Networth Reports'
        isNctTransaction
      >
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            sm={12}
          >
            <Investorcard
              sx={(theme: Theme) => ({
                padding: '35px 35px',
                [theme.breakpoints.down('sm')]: {
                  boxShadow: 'unset',
                  border: 'unset',
                  padding: '0px',
                },
              })}
            >
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, resetForm, setFieldValue }) => {
                  return (
                    <Form>
                      <Grid
                        container
                        spacing={2}
                      >
                        <Grid
                          item
                          xs={12}
                        >
                          <Typography
                            variant='subtitle2'
                            sx={{ mb: 2 }}
                          >
                            Networth Report
                          </Typography>
                          <FormStyledRadioButton
                            options={[
                              { label: 'Email', value: 'Email' },
                              { label: 'PAN', value: 'PAN' },
                            ]}
                            name='networthReport'
                            handleChange={e => {
                              resetForm();
                              setFieldValue('networthReport', e.target.value);
                            }}
                          />
                        </Grid>
                        {values.networthReport === 'PAN' ? (
                          <Grid
                            item
                            sm={6}
                            xs={12}
                          >
                            <FormTextInput
                              name='pan'
                              label='PAN'
                            />
                          </Grid>
                        ) : (
                          <>
                            <Grid
                              item
                              sm={6}
                              xs={12}
                            >
                              <FormTextInput
                                name='emailId'
                                label='Email ID'
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                            >
                              <FormPasswordInput
                                name='password'
                                label='Self Extraction Password'
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={6}
                            >
                              <FormPasswordInput
                                name='confirmPassword'
                                label='Confirm Self Extraction Password'
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <Box sx={{ display: 'flex', mt: 5 }}>
                        <SubmitButton
                          sx={(theme: Theme) => ({
                            '&.MuiButtonBase-root': {
                              borderRadius: '5px',
                              minWidth: '200px',

                              [theme.breakpoints.down('sm')]: {
                                minWidth: '100%',
                              },
                            },
                          })}
                          label='Submit'
                        />
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            </Investorcard>
          </Grid>
        </Grid>
        <CustomDialog
          isVisible={openDialogBox}
          showFirstButton={false}
          firstButtonHandler={() => {}}
          firstButtonTitle=''
          showSecondButton
          secondButtonTitle='Continue'
          secondButtonHandler={() => navigate('/transaction-dashboard/mis/mfs-reports')}
          handleClose={() => {}}
        >
          <Box
            sx={{
              'textAlign': 'center',
              'pt': 2,
              '& img': {
                maxWidth: { xs: '17%', md: '70%' },
              },
            }}
          >
            <img
              src={VerifiedIcon}
              alt='verified-icon'
            />
            <Typography
              variant='h3'
              sx={{ color: 'primary.main', my: 2 }}
            >
              Successfully submitted your request
            </Typography>
            <Typography variant='subtitle1'>{responseMsg}</Typography>
          </Box>
        </CustomDialog>
      </Layout>
    </>
  );
}
