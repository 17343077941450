import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer as MainTable,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { ShimmerTable } from 'react-shimmer-effects';

import { ReactComponent as NoDataIcon } from '../../../assets/no-data-table.svg';
import { AUTHORIZATION_PENDING } from '../constants';
import { Order, TransactionHistory } from '.';
import TableAction from './TableAction';

function TableHeader() {
  return (
    <TableHead
      sx={(theme: Theme) => ({
        '& .MuiTableCell-root.MuiTableCell-head': {
          fontSize: '15px',
          color: 'text.labelColor',
          fontWeight: '500',
          textAlign: 'left',
          [theme.breakpoints.between(768, 1024)]: {
            fontSize: '13px',
          },
        },
      })}
    >
      <TableRow
        sx={(theme: Theme) => ({
          backgroundColor: theme.palette.action.hover,
        })}
      >
        <TableCell>INVESTOR</TableCell>
        <TableCell>CONTACT</TableCell>
        <TableCell>TYPE</TableCell>
        <TableCell>DATE</TableCell>
        <TableCell>SCHEME</TableCell>
        <TableCell>Ref No</TableCell>
        <TableCell>AMOUNT/UNITS</TableCell>
        <TableCell>ACTION</TableCell>
      </TableRow>
    </TableHead>
  );
}

interface TableContainerProps {
  active: string;
  snapshotDetails: TransactionHistory[];
  setOpenDialogBox: (open: boolean) => void;
  loading?: boolean;
}

export default function TableContainer({
  active,
  snapshotDetails,
  setOpenDialogBox,
  loading,
}: TableContainerProps) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  return (
    <>
      {loading ? (
        <ShimmerTable
          row={4}
          col={8}
        />
      ) : (
        <MainTable
          component={Paper}
          sx={{ boxShadow: 'unset', borderRadius: 'unset', bgcolor: 'unset' }}
        >
          <Table
            sx={{
              '& .MuiTableCell-root ': {
                padding: '16px 25px',
              },
            }}
          >
            <TableHeader />
            <TableBody
              sx={{
                '&.MuiTableBody-root': {
                  '& .MuiTableRow-root': {
                    'backgroundColor': 'common.white',
                    'cursor': 'pointer',
                    '& .MuiTableCell-root ': {
                      color: 'common.black',
                      fontSize: '14px',
                      fontWeight: '400',
                      textAlign: 'left',
                    },
                  },
                },
              }}
            >
              {!loading && snapshotDetails.length ? (
                snapshotDetails
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((item: any, index: any) => {
                    const isCart = Boolean(item.dco_cart_id);
                    const isPending = active === AUTHORIZATION_PENDING;
                    return (
                      <React.Fragment key={index}>
                        {item.orders?.map((order: Order, orderIndex: any) => (
                          <TableRow
                            key={orderIndex}
                            sx={(theme: Theme) => ({
                              'borderBottom': `1px solid ${theme.palette.text.borderColorDark}`,
                              'backgroundColor': theme.palette.common.white,
                              '&:last-child td, &:last-child th': {
                                border: 0,
                              },
                            })}
                          >
                            {orderIndex === 0 ? (
                              <>
                                <TableCell rowSpan={item.orders?.length}>
                                  {order.dco_inv_name || '-'}
                                </TableCell>

                                <TableCell rowSpan={item.orders?.length}>
                                  {order.dco_inv_mobile || 'NA'}
                                </TableCell>

                                <TableCell rowSpan={item.orders?.length}>
                                  {order.investment_type}
                                </TableCell>
                                <TableCell rowSpan={item.orders?.length}>
                                  {item.dco_updated_at &&
                                    format(new Date(item.dco_updated_at), 'dd MMM yyyy')}
                                </TableCell>
                              </>
                            ) : null}

                            <TableCell key={order.dco_ihno}>{order.scheme}</TableCell>
                            <TableCell>{order.dco_ihno || 'NA'}</TableCell>

                            <TableCell>
                              {parseInt(order.units as string) ? (
                                `U - ${parseInt(order.units as string)}`
                              ) : order.amount && order.sipamount ? (
                                <>
                                  {`Lumpsum Amount ${new Intl.NumberFormat('en-IN', {
                                    style: 'currency',
                                    currency: 'INR',
                                  }).format(parseFloat(order.amount))},`}
                                  <br />
                                  {`SIP Amount ${new Intl.NumberFormat('en-IN', {
                                    style: 'currency',
                                    currency: 'INR',
                                  }).format(parseFloat(order.sipamount))}`}
                                </>
                              ) : order.sipamount ? (
                                new Intl.NumberFormat('en-IN', {
                                  style: 'currency',
                                  currency: 'INR',
                                }).format(parseFloat(order.sipamount))
                              ) : order.amount ? (
                                new Intl.NumberFormat('en-IN', {
                                  style: 'currency',
                                  currency: 'INR',
                                }).format(parseFloat(order.amount))
                              ) : (
                                '-'
                              )}
                            </TableCell>

                            <TableAction
                              isPending={isPending}
                              isDotNet={item.isDotNet}
                              order={order}
                              isCart={isCart}
                              orderIndex={orderIndex}
                              length={item.orders?.length}
                              item={item}
                              setOpenDialogBox={setOpenDialogBox}
                            />
                          </TableRow>
                        ))}
                      </React.Fragment>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        maxWidth: '400px',
                        margin: '5% auto',
                      }}
                    >
                      <NoDataIcon />
                      <Typography
                        sx={{
                          fontSize: { xs: '14px', sm: '16px', xl: '18px' },
                          fontWeight: 500,
                          color: 'text.labelColor',
                          my: 4,
                        }}
                      >
                        You currently have no Investments for this transaction
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </MainTable>
      )}
      {!loading && snapshotDetails.length !== 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 15, 20]}
          component='div'
          count={snapshotDetails?.length || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
}
