import apiSlice from '../../../service/api';
const authSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    signUp: builder.mutation({
      query: payload => ({
        url: '/auth/signup/individual/send-otp',
        method: 'POST',
        body: payload,
      }),
    }),
    nonKydSignUp: builder.mutation({
      query: payload => ({
        url: '/auth/signup/individual/send-non-kyd-otp',
        method: 'POST',
        body: payload,
      }),
    }),
    nonIndnonKydSignUp: builder.mutation({
      query: payload => ({
        url: '/auth/signup/company/send-nonkyd-company-otp',
        method: 'POST',
        body: payload,
      }),
    }),
    verifyMobileOtp: builder.mutation({
      query: payload => ({
        url: '/auth/signup/individual/verify-mobile',
        method: 'POST',
        body: payload,
      }),
    }),
    verifyEmailOtp: builder.mutation({
      query: payload => ({
        url: '/auth/signup/individual/verify-email',
        method: 'POST',
        body: payload,
      }),
    }),
    signUpCompany: builder.mutation({
      query: payload => ({
        url: '/auth/signup/company/send-otp',
        method: 'POST',
        body: payload,
      }),
    }),
    verifyMobileCompanyOtp: builder.mutation({
      query: payload => ({
        url: '/auth/signup/company/verify-mobile',
        method: 'POST',
        body: payload,
      }),
    }),
    verifyEmailCompanyOtp: builder.mutation({
      query: payload => ({
        url: '/auth/signup/company/verify-email',
        method: 'POST',
        body: payload,
      }),
    }),
    changeIndividualEmail: builder.mutation({
      query: payload => ({
        url: '/auth/signup/individual/change-email',
        method: 'POST',
        body: payload,
      }),
    }),
    changeIndividualMobile: builder.mutation({
      query: payload => ({
        url: '/auth/signup/individual/change-mobile',
        method: 'POST',
        body: payload,
      }),
    }),
    changeCompanyEmail: builder.mutation({
      query: payload => ({
        url: '/auth/signup/company/change-email',
        method: 'POST',
        body: payload,
      }),
    }),
    changeCompanyMobile: builder.mutation({
      query: payload => ({
        url: '/auth/signup/company/change-mobile',
        method: 'POST',
        body: payload,
      }),
    }),
    checkDitUsername: builder.query({
      query: () => ({
        url: '/auth/signup/individual/check-dit-username',
        method: 'GET',
      }),
    }),
    ditSignUp: builder.mutation({
      query: payload => ({
        url: '/auth/signup/individual/dit-login',
        method: 'POST',
        body: payload,
      }),
    }),
    setUsername: builder.mutation({
      query: payload => ({
        url: '/auth/signup/individual/set-username',
        method: 'POST',
        body: payload,
      }),
    }),
    verifyUsername: builder.mutation({
      query: payload => ({
        url: '/auth/signup/individual/verify-username',
        method: 'POST',
        body: payload,
      }),
    }),

    setCompanyUsername: builder.mutation({
      query: payload => ({
        url: '/auth/signup/company/set-username',
        method: 'POST',
        body: payload,
      }),
    }),
    verifyCompanyUsername: builder.mutation({
      query: payload => ({
        url: '/auth/signup/company/verify-username',
        method: 'POST',
        body: payload,
      }),
    }),
    resendMobileOtp: builder.query({
      query: () => ({
        url: '/auth/signup/individual/resend-mobile-otp',
        method: 'GET',
      }),
    }),
    resendEmailOtp: builder.query({
      query: () => ({
        url: '/auth/signup/individual/resend-email-otp',
        method: 'GET',
      }),
    }),
    resendCompanyMobileOtp: builder.query({
      query: () => ({
        url: '/auth/signup/company/resend-mobile-otp',
        method: 'GET',
      }),
    }),
    resendCompanyEmailOtp: builder.query({
      query: () => ({
        url: '/auth/signup/company/resend-email-otp',
        method: 'GET',
      }),
    }),
    loginCompanyOtp: builder.mutation({
      query: payload => ({
        url: '/auth/login/company/login-otp',
        method: 'POST',
        body: payload,
      }),
    }),
    // TODO: Need to change its file path.
    getAssets: builder.query({
      query: () => ({
        url: '/user/assets/asset-classes',
        method: 'GET',
      }),
    }),
    verifyAssetClasses: builder.mutation({
      query: payload => ({
        url: 'user/assets/asset-classes',
        method: 'POST',
        body: payload,
      }),
    }),

    getAuthSign: builder.query({
      query: () => ({
        url: '/user/auth-sign',
        method: 'GET',
      }),
    }),

    addAuthSigns: builder.mutation({
      query: (payload: any) => ({
        url: '/user/auth-sign',
        method: 'POST',
        body: payload,
      }),
    }),

    sendLoginOTPIndividual: builder.mutation({
      query: (payload: {
        username: string;
        password: string;
        verificationChannel: 'email' | 'mobile';
        isFirstLogin: boolean;
        loginAsAdmin?: boolean;
      }) => ({
        url: '/auth/login/individual/send-otp',
        method: 'POST',
        body: payload,
      }),
    }),
    verifyLoginOTPIndividual: builder.mutation({
      query: (payload: { otp: string }) => ({
        url: '/auth/login/individual/submit-otp',
        method: 'POST',
        body: payload,
      }),
    }),
    verifyLoginOTPCompany: builder.mutation({
      query: (payload: { otp: string }) => ({
        url: '/auth/login/company/submit-otp',
        method: 'POST',
        body: payload,
      }),
    }),

    resendLoginIndividualOtp: builder.query({
      query: () => ({
        url: '/auth/login/individual/resend-otp',
        method: 'GET',
      }),
    }),
    resendLoginCompanyOtp: builder.query({
      query: () => ({
        url: '/auth/login/company/resend-otp',
        method: 'GET',
      }),
    }),
    createAdminDetails: builder.mutation({
      query: (payload: { otp: string }) => ({
        url: '/auth/signup/company/admin-details',
        method: 'POST',
        body: payload,
      }),
    }),
    verifyUser: builder.mutation({
      query: (payload: any) => ({
        url: '/auth/login/verify-username',
        method: 'POST',
        body: payload,
      }),
    }),
    companySubmitOtp: builder.mutation({
      query: (payload: { otp: string }) => ({
        url: '/auth/login/company/submit-otp',
        method: 'POST',
        body: payload,
      }),
    }),
    setAdminPassword: builder.mutation({
      query: payload => ({
        url: '/user/profile/set-admin-password',
        method: 'POST',
        body: payload,
      }),
    }),
    setIndividualPassword: builder.mutation({
      query: payload => ({
        url: '/user/profile/set-password',
        method: 'POST',
        body: payload,
      }),
    }),

    sendOTPEmployee: builder.mutation({
      query: payload => ({
        url: '/auth/login/company/login-otp',
        method: 'POST',
        body: payload,
      }),
    }),
    createProfile: builder.mutation({
      query: (payload: any) => ({
        url: '/user/profile/create-profile',
        method: 'POST',
        body: payload,
      }),
    }),
    forgotPasswordOtp: builder.mutation({
      query: (payload: any) => ({
        url: '/auth/login/forgot-password',
        method: 'POST',
        body: payload,
      }),
    }),
    verifyForgotPasswordOtp: builder.mutation({
      query: payload => ({
        url: '/auth/login/verify-forgot-password-otp',
        method: 'POST',
        body: payload,
      }),
    }),
    resendForgotPasswordOtp: builder.query({
      query: payload => ({
        url: '/auth/login/verify-forgot-otp',
        method: 'GET',
        body: payload,
      }),
    }),
    verifySpocEmailOtp: builder.mutation({
      query: payload => ({
        url: '/auth/signup/company/verify-spoc-email-otp',
        method: 'POST',
        body: payload,
      }),
    }),
    resendSpocEmailOtp: builder.query({
      query: () => ({
        url: '/auth/signup/company/resend-spoc-email-otp',
        method: 'GET',
      }),
    }),
    checkCompanyDitUsername: builder.query({
      query: () => ({
        url: '/auth/signup/company/check-dit-username',
        method: 'GET',
      }),
    }),
    companyDitLogin: builder.mutation({
      query: payload => ({
        url: '/auth/signup/company/dit-login',
        method: 'POST',
        body: payload,
      }),
    }),
    createChangeRequest: builder.mutation({
      query: payload => ({
        url: '/user/profile/create-change-request',
        method: 'POST',
        body: payload,
      }),
    }),
    verifyEditOtp: builder.mutation({
      query: payload => ({
        url: '/user/profile/verify-otp',
        method: 'POST',
        body: payload,
      }),
    }),
    updateChangeRequest: builder.mutation({
      query: payload => ({
        url: '/user/profile/update-change-request',
        method: 'POST',
        body: payload,
      }),
    }),
    resendEditOtp: builder.mutation({
      query: payload => ({
        url: '/user/profile/resend-otp',
        method: 'POST',
        body: payload,
      }),
    }),
    getCountriesList: builder.query({
      query: () => ({
        url: '/get-country-list',
        method: 'GET',
      }),
    }),
    // states list query.
    refreshToken: builder.query({
      query: () => ({
        url: '/auth/refresh-token',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useSignUpMutation,
  useNonKydSignUpMutation,
  useNonIndnonKydSignUpMutation,
  useSignUpCompanyMutation,
  useVerifyEmailOtpMutation,
  useVerifyMobileOtpMutation,
  useVerifyEmailCompanyOtpMutation,
  useVerifyMobileCompanyOtpMutation,
  useChangeIndividualEmailMutation,
  useChangeIndividualMobileMutation,
  useChangeCompanyEmailMutation,
  useChangeCompanyMobileMutation,
  useCheckDitUsernameQuery,
  useDitSignUpMutation,
  useSetUsernameMutation,
  useVerifyUsernameMutation,
  useSetCompanyUsernameMutation,
  useVerifyCompanyUsernameMutation,
  useLazyResendMobileOtpQuery,
  useLazyResendEmailOtpQuery,
  useLazyCheckDitUsernameQuery,
  useLazyResendCompanyEmailOtpQuery,
  useLazyResendCompanyMobileOtpQuery,
  useLoginCompanyOtpMutation,
  useSendLoginOTPIndividualMutation,
  useVerifyLoginOTPIndividualMutation,
  useVerifyLoginOTPCompanyMutation,
  useCompanySubmitOtpMutation,
  useLazyResendLoginIndividualOtpQuery,
  useLazyResendLoginCompanyOtpQuery,
  useGetAssetsQuery,
  useVerifyAssetClassesMutation,
  useGetAuthSignQuery,
  useAddAuthSignsMutation,
  useCreateAdminDetailsMutation,
  useVerifyUserMutation,
  useSetAdminPasswordMutation,
  useSendOTPEmployeeMutation,
  useCreateProfileMutation,
  useSetIndividualPasswordMutation,
  useForgotPasswordOtpMutation,
  useVerifyForgotPasswordOtpMutation,
  useLazyResendForgotPasswordOtpQuery,
  useVerifySpocEmailOtpMutation,
  useLazyResendSpocEmailOtpQuery,
  useLazyCheckCompanyDitUsernameQuery,
  useCompanyDitLoginMutation,
  useCreateChangeRequestMutation,
  useVerifyEditOtpMutation,
  useUpdateChangeRequestMutation,
  useResendEditOtpMutation,
  useLazyGetCountriesListQuery,
  useLazyRefreshTokenQuery,
} = authSlice;
